<template>
	<div>
		<el-dialog :before-close='bclose' :destroy-on-close='true' :visible.sync="isDia" :close-on-click-modal="false"
			append-to-body width="80%" top="10vh" center>
			<div class="topBox">
				通话列表
				<el-button @click="bclose" style="position: absolute;right: 12%;" size="small"
					type="danger">挂断</el-button>
			</div>
			<div style="height: 60vh;overflow-y: scroll; padding: 30px 20px;" class="scrollElement">
				<video v-show="false" style="width: 100%;height: 100%;" controls autoplay id="myPlayJk" />
				<template v-for="item in jkMsg.playUrlList">
					<div class="vdBox">
						<video :id="item.code" style="width: 100%;height: 100%;" controls autoplay />
					</div>
				</template>
			</div>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button type="danger">挂断</el-button> -->
				<!-- <el-button size="small" type="primary" @click="bclose">关 闭</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ['groupId'],
		data() {
			return {
				jkobj: [],
				isDia: true,
				jkMsg: {},
				meplayer: null
			}
		},
		mounted() {
			this.$nextTick(() => {
				let agentData = {
					act: 'ma_webrtc_start_group_call',
					groupId: this.groupId
				}
				this.socketCapsApi.sendCapsSock(agentData, (e) => {
					if (e.status && e.cmd == 'ma_webrtc_start_group_call') {
						console.log('群组数据', JSON.parse(e.data))
						this.jkMsg = JSON.parse(e.data)
						this.setBy()
						setTimeout(() => {
							this.jkMsg.playUrlList.forEach((res, ind) => {
								this.getBy(res, ind)
							})
						}, 500)
					} else {
						this.$message({
							type: "error",
							message: e.msg,
						});
						// this.$emit('dioClose')
					}
				})
			})
		},
		beforeDestroy() {
			this.hangUp()
		},
		methods: {
			hangUp() {
				if (!this.jkMsg.playUrlList) {
					return
				}
				this.jkobj.forEach((res, ind) => {
					if (res) {
						res.close();
						res = null;
					}
					var local = document.getElementById(this.jkMsg.playUrlList[ind].code);
					if (local) {
						local.load();
						local.srcObject = null;
					}
				})
				let agentData = {
					act: 'ma_webrtc_stop_group_call',
					groupId: this.groupId
				}
				this.socketCapsApi.sendCapsSock(agentData, (e) => {
					if (e.status && e.cmd == 'ma_webrtc_start_group_call') {
						this.$message({
							type: "success",
							message: e.msg,
						});
					} else {
						this.$message({
							type: "error",
							message: e.msg,
						});
					}
				})
			},
			getBy(res, ind) {
				this.$nextTick(() => {
					const that = this
					const jswebrtc = document.getElementById(res.code)
					const playUrl = res.playUrl
					console.log('设备', ind, jswebrtc, playUrl)
					this.jkobj[ind] = new ZLMRTCClient.Endpoint({
						element: jswebrtc, // video 标签
						debug: true, // 是否打印日志
						zlmsdpUrl: playUrl, //流地址
						simulcast: false,
						useCamera: false,
						audioEnable: false,
						videoEnable: false,
						recvOnly: true, //拉流为true 推流为false
						resolution: {
							w: 640,
							h: 480
						}
					});
					this.jkobj[ind].on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
						console.log('ICE 协商出错')
					});
					this.jkobj[ind].on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
						console.log('播放成功', e.streams)
						clearInterval(that.timeWebRtc)
						// that.jkMsg.playUrlList[ind].loading = false
						// that.$set(that.jkMsg.playUrlList[ind],'loading',false)
					});
					this.jkobj[ind].on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
						e) { // offer anwser 交换失败
						console.log('offer anwser 交换失败', e)
					});
					this.jkobj[ind].on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
						jswebrtc.srcObject = s;
						jswebrtc.muted = false;
						console.log('获取本地流成功--拉流')
					});
					this.jkobj[ind].on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
						console.log('获取本地流失败--拉流')
					});
				})
			},
			setBy() {
				console.log(22331122, this.jkMsg.pushUrl)
				if (this.meplayer) {
					this.meplayer.close();
					this.meplayer = null;
				}
				const jswebrtc = document.getElementById('myPlayJk')
				if (jswebrtc) {
					jswebrtc.load();
					jswebrtc.srcObject = null;
				}
				const that = this
				that.meplayer = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: this.jkMsg.pushUrl, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: true,
					videoEnable: false,
					recvOnly: false, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480
					}
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
					console.log('ICE 协商出错')
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
					console.log('播放成功', e.streams)
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
					e) { // offer anwser 交换失败
					that.dropped()
					that.$message.error('拨打失败，请重试！')
					console.log('offer anwser 交换失败', e)
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
					jswebrtc.srcObject = s;
					jswebrtc.muted = true;
				});
				that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
					that.dropped()
					console.log('获取本地流失败', data)
				});
			},
			bclose() {
				this.$confirm("此操作将结束此次群组通话, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						// this.hangUp()
						this.$emit('dioClose')
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消挂断",
						});
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.vdBox {
		width: 31%;
		height: 40%;
		padding: 1%;
		background-color: black;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.topBox {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: white;
	}
</style>