<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body width="1250px" top="8vh"
    @close="closeDialog">
    <div style="height: 80vh;">
      <el-tabs style="margin-top: 10px;" v-model="activeName" lazy @tab-click="tabClick">
        <el-tab-pane label="视频监控" name="spjk">
          <div style="height: 65vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <VidoSurveillance v-if="activeName=='spjk'" :eleInfo="eleInfo" :Id="Id"
              :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="禁停区域" name="jtqy">
          <div style="height: 65vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Forbid ref="SosInfo" v-if="activeName=='jtqy'" :Id="Id" :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="报警信息" name="bjxx">
          <div style="height: 65vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <SosInfo ref="SosInfo" v-if="activeName=='bjxx'" :Id="Id" :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="云端视频" name="splb">
          <div style="height: 65vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <MonitorVideo ref="videoList" v-if="activeName=='splb'" :Id="Id" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="本地视频" name="scsp">
          <div style="height: 65vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <UploadVideo ref="UploadVideo" v-if="activeName=='scsp'" :Id="Id"
              :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="设备日志" name="sbrz" v-if=" adminInfo.userName == 'admin'">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Equipment ref="Equipment" v-if="activeName=='sbrz'" :deviceCode="deviceCode" :Id="Id" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
  import VidoSurveillance from './VideoSurveillance.vue' //视频监控
  import Forbid from './Forbid .vue' //禁停区域
  import SosInfo from './sosInfo.vue' //报警视频
  import MonitorVideo from './monitorVideo.vue' //云端视频
  import UploadVideo from './uploadVideo.vue' // 上传视频
  import Equipment from './equipment.vue' //设备日志
  export default {
    props: ['deviceCode', 'Id', 'eleInfo'],
    components: {
      VidoSurveillance,
      Forbid,
      SosInfo,
      MonitorVideo,
      UploadVideo,
      Equipment,
    },
    data() {
      return {
        activeName: 'spjk', // 默认第一屏
        dialogVisible: true,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    mounted() {
      console.log(222)
      if (this.activeName === 'scsp') {
        this.$nextTick(() => {
          this.$refs['UploadVideo'].searchElevator()
        })
      }
    },
    methods: {
      tabClick() {
        switch (this.activeName) {
          case 'spjk':
            break
          case 'jtqy':
            // this.$nextTick(() => {
            //   this.$refs['SosInfo'].onRefer()
            // })
            break
          case 'bjxx':
            this.$nextTick(() => {
              this.$refs['SosInfo'].onRefer()
            })
            break
          case 'splb':
            this.$nextTick(() => {
              this.$refs['videoList'].getVideo()
            })
            break
          case 'scsp':
            this.$nextTick(() => {
              this.$refs['UploadVideo'].searchElevator()
            })
            break
          case 'sbrz':
            this.$nextTick(() => {
              this.$refs['Equipment'].getFileList('/')
            })
            break
          default:
            break
        }
      },
      closeDialog() {
        this.dialogVisible = false
        this.$emit('closeMonitor')
      },
    },
  }
</script>

<style>
</style>
