<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="名称">
          <el-input placeholder="请输入" v-model="searchForm.name"></el-input>
        </el-form-item>
        <el-form-item label="展示状态">
          <el-select v-model="searchForm.status">
            <el-option label="展示" :value="1" :key="1"></el-option>
            <el-option label="不展示" :value="0" :key="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="OnRefer">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" @click="addDj">新增</el-button>
    </div>
    <div>
      <template v-if="noticeTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="编号" width="50px" />
          <el-table-column align="center" prop="name" label="名称" min-width="250px" />
          <el-table-column align="center" prop="pageName" label="关联页面" min-width="200px" />
          <el-table-column align="center" prop="status" label="展示状态" min-width="100px">
            <template #default="scope">
              {{scope.row.status == 1? '展示' : '不展示'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
          <el-table-column align="center" prop="updateTime" label="更新时间" min-width="150px" />
          <el-table-column fixed="right" align="center" label="操作" class="fixedRight" width="300px">
            <template slot-scope="scope">
              <el-button v-if="adminInfo.userName == 'admin'" @click="edit(scope.row)" type="warning" size="small">编辑
              </el-button>
              <el-button v-if="adminInfo.userName == 'admin'" @click="del(scope.row)" type="danger" size="small">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="noticeTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="noticePage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <el-dialog v-if='visibale' :visible.sync="visibale" :close-on-click-modal="false" append-to-body width="900px"
      :title="Tit">
      <div style="padding: 20px;height: 500px;overflow-y: auto;" class="scrollElement">
        <el-form ref="elevatorForm" :model="subForm" label-width="90px" label-position="right" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model="subForm.name" placeholder="请输入名称,不超过30字"></el-input>
          </el-form-item>
          <el-form-item label="关联页面" prop="firstId">
            <div style="display: flex;justify-content: space-between;">
              <el-select style="width: 49%;" v-model="subForm.firstId" @change="checkTwo" placeholder="请选择一级菜单">
                <el-option v-for="(item,index) in treeOne" :key="index" :label="item.resourceName"
                  :value="item.id"></el-option>
              </el-select>
              <el-select style="width: 49%;" v-model="subForm.secondId" @change="changeTwo" placeholder="请选择二级菜单"
                clearable>
                <el-option v-for="(item,index) in treeTwo" :key="index" :label="item.resourceName"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="展示状态" prop="status">
            <el-switch v-model="subForm.status" inactive-color="rgba(215, 215, 215, 1)"
              active-color="rgba(255, 118, 2, 1)" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <div class="scrollElement" style="height: 30vh;">
              <el-upload class="avatar-uploader" :action="serverUrl" :show-file-list="false" :on-success="uploadSuccess"
                :on-error="uploadError" :before-upload="beforeUpload">
              </el-upload>
              <quill-editor @focus="focus($event)" @change="onChange($event)" v-model="subForm.content"
                :options="editorOption" class="quillClass" ref="myQuillEditor" />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="small" @click='show()'>预览</el-button>
        <el-button type="primary" size="small" @click="sure()">确定</el-button>
        <el-button size="small" @click="close()">取消</el-button>
      </span>
    </el-dialog>
    <bgStock v-if='isShow' ref="bgStockVisibale" :subForm='subForm' @closeCheck="isShow = false" />
  </div>
</template>

<script>
  // 工具栏配置
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    [{
      header: 1
    }, {
      header: 2
    }], // 1、2 级标题
    [{
      list: "ordered"
    }, {
      list: "bullet"
    }], // 有序、无序列表
    [{
      script: "sub"
    }, {
      script: "super"
    }], // 上标/下标
    [{
      indent: "-1"
    }, {
      indent: "+1"
    }], // 缩进
    // [{'direction': 'rtl'}],                         // 文本方向
    [{
      size: ["small", false, "large", "huge"]
    }], // 字体大小
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }], // 标题
    [{
      color: []
    }, {
      background: []
    }], // 字体颜色、字体背景颜色
    [{
      font: []
    }], // 字体种类
    [{
      align: []
    }], // 对齐方式
    ["clean"], // 清除文本格式
    ["link", "image"] // 链接、图片、视频
  ];
  import Pagination from '@/components/Pagination'
  import bgStock from './components/bzStock.vue'
  import FileUpload from '@/components/FileUpload'
  export default {
    components: {
      Pagination,
      bgStock,
      FileUpload
    },
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        noticeTotal: 0,
        visibale: false,
        isShow: false,
        searchForm: {
          current: 1,
          size: 10
        },
        subForm: {
          status: 1,
          firstId: '',
          secondId: '',
          content: ''
        },
        editId: '',
        rules: {
          name: [{
              required: true,
              message: '名称不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              validator: this.changeName,
              trigger: 'blur'
            }
          ],
          firstId: [{
            required: true,
            message: '请选择一级菜单',
            trigger: 'change'
          }],
          status: [{
            required: true,
            message: '展示状态不能为空',
            trigger: 'change'
          }],
          content: [{
            required: true,
            message: '内容不能为空',
            trigger: 'blur'
          }],
        },
        Tit: '',
        tableData: [],
        treeOne: [],
        treeTwo: [],
        quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
        // 富文本编辑器
        editorOption: {
          //placeholder: "",
          theme: "snow", // or 'bubble'
          placeholder: "请输入",
          modules: {
            clipboard: {
              // 粘贴版，处理粘贴时候带图片
              matchers: [
                [Node.ELEMENT_NODE, this.handleCustomMatcher]
              ],
            },
            toolbar: {
              container: toolbarOptions,
              // container: "#toolbar",
              handlers: {
                image: function(value) {
                  if (value) {
                    // 触发input框选择图片文件
                    document.querySelector(".avatar-uploader input").click();
                  } else {
                    this.quill.format("image", false);
                  }
                },
              }
            }
          },
        },
        serverUrl: "/api/file/web/uploadByOSS", // 这里写你要上传的图片服务器地址
        imageUrl: '',
        images: null,
        tableLoading: true
      }
    },
    created() {
      this.getTree()
      this.OnRefer()
    },
    methods: {
      handleCustomMatcher(node, Delta) {
        console.log('eee', node, Delta)
        let ops = []
        Delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
            ops.push({
              insert: op.insert,
            })
          } else {
            if (op.insert.image.includes('file://') || (!op.insert.image.includes('.jpg')&&!op.insert.image.includes('.png')&&!op.insert.image.includes('.jpeg'))) { //本地图片会使文件file开头
              this.$message.warning('不允许粘贴图片,请手动上传')
            } else {
              ops.push({
                insert: op.insert,
              })
            }
            // this.$message({
            //   message: '不允许粘贴图片,请手动上传',
            //   type: 'warning'
            // })
          }
        })
        Delta.ops = ops
        return Delta
      },
      changeName(rule, value, callback) {
        if (value.length > 30) {
          callback(new Error('名称长度不能超过30'))
        } else {
          callback()
        }
      },
      // 富文本图片上传前
      beforeUpload(file) {
        console.log("1.", file)
        this.quillUpdateImg = true;
      },
      uploadSuccess(res, file) {
        console.log("2.", file, res.data)
        this.imageUrl = res.data.imgurl
        this.images = (this.images == null ? "" : (this.images + "#")) + this.imageUrl
        // res为图片服务器返回的数据
        // 获取富文本组件实例
        let quill = this.$refs.myQuillEditor.quill;

        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        console.log(this.imageUrl, 'imageUrl')
        quill.insertEmbed(length, "image", this.imageUrl, 'style', 'with:100px');
        // 调整光标到最后
        quill.setSelection(length + 1);
        // loading动画消失
        this.quillUpdateImg = false;
      },

      // 富文本图片上传失败
      uploadError() {
        // loading动画消失
        this.quillUpdateImg = false;
        this.$message.error("图片上传失败");
      },
      getTree(istrue) { //获取菜单列表(一级菜单)
        this.treeOne = []
        let data = {
          disabled: istrue,
        }
        this.$http
          .get('/api/system/web/resource/tree', data)
          .then((res) => {
            console.log('res123', res.data.data)
            res.data.data.children.forEach((item) => {
              this.treeOne.push(item)
            })
          })
      },
      checkTwo(data) { //(获取二级菜单数据)
        console.log(data, '一级菜单数组')
        this.subForm.secondId = ''
        this.treeTwo = this.treeOne.find(item => item.id == data).children
        console.log(this.treeTwo, '二级菜单')
      },
      changeTwo(id) {
        console.log(id, 666)
        // this.subForm.secondId = id
        this.$set(this.subForm, 'secondId', id)
        // console.log(this.subForm.secondId)
      },
      OnRefer() { //搜索
        this.tableLoading = true
        this.$http.post('/api/system/web/use/getHelpList', this.searchForm).then((res) => {
          console.log(res, '帮助中心')
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.noticeTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            });
          }
          this.tableLoading = false
        })
      },
      reset() { //重置
        this.searchForm = {
          current: 1,
          size: 10
        }
        this.OnRefer()
      },
      addDj() { //新增
        this.visibale = true
        this.Tit = '新增'
        this.subForm = {
          content: '',
          name: '',
          status: 1,
          firstId: '',
          secondId: ''
        }
      },
      edit(data) { //编辑
        console.log(data, 'data')
        this.visibale = true
        this.Tit = '编辑'
        this.editId = data.id
        this.subForm = {
          content: data.content,
          name: data.name,
          status: data.status,
          firstId: data.firstId,
          secondId: data.secondId
        }
        console.log(this.subForm, 666)
        this.treeTwo = this.treeOne.find(item => item.id == data.firstId).children
      },
      del(data) { //删除
        this.$confirm('是否继续当前操作', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确认',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/system/web/use/removeHelp?id=${data.id}`).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.OnRefer()
            }
          })
        })

      },
      close() { //取消
        this.visibale = false
      },
      sure() { //确认
        this.$refs.elevatorForm.validate(async (valid) => {
          if (valid) {
            let data = null
            console.log(this.subForm, 'this.subForm')
            if (this.Tit == '新增') {
              data = JSON.parse(JSON.stringify((this.subForm)))
            } else {
              data = {
                id: this.editId,
                ...JSON.parse(JSON.stringify((this.subForm)))
              }
            }
            console.log(data, 'data')
            this.$http.post('/api/system/web/use/saveOrUpdateHelp', data).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.OnRefer()
                this.visibale = false
              } else {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
              }
            })
          }
        })
      },
      noticePage(data) { //翻页
        this.searchform.current = data.page
        this.searchform.size = data.limit
      },
      show() { //预览
        this.isShow = true
      },
      //获取焦点事件
      focus(event) {
        console.log(event, 'event111')
        // event.enable(false) //设置富文本编辑器不可编辑
      },
      onChange(event) {
        console.log(event, 'event')
        // event.quill.enable(false) //设置富文本编辑器不可编辑
      },
      handlePhone(value) {
        console.log(value, 777)
      },
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quillClass {
    position: relative;
  }
</style>
