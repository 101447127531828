<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">图片名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">拍摄时间</span>
          </span>
          <el-date-picker v-model="searchForm.allTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input v-model="searchForm.userName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom: 20px;">
      <el-button type="danger" size="small" @click="delAll()">批量删除</el-button>
    </div>
    <div>
      <template v-if="total != 0">
        <el-row>
          <template v-for="(item, index) in tableData">
            <el-col :span="6" :key="index" class="col20">
              <div v-show="item.looks" @mousemove="item.looks=true" @mouseout="checks(item,index)"
                style="width: 290px; position: absolute;z-index: 99;background: rgba(215, 215, 215, 1);height: 30px;display: flex;align-items: center;padding: 0px 5px;justify-content: space-between;">
                <div style="height: 15px;width: 15px;background: #FFF;cursor: pointer;" v-if='item.isShow'
                  @click="checkFn(index,item,1)">
                </div>
                <div
                  style="height: 15px;width: 15px;background: #409EFF;cursor: pointer;display: flex;justify-content: center;border-radius: 2px;align-items: end;"
                  v-else @click="checkFn(index,item,2)">
                  <i class="el-icon-check" style="font-size: 10px;"></i>
                </div>
                <i class="el-icon-circle-close" style="font-size: 25px;cursor: pointer;" @click="delOne(item)"></i>
              </div>

              <div @click="openDetail(item)"  @mousemove="item.looks=true" @mouseout="checks(item,index)">
                <div class="imgBox">
                  <el-image style="width: 100%; height: 180px" :src="item.url" fit="cover" />
                  <p class="boxTime">拍摄时间：{{ item.shootingTime }}</p>
                </div>
                <h5 class="tpglH5">{{ item.name }}</h5>
                <h5 class="tpglH5">
                  {{ item.userName }} ({{
                    item.capCode.substring(
                      item.capCode.length - 4,
                      item.capCode.length
                    )
                  }})
                </h5>
              </div>
            </el-col>
          </template>
        </el-row>
        <Pagination :total="total" :layout="'total, sizes, prev, pager, next'" :page.sync="searchForm.current"
          :limit.sync="searchForm.size" @pagination="videoRulePage" />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <DetailInfo v-if="detailInfoVisible" ref="detailInfo" :info="info" @detailClose="detailClose" />
  </div>
</template>
<script>
  import Pagination from "../../../components/Pagination";
  import DetailInfo from "./components/detailInfo";
  export default {
    components: {
      Pagination,
      DetailInfo,
    },
    data() {
      return {
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        total: 0,
        detailInfoVisible: false,
        info: {},
        delarr: [], //批量删除数据
      };
    },
    filters: {},
    methods: {
      // 分页
      personnelPage(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer();
      },
      checkFn(index, data, type) { //批量删除图片数据获取
        this.tableData[index].isShow = !this.tableData[index].isShow
        if (type == 1) {
          this.delarr.push(data)
          console.log(type, '数据1')
        } else {
          this.delarr = this.delarr.filter(item => item.id != data.id)
          console.log(type, '数据2')
        }
        console.log(this.delarr, 'arr')
      },
      delAll() { //
        let data = []
        if (this.delarr.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择要删除的图片'
          })
        } else {
          this.$confirm('此操作将永久删除记录，是否继续', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delarr.forEach((item) => {
              data.push(item.id)
            })
            console.log(data, '批量删除')
            this.$http.post(`api/cap/web/images/removeIds`,data).then((res) => {
              if(res.data.code == 200){
                this.$message({
                  type:'success',
                  message:res.data.msg
                })
                this.onRefer();
              }else{
                this.$message({
                  type:'error',
                  message:res.data.msg
                })
              }
            })
          })
        }
      },
      delOne(data) { //单个删除
        this.$confirm('此操作将永久删除记录，是否继续', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(data, '单个删除')
          this.$http.delete(`/api/cap/web/sos/delCapImages?id=${data.id}`).then((res) => {
            console.log(res, 'res')
            if(res.data.code == 200){
              this.$message({
                type:'success',
                message:res.data.msg
              })
              this.onRefer();
            }else{
              this.$message({
                type:'error',
                message:res.data.msg
              })
            }
          })
        })
      },
      checks(data,index){
        if(this.delarr.find((item)=>item.id == data.id)){
          this.tableData[index].looks = true
        }else{
          this.tableData[index].looks = false
        }
      },

      // 搜索
      searchOnRefer() {
        console.log(this.searchForm, "this.searchForm");
        this.searchForm.current = 1;
        this.onRefer();
      },
      // 重置
      reset() {
        console.log("重置");
        this.searchForm = {
          current: 1,
          size: 10,
        };
        this.onRefer();
      },
      // 视频
      videoRulePage(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer();
      },
      // 获取人员方法
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm));
        data.start = this.searchForm.allTime ?
          this.searchForm.allTime[0].getTime() :
          "";
        data.end = this.searchForm.allTime ?
          this.searchForm.allTime[1].getTime() + 24 * 60 * 60 * 1000 - 1000 :
          "";
        this.$http.post("/api/cap/web/images/getCapImages", data).then((res) => {
          if (res.data.success) {
            console.log(res.data,666)
            this.delarr = []
            const {
              data: {
                data
              },
            } = res;
            data.records.forEach((item, index) => {
              data.records[index].isShow = true
              data.records[index].looks = false
            })

            this.tableData = data.records ?? [];
            this.total = data.total ?? 0;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      // 报警详情
      detail(row) {
        this.detailVisible = true;
      },

      openDetail(row) {
        this.info = row;
        this.detailInfoVisible = true;
        this.$nextTick(() => {
          this.$refs.detailInfo.init("tpxq");
        });
      },

      // 详情弹框关闭
      detailClose() {
        this.detailInfoVisible = false;
      },
    },
    created() {},
    mounted() {
      this.$nextTick(() => {
        this.onRefer();
      });
    },
  };
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    margin: 0;
    font-size: 15px;
  }

  .mapBox {
    height: 50vh;
    background: #000;
    margin-left: 20px;
    border-radius: 5px;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .col20 {
    width: 20%;
    padding: 0 15px;
    color: #fff;
    margin-bottom: 10px;

    .imgBox {
      width: 300px;
      position: relative;
      overflow: hidden;

      .boxTime {
        position: absolute;
        bottom: 4px;
        margin: 0;
        background: #5c5c5cdb;
        padding: 8px;
        font-size: 14px;
        width: 100%;
      }
    }

    h5 {
      margin: 10px 0;
      line-height: 17px;
    }
  }
</style>
