<template>
  <el-dialog title="批量修改" :visible.sync="visible" :close-on-press-escape="false" :close-on-click-modal="false"
    append-to-body width="800px" top="20vh" @close="closeDialog">
    <div class="searchBox scrollElement">
      <div class="showCenter">
        <i class="el-icon-warning-outline" style="font-size: 24px; margin-right: 10px" />
        你已选中 {{ editAllData.length }} 台电梯，将对这些电梯进行批量设置为以下填写的属性值，请慎重操作！
      </div>
      <el-form :inline="true" label-width="150px" style="margin-top: 30px;">
        <el-form-item label="阻梯功能：">
          <el-radio-group v-model="haveBanClosed" @change="changeBanClosed">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电动车阈值：">
          <el-input-number class="wid90" v-model="bikeValue" :min="0" :max="1" :precision="2" :step="0.1" label="请输入" />
          <el-tooltip class="item" effect="dark" placement="top-end">
            <template slot="content" style="width: 227px;">
              参数值越低，灵敏度越高，电动车<br />识别更频繁，误报相对较多。
            </template>
            <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="人像模糊：">
          <el-radio-group v-model="portraitBlur">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="人体画框：">
          <el-radio-group v-model="headBorder">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="微信扫码呼救：">
          <el-radio-group v-model="wechatSos">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="煤气罐阈值：">
          <el-input-number class="wid90" v-model="gasTankValue" :min="0" :max="1" :precision="2" :step="0.1" label="请输入" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmForm">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
  export default {
    components: {},
    props: {
      // initData: {
      //   type: Object,
      //   default: () => {},
      // },
      editAllData: Array
    },
    data() {
      return {
        loading: true,
        visible: true,
        haveBanClosed: '', //阻梯
        headBorder: '', //人体画款
        portraitBlur: '', //人像模糊
        bikeValue: '', //电瓶车
        wechatSos: '', //微信呼救
        gasTankValue:'',//煤气罐阈值

      }
    },

    mounted() {},

    methods: {
      confirmForm() {
        // if (this.haveBanClosed === '') {
        //   this.$message.error('请先选择开启阻梯功能或关闭阻梯功能')
        //   return
        // }
        this.$confirm("您正在批量修改电梯参数，一旦确认将无法撤销，是否继续？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            // this.bikeValue
            this.$http.post(
              `/api/ele/web/elevatorInfo/updateBatchBanClosed?haveBanClosed=${this.haveBanClosed}&bikeValue=${this.bikeValue}&portraitBlur=${this.portraitBlur}&headBorder=${this.headBorder}&wechatSos=${this.wechatSos}&gasTankValue=${this.gasTankValue}`,
              this
              .editAllData).then((res) => {
              if (res.data.success) {
                // this.$message.success(res.data.msg)
                if (this.haveBanClosed) {
                  this.$message.success('稍后球机自动重启！')
                } else {
                  this.$message.success(res.data.msg)
                }
                this.visible = false
                this.$emit('batchEditClose')
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }).catch(() => {});

      },
      // 改变阻梯
      changeBanClosed(value) {
        if (value == 1) {
          this.$confirm("开启阻梯功能前，请先确认摄像头是否能够正常拍摄到电梯门下半部分及轿厢地板画面？如果可以，请点击确认！", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.haveBanClosed = value
            }).catch(() => {
              this.haveBanClosed = ''
            });
        }
      },
      closeDialog() {
        this.visible = false
        this.$emit('batchEditClose')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .showCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .showCenterRadio {
    width: 80%;
    margin: 30px auto;
    display: flex;
    align-items: center;
  }
</style>
