<template>
  <div>
    <!-- 高德地图 -->
    <div id="mapWB" :style="{ width: width, height: height }"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    point: {
      type: Array,
      default: () => [114.086228, 22.548266],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "40vh",
    },
    capsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      initPoint: [114.086228, 22.548266],
      markers: [],
      // capsList: [], // 帽子列表
      geoCoder: null,
      infoWindow: '',
    };
  },
  filters: {},
  methods: {
    mapInit() {
      this.map = new AMap.Map("mapWB", {
        zoom: 14, // 级别
        center: this.point,
        resizeEnable: true,
      });
      
      this.geoCoder = new AMap.Geocoder()
      this.setMapMarker();
    },
    // 设置锚点
    setMapMarker() {
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: require('@/assets/aqm.png'), // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = [];
      //信息窗口实例
      this.infoWindow = new AMap.InfoWindow({
        anchor: "bottom-center",
        offset: new AMap.Pixel(0, -30)
      });
      this.capsList.forEach((item, index) => {
        if (item.capCode && item.capStatus) {
          // 遍历生成多个标记点
          let marker = new AMap.Marker({
            map: this.map,
            zIndex: 9999999,
            icon: icon, // 添加 Icon 实例
            offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
            position: [Number(item.yPoint), Number(item.xPoint)], // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
          });
          this.geoCoder.getAddress([Number(item.yPoint), Number(item.xPoint)], (status, result) => {
              if (result.regeocode) {
                // this.$set(this.capsList[index], 'address', result.regeocode.formattedAddress)
                item.address = result.regeocode.formattedAddress
                let content =
                    '<div style="padding: 0.2em 15px;width: 280px;margin: 10px 0;">'
                    + '<p style="line-height:26px; font-size:14px;margin:6px">'
                    + '<span style="width:80px; display:inline-block;">姓  名：</span>'
                    + item.userName + '('+ item.capCode + ')' + '</p>'
                    + '<p style="line-height:26px; font-size:14px;margin:6px">'
                    + '<span style="width:80px; display:inline-block;">所属公司：</span>'
                    + item.orgName + '</p>'
                    + '<p style="line-height:26px; font-size:14px;margin:6px">'
                    + '<span style="width:80px; display:inline-block;">地  址：</span>'
                    + item.address + '</p>'
                    + '</div>'
                marker.content = content
              }
          })
          marker.on("click", this.markerClick)
          marker.emit('click', { target: marker });// 此处是设置默认出现信息窗体
          makerList.push(marker);
        }
      });
      this.map.add(makerList);
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    },
    // 控制标记的信息窗体的显示
    markerClick (e) {
      this.infoWindow.setContent(e.target.content);
      this.infoWindow.open(this.map, e.target.getPosition());
    }
  },
  created() {
    // 打开监控
    window.handleDetail = (deviceMarkId)=>{
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mapInit();
    });
  },
};
</script>
<style lang="scss" scoped>
#mapWB {
  width: 100%;
  border-radius: 5px;
}
/deep/.amap-info-content{
    background: #20bacd!important;
    .amap-info-close{
        color: #fff;
    }
    p{
        color: #fff;
    }
}
</style>
