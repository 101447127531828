<template>
  <div>
    <el-dialog v-if="visible" :title="tit" :visible.sync="visible" :close-on-click-modal="false"
      :width="tit == '充值' ?'700px' : '800px'" @close="bakc">
      <div class="scrollElement elefrom" :style="">
        <el-form label-position="right" label-width="150px" ref="formlist" :model="formlist" :rules="rules">
          <el-form-item label="公司名称" v-if="tit == '充值'">
            <span>{{amountform.orgName}}</span>
          </el-form-item>
          <el-form-item label="账户余额">
            <span class="formAmount">{{amountform.amount}} 元</span>
          </el-form-item>
          <el-form-item label="提现金额" v-if="tit == '提现申请'" prop="payAccount">
            <el-input placeholder="请输入大于等于1000的金额" style="width: 80%;" v-model.trim="formlist.payAccount" />
          </el-form-item>
          <el-form-item label="收款账号" v-if="tit == '提现申请'">
            <div class="form_input">
              <el-input style="width: 80%;" v-model="amountform.bankName" disabled />
              <el-input style="width: 80%;" v-model="amountform.orgName" disabled />
              <el-input style="width: 80%;" v-model="amountform.bankNumber" disabled />
              <el-input style="width: 80%;" v-model="amountform.bankOf" disabled />
            </div>
          </el-form-item>
          <el-form-item label="充值金额" v-if="tit == '充值'" prop="payAccount">
            <el-input placeholder="请输入充值金额" style="width: 80%;" v-model.trim="formlist.payAccount"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" v-if="tit == '充值'">
            <div class="pay_from">
              <div class="box-card" v-for="(item,index) in payList" @click="checkCard(index)"
                :class="{'info': item.label == cardName}">
                <!-- <div class="dataCard"> -->
                <img :src="item.img" class="img_style" />
                <div class="label_info">{{item.label}}</div>
                <img
                  src="https://test-mallshop.oss-cn-shenzhen.aliyuncs.com/mall/images/2024-01/08/202401081520265538.png"
                  class="img_position" />
                <!-- </div> -->
              </div>
              <!-- <el-card class="box-card" v-for="(item,index) in payList" @change="checkCard(index)" >
              <div class="dataCard">
                <img :src="item.img" style="width: 20px;height: 20px;" />
                <div>{{item.label}}</div>
              </div>
            </el-card> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="srue">确认</el-button>
        <el-button @click="backclose">取消</el-button>
      </span>
      <DuigongStock v-if="visibleStock" ref="DuigongStock" :code="codes" :datas="amountform" @close="stockbakc"
        @closeDialog="bakc" />
      <!-- 短信验证 -->
      <el-dialog v-if="Dialogvisible" title="短信验证" :visible.sync="Dialogvisible" append-to-body
        :close-on-click-modal="false" width="500px" top="25vh" @close="Dialogvisible = false">
        <div style="margin: 20px;">
          <span>手机号: {{ adminInfo.phone }}</span>
          <el-form ref="form" :model="form" :rules="formrules">
            <el-form-item label="短信验证" prop="code">
              <el-input placeholder="请输入短信验证码" style="width: 98%;" v-model="form.code">
                <template slot="append">
                  <el-button @click="send" :disabled="(!timeShow )"
                    type="primary">{{ timeShow ? '发送验证码' : `${time}秒后发送` }}</el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="DialogSure">确认</el-button>
          <el-button @click=" Dialogvisible = false">取消</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
  import DuigongStock from './DuigongStock.vue'
  import infoStock from '../../../cwgl/txsh/components/infoStock.vue'
  export default {
    components: {
      DuigongStock,
      infoStock
    },
    props: ["tit", "Id", "amountform"],
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        cardName: '',
        cardIndex: 3,
        visible: false,
        visibleStock: false,
        Dialogvisible: false,
        timeShow: true,
        time: '',
        codes: '',
        timershow: null,
        // datas:{},
        form: {
          code: ''
        },
        formlist: {
          payAccount: '',
          type: ''
        },
        rules: {
          payAccount: [{
            required: true,
            // message:'充值金额不能为空',
            validator: this.payValid,
            trigger: 'blur',
          }, ],
        },
        formrules: {
          code: [{
            required: true,
            message: '请输入短信验证码',
            trigger: 'blur',
          }, ],
        },
        payList: [
          // {
          //   img: 'https://test-mallshop.oss-cn-shenzhen.aliyuncs.com/mall/images/2024-01/08/202401081458303876.png',
          //   value:2,
          //   label: '支付宝'
          // },
          // {
          //   img: 'https://test-mallshop.oss-cn-shenzhen.aliyuncs.com/mall/images/2024-01/08/202401081502085126.png',
          //   value:1,
          //   label: '微信'
          // },
          {
            img: 'https://test-mallshop.oss-cn-shenzhen.aliyuncs.com/mall/images/2024-01/08/202401081502298955.png',
            value: 3,
            label: '对公转账'
          }
        ],
        // amountform: {

        // }
      }
    },
    mounted() {
      console.log(this.amountform, 555)
      // this.getDate()
      this.visible = true
    },
    created() {
      let EndTime = sessionStorage.getItem('rechargeEndTime');
      if (EndTime) {
        this.downTime(EndTime);
      }
    },
    methods: {
      DialogSure() {
        const data = {
          amount: this.formlist.payAccount,
          orgId: this.Id,
          accountName: this.amountform.orgName,
          bank: this.amountform.bankName,
          bankNumber: this.amountform.bankNumber,
          bankOf: this.amountform.bankOf,
          userName: this.adminInfo.userName,
          orgName: this.amountform.name,
          code: this.form.code
        }
        
        this.$refs.form.validate(async (valid) => {
          console.log(valid, 857)
          if (valid) {
            this.$http.post('/api/system/web/withdrawal/applicationForWithdrawal', data).then(res => {
              
              if (res.data.code == 200) {
                this.$emit('sureclose')
                this.visible = false
                this.Dialogvisible = false
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          }
        })
      },
      payValid(rule, value, callback) {
        let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
        // let ref = /^[0-9]$/
        if (this.tit == '充值') {
          console.log(value, 'value')
          if (value == null || value === '') {
            callback(new Error('充值金额不能为空!'))
          } else if (value <= 0) {
            callback(
              new Error('充值金额必须大于0')
            )
          } else if (!reg.test(value)) {
            callback(
              new Error('充值金额只能输入两位小数')
            )
          }
        } else {
          if (value == null || value === '') {
            callback(new Error('提现金额不能为空!'))
          } else if (value < 1000 || value > this.amountform.amount) {
            callback(
              new Error('请输入大于等于1000且小于账户余额的金额')
            )
          } else if (!reg.test(value)) {
            callback(
              new Error('提现金额只能输入两位小数')
            )
          }
        }
        // else if(ref.test(value)){
        //   new Error('请输入')
        // }
        callback()
      },
      downTime(timer) {
        if (timer) {
          this.time = Math.ceil((JSON.parse(timer) - new Date().getTime()) / 1000);
          this.timeShow = false;
          this.timershow = setInterval(() => {
            if (this.time > 0) {
              this.time--
            } else {
              this.timeShow = true;
              clearTimeout(this.timershow)
              sessionStorage.removeItem('rechargeEndTime')
              return
            }
          }, 1000)
        }
      },
      send() {
        let clicktime = new Date().getTime() + 60000;
        sessionStorage.setItem('rechargeEndTime', JSON.stringify(clicktime));
        console.log(clicktime, 'clicktime')
        // this.data.count = Math.ceil((JSON.parse(time) - new Date().getTime())/1000);  //取出计时
        // if (this.time == 0) {
        // this.time = 60
        this.$http.get(`/api/system/web/sms/sendInitiatingWithdrwalSms?phone=${this.adminInfo.phone}`).then(res => {
          
          if (res.data.code == 200) {
            this.downTime(clicktime)
            this.$message({
              type: "success",
              message: res.data.msg
            })
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            })
          }
        })
      },
      // getDate() {
      //   this.$http.get(`/api/system/web/account/getOrgAccountInfo?orgId=${this.Id}`).then(res => {
      //     console.log(res, 'res2')
      //     if (res.data.code == 200) {
      //       this.amountform = {
      //         bankName: res.data.data.bankName,
      //         bankOf: res.data.data.bankOf,
      //         bankNumber: res.data.data.bankNumber,
      //         amount: res.data.data.amount,
      //         orgName: res.data.data.orgName,
      //         taxCode: res.data.data.taxCode
      //       }
      //       console.log(this.amountform, 111)
      //     }
      //   })
      // },
      stockbakc() {
        console.log(this.visibleStock)
        this.visibleStock = false
      },
      backclose() {
        this.visible = false
        this.$emit('close')
      },
      bakc() {
        this.$emit('sureclose')
        this.visible = false
      },
      srue() {
        this.$refs.formlist.validate(async (valid) => {
          console.log(valid, 857)
          if (valid) {
            if (this.tit == '充值') {
              this.formlist.type = 3
              // this.payList[this.cardIndex].value
              // console.log(this.formlist.type, 'type')
              const data = {
                orgId: this.Id,
                amount: this.formlist.payAccount,
                type: this.formlist.type,
                payAccount: this.amountform.orgName,
                payCard: this.amountform.bankNumber,
                payBank: this.amountform.bankName + this.amountform.bankOf,
                userName: this.adminInfo.name
              }
              
              this.$http.post('/api/system/web/recharge/addRechargeRecord', data).then(res => {
                console.log(res, 666)
                if (res.data.code == 200) {
                  this.visibleStock = true
                  this.codes = res.data.msg
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg
                  })
                }
              })
            } else {
              // console.log(this.amountform, 'amountform')
              // console.log(this.formlist, 'type')
              // console.log(this.adminInfo,'info')
              // const data = {
              //   amount: this.formlist.payAccount,
              //   orgId: this.Id,
              //   accountName: this.amountform.orgName,
              //   bank: this.amountform.bankName,
              //   bankNumber: this.amountform.bankNumber,
              //   bankOf: this.amountform.bankOf,
              //   userName: this.adminInfo.userName,
              //   orgName: this.amountform.orgName,
              //   code:this.form.code
              // }
              // 
              this.Dialogvisible = true
              // this.$http.post('/api/system/web/withdrawal/applicationForWithdrawal', data).then(res => {
              //   
              //   if (res.data.code == 200) {
              //     this.$emit('close')
              //     this.visible = false
              //   }
              // })
            }
          }
        })
      },
      checkCard(index) {
        console.log(index, 'index')
        this.cardIndex = index
        this.cardName = this.payList[index].label
      }
    }
  }
</script>

<style lang="scss" scoped>
  .elefrom {
    overflow-y: scroll;
    padding: 20px 10%;
  }

  .box-card {
    width: 134px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #D8DEEB;
    color: black;
    // padding: 0px 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }

  .img_style {
    width: 30px;
    height: 30px;
    margin-left: 6px;
  }

  .label_info {
    font-size: 16px;
    margin-left: 20px;
  }

  .img_position {
    position: fixed;
    height: 46px;
    width: 134px;
  }

  .form_input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
  }

  .pay_from {
    display: flex;
    justify-content: space-between;
    width: 80%
  }

  .info {
    border: 1px solid pink;
  }

  .dataCard {
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>