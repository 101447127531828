<template>
	<div v-loading="loading">
		<th style="display: flex;flex-wrap: wrap;">
			<tr class="content" v-for="(item,index) in itemArr">
				<!-- <el-row>
          <el-col :span="12"> -->
				<td style="width: 150px;">区域{{index+1}}</td>
				<td style="width: 480px; ">
					<!-- <el-image style="width: 200px; height: 200px;margin: 0 auto;" :src="item.url" fit="contain"></el-image> -->
					<canvas :id="'Canvas'+index" width="320" height="240"></canvas>
				</td>
				<!-- </el-col>
        </el-row> -->
			</tr>
		</th>
	</div>
</template>

<script>
	export default {
		props: ['Id'],
		data() {
			return {
				itemArr: [],
				loading: true
			}
		},
		created() {
			this.onRefer()
		},
		methods: {
			onRefer() {
				this.loading = true
				const data = {
					bikeId: this.Id
				}
				this.$http.post('api/bike/web/region/getDeviceRegion', data).then((res) => {
					console.log(res, '查看禁停区域')
					if (res.data.code == 200) {
						this.itemArr = res.data.data

						setTimeout(() => {
							this.itemArr.forEach((as, ind) => {
								let name = "Canvas" + ind
								let data = JSON.parse(as.region)
								data.forEach(qs => {
									qs.x = qs.x / 2
									qs.y = qs.y / 2
								})
								setTimeout(() => {
									this.doDraw(as.url, name, 2, data)
								}, ind * 100)
							})
						}, 500)

						setTimeout(() => {
							this.loading = false
						}, 2000)

						this.$forceUpdate()
					}
				})
			},
			doDraw(imageUrl, name, type, data) {
				this.banMa = []
				this.isBanMaShow = false
				//  获取canvas
				var canvas = document.getElementById(name)

				//  重复获取
				canvas = document.getElementById(name)
				//  可以理解为一个画笔，可画路径、矩形、文字、图像
				var context = canvas.getContext('2d')
				var img = new Image()
				img.src = imageUrl
				// img.src =
				// 	"https://test-fileserver.jiangsu-10.zos.ctyun.cn/newPublic/images/2024-07-10/19:17:31.380/微信图片_20240710191324.png"
				// //  加载图片
				img.onload = () => {
					if (img.complete) {
						let w = type == 1 ? 640 : 320
						let h = type == 1 ? 480 : 240
						//  根据图像重新设定了canvas的长宽
						canvas.setAttribute('width', w)
						canvas.setAttribute('height', h)
						//  绘制图片
						context.drawImage(img, 0, 0, w, h)

						// 渲染图形
						if (data) {
							console.log(data)

							context.strokeStyle = 'red'
							context.lineWidth = 2
							context.beginPath()
							data.forEach((res, ind) => {
								context.lineTo(res.x, res.y)
							})
							context.closePath()
							context.strokeStyle = 'pink'
							context.stroke()
						}
					}
				}


			},
		}
	}
</script>

<style scoped>
	.content {}

	.content td {
		border: 1px solid #00f6ff;
		height: 200px;
		color: #FFFFFF;
		align-content: center;
		padding: 20px 0;
	}
</style>
