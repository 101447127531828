<template>
  <div>
    <el-dialog
      title="电梯更换记录"
      :visible.sync="BJVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
      width="1300px"
      top="10vh"
      center
      @close="closeDialog"
    >
      <div class="flex-container">
        <div class="left-container">
          <el-input
            placeholder="输入关键字进行过滤"
            clearable
            v-model="filterText"
          >
          </el-input>
          <div class="tree-container">
            <el-tree
              ref="tree"
              :data="treeData"
              :filter-node-method="filterNode"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </div>
        <div class="right-container">
          <div class="page-top">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-form-item>
                <span slot="label">
                  <span class="lab-span">品牌</span>
                </span>
                <el-input
                  v-model="formInline.brand"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <span slot="label">
                  <span class="lab-span">型号</span>
                </span>
                <el-input
                  v-model="formInline.model"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <span slot="label">
                  <span class="lab-span">生产厂商</span>
                </span>
                <el-input
                  v-model="formInline.manufacturer"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <span slot="label">
                  <span class="lab-span">状态</span>
                </span>
                <el-select
                  v-model="formInline.status"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in statusArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onRefer(true)"
                  >查询</el-button
                >
                <el-button type="warning" @click="reset">重置</el-button>
              </el-form-item>
            </el-form>

            <div style="display: flex">
              <el-button type="success" @click="add" size="small"
                >新增</el-button
              >
              <!-- <el-button  type="danger" @click="deletBefore" size="small">批量删除</el-button> -->
            </div>
          </div>
          <div class="table-container">
            <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-top: 20px; margin-bottom: 20px"
              max-height="350"
            >
              <!-- <el-table-column
            type="selection"
            width="55">
          </el-table-column> -->
              <el-table-column type="index" label="序号" width="50px">
              </el-table-column>
              <el-table-column
                align="center"
                prop="changeTime"
                label="更换时间"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="changeUser"
                label="更换人员"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="changeReason"
                label="更换原因"
              >
              </el-table-column>
              <el-table-column align="center" prop="categoryName" label="部件类型">
              </el-table-column>
              <el-table-column align="center" prop="name" label="部件名称">
              </el-table-column>
              <el-table-column align="center" prop="brand" label="品牌">
              </el-table-column>
              <el-table-column
                align="center"
                prop="manufacturer"
                label="生产厂商"
              >
              </el-table-column>
              <el-table-column align="center" prop="model" label="型号">
              </el-table-column>
              <el-table-column align="center" prop="status" label="状态">
                <template slot-scope="scope">
                  {{
                    statusArr.filter(
                      (item) => item.value == scope.row.status
                    )[0]
                      ? statusArr.filter(
                          (item) => item.value == scope.row.status
                        )[0].label
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                prop="address"
                label="操作"
                width="300px"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status == 2 || scope.row.status == 3"
                    @click="details(scope.row, 4)"
                    type="primary"
                    size="small"
                    >更换部件</el-button
                  >
                  <el-button
                    @click="details(scope.row, 1)"
                    type="primary"
                    size="small"
                    >查看</el-button
                  >
                  <el-button
                    @click="details(scope.row, 3)"
                    type="primary"
                    size="small"
                    >编辑</el-button
                  >
                  <el-button
                    @click="deletBefore(false, scope.row.id)"
                    type="danger"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              :total="dictionarTotal"
              :page.sync="formInline.current"
              :limit.sync="formInline.size"
              @pagination="dictionarPage"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-if="dialogVisible"
      :title="tit"
      :visible.sync="dialogVisible"
      width="950px"
      top="10vh"
    >
      <div style="height: 60vh; overflow-y: scroll" class="scrollElement">
        <el-form
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          label-width="90px"
          :model="fign"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="更换时间:" prop="changeTime">
                <el-date-picker
                  :style="{ width: '306px' }"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="dialogType == 1"
                  v-model="fign.changeTime"
                  type="datetime"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="更换事由:" prop="changeType">
                <el-select
                  v-model="fign.changeType"
                  :disabled="dialogType == 1"
                  :style="{ width: '306px' }"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in typeArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="更换人员:" prop="changeUser">
                <el-input
                  :disabled="dialogType == 1"
                  v-model="fign.changeUser"
                  placeholder="请输入"
                  class="einp"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="更换原因:" prop="changeReason">
                <el-input
                  :disabled="dialogType == 1"
                  v-model="fign.changeReason"
                  placeholder="请输入"
                  class="einp"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="部件类型:" prop="categoryName">
                <el-cascader
                  :style="{ width: '306px' }"
                  v-model="fign.categoryName"
                  :disabled="dialogType == 1"
                  filterable
                  :props="cascaderProps"
                  :options="treeData"
                  ref="cascader"
                  @change="changeTree"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="部件名称:" prop="name">
                <el-select
                  v-model="fign.name"
                  :disabled="dialogType == 1 || !fign.categoryId"
                  :style="{ width: '306px' }"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in parts"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="品牌:" prop="brand">
                <el-input
                  disabled
                  :value="nameData.brand"
                  placeholder="请输入"
                  class="einp"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生产厂商:" prop="manufacturer">
                <el-input
                  disabled
                  :value="nameData.manufacturer"
                  placeholder="请输入"
                  class="einp"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="型号:" prop="model">
                <el-input
                  disabled
                  :value="nameData.model"
                  placeholder="请输入"
                  class="einp"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>寿命影响因素与使用临界</div>
        <div class="custom-table">
          <div class="custom-table-header">
            <div class="td custom-table-tr-1">影响因素</div>
            <div class="td custom-table-tr-2">限度单位</div>
            <div class="td custom-table-tr-3">预警值</div>
            <div class="td custom-table-tr-4">告警值</div>
          </div>
          <div class="custom-table-body">
            <div class="custom-table-tr custom-table-tr-1">
              <div class="td td-1">
                <el-input
                  disabled
                  value="安装时长"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-2">
                <el-input
                  disabled
                  value="实际使用时长"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-3">
                <el-input
                  disabled
                  value="制动次数"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-4">
                <el-input
                  disabled
                  value="开关门次数"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="custom-table-tr custom-table-tr-2">
              <div class="td td-1">
                <el-input
                  disabled
                  value="月"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-2">
                <el-input
                  disabled
                  value="月"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-3">
                <el-input
                  disabled
                  value="次"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div class="td td-4">
                <el-input
                  disabled
                  value="次"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="custom-table-tr custom-table-tr-3">
              <div
                class="td"
                v-for="(value, name) in nameData.influenceCriticality.yjz"
              >
                <el-input
                  disabled
                  :value="nameData.influenceCriticality.yjz[name]"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="custom-table-tr custom-table-tr-3">
              <div
                class="td"
                v-for="(value, name) in nameData.influenceCriticality.gjz"
              >
                <el-input
                  disabled
                  :value="nameData.influenceCriticality.gjz[name]"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
var validateName = (rule, value, callback) => {
  let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,8}$/;
  // true：必填  false：非必填
  inpYz(
    rule,
    value,
    callback,
    true,
    operation,
    "可输入中文，字母，数值，长度为2-8"
  );
};
var validatememo = (rule, value, callback) => {
  let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,16}$/;
  inpYz(
    rule,
    value,
    callback,
    false,
    operation,
    "可输入中文，字母，数值，长度为2-16"
  );
};

import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    myVisible: Boolean,
    initData: {
      type: Object,
      default: () => {},
    },
  },
  // model: {
  //     prop: 'myVisible',
  //     event: 'changeValue'
  // },
  data() {
    return {
      BJVisible: this.myVisible,
      statusArr: [],
      typeArr: [],
      parts: [],
      visible: true,
      multipleSelection: [],
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
      },
      tableData: [],
      tableAllData: [],
      // 分页参数
      dictionarTotal: 0,

      dialogType: 1,
      dialogData: {},
      tit: "",
      dialogVisible: false,
      fign: {},
      // 表单限制
      rules: {
        changeTime: [
          {
            required: true,
            message: "此选项不能为空",
            trigger: "blur",
          },
        ],
        changeType: [
          {
            required: true,
            message: "此选项不能为空",
            trigger: "blur",
          },
        ],
        changeUser: [
          {
            required: true,
            message: "此选项不能为空",
            trigger: "blur",
          },
        ],
        categoryName: [
          {
            required: true,
            message: "此选项不能为空",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "此选项不能为空",
            trigger: "blur",
          },
        ],
      },
      cascaderProps: {
        value: "label",
        emitPath: false,
      },
      filterText: "",
      categoryId: '',
      // tree
      treeData: [],
      treeData1: [
        {
          label: "曳引系统",
          children: [
            {
              label: "曳引机",
              children: [
                {
                  label: "减速箱",
                },
                {
                  label: "制动器",
                },
                {
                  label: "曳引轮",
                },
                {
                  label: "电动机",
                },
                {
                  label: "联轴器",
                },
                {
                  label: "盘车手轮",
                },
                {
                  label: "机座",
                },
              ],
            },
            {
              label: "曳引钢丝绳",
              children: [
                {
                  label: "主机钢丝绳",
                },
                {
                  label: "主机复合钢带",
                },
              ],
            },
            {
              label: "导向轮",
              children: [
                {
                  label: "导向轮",
                },
              ],
            },
            {
              label: "反绳轮",
            },
          ],
        },
        {
          label: "导向系统",
          children: [
            {
              label: "导轨",
              children: [
                {
                  label: "导轨支架",
                },
                {
                  label: "导轨",
                },
              ],
            },
            {
              label: "导靴",
              children: [
                {
                  label: "滑动导靴",
                },
                {
                  label: "滚动导靴",
                },
              ],
            },
          ],
        },
        {
          label: "轿厢系统",
          children: [
            {
              label: "轿顶",
              children: [
                {
                  label: "钢丝绳绳头组件",
                },
                {
                  label: "轿顶电气部分",
                },
                {
                  label: "安全窗",
                },
                {
                  label: "安全钳拉条组件",
                },
                {
                  label: "散热系统",
                },
                {
                  label: "照明",
                },
                {
                  label: "润滑组合",
                },
              ],
            },
            {
              label: "轿厢",
              children: [
                {
                  label: "内呼系统",
                },
                {
                  label: "轿厢对讲",
                },
                {
                  label: "轿厢显示板",
                },
                {
                  label: "轿厢操作箱",
                },
                {
                  label: "轿厢照明",
                },
                {
                  label: "轿厢警示牌",
                },
              ],
            },
            {
              label: "轿底",
              children: [
                {
                  label: "称重装置",
                },
                {
                  label: "安全钳",
                },
              ],
            },
          ],
        },
        {
          label: "门系统",
          children: [
            {
              label: "轿厢门",
              children: [
                {
                  label: "门机组件",
                },
                {
                  label: "机械锁",
                },
                {
                  label: "轿门滑轮",
                },
                {
                  label: "轿门滑块",
                },
              ],
            },
            {
              label: "层门",
              children: [
                {
                  label: "层门头组件",
                },
                {
                  label: "应急开锁装置",
                },
                {
                  label: "层门滑块",
                },
                {
                  label: "层门滑轮",
                },
                {
                  label: "层门传动装置",
                },
                {
                  label: "层门自闭力装置",
                },
              ],
            },
            {
              label: "开关门系统及门附属零部件",
              children: [
                {
                  label: "光幕",
                },
                {
                  label: "安全触板",
                },
                {
                  label: "地坎",
                },
              ],
            },
          ],
        },
        {
          label: "重量平衡系统",
          children: [
            {
              label: "重量补偿装置",
              children: [
                {
                  label: "随行电缆",
                },
                {
                  label: "对重块",
                },
              ],
            },
          ],
        },
        {
          label: "电力拖动系统",
          children: [
            {
              label: "曳引电动机",
              children: [
                {
                  label: "永磁同步电机",
                },
                {
                  label: "蜗轮蜗杆异步电机",
                },
              ],
            },
            {
              label: "供电系统",
              children: [
                {
                  label: "供电箱",
                },
                {
                  label: "接触器",
                },
                {
                  label: "继电器",
                },
              ],
            },
            {
              label: "速度反馈装置",
              children: [
                {
                  label: "速度装置",
                },
              ],
            },
            {
              label: "电动机调速装置",
              children: [
                {
                  label: "测速机",
                },
                {
                  label: "编码器",
                },
              ],
            },
          ],
        },
        {
          label: "电气控制系统",
          children: [
            {
              label: "操纵箱",
              children: [
                {
                  label: "电气开关",
                },
              ],
            },
            {
              label: "召唤箱",
              children: [
                {
                  label: "内呼板",
                },
                {
                  label: "外呼板",
                },
                {
                  label: "井道召唤通讯板",
                },
              ],
            },
            {
              label: "位置显示装置",
              children: [
                {
                  label: "LED显示板",
                },
              ],
            },
            {
              label: "控制柜",
              children: [
                {
                  label: "控制柜",
                },
              ],
            },
            {
              label: "平层装置",
              children: [
                {
                  label: "磁感式",
                },
                {
                  label: "光电式",
                },
              ],
            },
          ],
        },
        {
          label: "安全保护系统",
          children: [
            {
              label: "限速器",
              children: [
                {
                  label: "摆锤式限速器",
                },
                {
                  label: "离心式限速器",
                },
              ],
            },
            {
              label: "涨紧装置",
              children: [
                {
                  label: "悬挂式涨紧装置",
                },
                {
                  label: "悬臂式涨紧装置",
                },
                {
                  label: "涨紧轮",
                },
                {
                  label: "涨紧轮轴承",
                },
              ],
            },
            {
              label: "安全钳",
              children: [
                {
                  label: "渐进式安全钳",
                },
                {
                  label: "瞬时式安全钳",
                },
              ],
            },
            {
              label: "缓冲器",
              children: [
                {
                  label: "液压缓冲器",
                },
                {
                  label: "弹簧缓冲器",
                },
                {
                  label: "聚氨酯缓冲器",
                },
              ],
            },
            {
              label: "超速保护装置",
              children: [
                {
                  label: "超速保护装置",
                },
              ],
            },
            {
              label: "供电系统断相错相保护装置",
              children: [
                {
                  label: "相序保护继电器",
                },
                {
                  label: "断相保护装置",
                },
                {
                  label: "错相保护装置",
                },
              ],
            },
            {
              label: "超越上下极限装置",
              children: [
                {
                  label: "强迫减速开关",
                },
                {
                  label: "限位开关",
                },
                {
                  label: "极限开关",
                },
              ],
            },
            {
              label: "层门锁与轿门电气联锁装置",
              children: [
                {
                  label: "层门锁与轿门电气联锁装置",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "resourceName",
      },
      id: null,
    };
  },
  computed: {
    nameData() {
      let data = this.parts.filter((item) => item.name == this.fign.name);
      if (data.length > 0) {
        data = data[0];
        data.influenceCriticality =
          typeof data.influenceCriticality == "object"
            ? data.influenceCriticality
            : JSON.parse(data.influenceCriticality);
      } else {
        data = {
          brand: "",
          manufacturer: "",
          model: "",
          influenceCriticality: {
            yjz: {
              azsc: "", // 安装时长
              sjsysc: "", // 实际使用时长
              zdcs: "", // 制动次数
              kgmcs: "", // 开关门次数
            },
            gjz: {
              azsc: "", // 安装时长
              sjsysc: "", // 实际使用时长
              zdcs: "", // 制动次数
              kgmcs: "", // 开关门次数
            },
          },
        };
      }
      return data;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "fign.categoryName": {
      handler: function (value) {
        if (this.fign.name) {
          this.fign.name = "";
        }
        this.getParts(this.fign.categoryId);
      },
    },
  },
  methods: {
    changeTree(value) {
      this.fign.categoryId = this.$refs["cascader"].getCheckedNodes()[0].data.value
      this.fign.partsCategoryId = this.$refs["cascader"].getCheckedNodes()[0].data.value
    },
    getParts(categoryId) {
      this.$http
        .post("/api/ele/web/parts/list", { current: 1, size: 99999, categoryId})
        .then((res) => {
          if (res.data.success) {
            this.parts = res.data.data.records;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 关闭弹框
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树的勾选方法
    nodeClick(data, node) {
      this.childNodesChange(node);
    },
    childNodesChange(node) {
      let len = node.childNodes.length;
      if (len > 0) {
        for (let i = 0; i < len; i++) {
          if (!node.checked) {
            node.childNodes[i].checked = false;
          } else {
            node.childNodes[i].checked = true;
          }
          this.childNodesChange(node.childNodes[i]);
        }
      }
    },
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page;
      this.formInline.size = data.limit;
      this.onRefer();
    },

    // 弹出框提交
    dioSub() {
      if (this.dialogType == 1) {
        this.dialogVisible = false;
      } else {
        this.$refs.ruleForm.validate((value) => {
          if (value) {
            this.saveOrUpdate();
          }
        });
      }
    },
    saveOrUpdate() {
      let influenceCriticality = JSON.stringify(
        this.nameData.influenceCriticality
      );
      const data = {
        ...this.fign,
        manufacturer: this.nameData.manufacturer,
        brand: this.nameData.brand,
        model: this.nameData.model,
        influenceCriticality,
        eleId: this.initData.id,
      };
      if (this.dialogType == 3) {
        data.id = this.dialogData.id;
      } else if (this.dialogType == 4) {
        // data.id=this.dialogData.id
        data.upId = this.dialogData.id;
      }
      this.$http
        .post("/api/ele/web/parts/changeRecord/saveOrUpdate", data)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.dialogVisible = false;
            this.onRefer();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    onRefer(ishm) {
      if (ishm) {
        this.formInline.current = 1;
      }
      this.$http
        .post("/api/ele/web/parts/changeRecord/list", {
          eleId: this.initData.id,
          ...this.formInline,
          // type: this.filterText,
          partsCategoryId: this.categoryId
        })
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records;
            this.dictionarTotal = res.data.data.total;
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleNodeClick(data, node) {
      if (node.isLeaf) {
        this.filterText = data.label;
        this.categoryId = data.value
        this.onRefer(true);
      }
    },
    // 重置按钮事件
    reset() {
      this.formInline = {
        current: 1,
        size: 10,
        brand: "",
        model: "",
        manufacturer: "",
        status: "",
      };
      this.filterText = "";
      this.categoryId = ''
      this.onRefer();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    details(data, openType) {
      
      let { changeTime, changeType, changeUser, changeReason, type, name, categoryName, partsCategoryId } =
        data;
        
      this.dialogType = openType;
      this.tit =
        openType == 1
          ? "部件更换记录详情"
          : openType == 3
          ? "编辑部件更换记录"
          : "更换部件";
      this.dialogData = data;
      this.dialogVisible = true;
      this.fign = {
        changeTime,
        changeType: changeType + "",
        changeUser,
        changeReason,
        type,
        name,
        categoryName,
        partsCategoryId
      };
      setTimeout(() => {
        this.fign.name = name;
      }, 0);
    },
    add() {
      this.dialogType = 2;
      this.tit = "添加部件更换记录";
      this.dialogVisible = true;
      this.restFign();
    },
    restFign() {
      this.fign = {
        changeTime: "",
        changeType: "",
        changeUser: "",
        changeReason: "",
        type: "",
        name: "",
      };
    },
    deletBefore(isBatch = true, ids) {
      if (isBatch) {
        if (this.multipleSelection.length > 0) {
          ids = this.multipleSelection.map((item) => item.id);
        } else {
          this.$message.error("请先勾选设备");
          return false;
        }
      }
      this.$confirm("此操作将永久删除记录，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delet(ids);
        })
        .catch(() => {});
    },
    //删除
    delet(id) {
      this.$http
        .post("/api/ele/web/parts/changeRecord/delete", { id })
        .then((res) => {
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.onRefer(true);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getDictionary(codes = ["ELE_PARTS_STATUS", "ELE_PARTS_CHANGE_TYPE"]) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${codes}`)
        .then((res) => {
          this.statusArr = res.data.data.ELE_PARTS_STATUS;
          this.typeArr = res.data.data.ELE_PARTS_CHANGE_TYPE;
        });
    },
    // 去除hasChildren属性
    getNewData(data) {
      data.forEach((item, index) => {
        item.label = item.title;
        item.categoryId = item.id;
        item.categoryName = item.title;
        if (item.children && item.children.length > 0) {
          this.getNewData(item.children);
        }
      });
      return data;
    },
    getTree() {
      this.$http.get("/api/ele/web/partsCategory/tree?status=").then((res) => {
        if (res.data.success) {
          this.treeData = this.getNewData(res.data.data);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.onRefer();
    this.getTree();
    this.getDictionary();
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  height: 700px;
}
.left-container {
  padding: 0 20px;
  margin-right: 20px;
}
.tree-container {
  width: 260px;
  max-height: 540px;
  margin: 20px 0 0 0;
  color: #fff;
  flex: 0 0 auto;
  overflow-y: scroll;
}
.right-container {
  position: relative;
  flex: 1;
}
.table-container {
  position: absolute;
  width: 100%;
}
.custom-table {
  margin: 20px 34px 20px 0;
}
.custom-table-header {
  display: flex;
  color: #fff;
  background-color: #04e3ef;
}
.custom-table-body {
  display: flex;
}
.td {
  box-sizing: border-box;
  text-align: center;
  margin: 10px;
}

.custom-table-tr-1,
.custom-table-tr-2 {
  width: 180px;
}
.custom-table-tr-3,
.custom-table-tr-4 {
  width: 240px;
}
.td-1,
.td-2,
.td-3,
.td-4 {
  height: 40px;
  line-height: 40px;
}
.treeD {
  width: 100%;
  height: 300px;
}

.page-top {
  width: 100%;
  margin-bottom: 20px;
}

.page-bom {
}
</style>
