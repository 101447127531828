<template>
  <div class="jkBox">
    <div class="monitorTitle">
      <p :class="screenType > 6 ? (screenType > 12 ? 'smallBox' : 'middleBox') : 'bigBox' " class="overName"
        :title="eleInfo.name">
        摄像头名称：{{ eleInfo.name }}
      </p>
      <el-image fit="contain" class="delMonitorBtn" :src="require('@/assets/shutdown.png')"
        style="width: 23px;padding-right: 15px;" @click="delMMonitor(eleInfo)" />
    </div>
    <!-- 在线 -->
    <div v-if="isStatus" class="instabilityStatus" style="height: 100%">
      <img :src="require('@/assets/eleStatus.png')" style="width: 50%; z-index: 99; object-fit: contain" />
      <span class="eleStatusTips"
        :class="screenType > 12 ? (screenType > 20 ? 'eleStatusTipssmall' : 'eleStatusTipsmiddle') : 'eleStatusTipsbig'">当前设备信号不稳定，暂时无法查看实时监控</span>
    </div>
    <div v-else style="height: 100%">
      <!-- <editionC1
        class="playBox"
        :class="screenType > 4 ? 'smallPlayBox' : 'bigPlayBox'"
        v-if="!isOldJk"
        :jkData="jkData"
        :isCall="false"
        :playNum="playNum"
      /> -->

      <!-- <editionC1 v-if="!isOldJk" class="playBox" :class="screenType > 4 ? 'smallPlayBox' : 'bigPlayBox'"
        :jkData='jkData' :id="eleInfo.id" :percentage="percentage" @changePercentage="changePercentage" :isCall="false"
        :playNum="playNum" /> -->

      <editionC2 class="playBox" :class="screenType > 4 ? 'smallPlayBox' : 'bigPlayBox'" :jkData="jkData" :eleType="eleInfo.type"
        :id="eleInfo.id1" :percentage="percentage" @changePercentage="changePercentage" :isCall="false"
        :playNum="playNum" :key="playNum"/>

      <!-- <editionC1
      v-if="!isOldJk"
      :jkData='jkData'
      :percentage="percentage"
      @changePercentage="changePercentage"
      :id="eleInfo.id"
      :isCall="true"
      :playNum="1"/>
      <editionC2
      v-else :jkData='jkData'
      :id="eleInfo.id"
      :percentage="percentage"
      @changePercentage="changePercentage"
      :isCall="true"
      :playNum="1"/> -->
      <!-- <editionC2 :jkData='jkData' /> -->
    </div>
  </div>
</template>

<script>
  import editionC1 from "./editionC1.vue"; // 电梯监控 eleMonitoring
  import editionC2 from "./editionC2.vue"; // 电梯监控 eleMonitoring

  export default {
    props: ["eleInfo", "playNum", "screenType"],
    components: {
      editionC1,
      editionC2,
    },
    data() {
      return {
        isOldJk: null,
        jkData: null,
        isBegin: true,
        random: Math.floor(Math.random() * 10000),

        percentage: 25,
        isStatus: true,
      };
    },
    methods: {
      delMMonitor(item) {
        this.$emit('delMMonitor', item)
      },
      changePercentage(data) {
        this.percentage = data;
      },
      getStreamStatus() {
        console.log(this.eleInfo.id, "eleInfo");
        this.$http
          .get(`api/bike/web/getStreamStatus?id=${this.eleInfo.id1}&type=${this.eleInfo.type}`)
          .then((res) => {
            if (res.data.success) {
              this.percentage = res.data.data ? 75 : 50;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      playJk() {
        let agentData = {
          act: "ca_start_rtsp",
          type: this.eleInfo.type,
          device_id: this.eleInfo.deviceCode,
        };
        this.socketCarportApi.sendCarportSock(agentData, (e) => {
          if (e.status) {
            if (e.hb) {
              this.percentage = 25;
            }
            if (e.cmd == "ca_start_rtsp" && e.data) {
              let data = JSON.parse(e.data);
              this.percentage = 50;
              this.getStreamStatus();
              this.isOldJk = true;
              this.jkData = data;
              this.isBegin = false;
            }
          } else {
            this.$message.error(e.msg);
          }
        });
      },
    },
    created() {
      console.log("我是电梯监控通话的容器MonitorCarportBox", this.eleInfo);
    },
    mounted() {
      console.log(this.eleInfo.status, "this.eleInfo.status");
      if (this.eleInfo.status == 10) {
        this.isStatus = true;
      } else {
        this.isStatus = false;
        this.playJk();
      }
    },
    destroyed() {
      console.log("监控销毁了");
    },
  };
</script>

<style lang="scss" scoped>
  .imgbox {
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  .playBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
  }

  .smallPlayBox {
    height: 100%;
  }

  .bigPlayBox {
    height: 100%;
  }

  .jkBox {
    width: 100%;
    height: 100%;
    background: #000;
    flex-direction: column;
    align-items: start;
    color: #fff;
  }

  .instabilityStatus {
    background: #000;
    position: relative;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .smallBox {
    font-size: 12px;
    margin: 5px;
  }

  .middleBox {
    font-size: 15px;
    margin: 5px;
  }

  .bigBox {
    font-size: 15px;
    margin: 10px;
  }

  .eleStatusTips {
    font-weight: bolder;
    margin-top: 20px;
    font-size: 18px;
    white-space: nowrap;
  }

  .eleStatusTipssmall {
    margin-top: 0px;
    transform: scale(0.4)
  }

  .eleStatusTipsmiddle {
    transform: scale(0.6)
  }

  .eleStatusTipsbig {
    transform: scale(0.8)
  }

  .overName {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 10px;
  }

  .monitorTitle {
    opacity: 0;
    transition: opacity 1s;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    background: #00000066;
    display: flex;
    justify-content: space-between;
  }

  .jkBox:hover .monitorTitle {
    opacity: 1;
    transition: opacity 1s;
  }
</style>