<template>
  <el-dialog title="楼层学习记录" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="1400px"
    top="8vh" @close="closeDialog">
    <span type="info" class="floorinfoType" @click="changeName" v-if="unitData.length == 0">
      设置楼层别名
    </span>
    <div class="searchBox scrollElement" style="height: 65vh;overflow-y: scroll; padding-right: 20px">
      <el-table :data="unitData" v-loading="tableLoading" ref="multipleTable" border height="55vh"
        style="width: 100%; margin-top: 20px">
        <el-table-column type="index" label="序号" align="center" width="50px" />
        <el-table-column prop="createTime" label="学习时间" min-width="150px" align="center" />
        <el-table-column label="状态" min-width="120px" align="center">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? "未使用" : "使用" }}
          </template>
        </el-table-column>
        <el-table-column label="来源" min-width="150px" align="center">
          <template slot-scope="scope">
            {{ scope.row.source ? scope.row.source : "自学" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="450px">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)" type="primary" size="small">详情</el-button>
            <el-button @click="upd(scope.row)" type="warning" size="small">编辑楼层名称</el-button>
            <el-button @click="application(scope.row)" type="warning" size="small">应用楼层记录</el-button>
            <el-button v-if="scope.row.status === 0" @click="del(scope.row.id)" type="danger"
              size="small">删除</el-button>
            <el-button v-if="scope.row.status !== 0 && adminInfo.userName == 'admin'" @click="stop(scope.row.id)" type="danger"
              size="small">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination style="margin-top:15px" :total="unitTotal" :page.sync="unitQuery.current" :limit.sync="unitQuery.size"
        @pagination="unitPage" />
      <AnotherName v-if="nameVisable" @close="nameDialog" :Id="Ids" :num="num"/>
    </div>
    <el-dialog :title="title" :visible.sync="floorVisible" :close-on-click-modal="false" append-to-body width="1400px"
      top="8vh"  @close="closeFloorDialog">
      <div class="searchBox scrollElement" style="height: 68vh" >
        <div class="dataList">
          <el-table :data="floorList" ref="floorList" border style="width: 100%; margin-top: 20px;color: white;"
            max-height="500px" @cell-click="handleCurrentChange">
            <el-table-column type="index" label="序号" align="center" width="50px" />
            <el-table-column prop="floor" label="默认楼层名称" min-width="150px" align="center" />
            <el-table-column prop="floorName" align="center" label="楼层别名" min-width="150px">
              <template slot-scope="scope">
                <span v-if="floorType !== 'edit'">
                  {{ scope.row.floorName }}</span>
                <el-input v-else placeholder="请输入楼层" v-model="scope.row.floorName" @input="changeFloor(scope.$index)"
                  @blur="blurFloorName(scope.$index, scope.row, scope.column)">
                </el-input>
                <span v-show="false">
                  {{ floorList[scope.$index].floorName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="heightAltitude" label="海拔高度" min-width="150px" align="center">
              <template slot-scope="scope">
                <span v-if="floorType !== 'edit'">{{
                    scope.row.heightAltitude
                  }}</span>
                <template v-else>
                  <p v-if="!scope.row.altitudeType" @click="cellClick( scope.$index, scope.row, scope.column)">
                    {{ scope.row.heightAltitude }}
                  </p>
                  <el-input v-else :ref="'altitudeInput_' + scope.$index" placeholder="请输入楼层"
                    v-model="scope.row.heightAltitude" @input="changeAltitude(scope.$index)"
                    @blur="blurAltitude(scope.$index, scope.row, scope.column)">
                  </el-input>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="height" label="楼层高度" min-width="150px" align="center">
              <template slot-scope="scope">
                <span v-if="floorType !== 'edit'">{{ scope.row.height }}</span>
                <template v-else>
                  <p v-if="!scope.row.heightType" @click="cellClick( scope.$index, scope.row, scope.column)">
                    {{ scope.row.height }}
                  </p>
                  <el-input v-else :ref="'heightInput_' + scope.$index" placeholder="请输入楼层" v-model="scope.row.height"
                    @input="changeHeight(scope.$index)" @blur="blurHeight(scope.$index, scope.row, scope.column)">
                  </el-input>
                </template>
                <span v-show="false">
                  {{ floorList[scope.$index].height }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="信号强度" prop="signal" align="center" min-width="100px">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" >
        <el-button v-if="floorType === 'detail'" type="primary" @click="applicationOwn">应用楼层记录</el-button>
        <el-button v-if="floorType === 'edit'" type="primary" @click="editFloor">确 定</el-button>
        <el-button v-if="floorType === 'application'" type="primary" @click="applicationFloor">应 用</el-button>
        <el-button type="indo" @click="closeFloorDialog">取 消</el-button>
      </div>
    </el-dialog>
    <FloorElevator v-if="floorElevatorVisible" ref="FloorElevator" @transferElevator="selectOtherResult" @changeElevatorDialog="floorElevatorVisible = false" />
    <div slot="footer" class="dialog-footer" >
      <el-button type="primary" @click="closeDialog">确 定</el-button>
      <el-button type="indo" @click="closeDialog">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from "../../../../components/Pagination";
  import FloorElevator from "./floorElevator";
  import AnotherName from './anotherName .vue'
  export default {
    components: {
      Pagination,
      FloorElevator,
      AnotherName
    },
    data() {
      return {
        loading: true,
        visible: false,
        unitData: [],
        type: "",
        unitTotal: 0,
        unitQuery: {
          current: 1,
          size: 10,
        },
        fosName:'',
        selectRow: [],
        addBrandCallback: null, // 弹窗回调
        title: "", // 楼层学习标题
        floorVisible: false, // 楼层学习弹框
        floorList: null, // 楼层信息
        floorType: "", // 楼层操作类型
        applicationItem: null, // 应用电梯
        floorElevatorVisible: false,
        floorId: null,
        nameVisable:false,//设置别名窗口
        Ids:'',//电梯id
        num:'',//电梯楼层
        floorHeightInit: [], // 楼层海拔
        heightMapInit: [], // 楼层高度
        floorStatus: 0, // 楼层记录状态
        floorHeightChange: true,
        adminInfo:JSON.parse(sessionStorage.getItem('adminInfo')),
        initFloorAlias: [], // 初始别名
        tableLoading: true
      };
    },
    props: {
      eleInfo: {
        type: Object,
        default: () => {},
      },
    },

    mounted() {},

    methods: {
      // 父字典id，父字典数据，回调
      init(callback) {
        this.addBrandCallback = callback;
        this.visible = true;
        this.loading = false;
        this.$nextTick(() => {
          this.getPersonnel();
        });
      },

      // 单位分页
      unitPage(data) {
        this.unitQuery.current = data.page;
        this.unitQuery.size = data.limit;
        this.getPersonnel();
      },

      // 获取单位列表
      getPersonnel() {
        const data = JSON.parse(JSON.stringify(this.unitQuery));
        data.elevatorId = this.eleInfo.id;
        this.tableLoading = true
        this.$http.post("/api/ele/web/floor/getFloor", data).then((res) => {
          const {
            data: {
              data
            },
          } = res;
          if (res.data.code === 200) {
            this.unitData = data.records ?? [];
            this.unitTotal = data.total ?? 0;
            this.search = false;

            this.$nextTick(() => {
              if (this.unitTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          this.tableLoading = false
        });
      },
      closeDialog() {
        this.visible = false;
      },
      detail(item) {
        this.title = "楼层详情";
        this.floorVisible = true;
        this.floorId = item.id;
        this.floorType = "detail";
        // 别名
        const floorAlias = item.floorAlias ? JSON.parse(item.floorAlias) : [];
        // 海拔高度
        const floorHeight = item.floorHeight ?
          JSON.parse(item.floorHeight).data : [];
        // 层高
        const heightMap = item.heightMap ? JSON.parse(item.heightMap).data : [];
        this.floorList = heightMap;
        floorHeight.forEach((item, index) => {
          this.floorList[index].heightAltitude = item.height;
          this.floorList[index].index = index;
        });
        //信息强度
        const signalValue = item.signalValue ? JSON.parse(item.signalValue).data : [];
        console.log('signalValue', signalValue)
        signalValue.forEach((item, index) => {
          this.floorList[index].signal = item.rSSI;
        });
        if (floorAlias.data && floorAlias.data.length > 0) {
          floorAlias.data.forEach((item, index) => {
            this.floorList[index].floorName = item.name;
            this.floorList[index].index = index;
          });
        }
      },
      //设置楼层别名
      changeName() {
        this.nameVisable=true
        this.Ids=this.eleInfo.id
        this.num={
          onfloor:this.eleInfo.floorHeight-(this.eleInfo.underFloor),
          unfloor:this.eleInfo.underFloor != 0 ? -(this.eleInfo.underFloor) : 0
        }
        this.eleInfo.floorHeight+this.eleInfo.underFloor
      },
      nameDialog(){
        this.nameVisable=false
      },
      upd(item) {
        console.log(item, 666)
        this.title = "修改楼层别名";
        this.floorVisible = true;
        this.floorType = "edit";
        this.floorId = item.id;
        this.floorStatus = item.status
        // 别名
        const floorAlias = item.floorAlias ? JSON.parse(item.floorAlias) : { data: [] };
        this.initFloorAlias = JSON.parse(JSON.stringify(floorAlias))
        // 海拔高度
        this.floorHeightInit = item.floorHeight ?
          JSON.parse(item.floorHeight).data : [];
        // 层高
        const heightMap = item.heightMap ? JSON.parse(item.heightMap).data : [];
        this.heightMapInit = item.heightMap ?
          JSON.parse(item.heightMap).data : [];
        this.floorList = heightMap;
        this.floorHeightInit.forEach((item, index) => {
          this.floorList[index].heightAltitude = item.height;
          this.floorList[index].index = index;
        });
        if (floorAlias.data && floorAlias.data.length > 0) {
          floorAlias.data.forEach((item, index) => {
            this.floorList[index].floorName = item.name;
            this.floorList[index].index = index;
          });
        }
        this.floorList.forEach((item, index) => {
          this.floorList[index].altitudeType = false;
          this.floorList[index].heightType = false;
        })
      },
      // 改变修改状态
      handleCurrentChange(row, column, cell) {},
      cellClick(index, row, column) {
        if (column.label === '海拔高度') {
          row.altitudeType = !row.altitudeType;
          this.$set(this.floorList, index, row)
        }
        if (column.label === '楼层高度') {
          row.heightType = !row.heightType;
          this.$set(this.floorList, index, row)
        }
        this.$nextTick(() => {
          if (column.property === 'height') {
            this.$refs['heightInput_' + row.index].focus()
          } else {
            this.$refs['altitudeInput_' + row.index].focus()
          }
        })
      },
      // 修改楼层别名
      changeFloor(index) {
        if (
          this.floorList[index].floorName &&
          !/^[\u4E00-\u9FA5a-zA-Z0-9]{0,10}$/.test(
            this.floorList[index].floorName
          )
        ) {
          console.log(this.floorList[index].floorName,111)
          this.$message.error("可输入中文，数字，大小写字母，长度不可超过10，不可输入特殊字符");
          return;
        }
        this.fosName = this.floorList[index].floorName
        console.log(this.floorList[index].floorName,222)
        const list = this.floorList[index];
        this.$set(this.floorList, index, list);
      },
      blurFloorName(index, row, column) {
        if (
          this.floorList[index].floorName &&
          !/^[\u4E00-\u9FA5a-zA-Z0-9]{0,10}$/.test(
            this.floorList[index].floorName
          )
        ) {
          this.floorList[index].floorName = this.fosName ? this.fosName : ''
          this.$message.error("可输入中文，数字，大小写字母，长度不可超过10，不可输入特殊字符");
          return;
        }
        const list = this.floorList[index];
        this.$set(this.floorList, index, list);
      },

      // 修改海拔高度
      changeAltitude(index) {
        if (this.floorList[index].heightAltitude != "") {
          if (!/^[\d-]/.test(this.floorList[index].heightAltitude)) {
            this.$message.error("海拔高度只能输入数值");
            return;
          }
        }
        const list = this.floorList[index];
        this.$set(this.floorList, index, list);
      },
      blurAltitude(index, row, column) {
        if (this.floorList[index].heightAltitude != "") {
          if (
            !/^(-)?\d+(\.\d{0,2})?$/.test(this.floorList[index].heightAltitude)
          ) {
            this.$message.error("海拔高度只能输入数值且最多保留两位小数");
            this.floorList[index].heightAltitude =
              this.floorHeightInit[index].height;
            const list = this.floorList[index];
            this.$set(this.floorList, index, list);
            return;
          }
        }
        this.floorList.map((item, listIndex) => {
          // 目标楼层往下
          if (index < listIndex) {
            this.floorList[listIndex].heightAltitude = (Number(this.floorList[listIndex - 1].heightAltitude) +
              Number(this.floorList[listIndex - 1].height)).toFixed(2)
            const list = this.floorList[index]
            this.$set(this.floorList, index, list)
          } else {
            // 目标楼层往上
            let numHeight = 0
            if (listIndex != index) {
              // 从第一层到目标楼层的高度
              for (let lowIndex = listIndex; lowIndex < index; lowIndex++) {
                numHeight = numHeight + Number(this.floorList[lowIndex].height)
              }
              this.floorList[listIndex].heightAltitude = (Number(this.floorList[index].heightAltitude) - Number(
                numHeight)).toFixed(2)
              numHeight = 0
              const list = this.floorList[index]
              this.$set(this.floorList, index, list)
            }
          }
        });
        row.altitudeType = !row.altitudeType;
        this.$set(this.floorList, index, row)
      },
      // 修改楼层高度
      changeHeight(index) {
        if (this.floorList[index].height.indexOf("-") > -1) {
          this.$message.error("楼层高度不能小于0!");
          return;
        }
        if (index + 1 < this.floorList.length) {
          if (
            !/^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/.test(
              this.floorList[index].height
            )
          ) {
            this.$message.error("海拔高度只能输入数值且最多保留两位小数!");
            return;
          }
        }
        const list = this.floorList[index];
        this.$set(this.floorList, index, list);
      },
      blurHeight(index, row, column) {
        if (index + 1 < this.floorList.length) {
          if (
            !/^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/.test(
              this.floorList[index].height
            )
          ) {
            this.floorList[index].height = this.heightMapInit[index].height;
            const list = this.floorList[index];
            this.$set(this.floorList, index, list);
          }
        }
        this.floorList.map((item, listIndex) => {
          if (index < listIndex) {
            this.floorList[listIndex].heightAltitude = (Number(this.floorList[listIndex - 1].heightAltitude) +
              Number(this.floorList[listIndex - 1].height)).toFixed(2)
            const list = this.floorList[index]
            this.$set(this.floorList, index, list)
          }
        });
        row.heightType = !row.heightType;
        this.$set(this.floorList, index, row)
        // this.floorHeightChange = true
      },
      // 修改楼层别名
      editFloor() {
        const floorAlias = [];
        const floorHeight = [];
        const heightMap = [];
        let subPass = true;
        this.floorList.forEach((qs, index) => {
          floorAlias.push({
            name: qs.floorName ? qs.floorName : "",
            floor: qs.floor ? qs.floor : "",
          });
          if (qs.heightAltitude == "" && qs.heightAltitude == 0) {
            setTimeout(() => {
              this.$message.error(
                `楼层${this.floorList[index].floor}的海拔高度必须大于0且不能为空`
              );
            }, 10);
            subPass = false;
            return;
          }
          if (index + 1 < this.floorList.length) {
            if (qs.height == "" && qs.height == 0) {
              setTimeout(() => {
                this.$message.error(
                  `楼层${this.floorList[index].floor}的楼层高度必须大于0且不能为空`
                );
              }, 10);
              subPass = false;
              return;
            }
          }
          floorHeight.push({
            height: qs.heightAltitude ? qs.heightAltitude : 0,
            floor: qs.floor ? qs.floor : "",
          });
          heightMap.push({
            height: qs.height ? qs.height : 0,
            floor: qs.floor ? qs.floor : "",
          });
        });
        const data = {
          id: this.floorId,
          floorAlias: JSON.stringify({
            data: floorAlias,
          }),
          floorHeight: JSON.stringify({
            data: floorHeight,
          }),
          heightMap: JSON.stringify({
            data: heightMap,
          }),
        };

        if (subPass) {
          this.$http.post("api/ele/web/floor/edit", data).then((res) => {
            if (res.data.success) {
              let restartType = false
              if (this.floorStatus) {
                if (this.initFloorAlias.data.length > 0) {
                  this.initFloorAlias.data.map((item, index) => {
                    if (item.name != floorAlias[index].name) {
                      restartType = true
                    }
                  })
                  if (restartType) {
                    console.log('object');
                    this.restart(this.eleInfo.deviceCode)
                  }
                }

                // if (floorAlias.find(item => item.name != '')) {
                //   this.restart(this.eleInfo.deviceCode)
                // }
              }
              this.getPersonnel();
              this.$message.success(res.data.msg);
              this.floorVisible = false;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      },
      // 应用楼层记录
      application(item) {
        this.title = "应用楼层记录";
        this.applicationItem = item;
        this.floorElevatorVisible = true;
        this.$nextTick(() => {
          this.$refs.FloorElevator.init();
        });
      },

      // 重启球机
      restart(deviceCode) {
        if (this.socketApi.isheart) {
          let agentData = {
            act: "device_restart",
            device_id: deviceCode,
          };
          this.socketApi.sendSock(agentData, (e) => {
            if (e.cmd === "device_restart" && e.status) {
              this.$message.success("球机正在重启");
            } else {
              this.$message.error(e.msg);
            }
          });
        }
      },
      selectOtherResult(data) {
        this.selectElevator = data;
        this.title = "确认使用楼层记录";
        this.floorVisible = true;
        this.floorType = "application";
        // 别名
        const floorAlias = this.applicationItem.floorAlias ?
          JSON.parse(this.applicationItem.floorAlias) : [];
        // 海拔高度
        const floorHeight = this.applicationItem.floorHeight ?
          JSON.parse(this.applicationItem.floorHeight).data : [];
        // 层高
        const heightMap = this.applicationItem.heightMap ?
          JSON.parse(this.applicationItem.heightMap).data : [];
        this.floorList = heightMap;
        floorHeight.forEach((item, index) => {
          this.floorList[index].heightAltitude = item.height;
          this.floorList[index].index = index;
        });
        if (floorAlias.length > 0) {
          floorAlias.forEach((item, index) => {
            this.floorList[index].floorName = item.name;
            this.floorList[index].index = index;
          });
        }
      },
      // 应用自身记录
      applicationOwn() {
        this.$http
          .get(
            `/api/ele/web/floor/resetFloorData?device_id=${this.eleInfo.deviceCode}&floor_id=${this.floorId}`
          )
          .then((res) => {
            if (res.data.success) {
              this.getPersonnel();
              this.restart(this.eleInfo.deviceCode);
              this.closeFloorDialog();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      // 应用电梯
      applicationFloor() {
        const data = {
          id: this.applicationItem.id,
          deviceId: this.selectElevator.deviceCode,
          elevatorId: this.selectElevator.id,
        };

        this.$http.post("api/ele/web/floor/add", data).then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.newApplicationFloor(this.selectElevator.deviceCode, res.data.data)
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      // 对应电梯应用楼层
      newApplicationFloor(deviceId, floorId) {
        this.$http
          .get(
            `/api/ele/web/floor/resetFloorData?device_id=${deviceId}&floor_id=${floorId}`
          )
          .then((res) => {
            if (res.data.success) {
              this.restart(deviceId);
            } else {
              this.$message.error(res.data.msg);
            }
            this.getPersonnel();
            this.floorVisible = false;
          });
      },
      stop(id){//停用
        this.$http.post(`/api/ele/web/floor/outageRecord?id=${id}`).then((res) => {
          if (res.data.success) {
            this.$message.success('稍后球机自动重启');
            this.getPersonnel();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      del(id) {
        this.$http.delete(`api/ele/web/floor/remove?ids=${id}`).then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.getPersonnel();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      closeFloorDialog() {
        this.floorVisible = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .searchBox {
    /deep/.el-form-item__label {
      color: #00f6ff !important;
      font-size: 14px;
    }

    .searchBtn {
      margin-left: 15px;
    }
  }

  // .floorinfoType {
  //   color: white;
  //   display: flex;
  //   justify-content: end;
  //   margin-right: 22px;
  //   text-decoration: none;
  //   outline: none;
  //   cursor: pointer;
  // }
</style>
