<!-- <template>
  <div class="app-container">
    <div ref="Mychart" :style="{width:'100%',height:'100%'}"></div>
  </div>
</template> -->
<template>
  <div class="echart">
    <template>
        <div class="divType">
          <span>井道信号强度</span>
          <span>123</span>
        </div>
        <div class="divType">
          <sapn>运营商：{{this.Data.networkOperator}}</sapn>
          <span>BAND:{{this.Data.band}}</span>
        </div>
        <div class="divType">
          <span>曲线信号</span>
          <div>
            <span>RSSI:{{this.rSSIInfo}}</span>
            <span>RSRP:{{this.rSRPInfo}}</span>
            <span>RSRQ:{{this.rSRQInfo}}</span>
          </div>
        </div>
    </template>
    <div class="echart" id="mychart" :style="myChartStyle">
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props:['curveInfo'],
  data() {
    return {
      myChart: {},
      xData: [],
      //横坐标y
      yRSSI:[],
      yRSRP:[],
      yRSRQ:[],
      rSSIInfo:'',
      rSRPInfo:'',
      rSRQInfo:'',
      yData: [],
      Data: [],
      //人数数据
      myChartStyle: { float: "left", width: "100%", height: "342px" }
      //图表样式
    };
  },
  mounted() {
    this.initEcharts();
    console.log(this.curveInfo,66)
  },
  methods: {
    initEcharts() {
      this.Data=JSON.parse(this.curveInfo.networkData)
     const list=this.Data.signal

     for(var i=0;i<list.length ; i++){
       this.xData.push(list[i].date)
       this.yRSSI.push(list[i].rSSI)
       this.yRSRP.push(list[i].rSRP)
       this.yRSRQ.push(list[i].rSRQ)
     }
     this.rSSIInfo=list[list.length-1].rSSI
     this.rSRPInfo=list[list.length-1].rSRP
     this.rSRQInfo=list[list.length-1].rSRQ
     console.log(this.Data,333)
      const option = {
        xAxis: {
          data: this.xData,
          // position:'center'
        },
        yAxis: {
            min:-170,
            max:-40,
        },
        series: [
          {
            name:'RSSI',
            data: this.yRSSI,
            type: "line" ,
            smooth: true,
            // markPoint:{
            //   data:[
            //     {type:'max',name:'最大值'},
            //     {type:'min',name:'最小值'}
            //   ]
            // },
            markLine:{
              data:[{type:'average',name:'平均值'}]
            }
          }
        ],
      };
      this.myChart = echarts.init(document.getElementById("mychart"));
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {this.myChart.resize();});}}
};
</script>
<!-- <script>
  import echarts from 'echarts'
  export default{
    data(){
      return{
        msg:'曲线图例',
        Mychart:''
      }
    },
    mounted() {
      this.drawLine()
      console.log(this.$ref.Mychart,3)
    },
    methods:{
      drawLine(){
        var echarts=require("echarts")
        let myChart=echarts.init(this.$ref.Mychart)
        myChart.setOption({
          title:{
            text:'曲线',
            subtext:'实例'
          },
          tooltip:{
            trigger:'axis'
          },
          legend:{
            data:["最高","最低"]
          },
          toolbox:{
            show:true,
            feature:{
              dataZoom:{
                yAxisIndex:'none'
              },
              dataView:{readOnly:false},
              magicType:{tpye:['line','bar']},
              restore:{},
              saveAslmage:{}
            }
          },
          xAxis:{
            type:'category',
            boundaryGap:false,
            data:['1','2','3','4','5','6']
          },
          yAxis:{
            type:'value',
            axisLabel:{
              formatter:'{value}'
            }
          },
          series:[
            {
              name:'手机前7月的数据',
              type:'line',
              data:[12,13,25,42,53,90],
              markPoint:{
                data:[
                  {type:'max',name:'最大值'},
                  {type:'min',name:'最小值'}
                ]
              },
              markLine:{
                data:[{type:'average',name:'平均值'}]
              }
            },
            {
              name:'电视机前7月数据',
              type:'line',
              data:[31,41,15,66,45,50],
              markPoint:{
                data:[
                  {type:'max',name:'最大值'},
                  {type:'min',name:'最小值'}
                ]
              },
              markLine:{
                data:[{type:'average',name:'平均值'}]
              }
            }
          ]
        })
      }
    },
  }
</script> -->

<style scoped>
  .echart{
    color:white;
    background-color: #0C6D81;
  }
  .divType{
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }
</style>
