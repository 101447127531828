<template>
  <div>
    <el-row>
      <el-col :span="8" style="padding-right: 15px">
        <div class="page-top">
          <div style="display: flex">
            <el-input placeholder="请输入内容" clearable v-model="filterText" style="margin-right: 10px" class="input-with-select">
              <el-select v-model="select" slot="prepend" placeholder="请选择" @change="changeSelect">
                <el-option label="人员" value="1"></el-option>
                <el-option label="设备" value="2"></el-option>
              </el-select>
            </el-input>
            <el-button type="primary" class="searchBtn" @click="searchOnRefer"
              >搜索</el-button
            >
          </div>
        </div>
        <div>
          <template v-if="personnelTotal != 0">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :row-style="cellStyle"
              style="width: 100%; margin-top: 20px; margin-bottom: 20px"
              max-height="700"
              @current-change="handleCurrentChange"
            >
              <el-table-column type="index" label="序号" />
              <el-table-column
                align="center"
                prop="userInfo.name"
                label="人员名称"
              />
              <el-table-column align="center" prop="code" label="设备号" />
            </el-table>
          </template>
          <div class="nullDate" v-else>
            <img src="@/assets/nullDate.png" style="width: 260px" />
          </div>
          <div style="display: flex; justify-content: center">
            <el-button
              type="primary"
              :disabled="this.searchForm.current == 1"
              class="searchBtn"
              @click="back"
              >上一页</el-button
            >
            <el-button
              type="primary"
              :disabled="
                this.searchForm.current * this.searchForm.size > personnelTotal
              "
              @click="next"
              >下一页</el-button
            >
          </div>
        </div>
      </el-col>
      <el-col
        :span="16"
        style="
          background: #2b406ca6;
          padding: 15px 25px 25px;
          border-radius: 8px;
        "
      >
        <div class="page-top">
          <el-form
            :inline="true"
            :model="searchChartForm"
            class="demo-form-inline searchTop"
          >
            <el-form-item>
              <span slot="label">
                <span class="lab-span">日期</span>
              </span>
              <el-date-picker
                v-model="searchChartForm.allTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
              <!-- <el-date-picker
                v-model="searchChartForm.updateDate"
                style="width: 100%"
                type="date"
                placeholder="请输入"
              /> -->
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">在线时长：{{onlineDuration}}分钟</span>
              </span>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="searchBtn" @click="searchTime"
                >查询</el-button
              >
              <el-button type="warning" @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="height: 65vh; padding: 15px 30px">
          <BarChart
            :height="'65vh'"
            :sos-line-data="sosLineData"
            @initMap="initMap"
          />
        </div>

        <PlayBackDialog
          v-if="playBackDialogVisible"
          ref="playBackDialog"
          :id="state.userId"
          @closeDialog="closeDialog"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { formatDate } from "@/util";
import BarChart from "./components/barChart";
import PlayBackDialog from "./components/playBackDialog";

let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();
let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
let endTime = new Date(current_time1);
export default {
  components: {
    BarChart,
    PlayBackDialog,
  },
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      select: '1', //1人员 ， 2设备
      tableData: [],
      // 分页参数
      personnelTotal: 0,
      // 错误信息列表
      errorList: null,
      tit: "报警详情",
      detailVisible: false,
      filterText: "", //人员设备号

      searchChartForm: {
        allTime: [starTime, endTime],
      }, //右侧图表搜索
      playBackDialogVisible: false,
      // 统计柱状图数据
      sosLineData: {
        createTime: [], // 在线日期
        onlineTime: [], // 在线时间
      },
      state: {},

      data: [],
      onlineDuration: 0
    };
  },
  filters: {},
  methods: {
    cellStyle(row) {
      if (this.state && this.state.id === row.row.id) {
        // 注意！！！这里是 row.row.checkNumber
        return {
          backgroundColor: "#1282a563 !important",
          height: "60px",
        };
      }
      return { height: "60px" };
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },

    handleCurrentChange(val, oldVal) {
      if (val) {
        this.state = val;
        this.searchTime()
      }
    },
    
    // 设备人员切换
    changeSelect() {
      this.filterText = ''
    },

    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      if (this.select == 1) {
        data.userName = this.filterText;
      } else {
        data.code = this.filterText;
      }
      this.$http
        .post("/api/cap/web/capUser/getCapUserInfoList", data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.personnelTotal = data.total ?? 0;
            this.state = data.total > 0 ? this.tableData[0] : {};
            if (data.total > 0) {
              this.handleCurrentChange(this.state)
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    back() {
      this.searchForm.current--;
      this.onRefer();
    },
    next() {
      this.searchForm.current++;
      this.onRefer();
    },
    // 右侧图表
    searchTime() {
      this.getCharts();
    },
    reset() {
      this.searchChartForm.allTime = [starTime, endTime];
    },

    getCharts() {
      if (this.searchChartForm.allTime == null) {
        this.$message.error("请输入查询的时间");
        return;
      }
      const startDate = this.searchChartForm.allTime[0].getTime()
      const endDate = this.searchChartForm.allTime[1].getTime()
      if (endDate - startDate > 30 * 24 * 60 * 60 * 1000) {
        this.searchChartForm.allTime = [starTime, endTime]
        this.$message.error("最多只能查询30天内的数据");
        return;
      }
      // 清空柱状图数据
      this.sosLineData.createTime.splice(0);
      this.sosLineData.onlineTime.splice(0);
      const data = {
        startDate: startDate,
        endDate: endDate + 24 * 60 * 60 * 1000 - 1000,
        userId: this.state.userId,
      };
      this.$http.post("/api/cap/web/loginCount/getList", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.onlineDuration = 0
          data.map((item, i) => {
            this.onlineDuration = Number(item.loginTime) + this.onlineDuration
            // 第一项的放在第一个数组，第二项的放在第二个数组
            data.map((list, j) => {
              this.sosLineData.onlineTime.push([]);
              if (i === 0) {
                // 时间只赋值一次
                this.sosLineData.createTime.push(list.createTime.split(" ")[0]);
              }
              if (this.sosLineData.onlineTime[i]) {
                this.sosLineData.onlineTime[i].push({
                  minutes: list.loginTime,
                  maintain_user_id: list.loginTime,
                });
                this.sosLineData.onlineTime.splice(1)
              }
            });
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 初始化
    initMap(data) {
      if (data.target === "playBack" && !this.playBackDialogVisible) {
        
        this.playBackDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.playBackDialog.init(data, this.state.userId, (refresh) => {
            this.playBackDialogVisible = false;
          });
        });
      }
    },
    closeDialog() {
      this.playBackDialogVisible = false;
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.onRefer();
    });
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

 h4 {
    margin: 0;
    font-size: 15px;
  }
.mapBox {
  height: 50vh;
  background: #000;
  margin-left: 20px;
  border-radius: 5px;
}

.searchBtn {
  margin-right: 15px;
}
.searchTop {
  display: flex;
  justify-content: space-between;
}
/deep/.input-with-select{
  .el-input-group__prepend{
    width: 50px;
  }
}
</style>
