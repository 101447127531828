import { render, staticRenderFns } from "./switchStat.vue?vue&type=template&id=8052cb04&scoped=true&"
import script from "./switchStat.vue?vue&type=script&lang=js&"
export * from "./switchStat.vue?vue&type=script&lang=js&"
import style0 from "./switchStat.vue?vue&type=style&index=0&id=8052cb04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8052cb04",
  null
  
)

export default component.exports