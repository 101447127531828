<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="1400px"
    top="8vh"
    @close="closeDialog"
  >
    <div
      style="height: 68vh; overflow-y: scroll;padding-right: 20px;"
      class="scrollElement"
    >
      <h4>统计</h4>
      <SwitchStat
        :eleId="dataInfo.elevatorId"
        :dataInfo="dataInfo"
        @dateChange="dateChange"
      />

      <h4>日志记录</h4>
      <div v-if="listData.lenght != 0">
        <el-table
          ref="listTable"
          :data="listData"
          highlight-current-row
          max-height="500px"
          style="width: 100%;margin-bottom: 20px;"
        >
          <el-table-column
            type="index"
            align='center'
            label="序号"
          />
          <el-table-column
            align='center'
            prop="name"
            label="电梯名称"
            width="200px"
          />
          <el-table-column
            align='center'
            prop="deviceCode"
            label="电梯物联网编号"
            width="200px"
          />
          <el-table-column
            align='center'
            prop="caVer"
            label="物联网软件版本"
            width="250px"
          />
          <el-table-column
            align='center'
            prop="appVer"
            label="APK版本"
          />
          <el-table-column
            align='center'
            prop="createTime"
            label="上下线时间"
            width="200px"
          />
          <el-table-column
            align='center'
            label="类型"
            width="200px"
          >
            <template slot-scope="scope">
              {{scope.row.loginType ? (scope.row.loginType === 1 ? '上线': '下线') : ''}}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align='center'
            prop="address"
            label="操作"
            width="120px"
          >
            <template slot-scope="scope">
              <el-button
                v-focus
                @click="showDetails(scope.row)"
                type="primary"
                size="small"
              >查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page.sync="listFrom.current"
          :limit.sync="listFrom.size"
          @pagination="dictionarPage"
        />
      </div>
      <div
        class="nullDate"
        v-else
      >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px;"
        />
      </div>
    </div>
    <el-dialog
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :close-on-click-modal="false"
      append-to-body
      width="1400px"
      top="8vh"
      @close="closeDetail"
    >
      <div
        style="height: 60vh; overflow-y: scroll;padding-right: 20px;"
        class="scrollElement"
      >
        <div v-if="detailData.lenght != 0">
          <el-table
            ref="detailTable"
            :data="detailData"
            highlight-current-row
            max-height="500px"
            style="width: 100%;margin-bottom: 20px;"
          >
            <el-table-column
              type="index"
              align='center'
              label="序号"
              width="50px"
            />
            <el-table-column
              align='center'
              prop="eleName"
              label="电梯名称"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="deviceId"
              label="电梯物联网编号"
              width="150px"
            />
            <el-table-column
              align='center'
              prop="caVer"
              label="物联网软件版本"
              width="250px"
            />
            <el-table-column
              align='center'
              prop="appVer"
              label="APK版本"
            />
            <el-table-column
              align='center'
              prop="createTime"
              label="上报时间"
              width="200px"
            />
            <!-- <el-table-column
              align='center'
              prop="loginType"
              label="类型"
            > -->
              <!-- <template slot-scope="scope">
								{{scope.row.loginType ? (scope.row.loginType == 1 ? '上线': '下线') : ''}}
							</template> -->
            </el-table-column>
            <el-table-column
              align='center'
              prop="netStrenth"
              label="信号强度"
            >
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.netStrenth && scope.row.netStrenth.indexOf('L') > -1"
                  fit="contain"
                  :src="require(`@/assets/monitor/${scope.row.netStrenth}.png`)"
                  style="width:25px;height:25px"
                />
                <span v-else>{{scope.row.netStrenth ? scope.row.netStrenth : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              prop="batL"
              label="电量"
            >
              <template slot-scope="scope">
                <span>{{scope.row.batL ? `${scope.row.batL}%` : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              prop="status"
              label="设备状态"
            />
            <el-table-column
              align='center'
              prop="simDataNum"
              label="使用流量"
              width="150px"
            >
              <template slot-scope="scope">
                <span>{{scope.row.simDataNum ? `${scope.row.simDataNum}(M)` : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              label="门状态"
            >
              <template slot-scope="scope">
                {{scope.row.doorOpen ? (scope.row.doorOpen == 1 ? '关门': '开门') : ''}}
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              prop="floor"
              label="楼层"
            />
            <el-table-column
              align='center'
              prop="heads"
              label="人数"
            />
            <el-table-column
              align='center'
              prop="phoneNumber"
              label="sim卡号"
              width="150px"
            />
            <el-table-column
              align='center'
              prop="cangle"
              label="SD卡总容量(M)"
              width="150px"
            />
            <el-table-column
              align='center'
              prop="cspeed"
              label="SD卡剩余容量(M)"
              width="150px"
            />
          </el-table>
          <Pagination
            :total="detailTotal"
            :page.sync="detailFrom.current"
            :limit.sync="detailFrom.size"
            @pagination="dictionarPage"
          />
        </div>
        <div
          class="nullDate"
          v-else
        >
          <img
            src="@/assets/nullDate.png"
            style="width: 260px;"
          />
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
      </span>
    </el-dialog>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <!-- <el-button size="small" @click="closeDialog">关 闭</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination'
import SwitchStat from './switchStat'
import { formatDate } from "@/util";
let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();

let monthFrist = formatDate(new Date(new Date().setDate(1)), "yyyy-MM-dd") + " 00:00:00";
let starTime = new Date(monthFrist).getTime();
let endTime = current_time1 + 24 * 60 * 60 * 1000 - 1000;
export default {
  components: {
    Pagination,
    SwitchStat,
  },
  data() {
    return {
      loading: true,
      visible: true,
      listData: [],
      total: 0,
      listFrom: {
        current: 1,
        size: 10,
        startTime: starTime,
        endTime: endTime,
        // dateTime: [new Date(starTime), new Date(endTime)]
      },
      // 详情弹框
      detailVisible: false,
      detailData: [],
      detailTotal: 0,
      detailFrom: {
        current: 1,
        size: 10,
      },
      detailInfo: {},
    }
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {},
  mounted() {
    this.onRefer()
  },
  destroyed() {},

  methods: {
    dictionarPage(data) {
      if (this.detailVisible) {
        this.detailFrom.current = data.page
        this.detailFrom.size = data.limit
        this.detailOnRefer()
        return
      }
      this.listFrom.current = data.page
      this.listFrom.size = data.limit
      this.onRefer()
    },
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.listFrom))
			console.log(this.dataInfo,'dataInfo');
      data.eleId = this.dataInfo.elevatorId
      this.$http
        // .post(
        //   `/api/ele/web/loginLog/getLoginLogPage?current=${this.listFrom.current}&size=${this.listFrom.size}`,
        //   data
        // )
        .post(
          '/api/ele/web/loginLog/getLoginLogSubPage',
          data
        )
        .then((res) => {

          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.listData = data.records
            this.total = data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.listTable) {
                this.$refs.listTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    detailOnRefer() {
      const data = JSON.parse(JSON.stringify(this.detailFrom))
      console.log(this.detailInfo, 'detailInfo')
      this.$http
        .post(
          `/api/ele/web/location/getEleLocationInfo?current=${this.detailFrom.current}&size=${this.detailFrom.size}`,
          this.detailInfo
        )
        .then((res) => {

          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.detailData = data.records
            this.detailTotal = data.total
            this.$nextTick(() => {
              if (this.detailTotal > 0 && this.$refs.detailTable) {
                this.$refs.detailTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    showDetails(data) {
      this.detailVisible = true
      this.detailInfo = {
        elevatorId: data.elevatorId,
        start: new Date(data.createTime).getTime() - 5 * 60 * 1000,
        end: new Date(data.createTime).getTime() + 5 * 60 * 1000,
      }
      this.detailOnRefer()
    },
    dateChange(time) {
      console.log(time, 'time');
      this.listFrom.startTime = time[0]
      this.listFrom.endTime = time[1]
      this.onRefer()
    },
    closeDetail() {
      this.detailVisible = false
    },
    closeDialog() {
      this.visible = false
      this.$emit('closeVisible')
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
