<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">AI摄像头ID</span>
					</span>
					<el-input v-model="formInline.deviceCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">设备分类</span>
					</span>
					<el-select v-model="formInline.deviceSort" clearable placeholder="请输入">
						<el-option v-for="(item, index) in deviceType" :key="index" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)" >查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<div style="display:flex">
				<el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
				<el-button v-focus type="warning" @click="download(10)" size="small">下载模版</el-button>
				<form v-focus name="form1" style="width: 56px;margin: 0 10px;">
					<input type="file" id="docxFile" @change="readFile($event)"
						style="position:absolute; clip:rect(0 0 0 0);">
					<el-button type="primary" @click="toLead" size="small">导入</el-button>
				</form>
			</div>
		</div>
		<div>
		<el-table
			:data="tableData"
        	ref="multipleTable"
       		:height="$store.state.tabHeight"
        	style="width: 100%; margin-top: 20px; margin-bottom: 20px;"
			>
				<el-table-column type="index" label="序号" width="50px"/>
				<el-table-column align='center' prop="deviceCode" label="AI摄像头ID" min-width="150px" />
				<el-table-column align='center' prop="deviceSort" label="设备分类">
					<template slot-scope="scope">
						{{
							deviceType.find((item) => item.value === scope.row.deviceSort)
								? deviceType.find((item) => item.value === scope.row.deviceSort)
								.label : ''
						}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="memo" label="备注" min-width="150px">
				</el-table-column>
				<el-table-column align='center' prop="updateTime" label="创建时间">
				</el-table-column>
				<el-table-column fixed="right" align='center' prop="address" label="操作">
					<template slot-scope="scope">
						<el-button v-focus @click="del(scope.row.id)" type="danger" size="small">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination
				:total="dictionarTotal"
				:page.sync="formInline.current"
				:limit.sync="formInline.size"
				@pagination="dictionarPage"
			/>
		</div>

		<el-dialog v-if="dialogVisible" :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible"
			width="600px" top="20vh">
			<div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :model="fign">
					<el-row style="margin: 10px 0">
						<el-col :span="24">
							<el-form-item label="AI摄像头ID:" prop="deviceCode">
								<el-input v-model="fign.deviceCode" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="设备分类:" prop="deviceSort">
								<el-select v-model="fign.deviceSort" placeholder="请选择" class="einp" clearable>
									<el-option v-for="(item, index) in deviceType" :key="index" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="24">
							<el-form-item label="备注:" prop="memo">
								<el-input v-model="fign.memo" placeholder="请输入备注内容，不超过100字" class="einp textarea"
									type="textarea"
									:rows="3">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import Pagination from '../../../components/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
      },
	  deviceType: [],
      tableData: [],
      // 分页参数
      dictionarTotal: 0,
      // dialog参数
      isDeta: false, //disabled参数
      tit: '',
      dialogVisible: false,
      fign: {
		  memo: ''
	  },
      adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      // 表单限制
      rules: {
        deviceCode: [
          { required: true, message: 'AI摄像头ID不能为空', trigger: 'blur' },
        ],
        deviceSort: [
          { required: true, message: '设备分类不能为空', trigger: 'blur' },
        ]
      },
    }
  },
  methods: {
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page
      this.formInline.size = data.limit
      this.onRefer()
    },
	
    // 弹出框提交
    dioSub() {
	if (this.fign.memo && this.fign.memo.length > 100) {
		this.$message.error('备注不超过100字')
		return
	}
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let url = '/api/ele/web/easy/addElevatorEasy'
          console.log(this.fign,'this.fign')
		  const data = JSON.parse(JSON.stringify(this.fign))
          this.$http.post(url, data).then((res) => {
            if (res.data.success) {
              this.onRefer()
              this.dialogVisible = false
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },

	// 重置按钮事件
	reset() {
		
		this.formInline = {
			size: 10,
			current: 1,
		}
		this.onRefer(true)
	},

    onRefer(ishm) {
      if (ishm) {
        this.formInline.current = 1
      }
      this.$http
        .post('/api/ele/web/easy/getElevatorEasyList', this.formInline)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    del(ids) {
      this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http.delete(`/api/ele/web/easy/deleteElevatorEasy?ids=${ids}`).then((res) => {
            if (res.data.success) {
              if (this.tableData.length === 1) {
                this.formInline.current -= 1
              }
              this.onRefer()
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    addDj() {
      this.tit = '新增特殊设备'
      this.dialogVisible = true
	  this.fign = {
		  memo: ''
	  }
    },

	// 导入
	toLead() {
		document.form1.docxFile.click()
	},
	readFile(file) {
		let _this = this
		let docxFile = file.target.files[0]
		if (docxFile == undefined) {
			return
		}
		let hasFile = false
		let fileName = docxFile.name
		let fileArr = file.target.files[0].name.split('.')
		let files = docxFile
		if (!file) {
			this.$message('请先选择正确的文件类型上传')
		} else {
			let fileData = new FormData()
			fileData.append('file', files)
			fileData.append('type', files.type)
			let config = {
				onUploadProgress: (e) => {
					//属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
					//如果lengthComputable为false，就获取不到e.total和e.loaded
					if (e.lengthComputable) {
						let rate = e.loaded / e.total //已上传的比例
						if (rate < 1) {
							//这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
							//因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
							//等响应回来时，再将进度设为100%
							let uploadRate = (rate * 100).toFixed(2) + '%'
						}
					}
				},
			}
			this.$http
				.post(`/api/ele/web/batch/saveData/ELEVATOR_EASY`, fileData, config)
				.then((res) => {
					if (res.data.success) {
						this.onRefer()
						this.$message.success(res.data.msg)
					} else {
						this.errorList = res.data.data
						this.$message.error(res.data.msg)
					}
					file.target.value = ''
				})
		}
	},

	download(type){
		downloadMode(type)
	},

    // 获取全部角色
    onAllRefer(ishm) {
      let data = {
        current: 1,
        size: 10000,
      }
      this.$http.post('/api/system/web/role/getList', data).then((res) => {
        if (res.data.success) {
          this.tableAllData = res.data.data.records
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
	// 获取字典
	getNotice(code) {
	this.$http
		.post(`/api/system/web/dict/dictionary?codes=${code}`)
		.then((res) => {
			if (res.data.success) {
				const {
				data: { data },
				} = res
				this.deviceType = data.DEVICE_TYPE
			} else {
				this.$message.error(res.data.msg)
			}
		})
	},
  },
  created() {
	this.getNotice(['DEVICE_TYPE']);
    this.onRefer()
  },
}
</script>
<style scoped>
</style>
