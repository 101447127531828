<template>
  <el-dialog
    :title="'选择电梯'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectElevatorDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-form
        :inline="true"
        :model="selectElevatorSearch"
        class="demo-form-inline"
      >
        <el-form-item label="电梯名称">
          <el-input
            v-model="selectElevatorSearch.name"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchElevator"
            class="searchBtn"
          >搜索</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="selectElevatorList"
        :data="selectElevatorList"
        border
        stripe
        fit
        highlight-current-row
        max-height="500px"
        row-key="id"
        @select="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column
          label="序号"
          type="index"
          width="50px"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              (selectElevatorListQuery.current - 1) *
                selectElevatorListQuery.size +
              scope.$index +
              1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="电梯名称"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="code"
          label="电梯注册代码"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="deviceCode"
          label="AI摄像头ID"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="rescueCode"
          label="救援识别码"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="maintainUnitName"
          label="维保单位"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="propertyUnitName"
          label="物业单位"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="createUserName"
          label="创建人"
          min-width="120px"
        />
      </el-table>
      <Pagination
        v-show="selectElevatorListTotal > 0"
        :total="selectElevatorListTotal"
        :page.sync="selectElevatorListQuery.current"
        :limit.sync="selectElevatorListQuery.size"
        @pagination="selectElevatorListGet"
      />
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        type="primary"
        @click="selectElevatorConfirm"
      >确 定</el-button>
      <el-button
        size="small"
        @click="visible = false"
      >取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { set } from 'vue'
import Pagination from '../../../../components/Pagination'

export default {
  components: {
    Pagination,
  },

  data() {
    return {
      visible: false,
      loading: true,
      selectElevatorListQuery: {
        current: 1,
        size: 100,
      },
      selectElevatorSearch: {
        name: '',
      },
      selectElevatorList: [],
      selectElevatorListTotal: 0,
      selectElevatorCallback: null,
      selectRow: {},
      elevatorId: [],
      selectData: [],
      initArr: [],
      updateArr: [],
    }
  },
  methods: {
    init(id, selectData, callback) {
      this.selectElevatorCallback = callback
      this.elevatorId = id ?? []
      this.initArr = selectData
      this.visible = true
      this.loading = false
      this.selectElevatorListQuery = {
        current: 1,
        size: 10,
      }
      this.$nextTick(() => {
        this.selectElevatorListGet()
      })
    },
    //全选
    selectAll(selection) {
      let stateArr = []
      if (selection.length > 0) {
        stateArr = JSON.parse(JSON.stringify(selection))
        const arr = [...selection, ...this.initArr]
        // 去重
        const res = new Map()
        this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
      } else {
        stateArr = JSON.parse(JSON.stringify(this.selectElevatorList))
        stateArr.map((item) => {
          if (this.initArr.find((row) => row.id === item.id)) {
            this.initArr = this.initArr.filter((row) => row.id !== item.id)
          }
        })
      }
      console.log(this.initArr, 'selection')
    },

    handleSelectionChange(val, row) {
      if (row.code === '') {
        this.$message({
          message: '请先给电梯设置注册码',
          type: 'error',
        })
        this.$refs.selectElevatorList.toggleRowSelection(row, false)
        return
      } else if (row.maintainUnitName === '') {
        this.$message({
          message: '请先给电梯设置维保单位',
          type: 'error',
        })
        this.$refs.selectElevatorList.toggleRowSelection(row, false)
        return
      }
      if (this.initArr.find((item) => item.id === row.id)) {
        this.initArr = this.initArr.filter((item) => item.id !== row.id)
      } else {
        this.initArr.push(row)
      }
      console.log(this.initArr, 'this.initArr1231123123123123')
    },

    selectElevatorListGet() {
      const params = this.selectElevatorListQuery
      params.name = this.selectElevatorSearch.name
      params.createUser = JSON.parse(sessionStorage.getItem('adminInfo')).id
      this.loading = true
      this.$http.post('/api/ele/web/elevatorInfo/list', params).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res
          this.loading = false
          this.selectElevatorList = data.records ?? []
          this.selectElevatorListTotal = data.total ?? 0
          this.$nextTick(() => {
            if (this.selectElevatorListTotal > 0 && this.$refs.selectElevatorList) {
              this.$refs.selectElevatorList.bodyWrapper.scrollTop = 0;
            }
            if (this.elevatorId.length > 0) {
              this.selectElevatorList.forEach((ele) => {
                this.elevatorId.forEach((item) => {
                  if (item === ele.id) {
                    this.$refs.selectElevatorList.toggleRowSelection(ele, true)
                  }
                })
              })
            }
          })
        }
      })
    },
    searchElevator() {
      this.selectElevatorListQuery.current = 1
      this.selectElevatorListGet()
    },

    // 重置
    reset() {

      this.selectElevatorListQuery = {
        current: 1,
        size: 10,
      }
      this.selectElevatorSearch.name = ''
      this.selectElevatorListGet()
    },

    selectElevatorConfirm() {
      const elevatorData = this.initArr

      if (elevatorData.length > 0) {
        this.$emit('transferElevator', elevatorData)
        this.visible = false
      } else {
        this.$message({
          message: '请选择电梯',
          type: 'error',
        })
      }
    },

    selectElevatorDialogClose() {
      this.selectElevatorListQuery.current = 1
      this.$refs.selectElevatorList.setCurrentRow()
      this.$refs.selectElevatorList.clearSelection()
    },
  },
}
</script>
