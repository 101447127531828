<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1400px"
    top="8vh"
    @close="closeDialog"
  >
    <el-tabs
      style="margin-top: 10px;"
      v-model="activeName"
      lazy
      @tab-click="tabClick"
    >
      <el-tab-pane
        label="实时监控"
        name="ssjk"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <MonitorInfo :eleInfo="eleInfo" v-if="activeName == 'ssjk'" ref="monitorInfo"/>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="运行轨迹"
        name="yxgj"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <LocusCharts v-if="activeName == 'yxgj'" ref="locusCharts" :useId="useId"/>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="电梯维保"
        name="dtwb"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <Maintenance v-if="activeName == 'dtwb'" ref="maintenance" :useId="useId"/>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="云端视频"
        name="ydsp"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <VideoList v-if="activeName == 'ydsp'" ref="videoList" :useId="useId"/>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="云端相片"
        name="ydxp"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <ImageList v-if="activeName == 'ydxp'" ref="imageList" :useId="useId"/>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="报警记录"
        name="bjjl"
      >
        <div
          style="height: 60vh; overflow-y: scroll; padding-right: 20px"
          class="scrollElement"
        >
          <SosList v-if="activeName == 'bjjl'" ref="sosList" :useId="useId"/>
        </div>
      </el-tab-pane>
    </el-tabs>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <!-- <el-button size="small" @click="closeDialog">关 闭</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import MonitorInfo from './monitorInfo';
import LocusCharts from './locusCharts';
import Maintenance from './maintenance';
import ImageList from './imageList';
import VideoList from './videoList';
import SosList from './sosList';
export default {
  components: {
    MonitorInfo,
    LocusCharts,
    Maintenance,
    ImageList,
    VideoList,
    SosList
  },
  data() {
    return {
      loading: true,
      dialogVisible: true,
      activeName: 'ssjk', // 默认第一屏
    }
  },
  props: {
    eleInfo: {
      type: Object,
      default: () => ({}),
    },
    useId: {
      type: String,
      default: '',
    },
    activeNameProps: {
      type: String,
      default: 'ssjk',
    },
    deviceCode: {
      type: String,
      default: '',
    },
  },

  created() {
    this.getNotice(['ELEVATOR_TYPE'])
    this.activeName = this.activeNameProps
  },
  mounted() {
  },
  destroyed() {
  },

  methods: {
    tabClick() {
      switch (this.activeName) {
        case 'ssjk':
          this.$nextTick(() => {
            this.$refs['monitorInfo'].onRefer()
          })
          break
        case 'yxgj':
          this.$nextTick(() => {
            this.$refs['locusCharts'].onRefer()
          })
          break
        case 'dtwb':
          this.$nextTick(() => {
            this.$refs['maintenance'].onRefer()
          })
          break
        case 'ydsp':
          this.$nextTick(() => {
            this.$refs['videoList'].onRefer()
          })
          break
        case 'ydxp':
          this.$nextTick(() => {
            this.$refs['imageList'].onRefer()
          })
          break
        case 'bjjl':
          this.$nextTick(() => {
            this.$refs['sosList'].onRefer()
          })
          break
        default:
          break
      }
    },

    // 获取字典
    getNotice(code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.eleType = data.ELEVATOR_TYPE
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    closeDialog() {
      this.dialogVisible = true
      this.activeName = 'ssjk'
      this.$emit('closeMonitor')
    },
  },
}
</script>
<style lang="scss" scoped>

/deep/.el-tabs__item {
  width: 120px;
  text-align: center;
  padding-left: 0;
}
</style>
