<template>
  <div v-if="reFresh" id="chart" style="width: 100%;height: 45vh;" />
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    // mixins: [resize],
    props: ['dateArray', 'echartDate'],
    data() {
      return {
        chart: null,
        reFresh: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },
    methods: {
      initChart() {
        this.chart = echarts.init(document.getElementById('chart'));
        this.setOptions()
      },
      setOptions() {
        const options = {
          // backgroundColor:'#FFFFFF',
          color: ['#ecd543', '#2fc25b', '#54adff'],
          // title: {
          //   text: 'Gradient Stacked Area Chart'
          // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['监测设备总数', '健康设备', '异常设备'],
            textStyle: {
              color: '#409EFF',
            },
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#4dc1ed',
              },
            },
            data: this.dateArray
          }],
          yAxis: [{
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#4dc1ed',
              },
            },
          }],
          series: [{
              name: '异常设备',
              type: 'line',
              stack: 'Total',
              // smooth: true,
              lineStyle: {
                color: '#ecd543'
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#fdf0b8'
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.abnormal
            }, {
              name: '健康设备',
              type: 'line',
              stack: 'Total',
              // smooth: true,
              lineStyle: {
                color: '#2fc25b'
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#c0edcd'
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.health
            },
            {
              name: '监测设备总数',
              type: 'line',
              stack: 'Total',
              // smooth: true,
              lineStyle: {
                color: '#54adff'
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#b9ddff'
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.monitoring
            },
          ]
        };
        this.chart.setOption(options)
      }
    }
  }
</script>

<style>
</style>
