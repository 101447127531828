<template>
	<el-dialog :title="tit" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="800px"
		top="8vh"  @close="closeDialog">
        <div style="height: 50vh; overflow-y: scroll; padding-right: 20px" class="scrollElement" >
        <el-form
          label-position="right"
          label-width="120px"
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
        >
            <el-form-item label="类型:" prop="type" >
                <el-select
                    class="wid90"
                    v-model="dataForm.type"
                    placeholder="请输入"
                    :disabled="!!dataForm.id"
                    @change="changeLevel"
                >
                    <el-option label="一级目录" :value="1" />
                    <el-option label="二级目录" :value="2" />
                    <el-option label="文件" :value="3" />
                </el-select>
            </el-form-item>
            <el-form-item label="名称:" prop="name"  >
                <el-input
                    class="wid90"
                    v-model="dataForm.name"
                    :disabled="dataForm.type == 3"
                    placeholder="请输入名称，不超过100字"
                />
            </el-form-item>
            <el-form-item label="序号:" prop="number"  >
                <el-input
                    class="wid90"
                    v-model="dataForm.number"
                    placeholder="请输入正整数，展示时按照升序展示。"
                />
            </el-form-item>

            <el-form-item label="所属一级目录:" prop="firstLevel" v-if="dataForm.type != 1">
                <el-select :key="key" class="wid90" v-model="dataForm.firstLevel" :disabled="dataForm.id && dataForm.type == 1 && dataForm.firstLevel != ''" placeholder="请选择所属一级目录" @change="changeMenu" filterable :clearable="dataForm.type != 3">
                    <el-option v-for="(item, index) in levelData" :key="index" :label="item.name"
                    :value="item.id" />
                </el-select>
            </el-form-item>

            <el-form-item label="所属二级目录:" prop="secondLevel" v-if="dataForm.type != 1 && dataForm.type != 2">
                <el-select :key="key2" class="wid90" v-model="dataForm.secondLevel" :disabled="!dataForm.firstLevel" placeholder="请选择所属二级目录" @change="changeSecondMenu" filterable clearable title="请先选择一级目录">
                    <el-option v-for="(item, index) in level2Data" :key="index" :label="item.name"
                    :value="item.id" />
                </el-select>
            </el-form-item>

            <el-form-item label="上传文件:" prop="url" v-if="dataForm.type == 3" >
                <FileUpload  :md5s.sync="dataForm.url" :multiple="false" :limit="1" :imgList="['pdf', 'docx']" :fileSize="30" :url="uploadUrl" @uploadfile="uploadfile" />
            </el-form-item>
            <el-form-item label="展示状态:" prop="status" v-if="dataForm.type == 3">
                <el-switch v-model="dataForm.status" :active-value="0" :inactive-value="1"/>
            </el-form-item>

          <el-row style="margin: 10px 0">
            <el-col :span="24">
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button size="small" type="primary" :loading="loading" @click="dioSub()" >保 存</el-button>
        <el-button size="small" @click="closeDialog" >取 消</el-button>
        </span>
	</el-dialog>
</template>

<script>
    import FileUpload from '@/components/FileUploadPWP_zl'
    var validateTime = (rule, value, callback) => {
      let operation = /^([1-9][0-9]*)$/
      // true：必填  false：非必填
      inpYz(rule, value, callback, true, operation, "只能输入正整数")
    };
	export default {
		components: {
            FileUpload
		},
        props:{
            tit: {
                type: String,
                default: ''
            },
            actionForm: {
                type: Object,
                default: () => {},
            },
        },
		data() {
			return {
				visible: true,
                loading: false,
                levelData: [],
                level2Data: [],
                dataForm: {
                    status: 0
                },
                uploadUrl: '/api/file/web/uploadByOssFile', // 上传地址
                // 表单限制
				rules: {
					type: [{
						required: true,
                        message: '类型不能为空',
						trigger: 'change',
					}],
					name: [{
						required: true,
                        max: 100,
                        message: '请输入名称，不超过100字',
						trigger: 'change',
					}],
					number: [{
						required: true,
                        validator: validateTime,
						trigger: 'blur',
					}],
					firstLevel: [{
						required: true,
                        message: '所属一级目录不能为空',
						trigger: 'blur',
					}],
					url: [{
						required: true,
                        message: '上传文件不能为空',
						trigger: 'blur',
					}],
					status: [{
						required: true,
						trigger: 'blur',
					}],
				},
                key: 0,
                key2: 0,
			}
		},
        created(){
            this.getMenu(1)
        },
        mounted(){
            if (this.actionForm.id) {
                this.dataForm = JSON.parse(JSON.stringify(this.actionForm))
                if (this.actionForm.type == 2) {
                    this.dataForm.firstLevel = this.actionForm.topId
                }
                if (this.actionForm.type == 3) {
                    this.getMenu(2, this.actionForm.topId)
                    this.dataForm.firstLevel = this.actionForm.topId
                    if (this.actionForm.level == 3) {
                        this.dataForm.secondLevel = this.actionForm.parentId
                    }
                }
            } else{
                this.dataForm = {
                    status: 0,
                    url: ''
                }
            }
        },

		methods: {
            getMenu (level, id) {
                const data = { level }
                if (id) {
                    data.topId = id
                }
                this.$http
					.post('/api/base/data/getCenterList', data)
					.then((res) => {
						if (res.data.code === 200) {
							const { data: { data } } = res
                            if (level == 1) {
                                this.levelData = data ? JSON.parse(JSON.stringify(data)) : []
                            } else {
                                this.level2Data = data ? JSON.parse(JSON.stringify(data)) : []
                            }
						}
					})
            },

            changeLevel(value){
                this.dataForm.level = value
                this.$set(this.dataForm, 'firstLevel', '')
                this.$set(this.dataForm, 'secondLevel', '')
                this.$set(this.dataForm, 'name', '')
                this.$set(this.dataForm, 'url', '')
            },

            changeMenu(value){
                this.key++
                if (value) {
                    this.getMenu(2, this.dataForm.firstLevel)
                    this.$set(this.dataForm, 'secondLevel', '')
                } else{
                    this.$set(this.dataForm, 'firstLevel', '')
                    this.$set(this.dataForm, 'secondLevel', '')
                }
            },
            changeSecondMenu(value){

                this.key2++
                if (!value) {
                    this.$set(this.dataForm, 'secondLevel', '')
                }
            },

            uploadfile(data){
                this.dataForm.name = data.name
            },

			dioSub(){
                this.loading = true;
                this.$refs.dataForm.validate(async (valid) => {
                    if (valid) {
                        const data = {
                            ...this.dataForm,
                            type: this.dataForm.type * 1,
                            level: this.dataForm.type * 1,
                            number: this.dataForm.number * 1,
                        }
                        if (this.dataForm.type == 1) {
                            data.topId = ''
                            data.parentId = ''
                        }
                        if (this.dataForm.type == 2) {
                            data.topId = this.dataForm.firstLevel
                            data.parentId = this.dataForm.firstLevel
                        }
                        if (this.dataForm.type == 3) {
                            data.topId = this.dataForm.firstLevel ?? ''
                            data.parentId = this.dataForm.secondLevel ? this.dataForm.secondLevel : this.dataForm.firstLevel
                            data.level = this.dataForm.secondLevel ? 3 : 2
                        }
                        console.log(this.dataForm,'this.dataForm');
                        let url = this.dataForm.id ? '/api/base/data/editDataCenter' : '/api/base/data/addDataCenter'
                        this.$http.post(url, data)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.visible = false
                                this.$emit('updateList')
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                });
                this.loading = false;
            },

			closeDialog() {
				this.visible = false
                this.$emit('closeAction')
			},
		},
	}
</script>
<style lang='scss' scoped>
	.searchBox {
		/deep/.el-form-item__label {
			color: #00f6ff !important;
			font-size: 14px;
		}

		.searchBtn {
			margin-left: 15px;
		}
	}
    .wid90{
        width: 90%;
    }
</style>
