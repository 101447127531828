<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1100px"
    top="8vh"
    @close="closeDialog"
  >
    <div class="searchBox scrollElement">
      <div class="admin-project-myproject-container">
        <template v-if="tableData != 0">
          <el-table
            ref="multipleTable"
            :data="tableData"
            height="650px"
            v-loading="loading"
          >
            <el-table-column type="index" label="序号" width="50px" />

            <el-table-column align="center" label="生成状态" min-width="100px">
              <template slot-scope="scope">
                {{
                  scope.row.status == 1 ? '已完成' : (scope.row.status == 2 ? '生成失败' : '生成中')
                }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="数据时间范围" min-width="150px">
              <template slot-scope="scope">
                {{scope.row.startTime ? scope.row.startTime.split(' ')[0] : '-'}}
                至
                {{ scope.row.endTime ? scope.row.endTime.split(' ')[0] : '-'}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="申请时间"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{scope.row.createTime ? scope.row.createTime : '-'}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="address"
              label="操作"
              width="200px"
            >
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.status == 0 || scope.row.status == 2"
                  @click="actionBtn(scope.row.url, 'download')"
                  type="primary"
                  size="small"
                  >下载
                </el-button>
               <!-- <el-button
                  :disabled="scope.row.status == 0 || scope.row.status == 2"
                  @click="actionBtn(scope.row.url, 'detail')"
                  type="primary"
                  size="small"
                  >详情
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="tableTotal"
            :page.sync="searchForm.current"
            :limit.sync="searchForm.size"
            @pagination="maintenRulePage"
          />
        </template>

        <div class="nullDate" v-else v-loading="loading">
        	<img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px;" />
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      loading: true,
      dialogVisible: true,
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      tableTotal: 0,
    };
  },
  props: {
    elevatorId: {
      type: String,
      default: "",
    },
  },

  created() {},
  mounted() {
    this.getData();
  },
  destroyed() {},

  methods: {
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.getData();
    },

    //
    actionBtn(pdfUrl, type) {
      // if (type == "download") {
      //   const response = await axios({
      //     url: pdfUrl,
      //     method: 'GET',
      //     responseType: 'blob', // 必须指定为blob类型才能下载
      //   });
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
        // console.log('1`23123123213');
        // const link = document.createElement('a');
        // link.href = pdfUrl;
        // link.setAttribute('download', '运行报告.pdf');
        // document.body.appendChild(link);
        // link.click();
      // } else {
      //   window.open(pdfUrl, "_blank");
      // }
      const x = new XMLHttpRequest()
      x.open('GET', pdfUrl, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = '运行报告.pdf'
        a.click()
        document.body.removeChild(a)
        // 然后移除
      }
      x.send()
    },

    getData() {
      this.$http
        .get(
          `/api/ele/web/operationalReport/list?type=${1}&eleId=${this.elevatorId}&current=${this.searchForm.current}&size=${this.searchForm.size}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.tableTotal = data.total ?? 0;
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false
        });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeCheck");
    },
  },
};
</script>
<style lang="scss" scoped>
.searchBox {
  margin-top: 20px;
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}

.nullDate {
  width: 100%;
  height: 70vh;
  max-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
