<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">角色名称</span>
          </span>
          <el-input v-model="formInline.roleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select v-model="formInline.isActive" placeholder="请输入">
            <el-option label="全部" value="null"></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="未启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
    </div>
    <div>
      <el-table ref="multipleTable" :data="tableData" :height="$store.state.tabHeight"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" width="50px">
        </el-table-column>
        <el-table-column align='center' prop="roleName" label="角色名称">
        </el-table-column>
        <el-table-column align='center' prop="memo" label="备注">
        </el-table-column>
        <el-table-column align='center' prop="updateTime" label="更新时间">
        </el-table-column>
        <el-table-column align='center' prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column align='center' prop="address" label="是否启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isActive" active-color="#409eff" inactive-color="#909399" :active-value="1"
              :inactive-value="0" @change='swChange(scope.row)'>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createUserName" label="创建人" />
        <el-table-column fixed="right" align='center' prop="address" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button v-focus @click="upd(scope.row)"
              v-if="scope.row.createUser == adminInfo.id || adminInfo.userName == 'admin'" type="primary"
              size="small">编辑权限
            </el-button>
            <!-- <el-button v-focus @click="upd(scope.row)"
              v-if="!scope.row.onlyRead || (adminInfo.userName == 'admin' && scope.row.roleName == '集团物业')"
              type="primary" size="small">编辑权限
            </el-button> -->
            <!-- <el-button @click="details(scope.row)" type="primary" size="small">详情
						</el-button> -->
            <!-- <el-button v-focus @click="del(scope.row)" type="primary" size="small">复制</el-button> -->
            <el-button v-focus @click="del(scope.row)"
              v-if="scope.row.createUser == adminInfo.id || adminInfo.userName == 'admin'" type="danger" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <el-dialog v-if="dialogVisible" :title="tit" :visible.sync="dialogVisible" width="950px" top='20vh'>
      <div style="height: 50vh;overflow-y: scroll;" class="scrollElement">
        <p style="font-weight: bold;font-size: 18px;">角色信息</p>
        <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="90px" :model="fign">
          <el-row style="margin:10px 0">
            <el-col :span="12">
              <el-form-item label="角色名称:" prop="roleName">
                <el-input :disabled='isDeta' v-model="fign.roleName" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注:" prop="memo">
                <el-input :disabled='isDeta' v-model="fign.memo" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin:10px 0">
            <el-col :span="12">
              <el-form-item label="使用模板:">
                <el-select :disabled='isDeta' v-model="fign.roleMode" placeholder="请选择" class="einp" @change="opChange"
                  clearable>
                  <el-option v-for="item in tableAllData" :key="item.id" :label="item.roleName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大屏权限:" prop="bigScreenType">
                <el-select :disabled='isDeta' v-model="fign.bigScreenType" placeholder="请选择" class="einp">
                  <el-option label="无此权限" :value="0"></el-option>
                  <el-option label="监管" :value="1"></el-option>
                  <el-option label="运营" :value="2"></el-option>
                  <el-option label="维保" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <p style="font-weight: bold;font-size: 18px;">功能权限管理</p>
        <div class="treeD">
          <el-tree check-on-click-node :check-strictly="true" @node-contextmenu="nodeClick" :default-expand-all='true'
            :data="treeData" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  var validateName = (rule, value, callback) => {
    let operation = /^.{2,8}$/
    // true：必填  false：非必填
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      '可输入长度为2-8的字符'
    )
  }
  var validatememo = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,16}$/
    inpYz(
      rule,
      value,
      callback,
      false,
      operation,
      '可输入长度为2-16的中文，字母，数值'
    )
  }

  let id = 0
  import Pagination from '../../../components/Pagination'
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        dateType: '',
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
        },
        tableData: [],
        tableAllData: [],
        // 分页参数
        dictionarTotal: 0,
        // dialog参数
        isDeta: false, //disabled参数
        tit: '',
        dialogVisible: false,
        fign: {
          bigScreenType: 0,
        },
        checkIten: '',
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        buttonType: JSON.parse(sessionStorage.getItem('BUTTON_TYPE')),
        butObj: JSON.parse(sessionStorage.getItem("adminInfo")).buttons,
        userResource: [],
        // 表单限制
        rules: {
          roleName: [{
            required: true,
            validator: validateName,
            trigger: 'blur',
          }, ],
          memo: [{
            validator: validatememo,
            trigger: 'blur',
          }, ],
          bigScreenType: [{
            required: true,
            message: '内容不能为空!',
            trigger: 'blur',
          }, ]
        },
        // tree
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'resourceName',
        },
        id: null
      }
    },
    methods: {
      // 树的勾选方法
      nodeClick(data, node) {
        console.log('勾选内容', data, node)
        this.childNodesChange(node)
      },
      childNodesChange(node) {
        console.log(node.checked, node.childNodes)
        let len = node.childNodes.length
        if (len > 0) {
          for (let i = 0; i < len; i++) {
            if (!node.checked) {
              node.childNodes[i].checked = false
            } else {
              node.childNodes[i].checked = true
            }
            this.childNodesChange(node.childNodes[i])
          }
        }
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      clearChecked(tit) {
        if (tit == '新增') {
          this.$refs.tree.setCheckedKeys(this.$refs.tree.getCheckedNodes(), false);
        } else {
          this.upd(this.checkIten)
        }
      },
      // 弹出框选择器
      opChange(item) {
        let tit = this.tit
        // if (this.tit == '修改') {
        this.$confirm('修改角色模板将清空当前已勾选的功能权限，且仅能从目标模板中选择功能权限，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          console.log(111, res)
          this.fign.roleMode = item
          this.dateType = item
          if (item == '') {
            this.clearChecked(tit)
          }
          this.tableAllData.forEach((qs) => {
            if (item == qs.id) {
              let obj = JSON.parse(JSON.stringify(qs))
              obj.roleMode = item
              obj.memo = this.fign.memo
              obj.roleName = this.fign.roleName
              this.upd(obj, tit)
              // obj.roleName = this.fign.roleMode
            }
          })
        }).catch(() => {
          console.log(222)
          this.fign.roleMode = this.dateType
        })
        console.log(this.fign.roleMode, this.dateType, 666)
      },
      // 弹出框提交
      dioSub() {
        let arr = this.$refs.tree.getCheckedKeys()
        this.$refs.ruleForm.validate((valid) => {
          if (valid && arr.length > 0) {
            let url = '/api/system/web/role/edit'
            this.fign.id = this.id
            if (this.tit == '新增') {
              this.fign.id = null
              url = '/api/system/web/role/add'
            }
            this.fign.roleResourceList = []
            arr.forEach((qs) => {
              let obj = {
                resourceId: qs,
              }
              this.fign.roleResourceList.push(obj)
            })
            this.fign.createUser = this.createUser
            this.fign.createUserName = this.createUserName
            console.log(this.fign, 'this.fign')
            this.$http.post(url, this.fign).then((res) => {
              if (res.data.success) {
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.warning('请将表单填写完整且至少勾选一个菜单')
            return false
          }
        })
      },

      // 表格方法
      swChange(item) {
        console.log(item)
        let url = '/api/system/web/role/edit'
        let data = {
          id: item.id,
          isActive: item.isActive,
        }
        this.$http.post(url, data).then((res) => {
          if (res.data.success) {
            this.onRefer()
            this.$message.success(res.data.msg)
          } else {
            this.$set(item, 'isActive', item.isActive == 0 ? 1 : 0)
            this.$message.error(res.data.msg)
          }
        })
      },
      onRefer(ishm) {
        if (ishm) {
          this.formInline.current = 1
        }
        this.$http
          .post('/api/system/web/role/getList', this.formInline)
          .then((res) => {

            if (res.data.success) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // details(item) {
      // 	this.tit = '详情'
      // 	this.getTree(true)
      // 	this.upd(item)
      // 	this.isDeta = true
      // },
      upd(item, tit) {
        console.log(223, item, tit)
        this.onAllRefer(item)
        if (!tit) {
          this.checkIten = item
          this.id = item.id
          this.createUser = item.createUser
          this.createUserName = item.createUserName
        }
        // this.onAllRefer(item)
        this.tit = '修改'
        this.isDeta = false
        let data = {
          id: item.id,
        }
        this.$http.post('/api/system/web/role/getInfo', data).then((res) => {
          if (res.data.success) {
            if (tit != undefined) {
              console.log(tit)
              this.tit = tit
            }
            this.dialogVisible = true
            this.fign = JSON.parse(JSON.stringify(item))
            console.log(this.fign,'this.fign');
            console.log(res.data.data.resourceIds,'res.data.data.resourceIds');
            this.fign.resourceIds = res.data.data.resourceIds
            this.$nextTick(() => {
              if (this.fign.resourceIds != null) {
                this.$refs.tree.setCheckedKeys(this.fign.resourceIds)
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      del(item) {
        this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            let data = {
              id: item.id,
            }
            this.$http.post('/api/system/web//role/delete', data).then((res) => {
              if (res.data.success) {
                if (this.tableData.length === 1) {
                  this.formInline.current -= 1
                }
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
            console.log(item)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      addDj() {
        this.onAllRefer()
        this.tit = '新增'
        this.dialogVisible = true
        this.fign = {
          bigScreenType: 0
        }
      },
      // 获取全部角色
      onAllRefer(item) {
        console.log(item, '角色数据')
        let data = {
          current: 1,
          size: 10000,
        }
        this.$http.post('/api/system/web/role/getList', data).then((res) => {
          if (res.data.success) {
            this.tableAllData = res.data.data.records ?? []
            this.tableAllData = item ? this.tableAllData.filter(ids => ids.id !== item.id) : res.data.data.records
            console.log(this.tableAllData, '模板')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getUserResource(data) {
        data.map(item => {
          this.userResource.push(item)
          if (item.children && item.children.length > 0) {
            this.getUserResource(item.children)
          }
        })
      },
      getNewTree(Arr) {
        const data = []
        Arr.map(item => {
          if (item.buttonKey != '') {
            if (this.butObj.includes(item.buttonKey)) {
              data.push(item)
            }
          } else {
            if (item.children) {
              item.children = this.getNewTree(item.children)
            }
            if (this.userResource.find(resItemm => resItemm.routePath == item.routePath)) {
              data.push(item)
            }
          }
        })
        return data
      },
      // 获取菜单树
      getTree(istrue) {
        let data = {
          disabled: istrue,
        }
        this.$http
          .get('/api/system/web/resource/tree', data)
          .then((res) => {
            this.treeData = JSON.parse(JSON.stringify([res.data.data]))
            if (this.adminInfo.userName != 'admin') {
              const newData = this.getNewTree(res.data.data.children)
              if (this.userResource.find(resItemm => resItemm.routePath == '/dashboard')) {
                newData.unshift(res.data.data.children.find(item => item.buttonKey == 'sy'))
              }
              this.treeData[0].children = newData
            }
          })
      },
    },
    created() {
      this.onRefer()
      this.onAllRefer()
      this.getTree()
      this.getUserResource(this.adminInfo.resource)
    },
  }
</script>
<style scoped>
  .treeD {
    width: 100%;
    height: 300px;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-bom {}
</style>
