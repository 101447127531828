<template>
  <el-dialog
    :title="firstTimeTitle"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="960px"
    top="10vh"
    @close="Close"
  >
    <div class="admin-project-myproject-container">
      <div style="height: 55vh; overflow-y: scroll" class="scrollElement">
        <el-table ref="selectUnitList" :data="tableData" :row-key="getRowKeys">
          <el-table-column
            label="序号"
            type="index"
            prop="index"
            width="50px"
            align="center"
          >
          </el-table-column>
          <el-table-column align="center" prop="name" label="电梯名称" />
          <el-table-column align="center" prop="code" label="电梯注册代码" />
          <el-table-column align="center" label="首次维保日期" width="300px">
            <template slot-scope="scope">
              <div class="isdisplay">
                <el-date-picker
                  class="einp"
                  :disabled="isEdit"
                  v-model="scope.row.firstTime"
                  :picker-options="pickerOptions"
                  type="date"
                  placeholder="选择日期"
                  :key="scope.row.id"
                  @change="changeDate(scope.$index)"
                />
                <el-button
                  v-if="!scope.row.firstTime && scope.$index != 0 && tableData[scope.$index - 1].firstTime"
                  @click="copyTime(scope.$index)"
                  type="warning"
                  size="small"
                  >同上个电梯</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button v-if="!isEdit" size="small" type="primary" @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="Close">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: ["firstTimeTitle", "initClooseEleInfo", "startTime", 'isEdit'],
  data() {
    return {
      visible: true,
      loading: true,
      tableData: [],
      pickerOptions: {}
    };
  },
  mounted() {
    if (this.initClooseEleInfo.length > 0) {
      this.tableData = JSON.parse(JSON.stringify(this.initClooseEleInfo));
      
      this.tableData.map(item => {
        item.firstTime = item.firstMaintainTime ? new Date(item.firstMaintainTime) : ''
      })
    }
    console.log(this.initClooseEleInfo, 'initClooseEleInfo');
    this.pickerOptions = {
        disabledDate: (time) => {
          // 禁用合同开始日期选择
          return time.getTime() < new Date(this.startTime).getTime() || time.getTime() > new Date(this.endTime).getTime(); 
        },
      }
  },
  methods: {
    getRowKeys (row) {
      return row.index;
    },
    changeDate (data) {
      
      // this.$forceUpdate()
    },
    copyTime (index) {
      this.$set(this.tableData[index], 'firstTime', this.tableData[index - 1].firstTime)
    },
    selectunitConfirm() {
      console.log(this.tableData, "this.tableData");
        if (this.tableData.find(item => !item.firstTime)) {
          this.$message.error(`${this.tableData.find(item => !item.firstTime).name}的首次维保日期不得为空`);
          return
        }
        this.tableData.map(item => {
          item.firstMaintainTime = item.firstTime.getTime()
        })
        this.$emit('close', this.tableData)
        this.visible = false
    },

    Close() {
      console.log(this.initClooseEleInfo, "this.initClooseEleInfo");
      this.$emit("close", this.initClooseEleInfo);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.isdisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.einp {
  max-width: 150px;
  margin-right: 15px;
}
</style>
