<template>
  <div
    :class="className"
    :style="{ width: width, height: height, margin: '0 auto', paddingRight: '2vw' }"
  />
</template>

<script>
import echarts from "echarts";

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "180px",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    sosPieData: {
      type: Array,
      default: () => [
      ],
    },
    trendColor: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chart: null,
      legendArr: []
    };
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },

  beforeDestroy() {
    if (!this.chart) return;
    // 释放图表实例
    this.chart.dispose();
    this.chart = null;
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el);
      this.legendArr = []
      this.sosPieData.map(item => {
          this.legendArr.push(item.name)
          })
      this.setOptions(this.sosPieData);
    },
    setOptions (data) {
      const options = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}: {c} 次({d}%)"   //鼠标放上去 展示内容
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          left: 'right',
          textStyle: {
            color: '#409eff'//字体颜色
          },
          data: this.legendArr
        },
        color: this.trendColor,
        series: [
          {
            name: '电梯报警类型',
            type: 'pie',
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      this.chart.setOption(options);
    },
  },
};
</script>
