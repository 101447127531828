<template>
  <div>
    <div
      style="height: 25vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <el-form
        label-position="right"
        label-width="120px"
        ref="personnelForm"
        :model="personnelForm"
        :rules="formRules"
      >
        <el-row style="margin: 10px 0">
          <el-col :span="24">
            <el-form-item label="人员名称:" prop="name">
              <el-input
                disabled
                v-model="personnelForm.name"
                placeholder="请输入"
                class="wid90"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="维保单位:" prop="orgName">
              <el-input
                class="wid90"
                disabled
                v-model="personnelForm.orgName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备ID:" prop="capCode">
              <el-input
                class="wid90"
                v-model="personnelForm.capCode"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="text-align: right; padding-right: 20px" class="dialog-footer">
      <el-button size="small" type="primary" :loading="loading" @click="dioSub"
        >确 定</el-button
      >
      <el-button size="small" @click="dialogClose">取 消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: ["tit",'useType', "personnelForm"],
  data() {
    return {
      // 人员类型
      personnelType: [],
      loading: false,
      formRules: {
        name: [
          {
            required: true,
            message: "人员名称不能为空",
            trigger: "blur",
          },
        ],
        orgName: [
          {
            required: true,
            message: "维保单位不能为空",
            trigger: "blur",
          },
        ],
        capCode: [
          {
            required: false,
            validator: this.changeAccount,
            trigger: "blur",
          },
        ],
      },
      userList: []
    };
  },
  filters: {},
  methods: {
    getUserApp(){
      console.log('userList');
      this.$http.get("/api/system/web/appUser/getAllAccount").then((res) => {
        if (res.data.success) {
          this.userList = res.data.data
        }
      });
    },
    
    changeAccount(rule, value, callback) {
      // if (value !== '') {
        if (/^[0-9]{4,20}$/.test(value) || value == '') {
            callback()
        } else {
          callback(new Error('设备id只可输入长度为4-20的数值!'))
        }
      // } else {
      //   callback(new Error('设备id不能为空'))
      // }
    },
    // 弹出框提交
    dioSub() {
      this.loading = true;
      this.$refs.personnelForm.validate(async (valid) => {
        if (valid) {
          this.$emit("subForm", this.personnelForm);
        }
      });
      this.loading = false;
    },
    dialogClose() {
      this.$emit("colseForm");
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      // this.onRefer();
    });
  },
  destroyed(){
    console.log('销毁');
  }
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}

.wid90 {
  width: 95%;
}

</style>
