<template>
  <div>
    <div class="search">
      <div class="searchLeft">
        <el-input placeholder="请输入路径" style="margin-right:15px" v-model.trim="route" type="text" />
        <el-button type="primary" size="small" @click="enterFile">进入</el-button>
      </div>
      <el-button type="primary" size="small" @click="openDialog">已上传</el-button>
    </div>
    <h4>
      当前文件路径：
      <template v-for="(item, index) in arrPath">
        <el-button style="margin-left: 0px" type="text" :key="index" @click="jump(index)">
          {{ item ? (index === 1 ? item.substring(1) : item) : "/" }}
        </el-button>
      </template>
    </h4>
    <el-table v-loading="loading" ref="equipmentList" :data="equipmentList" height="50vh" border stripe fit>
      <el-table-column label="序号" type="index" width="50px" align="center" />

      <el-table-column prop="Path" label="名称" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.IsDir" type="text"
            @click="clickFile(scope.row.Path)">{{ scope.row.Path }}</el-button>
          <span v-else>{{ scope.row.Path }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="IsDir" label="类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.IsDir ? "文件夹" : "文件" }}
        </template>
      </el-table-column>
      <el-table-column prop="Size" label="大小" align="center">
        <template slot-scope="scope">
          {{ calculationFile(scope.row.Size) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="150px">
        <template slot-scope="scope">
          <template v-if="!scope.row.IsDir">
            <!-- {{ upload ? "" : "" }} -->
            <el-button v-if="!scope.row.upload" type="primary" size="small" @click="uploadTxt(scope.row)"
              v-text="'上传'" />
            <p v-else style="margin: 7px 0" >已请求</p>
            <!-- <p style="margin: 7px 0" v-else>{{ scope.row.upload }}</p> -->
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 已上传弹框 -->
    <el-dialog title="已上传文件" :visible.sync="visible" append-to-body width="1200px" top="8vh"
      :close-on-click-modal="false" @close="closeDialog">
      <el-table v-loading="uploadLoading" ref="multipleTable" :data="uploadList" max-height="520px" height="55vh" border
        stripe fit>
        <el-table-column label="序号" type="index" width="50px" align="center" fixed="left" />

        <el-table-column prop="name" label="名称" align="center" />
        <el-table-column prop="path" label="文件路径" align="center" />
        <el-table-column prop="size" label="文件大小" align="center">
          <template slot-scope="scope">{{ calculationFile(scope.row.size) }} </template>
        </el-table-column>
        <el-table-column label="上传时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="downLoadTxt(scope.row)" v-text="'下载'" />
            <!-- <p style="margin: 9px 0" v-else>上传中</p> -->
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <Pagination :total="videoListTotal" :page.sync="videoPage.current" :limit.sync="videoPage.size"
          @pagination="videoRulePage" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  export default {
    components: {
      Pagination,
    },
    computed: {
      // ...mapGetters(["serverFile", "rtcInfo"]),
    },
    props: {
      deviceCode: {
        type: String,
        default: '',
      },
      Id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        visible: false,
        loading: false,
        equipmentList: [], // 列表数据
        route: '', // 路径
        uploadList: [], // 已上传列表
        videoListTotal: 0, // 已上传条数
        uploadLoading: false,
        currentPath: '/',
        arrPath: [''],
        timeId: null,
        showBtn: true, // 上传按钮
        upload: '0%',
        // 上传页码
        videoPage: {
          current: 1,
          size: 10,
        },
        i: 0,
      }
    },

    created() {
      // this.loading = true
      // this.getFileList('/')
      // this.loading = false
    },
    mounted() {},
    beforeDestroy() {},

    methods: {
      calculationFile(file) {
        if (file > 10240) {
          const fileSize = `${(file / 1024 / 1024).toFixed(2)} MB`
          return fileSize
        } else if (file > 10) {
          const fileSize = `${(file / 1024).toFixed(2)} KB`
          return fileSize
        } else {
          const fileSize = `${file} B`
          return fileSize
        }
      },
      init() {
        this.loading = true
        this.getFileList('/')
        this.loading = false
      },

      // 获取文件列表
      getFileList(url) {
        if (this.socketApiCarport.stopCarportHeartBeat) {
          let agentData = {
            act: 'get_server_file_list',
            url: url,
            device_id: this.deviceCode,
          }
          this.socketApiCarport.sendCarportSock(agentData, (e) => {
            console.log(e, '设备日志列表')
            if (e.cmd == 'get_server_file_list' && e.s) {
              this.equipmentList = e.d && e.d !== '' ? JSON.parse(e.d) : []
            }
            this.loading = false
          })
        }
      },
      // 已上传弹框
      openDialog() {
        this.visible = true
        const data = {
          bikeId: this.Id,
          type: 2,
          status: 1,
          current: this.videoPage.current,
          size: this.videoPage.size,
        }
        this.$http
          .post(
            `/api/bike/web/bikeLog/getDeviceLog`,
            data
          )
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.uploadList = [...data.records] ?? []
              this.videoListTotal = data.total ?? 0
              this.$nextTick(() => {
                if (this.videoListTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.videoList = []
              this.$message.error(res.data.msg)
            }
          })
      },

      // 分页
      videoRulePage(data) {
        this.videoPage.current = data.page
        this.videoPage.size = data.limit
        this.openDialog()
      },
      closeDialog() {
        this.visible = false
        clearTimeout(this.timer)
      },

      //跳转连接
      jump(index) {
        this.arrPath = this.arrPath.slice(0, index + 1)
        this.currentPath =
          this.arrPath.join('') != '' ? this.arrPath.join('') : '/'
        this.getFileList(this.currentPath)
      },
      //进入下一集文件
      clickFile(fileName) {
        this.loading = true
        this.arrPath.push(`/${fileName}`)
        this.currentPath =
          this.currentPath === '/' ?
          `${this.currentPath}${fileName}` :
          `${this.currentPath}/${fileName}`
        this.getFileList(this.currentPath)
      },

      //进入文件
      enterFile() {
        this.currentPath = this.route ? `/${this.route}` : '/'
        this.arrPath = this.route.split('/').map((item) => {
          if (item != '') {
            return `/${item}`
          }
        })
        this.getFileList(this.currentPath)
      },

      //上传文件
      uploadTxt(row) {
        // console.log(fileName);
        if (!this.showBtn) {
          this.$message('正在上传')
        }
        if (row.Size / 1024 / 1024 / 50 > 1) {
          this.$message({
            title: 'error',
            message: '不要上传超过50兆的文件',
          })
          return
        }
        this.showBtn = false
        this.$set(
          this.equipmentList.find((item) => item.Path === row.Path),
          'upload',
          '0%'
        )
        const url =
          this.currentPath === '/' ?
          `${this.currentPath}${row.Path}` :
          `${this.currentPath}/${row.Path}`
        const urlArr = []
        urlArr.push(url)
        const data = {
          deviceId: this.deviceCode,
          fileList: urlArr,
          type: 2,
          md5: row.Md5,
          talkBackId: row.talkBackId
        }
        this.$http.post('/api/bike/web/bikeLog/pushFile', data).then((res) => {
          if (res.data.success) {
            this.$message.success(res.data.msg)
            // 循环调用获取进度
            // this.timer = setInterval(() => {
            //   // this.get_progress(row)
            // }, 5 * 1000)
          } else {
            this.$set(
              this.equipmentList.find((item) => item.Path === row.Path),
              'upload',
              ''
            )
            this.$message.error(res.data.msg)
          }
        })
      },

      // 获取对应文件的上传进度
      // get_progress(row) {
      //   this.i++
      //   this.$http
      //     .get(`/api/group1/uploadGetProgress?X-Progress-ID=${row.Md5}`)
      //     .then((res) => {
      //       if (res.data) {
      //         const data = res.data

      //         if (data.status === 'uploading') {
      //           this.upload = `${data.message}%`
      //           this.$set(
      //             this.equipmentList.find((item) => item.Path === row.Path),
      //             'upload',
      //             this.upload
      //           )
      //         }
      //         // 上传完成
      //         if (data.status === 'starting') {
      //           this.upload = '50%'

      //           this.$set(
      //             this.equipmentList.find((item) => item.Path === row.Path),
      //             'upload',
      //             this.upload
      //           )
      //         }
      //         // // 上传开始
      //         // if (data.status === '') {
      //         //   const num = this.i * 5 < 100 ? this.i * 5 : 99
      //         //   console.log(num,'status');
      //         //   this.upload = `${num}%`

      //         //   this.$set(
      //         //     this.equipmentList.find(
      //         //       (item) => item.Path === row.Path
      //         //     ),
      //         //     'upload',
      //         //     this.upload
      //         //   )
      //         // }
      //         // 上传完成
      //         if (data.status === 'ok') {
      //           this.i = 0
      //           this.upload = '100%'
      //           this.$set(
      //             this.equipmentList.find((item) => item.Path === row.Path),
      //             'upload',
      //             this.upload
      //           )
      //           clearInterval(this.timer)
      //         }
      //         // 删除定时器对应md5的上传文件
      //         if (this.upload === '100%') {
      //           clearInterval(this.timer)
      //         }
      //       }
      //     })
      // },

      //下载文件
      async downLoadTxt(item) {
        // window.open(filePath)
        console.log(item, 'item.url');
        // window.open(item.url, '_blank')
        let fileName = item.name

        const x = new XMLHttpRequest()
        x.open('GET', item.url, true)
        x.responseType = 'blob'
        x.onload = function() {
          const url = window.URL.createObjectURL(x.response)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          a.click()
          // document.body.removeChild(a)
          // 然后移除
        }
        x.send()
        console.log("下载文件123123")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search {
    display: flex;
    align-content: center;
    justify-content: space-between;

    .searchLeft {
      width: 45%;
      display: flex;
      align-content: center;
      justify-content: space-between;

      >>>.el-input {
        width: 80%;
      }
    }
  }
</style>
