<template>
</template>

<script>
	export default{
		created() {
			window.history.go(-1)
		}
	}
</script>

<style>
</style>
