<template>
  <div>
    <el-form :inline="true" class="el-form-style" :model="searchForm">
      <el-form-item label="单位名称">
        <el-input placeholder="请输入" v-model="searchForm.orgName" clearable></el-input>
      </el-form-item>
      <el-form-item label="单位类型">
        <el-select v-model="searchForm.unitType" filterable clearable>
          <el-option v-for="item in typeData"
          :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="checkInfo">查询</el-button>
        <el-button type="warning" @click="reseat">重置</el-button>
      </el-form-item>
    </el-form>

    <template v-if="noticetotal != 0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-102" style="width:100%; margin: 20px 0;">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="单位名称" align="center" prop="name"></el-table-column>
        <el-table-column label="单位地址" align="center" prop="address"></el-table-column>
        <el-table-column label="联系人" align="center" prop="contact"></el-table-column>
        <el-table-column label="联系方式" align="center" prop="phone"></el-table-column>
        <el-table-column label="单位类型" align="center">
          <template slot-scope="scope">
            {{
              typeData.find(item => item.value == scope.row.type)?
              typeData.find(item => item.value == scope.row.type).label : ''
            }}
          </template>
        </el-table-column>
      </el-table>
      <Pagination
      :total="noticetotal"
      :page.sync="searchForm.current"
      :limit.sync="searchForm.size"
      @pagination=" changPage"/>
    </template>

		<div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default{
    components:{
      Pagination
    },
    data(){
      return{
        tableData:[],
        typeData:[],
        noticetotal:0,
        loading:true,
        searchCallback:null,
        searchForm:{
          current:1,
          size:10,
        },
        unitForm:{
          current:1,
          size:10,
          type:''
        },
        tableLoading: true
      }
    },
    methods:{
      //字典获取
      UnitType(item){
        this.$http.post(`/api/system/web/dict/dictionary?codes=${item}`)
        .then((res) =>{
          console.log('type',res)
          if(res.data.success){
            const unitList = res.data.data.UNIT_LIST ?? []
            const otherList = res.data.data.OTHER_UNIT ?? []
            this.typeData = [...unitList, ...otherList]
          }
        })
      },
      //初始化
      init(callback){
        this.searchCallback = callback;
        this.loading = false;
        // this.$nextTick(()=>{
        //   this.onRefer()
        // })
      },
      //分页
      changPage(data){
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer();
      },
      //重置
      reseat(){
        this.searchForm = {
          current:1,
          size:10
        }
        this.onRefer();
      },
      //查询
      checkInfo(){
        this.searchForm.current = 1
        this.onRefer();
      },
      //关联单位信息获取
      onRefer(){
        this.tableLoading = true
        this.$http.post('/api/ele/web/elevatorInfo/eleAssociatedUnit',this.searchForm)
        .then((res)=>{
          console.log('单位',res.data)
          if(res.data.success){
            this.tableData = res.data.data.records ?? []
            this.noticetotal = res.data.data.total ?? 0
            this.$nextTick(()=>{
              if(this.noticetotal > 0){
                this.$refs.multipleTable.bodyWrapper.scrolltop = 0
              }
            })
          }else{
            this.$message.error(res.data.data.msg)
          }
          this.tableLoading = false
        })
      }
    },
    created(){
      this.onRefer();
      this.UnitType(['UNIT_LIST','OTHER_UNIT'])
    }
  }
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
