<template>
  <div>
    <!-- 高德地图 -->
    <div id="mapWB" :style="{width: width,height: height}"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    point: {
      type: Array,
      default: () => [114.086228, 22.548266],
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '50vh'
    },
    isCaps: {
      type: Boolean,
      default: true
    },
    isWalk: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      map: null,
      initPoint: [114.086228, 22.548266],
      markers: [],
      capImage: require('@/assets/aqm.png'),
      capWalk: require('@/assets/walk.gif'),
      image: require('@/assets/newBigmianban/nj.png')
    };
  },
  filters: {},
  methods: {
    mapInit() {
      console.log(this.point, 'this.point');
      this.map = new AMap.Map("mapWB", {
        zoom: 14, // 级别
        center: this.point,
        resizeEnable: true,
      });
      this.setMapMarker(this.point);
    },
    // 设置点击位置的标记
    setMapMarker(lnglat) {
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: this.isWalk ? this.capWalk : (this.isCaps ? this.capImage : this.image), // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      });
      const marker = new AMap.Marker({
        map: this.map,
        position: lnglat ?? this.point,
        icon: icon,
        offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
      });
      // 将 markers 添加到地图
      this.markers.push(marker);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.mapInit();
    })
  },
};
</script>
<style lang="scss" scoped>
#mapWB {
  width: 100%;
  border-radius: 5px;
}
</style>
