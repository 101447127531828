<template>
  <div>
    <div class="page-top">
      <el-form inline>
        <el-form-item label="流水类型">
          <el-select placeholder="请选择流水类型" v-model="formList.flowType" clearable>
            <el-option v-for="(item,index) in waterOptions" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收支">
          <el-select placeholder="请选择收支" v-model="formList.incomeType" clearable>
            <el-option :key="1" :value="0" label="收入"></el-option>
            <el-option :key="2" :value="1" label="支出"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"> 查询</el-button>
          <el-button type="warning" @click="reast"> 重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-325"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" />
        <el-table-column prop="flowType" label="流水类型" align="center" min-width="120px">
          <template slot-scope="scope">
            {{waterOptions.filter(item=>item.value == scope.row.flowType)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="incomeType" label="收支" align="center" min-width="120px">
          <template slot-scope="scope">
            {{scope.row.incomeType == 0 ? '收入' : '支出'}}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="金额" align="center" min-width="120px" />
        <el-table-column prop="previousAmount" label="更新前余额" align="center" min-width="120px" />
        <el-table-column prop="subsequentAmount" label="更新后余额" align="center" min-width="120px" />
        <el-table-column prop="oddNumber" label="业务单号" align="center" min-width="120px" />
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="120px" />
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formList.current" :limit.sync="formList.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    components: {
      Pagination
    },
    props: ['Id', 'money'],
    data() {
      return {
        dictionarTotal: 0,
        current: 1,
        formList: {
          current: 1,
          size: 10,
        },
        tableDate: [],
        waterOptions: [{
            value: 0,
            label: '充值',
          },
          {
            value: 1,
            label: '提现',
          },
          {
            value: 2,
            label: '维保扣款',
          },
          {
            value: 3,
            label: '维保收款',
          },
          {
            value: 4,
            label: '维保退款',
          },
          {
            value: 5,
            label: '提现驳回',
          }
        ],
        tableLoading: true
      }
    },
    created() {
      this.getDate()
    },
    watch: {
      // Id(newvalue) {
      //   if (newvalue == this.Id) {
      //     this.getDate()
      //   }
      // },
      money(newvalue) {
        if (newvalue == this.money) {
          this.getDate()
        }
      },
    },
    methods: {
      getDate() {
        this.formList.orgId = this.Id != undefined ? this.Id : ''
        const data = {
          orgId: this.orgId,
          ...this.formList
        }
        this.tableLoading = true
        this.$http.post("/api/system/web/statement/getAccountStatementList", data).then((res) => {
          
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records ? res.data.data.records : []
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        this.tableLoading = false
        })
      },
      noticePage(data) {
        this.formList.current = data.page
        this.formList.size = data.limit
        this.getDate()
      },
      search() {
      this.formList.current = 1
        this.getDate()
        this.$emit('checkInfo')
      },
      reast() {
        this.formList= {
          current: 1,
          size: 10,
        }
        this.getDate()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
