<template>
  <div style="height: 70vh;">
    <div class="Newcontent scrollElement">
      111
      <!-- {{forms.content}} -->
      <!-- <div v-html="forms.content" v-if="forms.content"></div> -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    created() {
      console.log(223, this.$route)
    },
  }
</script>

<style>
</style>
