<template>
  <div>
    <!-- v-if="eleInfo.deviceCode" -->
    <div class="supervisoryBox">
      <template v-if="diaDate.deviceInfoDTO && !diaDate.isOnline">
        <!-- 24小时在线 -->
        <div class="instabilityStatus">
          <img :src="require('@/assets/eleStatus.png')" style="width: 200px;z-index: 99; object-fit: contain;" />
          <span style="font-size: 18px; font-weight: bolder; margin-top: 20px;">当前设备信号不稳定，暂时无法查看实时监控</span>
        </div>
      </template>
      <AcContainer v-else :deviceCode="deviceCode" :eleInfo="diaDate.deviceInfoDTO" :vlSos="vlSos" :sosDialog="false"
        @update="update" />
    </div>


    <div class="dtBox2" style="margin-top: 20px;">
      <div class="titleHeader">
        <h3 class="changepointer">
          AI摄像头：{{diaDate.deviceInfoDTO&&diaDate.deviceInfoDTO.name?diaDate.deviceInfoDTO.name : '-'}}（{{diaDate.deviceInfoDTO&&diaDate.deviceInfoDTO.deviceCode ? diaDate.deviceInfoDTO.deviceCode : '-'}}）
        </h3>
      </div>
      <el-descriptions :column="4" border class="descriptionsType">
        <el-descriptions-item label="信号强度">
          <el-image
            v-if="diaDate.heartbeatLocation && (new Date().getTime() - diaDate.deviceInfoDTO.lastLogoutTime < 24 * 3600 * 1000) "
            fit="contain" :src="require(`@/assets/monitor/L0.png`)" style="width:25px;height:25px" />
          <el-image
            v-else-if="diaDate.heartbeatLocation && diaDate.heartbeatLocation.netStrength && diaDate.heartbeatLocation.netStrength.indexOf('L') > -1"
            fit="contain" :src="require(`@/assets/monitor/${diaDate.heartbeatLocation.netStrength}.png`)"
            style="width:25px;height:25px" />
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="IP地址">
          {{diaDate.heartbeatLocation && diaDate.heartbeatLocation.eth0_ip ? diaDate.heartbeatLocation.eth0_ip : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="SIM卡号">
          {{diaDate.heartbeatLocation && diaDate.heartbeatLocation.phoneNumber ? diaDate.heartbeatLocation.phoneNumber : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="心跳时间">
          {{diaDate.heartbeatLocation && diaDate.heartbeatLocation.nowTime ? $formatDate(diaDate.heartbeatLocation.nowTime) : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="4G模块ID">
          {{diaDate.deviceInfoDTO && diaDate.deviceInfoDTO.deviceImei4g ? diaDate.deviceInfoDTO.deviceImei4g : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="SD卡剩余容量">
          {{diaDate.deviceInfoDTO && diaDate.deviceInfoDTO.sdVolume ? diaDate.deviceInfoDTO.sdVolume+'MB' : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="本月流量">
          {{diaDate.deviceInfoDTO && diaDate.deviceInfoDTO.monthFlow ? diaDate.deviceInfoDTO.monthFlow+'M' : '-'}}
        </el-descriptions-item>
        <template v-if="adminInfo.userName == 'admin'">
          <el-descriptions-item label="最近视频时间">
            {{diaDate.videoStatus && diaDate.videoStatus.videoTime ? $formatDate(diaDate.videoStatus.videoTime) : '-'}}
          </el-descriptions-item>
          <el-descriptions-item label="最近视频状态">
            {{diaDate.videoStatus&&diaDate.videoStatus.visStatus ? (diaDate.videoStatus.visStatus == 1 ? '成功' :(diaDate.videoStatus.visStatus == 2?'失败' : '-')) : '-'}}
          </el-descriptions-item>
          <el-descriptions-item label="APK版本">
            {{diaDate.deviceInfoDTO && diaDate.deviceInfoDTO.appVersion ? diaDate.deviceInfoDTO.appVersion : '-'}}
          </el-descriptions-item>
          <el-descriptions-item label="软件版本">
            {{diaDate.deviceInfoDTO && diaDate.deviceInfoDTO.apkVersion ? diaDate.deviceInfoDTO.apkVersion : '-'}}
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </div>
    <!-- v-if="eleInfo.deviceCode" -->
    <div class="dtBox2" style="margin-top: 20px;">
      <div class="titleHeader">
        <h3 class="changepointer">车棚信息</h3>
      </div>
      <el-descriptions :column="3" border class="descriptionsType">
        <el-descriptions-item label="车棚名称">
          {{diaDate.carportInfo && diaDate.carportInfo.name ? diaDate.carportInfo.name : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="所在小区">
          {{diaDate.carportInfo && diaDate.carportInfo.plotName ? diaDate.carportInfo.plotName : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="AI摄像头数量">
          {{diaDate.deviceInfoDTO &&  diaDate.deviceInfoDTO.carportSize ? diaDate.deviceInfoDTO.carportSize : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="车棚位置">
          {{diaDate.carportInfo ? diaDate.carportInfo.address : '-'}}
        </el-descriptions-item>
      </el-descriptions>
    </div>


  </div>
</template>

<script>
  import {
    formatDate
  } from '@/util'
  import AcContainer from './acContainer/index.vue' // 电梯监控 eleMonitoring
  export default {
    props: ['Id', 'deviceCode', 'eleInfo'],
    components: {
      AcContainer
    },
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        timeOutType: null,
        getDeTime: null,
        showDio: true,
        count: 0,
        diaDate: {},
        vlSos: 0
      }
    },
    beforeDestroy() {
      // getDeTime = null
      this.showDio = false
      clearTimeout(this.timeOutType)
      clearInterval(this.getDeTime)
    },
    destroyed() {
      console.log('详情销毁')
      clearTimeout(this.timeOutType)
      clearInterval(this.getDeTime)
    },
    created() {
      this.timeOutType = setTimeout(() => {
        if (this.showDio) {
          this.getFacility()
          this.getDeTime = setInterval(() => {
            this.getFacility()
          }, 2000)
        }
      }, 1000)
    },
    methods: {
      update(time, type) {
        console.log('update2')
        this.eleInfo.videoTime = this.getSysremTime(time)
        this.eleInfo.videoStatus = type
        this.$forceUpdate()
      },
      getSysremTime(time) {
        return formatDate(new Date(time), 'yyyy-MM-dd HH:mm:ss')
      },
      getFacility() {
        console.log(111)
        this.count++
        this.nowTime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
        if (this.socketApiCarport.stopCarportHeartBeat) {
          let agentData = {
            act: 'ma_get_carport_detail',
            id: this.Id,
          }
          this.socketApiCarport.sendCarportSock(agentData, (e) => {
            if (e.cmd === 'ma_get_carport_detail' && e.status) {
              // console.log(e, 'e')
              if (e.data) {
                this.diaDate = JSON.parse(e.data)
                this.vlSos = this.diaDate.heartbeatLocation.vlSos
                console.log(this.diaDate, 'hh')
              }
            }
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .supervisoryBox {
    width: 100%;
    min-height: 40vh;
    margin-right: 1%;
  }

  .instabilityStatus {
    background: #000;
    position: relative;
    flex-direction: column;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
