<template>
  <div
    :class="className"
    :style="{width:width, height:height,margin:'0 auto'}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '650px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    sosLineData: {
      type: Object,
      default: () => {
        return {
          // activeAlarm: [0, 0, 0, 0, 0, 0, 0], // 主动报警
          // voiceAlarm: [0, 0, 0, 0, 0, 0, 0], // 语音报警
          // peopleAlarm: [0, 0, 0, 0, 0, 0, 0], // 困人报警
          // date: [0, 0, 0, 0, 0, 0, 0], // 日期

          rescueNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 救援数据
          failureNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 故障数据
          maintainNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 维保数据
          claimNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 理赔数据
          sosNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 报警数据
          dateList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 日期
        }
      },
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions(data) {
      const { dateList, rescueNumList, failureNumList, maintainNumList, claimNumList, sosNumList } = data

      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = ``
            let type = ''
            let time = ''
            params.map((item) => {
              switch (item.seriesName) {
                case '主动报警':
                  type = '次'
                  break
                case '语音报警':
                  type = '次'
                  break
                case '困人报警':
                  type = '次'
                  break

                default:
                  break
              }
              time = item.name
              value += `${item.seriesName}：${item.value + type} <br>`
            })
            return `${time}<br>${value}`
          },
        },
        textStyle: {
          color: '#409eff', // 所有文字的颜色
        },
        legend: {
          right: '30px',
          top: '1%',
          bottom: '10px',
          data: ['救援', '故障', '维保', '理赔', '报警'],
          textStyle: {
            color: '#409EFF',
          },
        },
        grid: {
          left: '10%',
          right: '4%',
          top: '12%',
          bottom: '20%',
          containLabel: false,
          borderColor: '#fff',
        },
        xAxis: {
          type: 'category',
          show: true,
          data: dateList,
          boundaryGap: false,
          splitLine: { show: false }, // 去除网格线,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed', // 坐标轴颜色
            },
          },
          axisLabel: {
            interval: 6,
            rotate: 0,
            textStyle: {
              fontSize: 14,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          show: true,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed',
            },
          },
          scale: true, //自适应
          minInterval: 20, //分割刻度
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: `救援`,
            type: 'line',
            barWidth: '30%',
            data: rescueNumList,
            itemStyle: {
              normal: {
                color: '#4b9845',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `故障`,
            type: 'line',
            barWidth: '30%',
            data: failureNumList,
            itemStyle: {
              normal: {
                color: '#7ae2ee',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `维保`,
            type: 'line',
            barWidth: '30%',
            data: maintainNumList,
            itemStyle: {
              normal: {
                color: '#eb696a',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `理赔`,
            type: 'line',
            barWidth: '30%',
            data: claimNumList,
            itemStyle: {
              normal: {
                color: '#088bed',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `报警`,
            type: 'line',
            barWidth: '30%',
            data: sosNumList,
            itemStyle: {
              normal: {
                color: '#ffb100',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
        ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
