<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.userName"></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">日期</span>
          </span>
          <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55px" />
          <el-table-column type="index" label="序号" width="55px" />
          <el-table-column align='center' prop="userName" label="人员名称" />
          <el-table-column align='center' label="类型">
            <template slot-scope="scope">{{scope.row.type == 0? '日管控':(scope.row.type == 1?'周排查' :'月调度')}}</template>
          </el-table-column>
          <el-table-column align='center' prop="typeName" label="职位" />
          <el-table-column align='center' prop="orgName" label="所属单位" />
          <el-table-column align='center' prop="createTime" label="提交时间" />
          <el-table-column fixed="right" align='center' prop="address" label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="info(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
    <RgkStock v-if="visibale" @close="back" :Id="Id"/>
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination'
  import RgkStock from './components/Rgkstock.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    components: {
      Pagination,
      RgkStock
    },
    data() {
      return {
        visibale:false,
        dictionarTotal: 0,
        tableData: [], //多选的数据
        formInline: {
          current: 1,
          size: 10,
          userName: '',
        },
        Id:'',
        sosType: '',
        dateTime: [],
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        tableLoading: true
      }
    },
    methods: {
      //获取字典数据
      getDictionar(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res;
              console.log(data, 'data')
              this.sosType = data.POSITION;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      getTName(typeDate, obj) {
        let tn = []
        console.log(typeDate, 'typeDate')
        typeDate.forEach(qs => {
          console.log(qs, 'qs')
          obj.forEach(qs2 => {
            if (qs.value == obj) {
              tn.push(qs.label)
              console.log(qs.label, 'label')
              return
            }
          })
        })
        return tn.join(',')
      },
      onRefer(flag) {
        if (flag) {
          this.formInline.current = 1
        }
        console.log(this.dateTime, 'dateTime')
        let time = this.dateTime != null? formatDate(new Date(this.dateTime[1]), 'yyyy-MM-dd 23:59:59'):''
        const time1 = this.dateTime != null? new Date(this.dateTime[0]).getTime():''
        const time2 = this.dateTime != null? new Date(time).getTime():''
        const data = {
          ...this.formInline,
          startTime: time1 ,
          endTime: time2 ,
        }
        this.tableLoading = true
        this.$http
          .post("/api/base/web/getBaseDayMCList", data)
          .then((res) => {
            console.log(res, 'res')
            if(res.data.code == 200){
              var that = this
              res.data.data.records.forEach((qs) => {
                qs.typeName = this.getTName(this.sosType, qs.positionName.split(','))
              })
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
            }
					  this.tableLoading = false
          })
      },
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          userName: '',
          startTime: '',
          endTime: ''
        }
        this.dateTime=[]
        this.onRefer()
      },
      handleSelectionChange() {

      },
      info(id){
        this.Id=id
        this.visibale = true
      },
      back(){
        this.visibale = false
      },
    },
    mounted() {
      this.getDictionar('POSITION');
      this.$nextTick(()=>{
        this.onRefer()
      })
    },
    created() {
      // this.getDictionar('POSITION');
      // this.$nextTick(()=>{
      //   this.onRefer()
      // })
      // this.onRefer()
    },
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
