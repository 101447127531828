<template>
  <el-dialog
    :title="'选择维保项'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-table
        ref="selectItemList"
        :data="selectItemList"
        border
        stripe
        fit
        max-height="500px"
        highlight-current-row
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="!type"
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column align="center" type="index" label="序号" width="50px" />
        <el-table-column
          align="center"
          prop="name"
          label="维保项名称"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="orgName"
          label="维保类型"
          min-width="150px"
        >
          <template slot-scope="scope">
            {{
              maintainType.find(
                (item1) => Number(item1.value) === scope.row.type
              )
                ? maintainType.find(
                    (item1) => Number(item1.value) === scope.row.type
                  ).label
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="updateTime"
          label="更新时间"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="isDeleted"
          label="维保项目数量"
          min-width="150px"
        >
          <template slot-scope="scope">
            {{ JSON.parse(scope.row.itemCodes).length }}
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="selectItemListTotal > 0"
        :total="selectItemListTotal"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.size"
        @pagination="selectItemListGet"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="selectunitDialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination";

export default {
  components: {
    Pagination,
  },
  props: ["ruleId"],

  data() {
    return {
      visible: false,
      loading: true,
      selectItemListQuery: {
        current: 1,
        size: 10,
      },
      selectItemList: [],
      selectItemListTotal: 0,
      selectunitCallback: null,
      selectRow: {},
      unitId: [],
      selectData: [],
      initArr: [],
      // 维保项类型
      maintainType: [],

      // 表格参数
      searchForm: {
        name: "",
        maintainUnitName: "",
        propertyUnitName: "",
        current: 1,
        size: 10,
      },

      type: null, // 是否查看
    };
  },
  methods: {
    init(id, type, selectData, callback) {
      this.type = type;
      this.selectunitCallback = callback;
      this.unitId = [id] ?? [];
      this.initArr = selectData;
      this.visible = true;
      this.loading = false;
      this.$nextTick(() => {
        this.selectItemListGet();
      });
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.selectItemListGet();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        orgName: "",
      };
      this.selectItemListGet();
    },

    // 选择
    handleSelectionChange(val, row) {
      this.initArr = val;
      this.selectItemList.forEach((item) => {
        if (val[val.length - 1] === item) {
          this.$refs.selectItemList.toggleRowSelection(item, true);
        } else {
          this.$refs.selectItemList.toggleRowSelection(item, false);
        }
      });
    },

    selectItemListGet() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      this.$http.post("/api/ele/web/maintainItem/getList", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.loading = false;
          this.selectItemList = data.records ?? [];
          this.selectItemListTotal = data.total ?? 0;
          this.$nextTick(() => {
            if (this.selectItemListTotal > 0 && this.$refs.selectUnitList) {
              this.$refs.selectUnitList.bodyWrapper.scrollTop = 0;
            }
            if (this.unitId.length > 0) {
              this.selectItemList.forEach((ele) => {
                this.unitId.forEach((item) => {
                  if (item === ele.id) {
                    this.$refs.selectItemList.toggleRowSelection(ele, true);
                  }
                });
              });
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    async selectunitConfirm() {
      const unitData = this.initArr;

      if (unitData.length > 0) {
        console.log(unitData, "unitData");
        this.$emit("transferUnit", unitData);
        this.visible = false;
      } else {
        this.$message({
          message: "请选择维保项",
          type: "error",
        });
      }
    },

    selectunitDialogClose() {
      this.visible = false;
    },

    // 获取字典数据
    getDictionar(code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            this.maintainType = data.MAINTAIN_TYPE;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
  created() {
    this.getDictionar(["MAINTAIN_TYPE"]);
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table__header-wrapper .el-checkbox {
  display: none;
  position: relative;
}
</style>
