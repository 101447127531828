<template>
  <div>
    <div class="main">
      <el-row >
        <el-col :span="10">
            <div class="dataBox">
                <div style="width: 100%; min-height: 25vh; padding: 0 15px;">
                    <div class="flexBox">
                        <h4 style="width: 50%"> 报警电梯TOP5 </h4>
                        <div>
                            <h4 style="display:inline-block; margin-left:10px"> <span class="circleBox TwoAgoColor"></span> {{TwoMonthAgo}}月 </h4>
                            <h4 style="display:inline-block; margin-left:10px"> <span class="circleBox lastColor"></span>{{lastMonth}}月 </h4>
                        </div>
                    </div>
                    <div style="height: 180px">
                        <div v-for="(item, index) in sosData" :key="item.eleId" class="flexBox" style="margin-bottom: 15px">
                            <div style="width: 50%" class="overTitle" :title="`${index + 1}.${item.name}`">{{`${index + 1}.${item.name}`}}</div>
                            <div style="width: 50%">
                            <el-progress
                            :title="item.num - item.lastNum >= 0 ? `${item.lastNum} / ${item.num} ` : `${item.num} / ${item.lastNum}`"
                            :percentage="item.num - item.lastNum >= 0 ? Number(((item.lastNum / item.num) * 100).toFixed(0))  : Number(((item.num / item.lastNum) * 100).toFixed(0))"
                            :color="item.num > item.lastNum ? '#3cb4ff' : '#84ff0a'"
                            :define-back-color="item.num > item.lastNum ? '#84ff0a' : '#3cb4ff'"
                            :stroke-width="10"
                            :show-text="false"></el-progress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :span="8" style="width: 33.2%;">
            <div class="dataBox" >
                <div style="width: 100%; min-height: 25vh; padding: 0 15px;">
                    <div class="flexBox">
                        <h4 style="width: 50%"> 报警异常电梯TOP5 </h4>
                        <h4 style="width: 15%; text-align: center;"> {{TwoMonthAgo}}月(次) </h4>
                        <h4 style="width: 17%; text-align: center;"> {{lastMonth}}月(次) </h4>
                        <h4 style="width: 18%; text-align: center;"> 环比增长 </h4>
                    </div>
                    <div style="height: 180px">
                        <div v-for="(item, index) in abnormalData" :key="item.eleId" class="flexBox" style="margin-bottom: 15px">
                            <div style="width: 50%" class="overTitle" :title="`${index + 1}.${item.name}`">{{`${index + 1}.${item.name}`}}</div>
                            <div style="width: 15%; text-align: center;">{{item.lastNum}}</div>
                            <div style="width: 17%; text-align: center;">{{item.firstNum}}</div>
                            <div style="width: 18%; text-align: center;">{{(item.ratio * 100).toFixed(0)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :span="6">
          <div class="dataBox" >
            <div style="width: 100%; min-height: 25vh; padding: 0 15px;">
              <h4 class="headerTitle" style="margin: 20px 0 0 15px">
                电梯报警类型TOP5
              </h4>
              <PieChart v-if="sosPieDataType" :sosPieData="sosPieData" :trendColor="trendColor" style="margin: 10px auto"/>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top:20px">
        <el-col :span="24">
          <div class="dataBox">
            <div style="width: 100%; min-height: 200px;padding: 0 15px;">
                <el-col :span='12'>
                    <div class="flexBox">
                        <h4 style="width: 50%"> 运行次数TOP5 </h4>
                        <h4 style="width: 13%"> {{TwoMonthAgo}}月(次) </h4>
                        <h4 style="width: 13%"> {{lastMonth}}月(次) </h4>
                        <h4 style="width: 10%"> 趋势 </h4>
                    </div>
                    <div style="height: 15vh">
                        <div v-for="(item, index) in runsNumData" :key="item.eleId" class="flexBox" style="margin-bottom: 15px">
                            <div style="width: 50%" class="overTitle" :title="`${index + 1}.${item.name}`">{{`${index + 1}.${item.name}`}}</div>
                            <div style="width: 13%">{{item.lastNumAgo}}</div>
                            <div style="width: 13%">{{item.lastNum}}</div>
                            <div style="width: 10%"><span class="circleBox" :style="{'background': trendColor[index]}"></span></div>
                        </div>
                    </div>
                </el-col>
                <el-col :span='12'>
                    <h4 style="margin-bottom: 5px;"> 运行次数趋势 </h4>
                    <LineChart
                        v-if="isLineNum"
                        :sosLineData="runsNumLineData"
                        :trendColor="trendColor"
                        :lineType ="'num'"
                        :height="'20vh'"
                    />
                </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top:20px">
        <el-col :span="24">
          <div class="dataBox">
            <div style="width: 100%; min-height: 200px;padding: 0 15px;">
                <el-col :span='12'>
                    <div class="flexBox">
                        <h4 style="width: 50%"> 运行时间TOP5 </h4>
                        <h4 style="width: 13%"> {{TwoMonthAgo}}月(小时) </h4>
                        <h4 style="width: 13%"> {{lastMonth}}月(小时) </h4>
                        <h4 style="width: 10%"> 趋势 </h4>
                    </div>
                    <div style="height: 15vh">
                        <div v-for="(item, index) in runsTimeData" :key="item.eleId" class="flexBox" style="margin-bottom: 15px">
                            <div style="width: 50%" class="overTitle" :title="`${index + 1}.${item.name}`">{{`${index + 1}.${item.name}`}}</div>
                            <div style="width: 13%">{{item.lastNumAgo}}</div>
                            <div style="width: 13%">{{item.lastNum}}</div>
                            <div style="width: 10%"><span class="circleBox" :style="{'background': trendColor[index]}"></span></div>
                        </div>
                    </div>
                </el-col>
                <el-col :span='12'>
                    <h4 style="margin-bottom: 5px;"> 运行时间趋势 </h4>
                    <LineChart
                        v-if="isLineTime"
                        :sosLineData="runsTimeLineData"
                        :trendColor="trendColor"
                        :lineType ="'time'"
                        :height="'20vh'"
                    />
                </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import LineChart from "./lineChart";
import PieChart from "./pieChart";
var month = new Date().getMonth() + 1
export default {
  components: {
    LineChart,
    PieChart,
  },
  data() {
    return {
			TwoMonthAgo: month > 2 ? month - 2 : (month > 1 ? 12 : 11), // 上上月
			lastMonth: month > 2 ? month - 1 : (month > 1 ? 1 : 12), // 上月

      sosType: [], // 报警类型
      sosPieData: [], // 电梯报警类型(扇形)
      sosPieDataType: false, // 扇形图显示
      adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),

			// 报警top5
      sosData: [],
			// 异常top5
      abnormalData: [],
			 // 运行次数数据
      isLineNum: false, // 折线图
			runsNumData: [],
      runsNumLineData: [],
			 // 运行时间数据
      isLineTime: false, // 折线图
			runsTimeData: [],
      runsTimeLineData: [],
			trendColor: ['#ff0000', '#ffd800', '#ee7ab8', '#7ae2ee', '#0014ff', '#00ff2f'],
      adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),

    };
  },
  methods: {
    init() {
      this.getHeader();
      this.getNumLineChart()
      this.getTimeLineChart()
    },
    getHeader() {
      this.$http.get("/api/ele/web/sos/getSosEleTop").then((res) => {
        if (res.data.success) {
          this.sosPieDataType = true;
					this.sosData = res.data.data.sosData
          this.abnormalData = res.data.data.abnormalData
          res.data.data.pieData.map((item) => {
            this.sosPieData.push({
              value: item.num,
              name: this.sosType.find((sosItem) => sosItem.value == item.sosType).label,
            });
          });
        }
      });
    },
    getNumLineChart() {
      this.$http
        .get("/api/ele/web/runningNum/getElevatorRunnumList")
        .then((res) => {
          this.isLineNum = true;
          if (res.data.success) {
						res.data.data.map((item, index) => {
							item.runningNumList.map(listItem => {
								if (listItem.eleName != '') {
									this.runsNumData.push({
										eleId: listItem.eleId,
										name: listItem.eleName,
										lastNum: listItem.runningNum
									})
								} else {
									this.runsNumData.map(dataItem => {
										if (dataItem.eleId == listItem.eleId) {
											dataItem.lastNumAgo = listItem.runningNum
										}
									})
								}
							})
						})
						this.runsNumData = this.runsNumData.slice(0, 5)
						const lineData = Object.values(res.data.data[0].runningTotalNumMap)
						this.runsNumData.map(item => {
							this.runsNumLineData.push(lineData.find(lineItem => lineItem.eleName == item.name))
						})
          }
        });
    },
    getTimeLineChart() {
      this.$http
        .get("/api/ele/web/runningNum/getElevatorRunTimeList")
        .then((res) => {
          this.isLineTime = true;
          if (res.data.success) {
						res.data.data.map((item, index) => {
							item.runningNumList.map(listItem => {
								if (listItem.eleName != '') {
									this.runsTimeData.push({
										eleId: listItem.eleId,
										name: listItem.eleName,
										lastNum: listItem.totalRunTime
									})
								} else {
									this.runsTimeData.map(dataItem => {
										if (dataItem.eleId == listItem.eleId) {
											dataItem.lastNumAgo = listItem.totalRunTime
										}
									})
								}
							})
						})
						this.runsTimeData = this.runsTimeData.slice(0, 5)
						const lineData = Object.values(res.data.data[0].eleDailyRunningTimeMap)
						this.runsTimeData.map(item => {
							this.runsTimeLineData.push(lineData.find(lineItem => lineItem.eleName == item.name))
						})
          }
        });
    },
    // 获取字典数据
    getDictionar(code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            const Arr = data.SOS_TYPE.filter((item)=>{
              if(this.adminInfo.userName != 'admin'){
                return item.value != '21'&&item.value != '35'
              }else{
                return item
              }
            })
            this.sosType = Arr;
            console.log(this.sosType, "this.sosType");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
  created() {
    this.getDictionar(["SOS_TYPE"]);
    // this.getCodeChart()
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })

  },
};
</script>

<style lang="scss" scoped>
// .dataBox {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 8px;
//   color: #fff;
//   overflow: hidden;
//   border: 1px solid #00f6ff;
//   background: #23649387;
//   cursor: pointer;
//   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
// }

.flexBox{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	h4{
		font-size: 16px;
		.TwoAgoColor{
			background: #84ff0a;
		}
		.lastColor{
			background: #3cb4ff;
		}
	}
}
.overTitle	{
		overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.circleBox{
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
}

.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
