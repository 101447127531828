<template>
  <div>
    <!-- 高德地图 -->
    <div id="mapWB" :style="{ width: width, height: height }"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    point: {
      type: Array,
      default: () => [114.086228, 22.548266],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "50vh",
    },
  },
  data() {
    return {
      map: null,
      initPoint: [114.086228, 22.548266],
      markers: [],
      capsList: [], // 帽子列表
      geoCoder: null,
      infoWindow: "",
    };
  },
  filters: {},
  methods: {
    // 地图数据
    getCapMap() {
      this.$http.get("/api/cap/web/capUser/getCapUserMapList").then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.capsList = data;
          this.$nextTick(() => {
            this.mapInit();
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    mapInit() {
      this.map = new AMap.Map("mapWB", {
        zoom: 14, // 级别
        center: this.point,
        resizeEnable: true,
      });

      this.geoCoder = new AMap.Geocoder();
      this.setMapMarker();
    },

    //设置标注
    // setMapMarker(data = []) {
    //   // this.map.remove(markers)
    //   this.map.clearMap();
    //   const points = this.capsList.map((item) => ({
    //     lnglat: [item.longitude, item.latitude],
    //     otherData: item,
    //   }));
    //   const count = points.length;
    //   var _renderMarker = (context) => {
    //     let qs = context.data[0].otherData;
    //     //设置图标
    //     let isStatus = this.getStatus(qs.status);
    //     context.marker.setIcon(
    //       new AMap.Icon({
    //         size: new AMap.Size(22, 31), // 图标尺寸
    //         image: isStatus.icon, // Icon的图像
    //         imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
    //         imageSize: new AMap.Size(22, 31), // 根据所设置的大小拉伸或压缩图片
    //       })
    //     );
    //     // 设置标题
    //     context.marker.setTitle(
    //       "电梯名称:" + qs.name + ";设备号:" + qs.deviceCode
    //     );
    //     //设置点击事件
    //     context.marker.on("click", () => {
    //       this.eleInfo = qs;
    //       this.elevatorId = qs.id;
    //       this.deviceCode = qs.deviceCode;
    //       this.MonitorInfoVisible = true;
    //     });
    //     //移入移出窗口
    //     let infoWindow = new AMap.InfoWindow({
    //       isCustom: true,
    //       //创建信息窗体
    //       content: this.getContent(qs, isStatus),
    //       offset: new AMap.Pixel(-32, -40),
    //     });
    //     context.marker.on("mouseover", (e) => {
    //       infoWindow.open(this.map, e.target.getPosition());
    //     });
    //     context.marker.on("mouseout", () => {
    //       infoWindow.close();
    //     });
    //   };
    //   var _renderClusterMarker = function (context) {
    //     var div = document.createElement("div");
    //     div.innerHTML = `<div class="map-marker-icon">${context.count}</div>`;
    //     context.marker.setOffset(new AMap.Pixel(0, 0));
    //     context.marker.setContent(div);
    //   };
    //   this.cluster = new AMap.MarkerCluster(this.map, points, {
    //     gridSize: 60,
    //     renderClusterMarker: _renderClusterMarker,
    //     renderMarker: _renderMarker,
    //   });
    //   //设置聚合点点击事件
    //   this.cluster.on("click", (item) => {
    //     //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
    //     if (item.clusterData.length <= 1) {
    //       return;
    //     }
    //     //这里是计算所有聚合点的中心点
    //     let alllng = 0,
    //       alllat = 0;
    //     for (const mo of item.clusterData) {
    //       alllng += mo.lnglat.lng;
    //       alllat += mo.lnglat.lat;
    //     }
    //     const lat = alllat / item.clusterData.length;
    //     const lng = alllng / item.clusterData.length;
    //     //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
    //     this.map.setZoomAndCenter(this.map.getZoom() + 2, [lng, lat]);
    //   });
    //   if (isOne) {
    //     isOne = false;
    //     // this.map.setFitView()
    //   }
    // },

    // 设置锚点
    setMapMarker() {
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: require("@/assets/aqm.png"), // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = [];
      //信息窗口实例
      this.infoWindow = new AMap.InfoWindow({
        anchor: "bottom-center",
        offset: new AMap.Pixel(0, -30),
      });
      this.capsList.forEach((item) => {
        // 遍历生成多个标记点
        let marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
          position: [item.lon, item.lat], // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        this.geoCoder.getAddress([item.lon, item.lat], (status, result) => {
          // this.$set(this.capsList[index], 'address', result.regeocode.formattedAddress)
          item.address = result.regeocode.formattedAddress;
          let content =
            '<div style="padding: 0.2em 15px;width: 280px;margin: 10px 0;">' +
            '<p style="line-height:26px; font-size:14px;margin:6px">' +
            '<span style="width:60px; display:inline-block;">姓  名：</span>' +
            item.userName +
            "(" +
            item.userPhone +
            ")" +
            "</p>" +
            '<p style="line-height:26px; font-size:14px;margin:6px">' +
            '<span style="width:60px; display:inline-block;">设备号：</span>' +
            item.code +
            "</p>" +
            '<p style="line-height:26px; font-size:14px;margin:6px">' +
            '<span style="width:60px; display:inline-block;">地  址：</span>' +
            item.address +
            "</p>" +
            '<p style="line-height:26px; font-size:14px;margin:6px">' +
            '<span style="width:60px; display:inline-block;">操 作：</span><strong style="font-size: 16px;color:#ffd400" onclick="handleDetail(\'' +
            item.code +
            "," +
            item.capId +
            "," +
            item.id +
            "," +
            item.userName +
            "')\">" +
            "查看监控" +
            "</strong></p>" +
            "</div>";
          marker.content = content;
        });
        marker.on("click", this.markerClick);
        marker.emit("click", { target: marker }); // 此处是设置默认出现信息窗体
        makerList.push(marker);
      });
      this.map.add(makerList);
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    },

    // 控制标记的信息窗体的显示
    markerClick(e) {
      this.infoWindow.setContent(e.target.content);
      this.infoWindow.open(this.map, e.target.getPosition());
    },
  },
  created() {
    this.getCapMap();
    // 打开监控
    window.handleDetail = (deviceMarkId) => {
      const data = {
        code: deviceMarkId.split(",")[0],
        capId: deviceMarkId.split(",")[1],
        id: deviceMarkId.split(",")[2],
        userName: deviceMarkId.split(",")[3],
      };
      // this.getInfo(data)
      this.$emit("openInfo", data);
    };
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#mapWB {
  width: 100%;
  border-radius: 5px;
}
/deep/.amap-info-content {
  background: #20bacd !important;
  .amap-info-close {
    color: #fff;
  }
  p {
    color: #fff;
  }
}
/deep/.bottom-center .amap-info-sharp {
  border-top: 8px solid #20bacd;
}
</style>
