<template>
  <el-dialog v-if="visibleDialog" :title="Tit" append-to-body :visible.sync="visibleDialog"
    :close-on-click-modal="false" width="1100px" top="10vh" @close="close">
    <div style="height: 75vh;">
      <el-form inline :model="searchForm">
        <template v-if="Tit=='物业单位明细'">
          <el-form-item label="物业单位名称">
            <el-input placeholder="请输入物业单位名称" v-model="searchForm.name"></el-input>
          </el-form-item>
          <el-form-item label="单位编码">
            <el-input placeholder="请输入单位编码" v-model="searchForm.unitCode"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="小区名称">
            <el-input placeholder="请输入小区名称" v-model="searchForm.name"></el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" @click="onRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" v-if="dictionarTotal" :data="tabledata" :height="$store.state.tabHeight-100">
        <template v-if="Tit=='物业单位明细'">
          <el-table-column type="index" label="序号" align="center" />
          <el-table-column label="物业单位名称" prop="name" align="center" />
          <el-table-column label="单位编码" prop="unitCode" align="center" />
          <el-table-column label="联系人" prop="contact" align="center" />
          <el-table-column label="联系方式" prop="phone" align="center" />
          <el-table-column label="单位地址" prop="address" align="center" />
          <el-table-column label="创建人" prop="createUserName" align="center" />
        </template>
        <template v-else>
          <el-table-column type="index" label="序号" align="center" />
          <el-table-column label="小区名称" prop="name" align="center" />
          <el-table-column label="关联电梯数" prop="eleNum" align="center" />
          <el-table-column label="所属区域" prop="mergerName" align="center" />
          <el-table-column label="物业公司" prop="propertyUnitName" align="center" />
          <el-table-column label="详细地址" prop="address" align="center" />
          <el-table-column label="创建人" prop="createUserName" align="center" />
        </template>
      </el-table>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
      <Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
        @pagination="dictionarPage" />
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    props: ['Tit', 'propForm'],
    components: {
      Pagination
    },
    data() {
      return {
        visibleDialog: true,
        searchForm: {
          current: 1,
          size: 10
        },
        dictionarTotal: 0,
        tabledata: []
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      // 分页
      dictionarPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      reset(){
        this.searchForm= {
          current: 1,
          size: 10
        }
        this.onRefer()
      },
      onRefer() {
        let url = ''
        let data = ''
        if (this.Tit == '物业单位明细') {
          data = {
            type: "TENEMENT_UNIT",
            ...this.searchForm,
            brand: this.propForm.uniqueCode
          }
          url = '/api/system/web/org/list'
        } else {
          data = {
            ...this.searchForm,
            brand: this.propForm.uniqueCode
          }
          url = '/api/ele/web/plot/list'
        }
        this.$http.post(url, data).then((res) => {
          console.log(res.data.data.total,'res')
          if (res.data.code == 200) {
            this.tabledata = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
        })
      },
      close() {
        this.visibleDialog = false
        this.$emit('closeDialog')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
