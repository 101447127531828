<template>
  <el-dialog
    title="轨迹回放"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="900px"
    top="8vh"
    center
    @close="addPlatformDataDialogClose"
  >
    <el-row>
      <el-col>
        <span>时间: {{name}}</span>
        <el-time-picker
          is-range
          v-model="searchChartForm.allTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          style="margin: 0 15px">
        </el-time-picker>
        <el-button type="primary" @click="getPlayBackData" v-text="'查找'" />
      </el-col>
      <el-col>
        <div style="padding: 15px 15px 30px">
          <!-- 用来显示地图 -->
          <!-- 可以不写宽度，但一定要有高度 -->
          <Map v-if="polyline04.length > 0" :polylinefather="polyline04"></Map>
          <ShowMap v-else :isWalk="true"/>
        </div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
// 接口
// import { get_playback } from "@/api/capsSettings/playBack";
import { formatDate } from "@/util";
import Map from "./map";
import ShowMap from "@/components/map";

let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();
let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
let endTime = new Date(current_time1);

export default {
  components: {
    Map,
    ShowMap
  },
  data() {
    return {
      loading: true,
      visible: false,
      id: null,
      searchChartForm: {
        allTime: [starTime, endTime],
      },
      date: [],

      polyline04: [],
      name: ''
    };
  },

  created() {},

  methods: {
    // 父组件调用初始化
    init(data, id, callback) {
      this.name = data.param.name
      this.id = id;
      this.visible = true;
      this.loading = false;
      let timeArr = this.name.split('-')
      this.searchChartForm.allTime = [new Date(timeArr[0], timeArr[1] - 1, timeArr[2], 0, 0, 0), new Date(timeArr[0], timeArr[1] - 1, timeArr[2], 23, 59, 59)]
      console.log(this.name,'this.name');
      this.$nextTick(() => {
        this.getPlayBackData(1);
      });
    },
    // 获取轨迹
    async getPlayBackData(type) {
      this.loading = true;
      const data = {
        startDate: this.searchChartForm.allTime[0].getTime(),
        endDate: this.searchChartForm.allTime[1].getTime(),
        // endDate:
        //   this.searchChartForm.allTime[1].getTime() +
        //   24 * 60 * 60 * 1000 -
        //   1000,
        userId: this.id,
      };
      this.$http.post("/api/cap/web/capHeartbeat/getList", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          data.map((item) => {
            this.polyline04.push([Number(item.ypoint), Number(item.xpoint)]);
          });
        }
      });
      this.loading = false;
    },
    // 选择日期
    changeDate() {},

    // 弹窗关闭
    addPlatformDataDialogClose() {
      this.visible = false;
      this.$emit("closeDialog");
      //   this.addPlatformDataCallback(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  margin: 10px 0;
}
</style>
