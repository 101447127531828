<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    top="10vh"
    @close="Close"
  >
    <div class="admin-project-myproject-container">
      <div v-loading="loading" style="height: 63vh; overflow-y: scroll" class="scrollElement">
        <template v-if="actionType == 'check'">
          <el-tabs v-model="activeName">
            <el-tab-pane label="合同信息" name="contractInfo">
              <ContractForm
                ref="ContractForm"
                v-if="!loading"
                v-loading="loading"
                :propertyUnit="propertyUnit"
                :maintainUnit="maintainUnit"
                :actionType="actionType"
                :initFign="initFign"
                @Close="Close"
              />
            </el-tab-pane>
            <el-tab-pane label="确认信息" name="confirmInfo" v-if="contractdata.propertyCheckStatus != 0 || contractdata.maintainCheckStatus != 0"
              >
              <ConfirmList :contractdata="contractdata"/>
              </el-tab-pane
            >
          </el-tabs>
        </template>
        <template v-else>
          <ContractForm
            ref="ContractForm"
            v-if="!loading"
            v-loading="loading"
            :propertyUnit="propertyUnit"
            :maintainUnit="maintainUnit"
            :actionType="actionType"
            :initFign="initFign"
            @Close="Close"
          />
        </template>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="actionType == 'check'"
        size="small"
        type="primary"
        @click="Close"
        >确 定</el-button
      >
      <el-button
        v-if="actionType != 'check'"
        size="small"
        type="primary"
        @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="Close">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import JSONbig from "json-bigint"
import ContractForm from "./contractForm.vue";
import ConfirmList from './confirmList.vue';
export default {
  components: {
    ContractForm,
    ConfirmList
  },
  props: ["maintainUnit", "propertyUnit", "contractdata", 'type'],
  data() {
    return {
      visible: true,
      loading: true,
      selectunitCallback: null,
      title: "",
      actionType: "",
      activeName: "contractInfo",
      initFign: {},
      periodicMaintainData:{
        byb: {},
        ydb: {},
        jdb: {},
        bnb: {},
        ndb: {}
      },
      wyBank: { bankName: "" },
      wbBank: { bankName: "" },
      eleList: []
    };
  },
  mounted() {
    this.actionType = this.type;
    this.title = this.type == "check" ? "维保合同详情" : (this.type == "add" ? "新增维保合同" : "修改维保合同");
    
    if (this.type != "add") {
      const axwb = this.contractdata.demandMaintainData != '' ? JSONbig.parse(this.contractdata.demandMaintainData) : {}
      this.getBankInfo(this.contractdata.propertyUnitId, 'wy')
      this.getBankInfo(this.contractdata.maintainUnitId, 'wb')
      this.getEleList(this.contractdata.id)
      if (axwb.falutRoleId) {
        this.getWbSetting(axwb.falutRoleId, 'gz')
      }
      if (axwb.operationRoleId) {
        this.getWbSetting(axwb.operationRoleId, 'wxzt')
      }
      setTimeout(() => {
        this.loading = false
          this.getData();
      }, 1000)
    } else {
      this.loading = false
    }
  },
  methods: {
    getEleList (id) {
      this.$http
      .get(`/api/ele/web/maintainContract/getEleList?contractId=${id}&current=1&size=999`)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data,'res.data.data');
            res.data.data.records.map(item =>{
              item.elevatorId = item.id
              item.firstTime = item.firstMaintainTime ? new Date(item.firstMaintainTime) : ''
              this.eleList.push(item)
            })
        }
      });
    },

    getWbSetting (id, type) {
      this.$http
      .get(`/api/ele/web/maintainDemandRole/getDemandRole?id=${id}`)
      .then((res) => {
        if (res.data.success) {
          if (type == 'gz') {
            this.roleByFalut = res.data.data
          } 
          if (type == 'yxzt') {
            this.roleByOperation = res.data.data
          }
        }
      });
    },
    
    getBankInfo (id, type) {
      this.$http
        .get(`/api/system/web/account/getOrgAccountList?orgId=${id}`)
        .then((res) => {
          if (res.data.success) {
            if (type == 'wy') {
              this.wyBank = res.data.data.length > 0 ? res.data.data[0] : { bankName: '' }
            } 
            if (type == 'wb') {
              this.wbBank = res.data.data.length > 0 ? res.data.data[0] : { bankName: '' }
            } 
          }
        });
     },
    getData() {
      const {
        id,
        code,
        type,
        propertyUnitId,
        maintainUnitId,
        contractTime,
        price,
        eleNum,
        startTime,
        endTime,
        periodicMaintainData,
        demandMaintainData,
        eleMaintainPaymentDataVo,
        periodicMaintainStatus,
        demandMaintainStatus,
        fixedMaintainUnit,
        fixedMaintainUser,
        maintainOrderStatus,
        fileUrl
      } = this.contractdata;
      
      
      this.initFign = {
        eleMaintainContractDTO: {
          id,
          code,
          type,
          propertyUnitId,
          maintainUnitId,
          contractTime,
          price,
          eleNum,
          startTime,
          endTime,
          periodicMaintainData: periodicMaintainData ? JSONbig.parse(periodicMaintainData) : this.periodicMaintainData,
          demandMaintainData: demandMaintainData ? JSONbig.parse(demandMaintainData) : {},
          eleMaintainContractElevatorDTOList: [...this.eleList],
          periodicMaintainStatus: !!periodicMaintainStatus,
          demandMaintainStatus: !!demandMaintainStatus,
          fixedMaintainUnit,
          fixedMaintainUser,
          maintainOrderStatus,
          eleMaintainPaymentDataVo,
          fileUrl
        },
        eleMaintainPaymentDataVo,
        propertyAccountDTO: this.wyBank,
        maintainAccountDTO: this.wbBank,
        roleByOperation: this.roleByOperation,
        roleByFalut: this.roleByFalut,
        eleMaintainItemDTOList: [
          { name: "", type: 1 },
          { name: "", type: 2 },
          { name: "", type: 3 },
          { name: "", type: 5 },
          { name: "", type: 4 },
        ],
      };
      console.log(this.contractdata, "contractdata");
    },

    selectunitConfirm() {
      this.$nextTick(() => {
        console.log(this.$refs.ContractForm, "this.$refs.ContractForm");
        this.$refs.ContractForm.confirm();
      });
    },

    Close() {
      this.visible = false;
      this.$emit("close");
    },
  },
};
</script>
