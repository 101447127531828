<template>
	<div id="chart" style="width: 100%;height: 50vh;" />
</template>

<script>
	import * as echarts from 'echarts';
	// import resize from '@/utils/mixins/resize'

	export default {
		// mixins: [resize],
		props: ['dioData'],
		data() {
			return {
				chart: null
			}
		},

		// 监听data里面对应的
		watch: {
			data: {
				deep: true,
				hander(val) {
					this.setOptions(val)
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.initChart()
			})
		},

		beforeDestroy() {
			if (!this.chart) return
			// 释放图表实例
			this.chart.dispose()
			this.chart = null
		},

		methods: {
			initChart() {
				this.chart = echarts.init(document.getElementById('chart'));
				this.setOptions(this.dioData.speedarr)
			},
			setOptions(data) {
				const options = {
					tooltip: {
						trigger: 'axis',
						formatter: '{a} <br/>{b}s: {c} m/s²',
						axisPointer: {
							type: 'shadow'
						}
					},
					textStyle: {
						color: 'white' // 所有文字的颜色
					},
					grid: {
						top: '40px',
						left: '50px',
						right: '50px',
						bottom: '20px',
						containLabel: true,
						borderColor: '#fff'
					},
					dataZoom: [
						// 图表内也可缩放
						{
							type: 'inside'
						}
					],
					xAxis: {
						type: 'category',
						name: '时间/s',
						show: true,
						data: (() => {
							const arr = []
							for (let i = 0; i <= data.length; i++) {
								arr.push((i * 0.1).toFixed(1))
							}
							return arr
						})(),
						minInterval: 1,
						boundaryGap: [0, 0.01],
						splitLine: {
							show: false
						}, // 去除网格线,
						axisLine: {
							lineStyle: {
								color: '#4dc1ed' // 坐标轴颜色
							}
						},
						axisLabel: {
							interval: 'auto',
							textStyle: {
								fontSize: 14
							}
						},
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						name: '加速度/m/s²',
						show: true,
						scale: true,
						/* 按比例显示*/
						axisLine: {
							lineStyle: {
								color: '#4dc1ed'
							}
						},
						axisTick: {
							show: false
						}
					},
					series: [{
						name: `加速度变化`,
						type: 'line',
						barWidth: '30%',
						data,
						itemStyle: {
							normal: {
								color: '#0294da',
								barBorderRadius: [0, 7, 7, 0]
							}
						},
						lineStyle: {
							smooth: true
						}
					}]
				}
				this.chart.setOption(options)
			}
		}
	}
</script>
