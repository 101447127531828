<template>
	<el-dialog :title="'添加人员'" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px"
		@close="selectElevatorDialogClose">
		<div class="admin-project-myproject-container">
			<el-form :inline="true" :model="selectElevatorSearch" class="demo-form-inline">
				<el-form-item label="人员名称">
					<el-input v-model="selectElevatorSearch.userName" clearable placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="单位名称">
					<el-input v-model="selectElevatorSearch.orgName" clearable placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchElevator" class="searchBtn">搜索</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
					<el-button type="success" @click="addAll">批量添加</el-button>
				</el-form-item>
			</el-form>
			<el-table ref="selectElevatorList" v-loading="loading" :data="selectElevatorList" border stripe fit highlight-current-row
				max-height="500px" row-key="id" @select="handleSelectionChange" @select-all="selectAll">
				<el-table-column type="selection" reserve-selection width="55px" align="center" />
				<el-table-column type="index" label="序号" width="55px" align="center" />
				<el-table-column align="center" prop="userInfo.name" label="人员名称" min-width="150px" />
				<el-table-column align="center" prop="code" label="设备编号" min-width="120px" />
				<el-table-column align="center" prop="userInfo.orgName" label="所属单位" min-width="120px" />
				<el-table-column align="center" prop="userInfo.contactWay" label="联系方式" min-width="120px" />
				<el-table-column align="center" prop="userInfo.createTime" label="添加时间" min-width="150px" />
				<el-table-column align="center" prop="userInfo.certificateCode" label="证书编号" min-width="120px" />
				<el-table-column align="center" prop="userInfo.createUserName" label="操作" min-width="120px">
					<template slot-scope="scope">
						<el-button @click="addCrew([scope.row.id], true)" type="success" size="small" :disabled="initIdArr.includes(scope.row.id)">添加</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination v-show="selectElevatorListTotal > 0" :total="selectElevatorListTotal"
				:page.sync="selectElevatorListQuery.current" :limit.sync="selectElevatorListQuery.size"
				@pagination="selectElevatorListGet" />
		</div>

		<span slot="footer" class="dialog-footer" >
			<!-- <el-button size="small" type="primary" @click="selectElevatorConfirm">确 定</el-button> -->
			<el-button size="small" @click="visible = false">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		set
	} from 'vue'
	import Pagination from '../../../../components/Pagination'

	export default {
		components: {
			Pagination,
		},

		data() {
			return {
				visible: false,
				loading: true,
				selectElevatorListQuery: {
					current: 1,
					size: 100,
				},
				selectElevatorSearch: {
					name: '',
				},
				selectElevatorList: [],
				selectElevatorListTotal: 0,
				selectElevatorCallback: null,
				selectRow: {},
				selectData: [],
				initArr: [],
				updateArr: [],
				g_id: null,

				initIdArr: [],
				initChoose: []
			}
		},
		methods: {
			addAll() {
				const elevatorData = this.initArr
				if (elevatorData.length > 0) {
					// this.$emit('transferElevator', elevatorData)
					let ids = []
					elevatorData.forEach((res) => {
						ids.push(res.id)
					})
					this.addCrew(ids, true)
					// this.visible = false
				} else {
					this.$message({
						message: '请选择电梯',
						type: 'error',
					})
				}
			},
			addCrew(ids, type) {;
				const chooseIds = []
				this.initChoose.map(item => {
					if (!ids.includes(item.id)) {
						chooseIds.push(item.id)
					}
				})
				let params = {
					userIds: [...chooseIds, ...ids],
					id: this.g_id.id,
					name: this.g_id.name
				}
				this.$http.post('/api/cap/web/capGroup/submit', params).then((
					res) => {
					if (res.data.success) {
						if (type) {
							this.visible = false
							this.$emit('transferElevator')
						}
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			init(selectData, g_id, callback) {
				console.log(selectData,'selectData');
				this.g_id = g_id
				this.selectElevatorCallback = callback
				this.initArr = selectData
				this.initChoose = JSON.parse(JSON.stringify(selectData))
				selectData.map(item => {
					this.initIdArr.push(item.id)
				})
				this.visible = true
				this.loading = false
				this.selectElevatorListQuery = {
					current: 1,
					size: 10,
				}
				this.$nextTick(() => {
					this.selectElevatorListGet()
				})
			},
			//全选
			selectAll(selection) {
				let stateArr = []
				if (selection.length > 0) {
					stateArr = JSON.parse(JSON.stringify(selection))
					const arr = [...selection, ...this.initArr]
					// 去重
					const res = new Map()
					this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
					console.log(stateArr)
				} else {
					stateArr = JSON.parse(JSON.stringify(this.selectElevatorList))
					stateArr.map((item) => {
						if (this.initArr.find((row) => row.id === item.id)) {
							this.initArr = this.initArr.filter((row) => row.id !== item.id)
						}
					})
				}
				console.log(this.initArr, 'selection')
			},

			handleSelectionChange(val, row) {
				if (row.code === '') {
					this.$message({
						message: '请先给电梯设置注册码',
						type: 'error',
					})
					this.$refs.selectElevatorList.toggleRowSelection(row, false)
					return
				} else if (row.maintainUnitName === '') {
					this.$message({
						message: '请先给电梯设置维保单位',
						type: 'error',
					})
					this.$refs.selectElevatorList.toggleRowSelection(row, false)
					return
				}
				if (this.initArr.find((item) => item.id === row.id)) {
					this.initArr = this.initArr.filter((item) => item.id !== row.id)
				} else {
					this.initArr.push(row)
				}
				console.log(this.initArr, 'this.initArr1231123123123123')
			},

			selectElevatorListGet() {
				const params = {
					...this.selectElevatorListQuery,
					...this.selectElevatorSearch
				}
				// params.name = this.selectElevatorSearch.name
				// params.createUser = JSON.parse(sessionStorage.getItem('adminInfo')).id
				this.loading = true
				this.$http.post('/api/cap/web/capUser/getCapUserInfoList', params).then((res) => {
					if (res.data.success) {
						const {
							data: {
								data
							},
						} = res
						this.selectElevatorList = data.records ?? []
						this.selectElevatorListTotal = data.total ?? 0
						this.$nextTick(() => {
							if (this.selectElevatorListTotal > 0 && this.$refs.selectElevatorList) {
								this.$refs.selectElevatorList.bodyWrapper.scrollTop = 0;
							}
							if (this.initArr.length > 0) {
								this.selectElevatorList.forEach((ele) => {
									this.initArr.forEach((item) => {
										if (item.capCode === ele.code) {
											this.$refs.selectElevatorList.toggleRowSelection(ele, true)
										}
									})
								})
							}
						})
					}
					this.loading = false
				})
			},
			searchElevator() {
				this.selectElevatorListQuery.current = 1
				this.selectElevatorListGet()
			},

			// 重置
			reset() {

				this.selectElevatorListQuery = {
					current: 1,
					size: 10,
				}
				this.selectElevatorSearch = {}
				this.selectElevatorListGet()
			},

			// selectElevatorConfirm() {
			// 	const elevatorData = this.initArr

			// 	if (elevatorData.length > 0) {
			// 		this.$emit('transferElevator', elevatorData)
			// 		this.visible = false
			// 	} else {
			// 		this.$message({
			// 			message: '请选择电梯',
			// 			type: 'error',
			// 		})
			// 	}
			// },

			selectElevatorDialogClose() {
				this.selectElevatorListQuery.current = 1
				this.$refs.selectElevatorList.setCurrentRow()
				this.$refs.selectElevatorList.clearSelection()
			},
		},
	}
</script>
