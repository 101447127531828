<template>
  <el-dialog
    title="品牌关联电梯"
    label-position="left"
    :visible.sync="tableVisible"
    width="1400px"
    @close="closeDialog"
  >
    <div
      style="height: 60vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <div class="el-table-admin">
        <el-table
          ref="multipleTable"
          :data="tableData"
          max-height="500px"
          :height="$store.state.tabHeight"
        >
          <el-table-column label="序号" type="index" width="55px">
          </el-table-column>
          <el-table-column
            label="电梯名称"
            prop="name"
            width="160px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="电梯注册代码"
            prop="code"
            width="160px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="救援识别码"
            prop="rescueCode"
            width="150px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="所属小区"
            prop="plotName"
            width="120px"
            align="center"
          >
          </el-table-column>
          <el-table-column align='center' label="使用场所" width="150px">
          <template slot-scope="scope">
            {{
              scope.row.elePlaceType ?
                (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                  elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
              : '-'
            }}
          </template>
        </el-table-column>
          <el-table-column
            label="楼层学习"
            prop="isInit"
            width="120px"
            align="center"
          >
          <template slot-scope="scope">
            {{
              scope.row.isInit == 1 ? '已完成 ' : '未完成 '
            }}
          </template>
          </el-table-column>
          <el-table-column
            label="电梯安装位置"
            prop="eleAddress"
            width="320px"
            align="center"
          >
          </el-table-column>
        </el-table>
        <pagination
          :total="noticetotal"
          :page.sync="searchform.current"
          :limit.sync="searchform.size"
          @pagination="changePage"
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination.vue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      tableVisible: false,
      loading: true,
      searchCallback: null,
      tableData: [],
      noticetotal: 0,
      searchform: {
        current: 1,
        size: 10,
        brandId: null,
      },
      elefrom: {
        name: "",
      },
      elePlaceList: [],
    };
  },
  methods: {
    //初始化
    init(id, callback) {
      console.log(id, "id");
      this.searchCallback = callback;
      this.tableVisible = true;
      this.loading = false;
      this.elefrom.name = "";
      this.searchform.brandId = id;
      this.$nextTick(() => {
        this.onRefer();
      });
    },
    //分页
    changePage(data) {
      this.searchform.current = data.page;
      this.searchform.size = data.limit;
      this.onRefer();
    },
    //列表信息获取
    onRefer() {
      this.$http
        .post("/api/ele/web/elevatorInfo/getElevatorList", this.searchform)
        .then((res) => {
          console.log("电梯明细", res.data);
          if (res.data.success) {
            this.tableData = res.data.data.records ?? [];
            this.noticetotal = res.data.data.total ?? 0;
            this.$nextTick(() => {
              if (this.noticetotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrolltop = 0;
              }
            });
          } else {
            this.$message.error(res.data.data.msg);
          }
        });
    },
    closeDialog() {
      this.tableVisible = false
      this.$emit("colseEleFrom");
    }
  },
  created() {
    this.$http
      .post(`/api/system/web/dict/dictionary?codes=USE_PLACE`)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          this.elePlaceList = res.data.data.USE_PLACE;
        }
      });
  },
};
</script>
<style lang="scss" scoped>
// .el-dialog{
//   height: 750px;
// }
// .el-table-admin{
//   border: 1px solid #00f6ff;
//   border-radius: 10px;
//   padding:20px;
//   margin-left: -10px;
// }
// .pagination-container{
//   margin-top: 30px;
//   margin-right: 300px;
// }
</style>
