<template>
	<el-dialog :before-close='bclose' :close-on-click-modal='false' append-to-body title="添加人员" :visible.sync="isDia" width="1200px"
		top='15vh'>
		<el-form :inline="true" :model="formRale" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">人员名称</span>
					</span>
					<el-input v-model="formRale.orgName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getRela(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		<div>
			<el-table :data="relaData" ref="multipleTable" v-loading="tableLoading" style="width: 100%;margin-bottom: 20px;"
          max-height="500px" @select="handleSelectionChange"
          @select-all="selectAll">
          		<el-table-column type="selection" width="55px" align="center"/>
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="name" label="人员名称" />
				<el-table-column align='center' prop="orgName" label="所属单位" />
				<el-table-column align='center' prop="typeName" label="角色" />
				<el-table-column align='center' prop="contactWay" label="手机号码" />
				<el-table-column align='center' prop="account" label="app账号" />
				<el-table-column align='center' prop="oriPassword" label="app登录密码" />
				<el-table-column align='center' prop="certificateCode" label="证书编号" />
				<el-table-column align='center' prop="createTime" label="添加时间" width='200px' />
			</el-table>
			<Pagination :total="relaTotal" :page.sync="formRale.current" :limit.sync="formRale.size"
				@pagination="dictionarPage" />
		</div>
		<span slot="footer" class="dialog-footer" >
			<el-button size="small" type="primary" @click="saveAdd">确 定</el-button>
			<el-button size="small" type="info" @click="bclose">关 闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import Pagination from '@/components/Pagination'
	export default {
		props: ["id"],
		components: {
			Pagination
		},
		data() {
			return {
				isDia: false,
				relaData: [],
				relaTotal: 0,
				formRale: {
					size: 10,
					current: 1,
					status: 2,
					name: '',
					orgName:''
				},
				typeDate: [],
				initArr: [],
				tableLoading: true
			};
		},
		created() {
			// codes=RELATED_CODES,CONTRACT_TYPE
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=RELATED_CODES`)
				.then((res) => {
					if (res.data.success) {
						this.typeDate = res.data.data
						this.getRela()
					}
				})
		},
		methods: {
			init() {
				this.isDia = true
			},
			// 分页
			dictionarPage(data) {
				this.formRale.current = data.page
				this.formRale.size = data.limit
				this.getRela()
			},

			// 重置按钮事件
			reset() {

				this.formRale = {
					size: 10,
					current: 1,
					status: 2,
					name: '',
					orgName: ''
				}
				this.getRela()
			},
			getRela(ishm) {
				if (ishm) {
					this.formRale.current = 1
				}
				this.formRale.name = this.formRale.orgName ? `*${this.formRale.orgName}*` : ''
				const data = JSON.parse(JSON.stringify(this.formRale))
				delete data.orgName
				this.tableLoading = true

				this.$http
					.post('/api/system/web/appUser/getPage', data)
					.then((res) => {
						if (res.data.success) {
							this.relaTotal = res.data.data.total
							res.data.data.records.forEach((qs) => {
								qs.typeName = this.getTName(this.typeDate.RELATED_CODES, qs.type.split(','))
							})
							this.relaData = res.data.data.records
							this.$nextTick(() => {
								if (this.relaData.length > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
						this.tableLoading = false
					})
			},
			// 全选
			selectAll(selection) {
				let stateArr = [];
				if (selection.length > 0) {
					stateArr = JSON.parse(JSON.stringify(selection));
					const arr = [...selection, ...this.initArr];
					// 去重
					const res = new Map();
					this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
				} else {
					stateArr = JSON.parse(JSON.stringify(this.relaData));
					stateArr.map((item) => {
					if (this.initArr.find((row) => row.id === item.id)) {
						this.initArr = this.initArr.filter((row) => row.id !== item.id);
					}
					});
				}
			},

			handleSelectionChange(val, row) {
				if (this.initArr.find((item) => item.id === row.id)) {
					this.initArr = this.initArr.filter((item) => item.id !== row.id);
				} else {
					this.initArr.push(row);
				}
			},

			//添加
			saveAdd() {
				console.log(this.initArr, "this.initArr");
				const listArr = [];
				this.initArr.map((item) => {
					listArr.push(item.id);
				});
				if (listArr.length === 0) {
					this.$message.error('请勾选需要添加的人员');
					return
				}
				this.$http.get(`/api/system/web/appUser/batchSaveOrDel?type=1&id=${this.id}&userIds=${listArr.join(",")}`)
				.then((res) => {
				if (res.data.success) {
					this.initArr = [];
					this.$refs.multipleTable.clearSelection();
					this.isDia = false;
					this.$emit("close");
					this.$message.success(res.data.msg);
				} else {
					this.$message.error(res.data.msg);
				}
				});
			},
			bclose() {
				this.isDia = false;
				this.$emit('close')
			},
			getTName(typeDate, obj) {
				let tn = []
				typeDate.forEach(qs => {
					obj.forEach(qs2 => {
						if (qs.value == qs2) {
							tn.push(qs.label)
							return
						}
					})
				})
				return tn.join(',')
			}
		}
	}
</script>

<style lang="scss" scoped>
/deep/.el-dialog__footer{
	text-align: center;
}

</style>
