<template>
  <div
    :class="className"
    :style="{width:width, height:height,margin:'0 auto'}"
  />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      // default: 'calc(100% - 30px)'
      default: '240px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    sosLineData: {
      type: Object,
      default: () => {
        return {
          activeAlarm: [0, 0, 0, 0, 0, 0, 0], // 主动报警
          voiceAlarm: [0, 0, 0, 0, 0, 0, 0], // 语音报警
          peopleAlarm: [0, 0, 0, 0, 0, 0, 0], // 困人报警
          date: [0, 0, 0, 0, 0, 0, 0], // 日期
        }
      },
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  // 监听data里面对应的
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions(data) {
      const { date, activeAlarm, voiceAlarm, peopleAlarm } = data
      console.log(date, 'running_num1')

      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = ``
            let type = ''
            params.map((item) => {
              switch (item.seriesName) {
                case '主动报警':
                  type = '次'
                  break
                case '语音报警':
                  type = '次'
                  break
                case '困人报警':
                  type = '次'
                  break

                default:
                  break
              }
              value += `${item.seriesName}：${item.value + type} <br>`
            })
            return value
          },
        },
        textStyle: {
          color: '#fff', // 所有文字的颜色
        },
        legend: {
          right: '30px',
          top: '1%',
          bottom: '10px',
          data: ['主动报警', '语音报警', '困人报警'],
          textStyle: {
            color: '#409EFF',
          },
        },
        grid: {
          left: '6%',
          right: '30px',
          top: '12%',
          bottom: '20%',
          containLabel: false,
          borderColor: '#fff',
        },
        xAxis: {
          type: 'category',
          show: true,
          data: date,
          boundaryGap: false,
          splitLine: { show: false }, // 去除网格线,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed', // 坐标轴颜色
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 0,
            textStyle: {
              fontSize: 14,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '',
          show: true,
          axisLine: {
            lineStyle: {
              color: '#4dc1ed',
            },
          },
          scale: true, //自适应
          minInterval: 20, //分割刻度
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: `主动报警`,
            type: 'line',
            barWidth: '30%',
            data: data.activeAlarm,
            itemStyle: {
              normal: {
                color: '#75147B',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `语音报警`,
            type: 'line',
            barWidth: '30%',
            data: data.voiceAlarm,
            itemStyle: {
              normal: {
                color: '#EB4334',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
          {
            name: `困人报警`,
            type: 'line',
            barWidth: '30%',
            data: data.peopleAlarm,
            itemStyle: {
              normal: {
                color: '#55A7B1',
                barBorderRadius: [0, 7, 7, 0],
              },
            },
            lineStyle: {
              smooth: true,
            },
          },
        ],
      }
      this.chart.setOption(options)
    },
  },
}
</script>
