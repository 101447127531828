<template>
  <el-dialog
    title="设备列表"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1200px"
    top="8vh"
    @close="closeDialog"
  >
    <div class="searchBox scrollElement">
      <div class="page-top" style="margin: 15px 0">
        <el-button type="success" @click="add" size="small">创建目录</el-button>
      </div>
      <div class="dataList">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            align="center"
            prop="index"
            label="序号"
            width="100px"
          />
          <el-table-column align="center" label="名称">
            <template slot-scope="scope">
              <span>
                {{ scope.row.title }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="是否启用">
            <template slot-scope="scope" v-if="scope.row.id != 0">
              <el-switch
                v-model="scope.row.isActive"
                active-color="#409eff"
                inactive-color="#909399"
                :active-value="0"
                :inactive-value="1"
                @change="changeSwitch(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="edit(scope.row)"
                type="primary"
                size="small"
                >编辑
              </el-button>
              <el-button
                @click="del(scope.row)"
                type="danger"
                size="small"
                >删除
              </el-button>
              <el-button
                @click="addDj(scope.row)"
                type="success"
                size="small"
                >创建下级
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </span>
    <el-dialog
      v-if="addVisible"
      :title="`${addTit}目录`"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
      append-to-body
      width="500px"
      top="8vh"
      @close="closeAddDialog"
    >
      <div class="addScrollElement">
        <el-form
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          label-width="100px"
          :model="fign"
          class="addForm"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="24" v-if="isParent">
              <el-form-item label="上级名称:" prop="parentName">
                <el-input v-model="fign.parentName" disabled placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
            <el-col :span="24">
              <el-form-item label="目录名称:" prop="name">
                <el-input v-model="fign.name" placeholder="请输入" class="einp">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" >
        <el-button type="primary" @click="saveAddDialog">确定</el-button>
        <el-button type="indo" @click="closeAddDialog">取消</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: true,
      visible: false,
      tableData: [],
      unitTotal: 0,
      addBrandCallback: null, // 弹窗回调

      initArr: [],

      visible: false, // 添加设备

      addVisible: false,
      addTit: "创建",
      fign: {
        isActive: 0,
        parentId: 0,
        id: null,
        name: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入目录名称",
            trigger: "blur",
          },
        ]
      },
      isParent: false
    };
  },
  props: {},

  mounted() {},

  methods: {
    // 父字典id，父字典数据，回调
    init(callback) {
      this.addBrandCallback = callback;
      this.visible = true;
      this.loading = false;
      this.$nextTick(() => {
        this.getPersonnel();
      });
    },
    getPersonnel() {
      this.$http.get("/api/ele/web/partsCategory/tree?status=").then((res) => {
        if (res.data.success) {
          this.tableData = this.getNewData(res.data.data);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 去除hasChildren属性
    getNewData(data) {
      data.forEach((item, index) => {
        item.index = index + 1
        delete item.hasChildren;
        if (item.children && item.children.length > 0) {
          this.getNewData(item.children);
        }
      });
      return data;
    },
    // 启用开关
    changeSwitch(row) {
      this.fign = row
      this.saveAddDialog()
    },
    // 创建目录
    add() {
      this.addVisible = true;
      this.isParent = false
      this.addTit = "创建";
      this.fign.name = ''
      this.fign.id = ''
    },
    // 编辑目录
    edit(row) {
      this.addTit = row.parentId != 0 ? "编辑下级" : "编辑";
      this.isParent = row.parentId != 0
      this.addVisible = true;
      this.fign.name = row.title
      this.fign.id = row.id
      this.fign.parentId = row.parentId != 0 ? row.parentId : ''
    },
    // 删除目录
    del(row) {
      this.$http.delete(`/api/ele/web/partsCategory/remove?ids=${row.id}`).then((res) => {
        if (res.data.success) {
          this.getPersonnel()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 创建下级目录
    addDj(row) {
      this.isParent = true
      this.addTit = "创建下级";
      this.addVisible = true;
      this.fign.name = ''
      this.fign.id = ''
      this.fign.parentId = row.id
      this.fign.parentName = row.title
    },
    // 提交
    saveAddDialog() {
      this.$refs.ruleForm.validate((value) => {
        if (value) {
          const data = JSON.parse(JSON.stringify(this.fign))
          if (!this.isParent ) {
            delete data.parentName
            data.parentId = ''
          }
          this.$http.post("/api/ele/web/partsCategory/submit", data).then((res) => {
            if (res.data.success) {
              this.addVisible = false;
              this.getPersonnel()
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      })
    },
    // 目录分类弹框
    closeDialog() {
      this.visible = false;
      this.$emit("manageTyleClose");
    },
    // 目录弹框
    closeAddDialog() {
      this.addVisible = false;
    },

    // 添加部件
    addBj() {

    },
  },
};
</script>
<style lang="scss" scoped>
.searchBox {
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}
/deep/.el-table__expand-icon {
  color: #fff;
}
.addScrollElement{
  height: 20vh;
  display: flex;
  align-items: center;
  .addForm{
    width: 100%;
  }
}
</style>
