<template>
  <div class="jkBox">
    <!-- 在线 -->
    <div v-if="isBegin" style="height:350px; background: #000; position: relative;display: flex; justify-content: center;align-items: center;
    " @click="playJk">
      <img class="imgbox" :src="require('@/assets/play.png')" style="width: 7%;z-index: 99;" />
    </div>
    <div v-else>
      <div class="videoBox">
        <editionC2 ref="edition" :jkData='jkData' :sosDialog="sosDialog" :eleInfoId="eleInfo.id" :height="350"
           :percentage="percentage" @changePercentage="changePercentage"
          @update="update" @changeDial="changeDial" />
        <el-divider direction="vertical" style="margin: auto 0; height: 80%"></el-divider>
        <editionRed :jkData='jkRedData' :height="350" :percentage="percentageRed" :eleInfoId="eleInfo.id"
           @changePercentage="changePercentage" @update="update" />
      </div>

      <div class="conBox">
        <el-button :disabled='isDial' @click="dialZlv" type="success" icon="el-icon-microphone">拨打</el-button>
        <el-button :disabled='!isDial' @click="dropped" type="danger" icon="el-icon-turn-off-microphone">挂断</el-button>
        <el-button v-if="!vlSos" @click="openLightSos(0)" type="success" icon="el-icon-bell">开启声光报警</el-button>
        <el-button v-else @click="openLightSos(1)" type="danger" icon="el-icon-message-solid">关闭声光报警</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  // import editionC1 from './editionC1.vue' // 电梯监控 eleMonitoring
  import editionC2 from './editionC2.vue' // 电梯监控 eleMonitoring
  import EditionRed from './editionRed.vue';

  export default {
    props: ['deviceCode', 'vlSos', 'sosDialog', 'eleInfo'],
    components: {
      // editionC1,
      editionC2,
      EditionRed
    },
    data() {
      return {
        jkData: null,
        jkRedData: null,
        isBegin: true,


        isDial: false,
        percentage: 25,
        percentageRed: 25
      }
    },
    mounted() {
      console.log(this.deviceCode, 'deviceCode')
      console.log(this.eleInfo, 'eleInfo')
    },
    methods: {
      update(time, type) {
        console.log('update1')
        this.$emit('update', time, type)
      },
      changePercentage(data, type) {
        if (type) {
          this.percentage = data
        } else {
          this.percentageRed = data
        }
      },
      getStreamStatus(type) {
        this.$http
          .get(`api/bike/web/getStreamStatus?id=${this.eleInfo.id}&type=${type}`)
          .then((res) => {
            if (res.data.success) {
              if (type) {
                this.percentage = res.data.data ? 75 : 50;
              } else {
                this.percentageRed = res.data.data ? 75 : 50;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      playJk() {
        this.openJK(0)
        this.openJK(1)
        // this.openRedJK(1)
      },
      openJK(type) {
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.deviceCode,
          type
        }
        this.socketCarportApi.sendCarportSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ca_start_rtsp' && e.data) {
              let data = JSON.parse(e.data)
              if (data.type == 1) {
                this.jkData = data
                this.percentage = 50;
                this.getStreamStatus(type);
              }
              if (data.type == 0) {
                this.jkRedData = data
                this.percentageRed = 50;
                this.getStreamStatus(type);
              }
              this.isBegin = false
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      openRedJK(type) {
        let agentData = {
          act: 'ca_start_rtsp',
          device_id: this.deviceCode,
          type
        }
        this.socketCarportApi.sendCarportSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ca_start_rtsp' && e.data) {
              let data = JSON.parse(e.data)
              this.jkRedData = data
              this.isBegin = false
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      dialZlv() {
        this.$nextTick(() => {
          this.$refs.edition.dialZlv()
        })
      },
      dropped() {
        this.$nextTick(() => {
          this.$refs.edition.dropped()
        })
      },

      changeDial(type) {
        this.isDial = type
      },
      openLightSos(status) {
        const data = {
          deviceCode: this.deviceCode,
          status
        }
        this.$http.get(`/api/bike/web/device/startLightSos?deviceCode=${this.deviceCode}&status=${status}`).then((
          res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
    created() {
      console.log('我是电梯监控通话的容器')
    },
    destroyed() {
      console.log('监控销毁了')
    }
  }
</script>

<style scoped>
  .imgbox {
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  /* div {
    width: 100%;
    height: 100%;
  } */

  .conBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .jkBox {
    width: 100%;
    height: 100%;
  }

  .videoBox {
    height: 360px;
    display: flex;
    justify-content: space-evenly;
    background: #000
  }

  .el-divider--vertical {
    height: 88%;
    margin: auto 0;
  }
</style>
