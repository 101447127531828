<template>
	<div v-loading="loading">
		<el-row>
			<el-col :span="8" style="padding-right: 15px">
				<div class="page-top">
					<div style="display: flex">
						<el-input placeholder="请输入群组名称" clearable v-model="filterText" style="margin-right: 10px" />
						<el-button type="success" @click="addGroup">创建分组</el-button>
						<el-button type="primary" @click="searchOnRefer">搜索</el-button>
					</div>
				</div>
				<div>
					<template v-if="personnelTotal != 0">
						<el-table ref="personTable" highlight-current-row :data="tableData" :row-style="cellStyle"
							style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="700"
							@current-change="handleCurrentChange">
							<el-table-column type="index" label="序号" />
							<el-table-column align="center" prop="name" label="群组名称" /><el-table-column fixed="right"
								align="center" prop="address" label="操作" width="150px">
								<template slot-scope="scope">
									<el-button @click="edit(scope.row)" type="warning" size="small">编辑</el-button>
									<el-button @click="del(scope.row)" type="danger" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
					<div class="nullDate" v-else>
						<img src="@/assets/nullDate.png" style="width: 260px" />
					</div>
					<div style="display: flex; justify-content: center">
						<el-button type="primary" :disabled="this.searchForm.current == 1" class="searchBtn"
							@click="back">上一页</el-button>
						<el-button type="primary" :disabled="
                this.searchForm.current * this.searchForm.size > personnelTotal
              " @click="next">下一页</el-button>
					</div>
				</div>
			</el-col>
			<el-col :span="16" style="background: #2b406ca6;padding: 15px 25px 25px;border-radius: 8px;				">
				<div style="text-align: right; margin-bottom: 10px">
					<el-button type="primary" @click="showBy" :disabled="!groupList.length > 0">视频通话</el-button>
					<el-button type="success" @click="addPerson">添加人员</el-button>
				</div>
				<div v-if="groupList.length > 0">
					<el-table ref="multipleTable" :data="groupList" max-height="350" height="350">
						<el-table-column type="index" label="序号" />
						<el-table-column align="center" prop="userName" label="人员名称" />
						<el-table-column align="center" prop="capCode" label="设备编号" />
						<el-table-column align="center" prop="orgName" label="所属单位" />
						<el-table-column align="center" label="状态">
							<template slot-scope="scope">
								{{ scope.row.capStatus == 0 ? "离线" : "在线" }}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="address" label="操作">
							<template slot-scope="scope">
								<el-button @click="delPerson(scope.row)" type="danger" size="small">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<template v-else>
					<div class="rightTop">
						<span>序号</span>
						<span>人员名称</span>
						<span>设备编号</span>
						<span>所属单位</span>
						<span>状态</span>
						<span>操作</span>
					</div>
					<div style="display: flex; justify-content: center; max-height: 300px">
						<img src="@/assets/nullDate.png" style="width: 200px" />
					</div>
				</template>
				<div style="margin: 15px 0 0">
					<Map v-if="showMap" :capsList="groupList" />
				</div>
			</el-col>
		</el-row>

		<!-- 弹框 -->
		<el-dialog v-if="detailVisible" :title="tit" :visible.sync="detailVisible" :close-on-click-modal="false"
			width="500px" top="20vh" @close="closeDialog">
			<div style="height: 25vh; overflow-y: scroll" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="140px" :model="fign"
					style="margin: 30px 0">
					<el-row>
						<el-col :span="24">
							<el-form-item label="群组名称:" prop="name">
								<el-input v-model="fign.name" placeholder="请输入" class="einp addressInp" />
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer" >
					<el-button size="small" type="primary" @click="save">保 存</el-button>
					<el-button size="small" type="info" @click="closeDialog">关 闭</el-button>
				</div>
			</div>
		</el-dialog>
		<groubZlv @dioClose="dioClose" v-if="groupBy" :groupId="state.id" />
		<SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />
	</div>
</template>
<script>
	import Map from './components/map';
	import SelectElevator from "./components/selectElevator";
	import Pagination from "@/components/Pagination";
	import groubZlv from "@/components/cap/groubZlv"
	export default {
		components: {
			Map,
			groubZlv,
			Pagination,
			SelectElevator,
		},
		data() {
			return {
				// 群组通话
				groupBy: false,
				// 过度动画
				loading: false,
				// 添加群组
				selectOtherDialogVisible: false,
				selectData: [],
				// 表格参数
				searchForm: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				personnelTotal: 0,
				tit: "",
				detailVisible: false,
				filterText: "", // 群组号
				fign: {}, //表单
				rules: {
					name: [{
						required: true,
						message: "请输入名称",
						trigger: "blur",
					}, ],
				}, //表单规则
				state: {}, //选中设备

				groupList: [], //群组人员列表
				showMap: true
			};
		},
		filters: {},
		methods: {
			dioClose() {
				this.groupBy = false
			},
			showBy() {
				if (this.groupList.find(item => item.capStatus == 1)) {
					this.loading = true
					navigator.mediaDevices.getUserMedia({
							audio: true,
							video: true
						})
						.then((stream) => {
							this.loading = false
							this.groupBy = true
						})
						.catch((error) => {
							this.loading = false
							this.$message.error('麦克风与摄像头权限异常！')
							// code for when there is an error
						});
				} else {
					this.$message.error('该群组所有成员都已离线')
				}
			},
			// 选择电梯返回
			selectOtherResult(data) {
				this.getConfig()
				this.selectOtherDialogVisible = false;
			},
			cellStyle(row) {
				if (this.state.id === row.row.id) {
					// 注意！！！这里是 row.row.checkNumber
					return {
						backgroundColor: "#1282a563 !important",
						height: "60px",
					};
				}
				return {
					height: "60px"
				};
			},

			// handleCurrentChange(val) {
			// 	this.state = val;
			// 	this.getConfig();
			// },
			handleCurrentChange(val, oldVal) {
				if (val) {
					this.state = val;
					this.getConfig()
				}
			},
			// 创建分组
			addGroup() {
				this.tit = "创建群组"
				this.detailVisible = true
			},
			//编辑分组
			edit(row) {
				(this.tit = "编辑群组"), (this.detailVisible = true);
				this.$set(this.fign, "name", row.name);
				this.$set(this.fign, "id", row.id);
			},
			//删除分组
			del(row) {
				this.$confirm("此操作将永久删除选中的群组, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$http
							.delete(`/api/cap/web/capGroup/delCapGroup?id=${row.id}`)
							.then((res) => {
								if (res.data.success) {
									this.onRefer();
									this.$message.success(res.data.msg);
								} else {
									this.$message.error(res.data.msg);
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			// 保存
			save() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						const data = JSON.parse(JSON.stringify(this.fign));
						if (this.tit == "编辑群组") {
							const userIds = []
							this.groupList.map(item => {
								userIds.push(item.capUserInfoId)
							})
							data.userIds = userIds
						}
						this.$http.post("/api/cap/web/capGroup/submit", data).then((res) => {
							if (res.data.success) {
								const { data: { data }, } = res;
								this.detailVisible = false;
								this.fign = {};
								this.onRefer();
								this.$message.success(res.data.msg);
							} else {
								this.$message.error(res.data.msg);
							}
						});
					}
				});
			},
			// 关闭弹框
			closeDialog() {
				this.fign = {};
				this.detailVisible = false;
			},

			// 搜索
			searchOnRefer() {
				this.searchForm.current = 1;
				this.onRefer();
			},

			// 获取群组方法
			onRefer() {
				const data = JSON.parse(JSON.stringify(this.searchForm));
				data.name = this.filterText;
				this.$http
					.post("/api/cap/web/capGroup/getCapGroupPage", data)
					.then((res) => {
						if (res.data.success) {
							const { data: { data } } = res;
							this.tableData = data.records ?? [];
							this.personnelTotal = data.total ?? 0;
							this.state = data.total > 0 ? this.tableData[0] : {};
							this.$nextTick(() => {
								if (data.total > 0) {
									this.$refs.personTable.setCurrentRow(this.tableData[0])
								}
							})
							if (data.total > 0) {
								this.getConfig();
							}
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			//翻页
			back() {
				this.searchForm.current--;
				this.onRefer();
			},
			next() {
				this.searchForm.current++;
				this.onRefer();
			},

			// 获取房间成员列表
			getConfig() {
				this.showMap = false
				const data = {
					groupId: this.state.id,
				};
				this.$http
					.post("/api/cap/web/capGroupUser/getGroupUserList", data)
					.then((res) => {
						if (res.data.code == 200) {
							this.groupList = JSON.parse(JSON.stringify(res.data.list))
							this.selectData = res.data.list
						} else {
							this.$message.error(res.data.msg);
						}
						this.showMap = true
					});
			},
			// 添加房间人员
			addPerson() {
				let list = JSON.parse(JSON.stringify(this.selectData))
				list.forEach(res => {
					res.id = res.capUserInfoId
				})
				this.selectOtherDialogVisible = true
				this.$nextTick(() => {
					this.$refs.SelectElevator.init(
						// this.selectData,
						list,
						this.state,
						(refresh) => {
							if (refresh) {}
						}
					);
				});
			},
			// 移除房间人员
			delPerson(row) {
				this.$confirm("此操作将永久删除选中的人员, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						const data = {
							ids: row.id
						}
						this.$http
							.post("/api/cap/web/capGroupUser/delGroupUser", data)
							.then((res) => {
								if (res.data.success) {
									this.getConfig();
									this.$message.success(res.data.msg);
								} else {
									this.$message.error(res.data.msg);
								}
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
		},
		created() {},
		mounted() {
			this.$nextTick(() => {
				this.onRefer();
			});
		},
	};
</script>
<style lang="scss" scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.searchBtn {
		margin-right: 25px;
	}

	.rightTop {
		display: flex;
		color: #fff;
		background: #1282a5e6;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;
		padding: 12px 15px;
		font-size: 14px;
		font-weight: bolder;
	}

	.radioClass {
		margin-bottom: 10px;

		/deep/.el-radio {
			color: #fff;
		}

		/deep/.is-checked {
			span {
				color: #00f6ff !important;
			}
		}
	}

	/deep/.input-with-select {
		.el-input-group__prepend {
			width: 50px;
		}
	}

	.addressInp {
		width: 96% !important;
	}
</style>
