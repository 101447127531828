<template>
  <el-dialog
    :title="'选择电梯'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-input
            v-model="searchForm.maintainUnitName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">物业单位</span>
          </span>
          <el-input
            v-model="searchForm.propertyUnitName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="selectUnitList"
        :data="selectUnitList"
        border
        stripe
        fit
        max-height="500px"
        highlight-current-row
        row-key="id"
        @select="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column
          label="序号"
          type="index"
          prop="index"
          width="50px"
          align="center"
        >
        </el-table-column>
        <el-table-column align="center" prop="name" label="电梯名称" />
        <el-table-column align="center" prop="code" label="电梯注册代码" />
        <el-table-column
          align="center"
          prop="deviceCode"
          label="物联网设备编码"
        />
        <el-table-column align="center" prop="rescueCode" label="救援识别码" />
        <el-table-column
          align="center"
          prop="maintainUnitName"
          label="维保单位"
          width="150px"
        />
        <el-table-column align="center" prop="rescueCode" label="救援识别码" />
        <el-table-column
          align="center"
          prop="propertyUnitName"
          label="物业单位"
          width="150px"
        />
        <el-table-column
          align="center"
          label="操作"
          width="150px"
          v-if="rowId !== ''"
        >
          <template slot-scope="scope">
            <el-button @click="del(scope.row)" type="danger" size="small"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="selectUnitListTotal > 0"
        :total="selectUnitListTotal"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.size"
        @pagination="selectUnitListGet"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <!-- 电梯明细 -->
      <el-button
        v-if="rowId"
        size="small"
        type="primary"
        @click="selectunitDialogClose"
        >确 定</el-button
      >
      <!-- 选择电梯 -->
      <el-button v-else size="small" type="primary" @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="selectunitDialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination";

export default {
  components: {
    Pagination,
  },
  props: ["ruleId"],

  data() {
    return {
      visible: false,
      loading: true,
      selectUnitListQuery: {
        current: 1,
        size: 10,
      },
      selectUnitList: [],
      selectUnitListTotal: 0,
      selectunitCallback: null,
      selectRow: {},
      unitId: [],
      selectData: [],
      initArr: [],

      // 表格参数
      searchForm: {
        name: "",
        maintainUnitName: "",
        propertyUnitName: "",
        current: 1,
        size: 10,
      },

      type: null, // 生效类型
      rowId: null,
    };
  },
  methods: {
    // 绑定电梯id集合 ，类型，选中值，是否绑定，回调
    init(id, type, selectData, rowId, callback) {
      this.type = type;
      this.rowId = rowId;
      this.selectunitCallback = callback;
      this.unitId = id ?? [];
      this.initArr = selectData;
      this.visible = true;
      this.loading = false;
      this.$nextTick(() => {
        this.selectUnitListGet();
      });
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.selectUnitListGet();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        name: "",
        maintainUnitName: "",
        propertyUnitName: "",
        current: 1,
        size: 10,
      };
      this.selectUnitListGet();
    },

    // 全选
    selectAll(selection) {
      let stateArr = [];
      if (selection.length > 0) {
        stateArr = JSON.parse(JSON.stringify(selection));
        const arr = [...selection, ...this.initArr];
        // 去重
        const res = new Map();
        this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
        console.log(stateArr);
      } else {
        stateArr = JSON.parse(JSON.stringify(this.selectUnitList));
        stateArr.map((item) => {
          if (this.initArr.find((row) => row.id === item.id)) {
            this.initArr = this.initArr.filter((row) => row.id !== item.id);
          }
        });
      }
      console.log(this.initArr, "selection");
    },
    // 选择
    handleSelectionChange(val, row) {
      console.log(row, "row");
      console.log(this.initArr, "this.initArr");
      if (this.initArr.find((item) => item.id === row.id)) {
        this.initArr = this.initArr.filter((item) => item.id !== row.id);
      } else {
        this.initArr.push(row);
      }
    },

    selectUnitListGet() {
      const params = JSON.parse(JSON.stringify(this.searchForm));
      delete params.current;
      delete params.size;
      params.id = this.rowId;
      params.ids = this.unitId.length > 0 ? this.unitId.join(",") : "";
      this.loading = true;
      this.$http
        .get(
          `/api/ele/web/maintainDemandRole/getEleList?type=${this.type}&current=${this.searchForm.current}&size=${this.searchForm.size}`,
          { params }
        )
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.loading = false;
            this.selectUnitList = data.records ?? [];
            this.selectUnitListTotal = data.total ?? 0;
            this.$nextTick(() => {
              if (this.selectUnitListTotal > 0 && this.$refs.selectUnitList) {
                this.$refs.selectUnitList.bodyWrapper.scrollTop = 0;
              }
              if (this.unitId.length > 0) {
                this.selectUnitList.forEach((ele) => {
                  this.unitId.forEach((item) => {
                    if (item === ele.id) {
                      this.$refs.selectUnitList.toggleRowSelection(ele, true);
                    }
                  });
                });
              }
            });
          }
        });
    },

    async selectunitConfirm() {
      const unitData = this.initArr;

      if (unitData.length > 0) {
        console.log(unitData, "unitData");
        this.$emit("transferUnit", unitData);
        this.visible = false;
      } else {
        this.$message({
          message: "请选择电梯",
          type: "error",
        });
      }
    },

    del(row) {
      this.$confirm("此操作将永久删除选中的电梯, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete(
            `/api/ele/web/maintainDemandRole/removeEle?ids=${row.id}&id=${this.rowId}`
          )
          .then((res) => {
            console.log("列表", res.data);
            if (res.data.success) {
              this.selectUnitListGet();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },

    selectunitDialogClose() {
      this.$refs.selectUnitList.setCurrentRow();
      this.$refs.selectUnitList.clearSelection();
      this.searchForm.orgName = "";
      this.visible = false;
    },
  },
};
</script>
