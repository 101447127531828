<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="searchChartForm" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">日期</span>
					</span>
					<el-date-picker v-model="searchChartForm.allTime" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-row>
				<el-col :span="24">
					<template v-if="sosLineData.length != 0">
						<div style="margin: 0 auto">
							<BarChart :width="'1200px'" :height="'50vh'" :sos-line-data="sosLineData"
								@initMap="initMap" />
							<PlayBackDialog v-if="playBackDialogVisible" ref="playBackDialog"
								@closeDialog="closeDialog" />
						</div>
					</template>

					<div class="nullDate" v-else>
						<img src="@/assets/nullDate.png" style="width: 260px" />
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	import BarChart from '../../yxgj/components/barChart';
	import PlayBackDialog from "../../yxgj/components/playBackDialog";
	import { formatDate } from "@/util";

	let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
	let current_time1 = new Date(current_time).getTime();
	let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
	let endTime = new Date(current_time1);
	export default {
		components: {
			BarChart,
			PlayBackDialog
		},
		props: ["useId"],
		data() {
			return {
				// 表格参数
				sosLineData: {
					createTime: [], // 在线日期
					onlineTime: [], // 在线时间
				},
				searchChartForm: {
					allTime: [starTime, endTime],
				},
				total: 0,
				playBackDialogVisible: false,
			};
		},
		filters: {},
		methods: {

			// 搜索
			searchOnRefer() {
				this.onRefer();
			},

			// 获取人员方法
			onRefer() {
				if (this.searchChartForm.allTime == null) {
					this.$message.error("请输入查询的时间");
					return;
				}
				const startDate = this.searchChartForm.allTime[0].getTime()
				const endDate = this.searchChartForm.allTime[1].getTime()
				if (endDate - startDate > 30 * 24 * 60 * 60 * 1000) {
					this.searchChartForm.allTime = [starTime, endTime]
					this.$message.error("最多只能查询30天内的数据");
					return;
				}
				// 清空柱状图数据
				this.sosLineData.createTime.splice(0);
				this.sosLineData.onlineTime.splice(0);
				const data = {
					startDate: startDate,
					endDate: endDate + 24 * 60 * 60 * 1000 - 1000,
					userId: this.useId,
				};
				this.$http.post("/api/cap/web/loginCount/getList", data).then((res) => {
					if (res.data.success) {
						const {
							data: {
								data
							},
						} = res;
						data.map((item, i) => {
							// 第一项的放在第一个数组，第二项的放在第二个数组
							data.map((list, j) => {
								this.sosLineData.onlineTime.push([]);
								if (i === 0) {
									// 时间只赋值一次
									this.sosLineData.createTime.push(list.createTime.split(" ")[
									0]);
								}
								if (this.sosLineData.onlineTime[i]) {
									this.sosLineData.onlineTime[i].push({
										minutes: list.loginTime,
										maintain_user_id: list.loginTime,
									});
									this.sosLineData.onlineTime.splice(1)
								}
							});
						});
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			// 初始化
			initMap(data) {
				if (data.target === "playBack" && !this.playBackDialogVisible) {
					this.playBackDialogVisible = true;
					this.$nextTick(() => {
						this.$refs.playBackDialog.init(data, this.useId, (refresh) => {
							this.playBackDialogVisible = false;
						});
					});
				}
			},
			closeDialog() {
				this.playBackDialogVisible = false;
			},
		},
		created() {},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>