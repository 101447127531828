<template>
	<div>
		<el-dialog v-if="SetupDialog" title="设置禁停区域" :visible.sync="SetupDialog" :close-on-press-escape="false"
			:close-on-click-modal="false" append-to-body width="950px" top="20vh"  @close="closeDialog">
			<div style="height: 50vh;overflow-y: scroll;margin: 20px;" class="scrollElement">
				<div>
					<th>
						<tr class="content">
							<td style="width: 100px;">编号</td>
							<td style="width: 500px;">区域示意图</td>
							<td style="width: 200px;">操作</td>
						</tr>
						<tr class="contentBox" v-for="(item,index) in itmeArr">
							<td style="width: 100px;">区域{{index+1}}</td>
							<td>
								<canvas :id="'Canvas'+index" width="320" height="240"></canvas>
								<!-- <el-image v-if="item.url" style="width: 200px; height: 200px;margin: 0 auto;"
									:src="item.url" fit="contain"></el-image> -->
							</td>
							<td style="width: 200px;">
								<el-button type="danger" @click="Del(item)">移除</el-button>
							</td>
						</tr>
						<tr class="contentFooter">
							<td style="width: 100px;">区域{{itmeArr.length+1}}</td>
							<td style="width: 500px;"></td>
							<td style="width: 200px;">
								<el-button type="primary" @click="addQy()">新增禁停区域</el-button>
							</td>
						</tr>
					</th>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>
		<el-dialog v-if="AddDialog" title="新增禁止停放区域" :visible.sync="AddDialog" :close-on-press-escape="false"
			:close-on-click-modal="false" append-to-body width="950px" top="20vh" center @close="AddCloseDialog">
			<div style="display: flex;justify-content: center;overflow-y: scroll;flex-direction: column;align-items: center"
				class="scrollElement">
				<div>
					<span style="color: #FFFF80;">提示：请获取当前监控画面后，用鼠标在画面范围画出禁止停放区域多边形。画完后点击完成按钮。</span>
					<a class="aType">查看示例</a>
				</div>
				<div style="min-width: 640px;width: 640px;margin-top: 10px;">
					<canvas id="mycanvas" @click="cim($event)" width="640" height="480"></canvas>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button v-if="isBanMaShow" size="small" type="success" @click="drawImg()">绘制</el-button>
				<el-button size="small" type="danger" @click="clear()">清空</el-button>
				<el-button size="small" type="primary" @click="finish()">完成</el-button>
				<el-button size="small" class="buttonInfo" @click="exit()">退出</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {

		},
		props: ['Id', 'info'],
		data() {
			return {
				SetupDialog: true,
				AddDialog: false, //新增禁停区域
				adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
				itmeArr: [1, 2], //数据列表
				ctx: '',
				width: '',
				height: '',
				points: [],
				isPaint: false,

				banMa: [],
				isBanMaShow: false,
			}
		},
		created() {
			this.onRefer()
			console.log('this.info', this.info)
		},
		methods: {
			clear() { //清空
				this.doDraw(this.info.firstFrameUrl, "mycanvas", 1)
			},
			finish() { //完成
				if (this.banMa.length >= 3) {
					let data = {
						bikeId: this.info.id,
						region: JSON.stringify(this.banMa),
						// url: this.info.firstFrameUrl,
						url: 'https://test-fileserver.jiangsu-10.zos.ctyun.cn/newPublic/images/2024-07-10/19:17:31.380/微信图片_20240710191324.png'
					}
					this.$http.post('/api/bike/web/region/saveRegion', data).then((res) => {
						if (res.data.code == 200) {
							this.onRefer()
							this.AddDialog = false
							this.$message({
								type: 'success',
								message: res.data.msg
							})
						}
					})
				} else {
					this.$message({
						type: 'error',
						message: '请绘制禁止区域后在完成'
					})
				}
			},
			cim(e) {
				let dt = {
					x: e.offsetX,
					y: e.offsetY,
				}
				this.banMa.push(dt)
				let canvas = document.getElementById('mycanvas')
				let context = canvas.getContext('2d')
				context.fillStyle = 'red' //图形边框的填充颜色
				context.fillRect(e.offsetX, e.offsetY, 2, 2) //绘制矩形（无填充）

				if (this.banMa.length >= 3) {
					this.isBanMaShow = true
				}
			},
			drawImg() {
				this.isBanMaShow = false
				let canvas = document.getElementById('mycanvas')
				let ctx = canvas.getContext('2d')

				ctx.strokeStyle = 'red'
				ctx.lineWidth = 2

				ctx.beginPath()

				this.banMa.forEach((res, ind) => {
					ctx.lineTo(res.x, res.y)
				})

				ctx.closePath()

				ctx.strokeStyle = 'pink'
				ctx.stroke()
			},
			//  imageUrl为后台提供图片地址
			doDraw(imageUrl, name, type, data) {
				this.banMa = []
				this.isBanMaShow = false
				//  获取canvas
				var canvas = document.getElementById(name)
				//  由于弹窗，确保已获取到
				var a = setInterval(() => {
					//  重复获取
					canvas = document.getElementById(name)
					console.log('canvas', !canvas)
					if (!canvas) {
						return false
					} else {
						clearInterval(a)
						console.log('来了绘制')
						//  可以理解为一个画笔，可画路径、矩形、文字、图像
						var context = canvas.getContext('2d')
						var img = new Image()
						img.src = imageUrl
						// img.src =
						// 	"https://test-fileserver.jiangsu-10.zos.ctyun.cn/newPublic/images/2024-07-10/19:17:31.380/微信图片_20240710191324.png"
						// //  加载图片
						img.onload = () => {
							if (img.complete) {
								let w = type == 1 ? 640 : 320
								let h = type == 1 ? 480 : 240
								//  根据图像重新设定了canvas的长宽
								canvas.setAttribute('width', w)
								canvas.setAttribute('height', h)
								//  绘制图片
								context.drawImage(img, 0, 0, w, h)

								// 渲染图形
								if (data) {
									console.log(data)

									context.strokeStyle = 'red'
									context.lineWidth = 2
									context.beginPath()
									data.forEach((res, ind) => {
										context.lineTo(res.x, res.y)
									})
									context.closePath()
									context.strokeStyle = 'pink'
									context.stroke()
								}
							}
						}
					}
				}, 100)
			},


			onRefer() {
				const data = {
					bikeId: this.Id
				}
				this.$http.post('api/bike/web/region/getDeviceRegion', data).then((res) => {
					console.log(res, '禁停列表')
					if (res.data.code == 200) {
						this.itmeArr = res.data.data

						this.itmeArr.forEach((as, ind) => {
							let name = "Canvas" + ind
							let data = JSON.parse(as.region)
							data.forEach(qs => {
								qs.x = qs.x / 2
								qs.y = qs.y / 2
							})
							this.doDraw(as.url, name, 2, data)
						})

						this.$forceUpdate()
					}
				})
			},
			closeDialog() { //关闭弹窗
				this.SetupDialog = false
				this.$emit('close')
			},
			AddCloseDialog() { //关闭新增弹窗
				this.AddDialog = false
			},
			addQy() { //新增

				if (this.itmeArr.length >= 10) {
					this.$message({
						type: 'warning',
						message: '一个摄像头最多可添加10个区域。'
					})
					return
				}
				if (this.info.firstFrameUrl == undefined || this.info.firstFrameUrl == '' || this.info.firstFrameUrl ==
					null) {
					this.$confirm('未获取到该摄像头最新监控画面截图，请先前往视频监控页面，打开监控并观看2分钟以上。是否立即前往查看？', '提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.SetupDialog = false
						this.$emit('close', this.info)
					}).catch(() => {})
					return
				}

				this.AddDialog = true
				this.$nextTick(() => {
					this.doDraw(this.info.firstFrameUrl, "mycanvas", 1)
				})
			},

			Del(data) { //移除
				this.$confirm('此操作将移除选中的区域, 是否继续?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.delete(`/api/bike/web/region/deletedRegion?id=${data.id}`).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: res.data.msg
							})
							this.onRefer()
						} else {
							this.$message({
								type: 'error',
								message: res.data.msg
							})
						}
					})
				}).catch(() => {})
			},
			exit() { //退出
				this.AddDialog = false
			},
			dioSub() { //确定
				this.SetupDialog = false
				this.$emit('close')
			},
		}
	}
</script>

<style scoped>
	#mycanvas {
		cursor: crosshair;
		border: 1px solid rgb(199, 198, 198);
	}

	.aType {
		color: #169BD5;
		cursor: pointer;
	}

	.buttonInfo {
		background: #bd0a29;
		color: rgb(255, 255, 255);
		border-color: #bd0a29;
	}

	.content td {
		border: 1px solid #00f6ff;
		height: 50px;
		line-height: 50px;
		color: #FFFFFF;
		background-color: #1282a5e6;
	}

	.contentBox td {
		border: 1px solid #00f6ff;
		height: 200px;
		color: #FFFFFF;
		align-content: center;
		padding: 10px;
	}

	.contentFooter td {
		border: 1px solid #00f6ff;
		height: 100px;
		color: #FFFFFF;
		align-content: center;
		padding: 10px;
	}
</style>
