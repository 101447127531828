<template>
  <el-dialog title="添加清单" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="1400px">
    <div style="height: 50vh;padding: 20px;overflow-y: scroll;" class="scrollElement">
      <el-form>
        <el-form-item label="单位名称">
          <el-select v-model="orgId" @change="changOder">
            <el-option v-for="(item,index) in orderDate" :key="index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-descriptions :column="3" border v-for="(item, index) in data" :key="index">
          <!-- <el-descriptions-item :label="index" class="desWidth" contentStyle="desWidth">
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label" class="labelInfo">{{index+1}}</template>
            <el-form-item class="formInfo">
              <el-select v-model="item.type" class="eleDetailSelect" clearable placeholder="请选择">
                <el-option key="1" :value="0" label="日管控"></el-option>
                <el-option key="2" :value="1" label="周排查"></el-option>
                <el-option key="3" :value="2" label="月调度"></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item class="desWidth">
            <template slot="label">
              检查项目
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">检查项目请输入风险类型，例如人<br />员、管理、设备、环境、投诉举报等</div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <el-form-item class="formInfo">
              <el-input placeholder="请输入" v-model="item.checkItem" class="eleDetailInput" maxlength="50"
                clearable></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item class="desWidth">
            <template slot="label">
              检查内容
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">检查内容请输入风险管控指标或措<br />施。例如检查电梯巡检人员到岗值守<br />情况，纠正和制止违章作业行为</div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <el-form-item class="formInfo">
              <el-input placeholder="请输入" v-model="item.checkContent" class="eleDetailInput" maxlength="200"
                clearable></el-input>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
        <div class="box-row" style="width: 140px;cursor: pointer;" @click="Add()">添加</div>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="back">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        detailVisible: true,
        // fromInline: {
        orgId: '',
        // },
        data: [{
          checkItem: '',
          type: '',
          checkContent: ''
        }],
        tooltipValue: '',
        // rules: {
        //   type: [{
        //     required: true,
        //     message: "请选择值",
        //     trigger: "change",
        //   }],
        //   checkItem: [{
        //     required: true,
        //     message: "请输入值",
        //     trigger: "blur",
        //   }],
        //   checkContent: [{
        //     required: true,
        //     message: "请输入值",
        //     trigger: "blur",
        //   }]
        // }, //表单规则
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        orderDate: []
      }
    },
    methods: {
      //获取单位信息
      getInfo() {
        this.$http.get(`/api/system/web/org/getUserOrgList`).then((res) => {
          console.log(res, 'res1')
          this.orderDate = res.data.data
          this.orgId = res.data.data.length > 0 ? res.data.data[0].id : ''
        })
      },
      changOder(value) {
        this.orgId = value
      },
      // changType(value) {
      //   this.fromInline.type = value
      // },
      Add() {
        this.data.push({
          checkItem: '',
          type: '',
          checkContent: ''
        })
      },
      sure() {

      },
      submit() {
        const datas = []
        for (var i = 0; i < this.data.length; i++) {
          console.log(this.data[i].type,'type')
          if (this.data[i].type === '') {
            this.$message({
              type: 'error',
              message: '请选择类型'
            })
            return
          } else if (!this.data[i].checkItem) {
            this.$message({
              type: 'error',
              message: '请输入检查项目'
            })
            return
          } else if (!this.data[i].checkContent) {
            this.$message({
              type: 'error',
              message: '请输入检查内容'
            })
            return
          } else {
            datas.push({
              orgId: this.orgId,
              checkItem: this.data[i].checkItem,
              type: this.data[i].type,
              checkContent: this.data[i].checkContent
            })
          }
        }
        console.log(datas, 'fromInline')
        const del = {
          riskManageList: [

          ]
        }
        del.riskManageList = datas
        if (datas[this.data.length - 1] != undefined) {
          this.$http.post('/api/base/web/item/addRiskManageItem', del).then((res) => {
            console.log(666, res)
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.detailVisible = false
              this.$emit('update')
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      },
    },
    created() {
      this.getInfo()
    },
  }
</script>

<style lang="scss" scoped>
  .box-row {
    border: 1px solid #EBEEF5;
    background: #1282a5;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    color: #FFFFFF;
  }

  /deep/.formInfo {
    height: 15px;
  }


  /deep/.eleDetailSelect {
    margin: -12px -6px;

    input {
      border: none !important;
      width: 150px;
      background-color: #041b5700 !important;
    }
  }

  /deep/.el-form-item__error {
    margin-top: 15px !important;
  }

  /deep/.eleDetailInput {
    input {
      border: none !important;
      background-color: #041b5700 !important;
    }
  }
</style>
