<template>
  <div style="height: 63vh; overflow-y: scroll" class="scrollElement">
    <el-form
      :rules="rules"
      ref="ruleForm"
      label-position="right"
      label-width="150px"
      :model="fign"
    >
      <el-row style="margin: 10px 0">
        <h2>1.基本信息</h2>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="12">
          <el-form-item label="合同编号:" prop="eleMaintainContractDTO.code">
            <el-input
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.code"
              placeholder="请输入"
              class="einp"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同类型:" prop="eleMaintainContractDTO.type">
            <el-select
              disabled
              v-model="fign.eleMaintainContractDTO.type"
              placeholder="请输入"
              class="einp"
              clearable
            >
              <el-option
                v-for="item in contractArr"
                :key="item.val"
                :label="item.lab"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="12">
          <el-form-item
            label="物业单位:"
            prop="eleMaintainContractDTO.propertyUnitId"
          >
            <el-select
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.propertyUnitId"
              placeholder="请输入"
              clearable
              filterable
              class="einp"
              @change="chooseProperty"
            >
              <el-option
                v-for="(item, index) in propertyUnit"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="维保单位:"
            prop="eleMaintainContractDTO.maintainUnitId"
          >
            <el-select
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.maintainUnitId"
              placeholder="请输入"
              clearable
              filterable
              class="einp"
              @change="chooseMaintain"
            >
              <el-option
                v-for="(item, index) in maintainUnit"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="12">
          <el-form-item
            label="合同开始日期:"
            prop="eleMaintainContractDTO.startTime"
          >
            <el-date-picker
              :disabled="isEdit"
              class="einp"
              v-model="fign.eleMaintainContractDTO.startTime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              @change="changeStartTime"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="合同时长:"
            prop="eleMaintainContractDTO.contractTime"
          >
            <el-select
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.contractTime"
              placeholder="请输入"
              class="einp"
              clearable
              @change="changeContractTime"
            >
              <el-option
                v-for="item in contractTimeArr"
                :key="item.val"
                :label="item.lab"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="12">
          <el-form-item label="合同总金额:" prop="eleMaintainContractDTO.price">
            <el-input
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.price"
              placeholder="请输入"
              class="einp"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="合同电梯数量:"
            prop="eleMaintainContractDTO.eleNum"
          >
            <el-input
              :disabled="isEdit"
              v-model="fign.eleMaintainContractDTO.eleNum"
              placeholder="请输入"
              class="einp"
            >
              <template slot="append">台</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row style="margin: 10px 0">
        <h2>2.电梯维保信息</h2>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="24">
          <div class="maintenanInfoTypeBox">
            <div class="maintenanInfoTypeLabel">
              <div class="InfoLabel">
                <span style="color: #f56c6c">*</span>
                <span>维保类型:</span>
              </div>
              <div>
                <span>（请勾选合同约定维保类型并设置维保项及规则）</span>
              </div>
            </div>
            <div style="margin-left: 150px">
              <div
                class="isDisplay displayFlex-start mb20"
                style="align-items: flex-start"
              >
                <el-checkbox
                  :disabled="isEdit"
                  v-model="fign.eleMaintainContractDTO.periodicMaintainStatus"
                  @change="changePeriodicStatus"
                  style="width: 10%; line-height: 40px"
                  >周期维保</el-checkbox
                >
                <div style="width: 80%">
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 170px"
                    >
                      <span>半月保</span>
                      <span>12次</span>
                      <span>单次金额</span>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 130px"
                    >
                      <el-input
                        :disabled="periodicStatus || isEdit"
                        v-model="
                          fign.eleMaintainContractDTO.periodicMaintainData.byb
                            .price
                        "
                        placeholder="单次金额"
                        class="inputAppend"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                    <div class="inputBox" style="width: 300px">
                      <el-button
                        v-if="
                          fign.eleMaintainContractDTO.periodicMaintainData.byb
                            .maintainItemId ||
                          fign.eleMaintainItemDTOList[0].name
                        "
                        :disabled="periodicStatus"
                        type="success"
                        size="mini"
                        @click="setItems(0, 'byb')"
                        style="margin-right: 15px"
                        >已设置维保项</el-button
                      >
                      <el-button
                        v-else
                        :disabled="periodicStatus"
                        type="primary"
                        size="mini"
                        @click="setItems(0)"
                        >请设置维保项</el-button
                      >
                    </div>
                  </div>
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 170px"
                    >
                      <span>月度保</span>
                      <span>8次</span>
                      <span>单次金额</span>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 130px"
                    >
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.periodicMaintainData.ydb
                            .price
                        "
                        :disabled="periodicStatus || isEdit"
                        placeholder="单次金额"
                        class="inputAppend"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                    <div class="inputBox" style="width: 300px">
                      <el-button
                        v-if="
                          fign.eleMaintainContractDTO.periodicMaintainData.ydb
                            .maintainItemId ||
                          fign.eleMaintainItemDTOList[1].name
                        "
                        :disabled="periodicStatus"
                        type="success"
                        size="mini"
                        @click="setItems(1, 'ydb')"
                        style="margin-right: 15px"
                        >已设置维保项</el-button
                      >
                      <el-button
                        v-else
                        :disabled="periodicStatus"
                        type="primary"
                        size="mini"
                        @click="setItems(1)"
                        >请设置维保项</el-button
                      >
                    </div>
                  </div>
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 170px"
                    >
                      <span>季度保</span>
                      <span>2次</span>
                      <span>单次金额</span>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 130px"
                    >
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.periodicMaintainData.jdb
                            .price
                        "
                        :disabled="periodicStatus || isEdit"
                        placeholder="单次金额"
                        class="inputAppend"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                    <div class="inputBox" style="width: 300px">
                      <el-button
                        v-if="
                          fign.eleMaintainContractDTO.periodicMaintainData.jdb
                            .maintainItemId ||
                          fign.eleMaintainItemDTOList[2].name
                        "
                        :disabled="periodicStatus"
                        type="success"
                        size="mini"
                        @click="setItems(2, 'jdb')"
                        style="margin-right: 15px"
                        >已设置维保项</el-button
                      >
                      <el-button
                        v-else
                        :disabled="periodicStatus"
                        type="primary"
                        size="mini"
                        @click="setItems(2)"
                        >请设置维保项</el-button
                      >
                    </div>
                  </div>
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 170px"
                    >
                      <span>半年保</span>
                      <span>1次</span>
                      <span>单次金额</span>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 130px"
                    >
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.periodicMaintainData.bnb
                            .price
                        "
                        :disabled="periodicStatus || isEdit"
                        placeholder="单次金额"
                        class="inputAppend"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                    <div class="inputBox" style="width: 300px">
                      <el-button
                        v-if="
                          fign.eleMaintainContractDTO.periodicMaintainData.bnb
                            .maintainItemId ||
                          fign.eleMaintainItemDTOList[3].name
                        "
                        :disabled="periodicStatus"
                        type="success"
                        size="mini"
                        @click="setItems(3, 'ndb')"
                        style="margin-right: 15px"
                        >已设置维保项</el-button
                      >
                      <el-button
                        v-else
                        :disabled="periodicStatus"
                        type="primary"
                        size="mini"
                        @click="setItems(3)"
                        >请设置维保项</el-button
                      >
                    </div>
                  </div>
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 170px"
                    >
                      <span>年度保</span>
                      <span>1次</span>
                      <span>单次金额</span>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 130px"
                    >
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.periodicMaintainData.ndb
                            .price
                        "
                        :disabled="periodicStatus || isEdit"
                        placeholder="单次金额"
                        class="inputAppend"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                    <div class="inputBox" style="width: 300px">
                      <el-button
                        v-if="
                          fign.eleMaintainContractDTO.periodicMaintainData.ndb
                            .maintainItemId ||
                          fign.eleMaintainItemDTOList[4].name
                        "
                        :disabled="periodicStatus"
                        type="success"
                        size="mini"
                        @click="setItems(4, 'ndb')"
                        style="margin-right: 15px"
                        >已设置维保项</el-button
                      >
                      <el-button
                        v-else
                        :disabled="periodicStatus"
                        type="primary"
                        size="mini"
                        @click="setItems(4)"
                        >请设置维保项</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="isDisplay displayFlex-start"
                style="align-items: flex-start"
              >
                <el-checkbox
                  :disabled="isEdit"
                  v-model="fign.eleMaintainContractDTO.demandMaintainStatus"
                  @change="changeDemandStatus"
                  style="width: 10%; line-height: 40px"
                  >按需维保</el-checkbox
                >
                <div style="width: 80%">
                  <div class="isDisplay" style="margin-bottom: 20px">
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 250px"
                    >
                      每年最大次数
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.demandMaintainData.maxNum
                        "
                        :disabled="demandStatus || isEdit"
                        placeholder="请输入"
                        class="inputAppend"
                        style="width: 50%"
                      >
                        <template slot="append">次</template>
                      </el-input>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 250px"
                    >
                      每年最小次数
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.demandMaintainData.minNum
                        "
                        :disabled="demandStatus || isEdit"
                        placeholder="请输入"
                        class="inputAppend"
                        style="width: 50%"
                      >
                        <template slot="append">次</template>
                      </el-input>
                    </div>
                    <div
                      class="inputBox isDisplay displayFlex-between"
                      style="width: 200px"
                    >
                      最大间隔
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.demandMaintainData.maxInterval
                        "
                        :disabled="demandStatus || isEdit"
                        placeholder="请输入"
                        class="inputAppend"
                        style="width: 60%"
                      >
                        <template slot="append">天</template>
                      </el-input>
                    </div>
                    <div class="inputBox isDisplay displayFlex-between" style="width: 180px">
                      单次金额
                      <el-input
                        v-model="
                          fign.eleMaintainContractDTO.demandMaintainData.price
                        "
                        :disabled="demandStatus || isEdit"
                        placeholder="单次金额"
                        class="inputAppend"
                        style="width: 60%"
                      >
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                  </div>
                  <div>
                    <div class="isDisplay" style="margin-bottom: 20px">
                      <div style="width: 150px">按需维保规则类型：</div>
                      <div class="inputBox">
                        <el-checkbox
                          @change="changeRuleStatus"
                          v-model="
                            fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus
                          "
                          :disabled="demandStatus || isEdit"
                          style="width: 10%"
                          >按运行状态</el-checkbox
                        >
                      </div>
                      <div class="inputBox" style="width: 300px">
                        <el-button
                          v-if="
                            fign.eleMaintainContractDTO.demandMaintainData.operationRoleId || (fign.roleByOperation && fign.roleByOperation.name)
                          "
                          :disabled="demandStatus"
                          type="success"
                          size="mini"
                          @click="setCondition('2', 'operation')"
                          style="margin-right: 15px"
                          >已设置生效条件</el-button
                        >
                        <el-button
                          v-else
                          :disabled="demandStatus"
                          type="primary"
                          size="mini"
                          @click="setCondition('2')"
                          >请设置生效条件</el-button
                        >
                      </div>
                    </div>
                    <div class="isDisplay" style="margin-bottom: 20px">
                      <div style="width: 150px"></div>
                      <div class="inputBox">
                        <el-checkbox
                          @change="changeRuleStatus"
                          v-model="
                            fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus
                          "
                          :disabled="demandStatus || isEdit"
                          style="width: 10%"
                          >按故障</el-checkbox
                        >
                      </div>
                      <div class="inputBox" style="width: 300px">
                        <el-button
                          v-if="
                            fign.eleMaintainContractDTO.demandMaintainData.falutRoleId || (fign.roleByFalut && fign.roleByFalut.name)
                          "
                          type="success"
                          size="mini"
                          :disabled="demandStatus"
                          @click="setCondition('1', 'falut')"
                          style="margin-right: 15px"
                          >已设置生效条件</el-button
                        >
                        <el-button
                          v-else
                          :disabled="demandStatus"
                          type="primary"
                          size="mini"
                          @click="setCondition('1')"
                          >请设置生效条件</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="24">
          <el-form-item label="选择电梯:">
            <div class="isDisplay">
              <el-input
                disabled
                class="inputBox"
                style="width: 40%"
                placeholder="请输入"
                v-model="elevatorName"
              >
                <el-button slot="append" @click="chooseEle">选择电梯</el-button>
              </el-input>
              <div class="inputBox" style="width: 180px" v-if="fign.eleMaintainContractDTO.periodicMaintainStatus">
                <el-button
                  v-if="
                    (fign.eleMaintainContractDTO
                      .eleMaintainContractElevatorDTOList.length > 0 || initClooseEleInfo.length > 0) &&
                    isEditTime
                  "
                  type="success"
                  size="mini"
                  @click="setFirstTime('2', 'falut')"
                  style="margin-right: 15px"
                  >已设置电梯首次维保时间</el-button
                >
                <el-button
                  v-else
                  :disabled="initClooseEleInfo.length == 0"
                  type="primary"
                  size="mini"
                  @click="setFirstTime('2')"
                  >请设置电梯首次维保时间</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <el-col :span="24">
          <el-form-item
            label="是否指定维保单位:"
            prop="eleMaintainContractDTO.fixedMaintainUnit"
          >
            <el-radio-group
              v-model="fign.eleMaintainContractDTO.fixedMaintainUnit"
              disabled
            >
              <el-radio :label="0">是</el-radio>
              <!-- <el-radio :label="0">否</el-radio> -->
            </el-radio-group>
            <!-- <span>（勾选是之后，以系统设置的维保单位为准）</span> -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="是否固定维保人员:"
            prop="eleMaintainContractDTO.fixedMaintainUser"
          >
            <el-radio-group
              v-model="fign.eleMaintainContractDTO.fixedMaintainUser"
              :disabled="isEdit"
            >
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
            <span>（勾选是之后，以系统设置的维保人员为准）</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <h2>3.收付款信息</h2>
        <el-col :span="24" class="mb20">
          <div class="maintenanInfoTypeLabel isDisplay mb20">
            <div class="InfoLabel">
              <span style="color: #f56c6c">*</span>
              <span>物业预付方式:</span>
            </div>
            <div class="isDisplay" style="width: 60%">
              <el-select
                :disabled="isEdit"
                style="margin: 0 20px"
                v-model="fign.eleMaintainPaymentDataVo.properPrepayType"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in paymentArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
              <el-button
                v-if="fign.propertyAccountDTO.bankName"
                type="success"
                size="mini"
                @click="setBank('Property', fign.propertyAccountDTO)"
                >已设置物业付款银行账号及开票信息</el-button
              >
              <el-button
                v-else
                type="primary"
                size="mini"
                @click="setBank('Property', { bankName: '' })"
                >请设置物业付款银行账号及开票信息</el-button
              >
            </div>
          </div>
          <div class="isDisplay mb20">
            <div style="width: 50px" />
            <div
              class="inputBox isDisplay displayFlex-between"
              style="width: 100px"
            >
              <span>物业公司需</span>
            </div>
            <div
              class="inputBox isDisplay displayFlex-between"
              v-if="fign.eleMaintainPaymentDataVo.properPrepayType == 1"
              style="width: 150px"
            >
              <div style="width: 60px">每月</div>
              <el-select
                :disabled="isEdit"
                style="margin: 0 10px"
                v-model="prepaymentDay"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in dayArr1"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="inputBox isDisplay displayFlex-between"
              v-else
              :style="{
                width:
                  fign.eleMaintainPaymentDataVo.properPrepayType != 1
                    ? '350px'
                    : '150px',
              }"
            >
              <div style="width: 60px">每年</div>
              <el-select
                :disabled="isEdit"
                v-if="fign.eleMaintainPaymentDataVo.properPrepayType == 2"
                style="margin: 0 10px; width: 300px"
                v-model="prepaymentSeason"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in seasonArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
              <el-select
                v-else
                :disabled="isEdit"
                v-model="prepaymentMonth"
                placeholder="请输入"
                @change="changeMaintainSettleType"
              >
                <el-option
                  v-for="item in monthArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
              <el-select
                :disabled="isEdit"
                style="margin: 0 10px"
                v-model="prepaymentDay"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in dayArr1"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputBox">之前存入</div>
            <div class="inputBox" style="width: 180px">
              <el-input
                :disabled="isEdit"
                v-model="fign.eleMaintainPaymentDataVo.properPrepayAmount"
                placeholder="请输入每次金额"
                class="inputAppend"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="maintenanInfoTypeLabel isDisplay mb20">
            <div class="InfoLabel inputBox">
              <span style="color: #f56c6c">*</span>
              <span>维保订单扣款方式:</span>
            </div>
            <div class="isDisplay" style="width: 70%">
              <el-radio-group
                v-model="fign.eleMaintainContractDTO.maintainOrderStatus"
                :disabled="isEdit"
              >
                <el-radio :label="0">自动扣款</el-radio>
                <el-radio :label="1">手动扣款</el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="maintenanInfoTypeLabel isDisplay mb20">
            <div class="InfoLabel inputBox">
              <span style="color: #f56c6c">*</span>
              <span>维保结算方式:</span>
            </div>
            <div class="isDisplay" style="width: 70%">
              <el-button
                v-if="fign.maintainAccountDTO.bankName"
                type="success"
                size="mini"
                @click="setBank('maintenan', fign.maintainAccountDTO)"
                >已设置维保收款银行账号</el-button
              >
              <el-button
                v-else
                type="primary"
                size="mini"
                @click="setBank('maintenan', { bankName: '' })"
                >请设置维保收款银行账号</el-button
              >
            </div>
          </div>
          <div class="isDisplay mb20" v-if="fign.eleMaintainContractDTO.periodicMaintainStatus">
            <div style="width: 50px" />
            <div
              class="inputBox isDisplay displayFlex-between"
              style="width: 100px"
            >
              周期维保结算：
            </div>
            <div class="inputBox">
              <el-select
                :disabled="isEdit"
                style="margin: 0 20px"
                v-model="
                  fign.eleMaintainPaymentDataVo.periodicMaintainSettleType
                "
                placeholder="请输入"
              >
                <el-option
                  v-for="item in settlementArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inputBox">维保公司于</div>

            <div
              class="inputBox isDisplay displayFlex-between"
              v-if="
                fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 1
              "
              style="width: 150px"
            >
              <div style="width: 60px">每月</div>
              <el-select
                :disabled="isEdit"
                style="margin: 0 10px"
                v-model="settlementDay"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in dayArr1"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="inputBox isDisplay displayFlex-between"
              v-else
              :style="{
                width:
                  fign.eleMaintainPaymentDataVo.periodicMaintainSettleType != 1
                    ? '350px'
                    : '150px',
              }"
            >
              <div style="width: 60px">每年</div>
              <el-select
                :disabled="isEdit"
                v-if="
                  fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 2
                "
                style="margin: 0 10px; width: 300px"
                v-model="settlementSeason"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in seasonArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
              <el-select
                v-else
                :disabled="isEdit"
                v-model="settlementMonth"
                placeholder="请输入"
                @change="changeMaintainSettleType"
              >
                <el-option
                  v-for="item in monthArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
              <el-select
                :disabled="isEdit"
                style="margin: 0 10px"
                v-model="settlementDay"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in dayArr1"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="inputBox" style="width: 130px;">
                  <el-input class="inputBox" v-model="fign.eleMaintainContractDTO.demandMaintainSettleType" placeholder="1号" style="width: 120px;"/>
                </div> -->
            <div class="inputBox">进行结算上一周期费用。</div>
          </div>

          <div class="isDisplay mb20" v-if="fign.eleMaintainContractDTO.demandMaintainStatus">
            <div style="width: 50px" />
            <div
              class="inputBox isDisplay displayFlex-between"
              style="width: 100px"
            >
              按需维保结算：
            </div>
            <div class="inputBox">
              <el-select
                :disabled="isEdit"
                style="margin: 0 20px"
                v-model="fign.eleMaintainPaymentDataVo.demandMaintainSettleType"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in onDemandArr"
                  :key="item.val"
                  :label="item.lab"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
            <template
              v-if="fign.eleMaintainPaymentDataVo.demandMaintainSettleType == 2"
            >
              <div class="inputBox">维保公司于</div>
              <div class="inputBox">每年</div>
              <div class="inputBox isDisplay" style="width: 230px">
                <el-select
                  :disabled="isEdit"
                  style="margin: 0 10px"
                  v-model="demandMaintainSettleMonth"
                  placeholder="请输入"
                  @change="changeMaintainSettleType"
                >
                  <el-option
                    v-for="item in monthArr"
                    :key="item.val"
                    :label="item.lab"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :disabled="isEdit"
                  style="margin: 0 10px"
                  v-model="demandMaintainSettleDay"
                  placeholder="请输入"
                >
                  <el-option
                    v-for="item in maintainTypeDayArr"
                    :key="item.val"
                    :label="item.lab"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
                <!-- <el-input class="inputBox" v-model="fign.eleMaintainContractDTO.price" placeholder="1号" style="width: 120px;"/> -->
              </div>
              <div class="inputBox">进行结算上一年度费用</div>
            </template>
            <div v-else>
              维保公司于维保订单完成确认后，自动收到完成订单费用。每年度完成最小次数后，进行剩余费用结算。
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0">
        <h2>4.合同文件</h2>
        <el-form-item label="上传合同文件:" prop="fileUrl">
          <FileUpload
            v-if="!isEdit"
            :md5s.sync="fign.eleMaintainContractDTO.fileUrl"
            :multiple="false"
            :imgList="['pdf']"
            acceptType=".pdf"
            :fileSize="10"
            :limit="1"
            :url="url"
            style="display: flex"
          />
          <p v-else @click="openFile(fign.eleMaintainContractDTO.fileUrl)">{{ fign.eleMaintainContractDTO.fileUrl }}</p>
          <div v-if="!fign.eleMaintainContractDTO.fileUrl">请上传单个pdf文件，大小不超过10MB</div>
        </el-form-item>
      </el-row>
    </el-form>

    <WbItemDialog
      ref="WbItemDialog"
      v-if="setItemsVisible"
      :wbItemTitle="wbItemTitle"
      :wbItemType="wbItemType"
      :initWbItemId="initWbItemId"
      :initWbItemInfo="initWbItemInfo"
      :isEdit="isEdit"
      @close="closeSetItems"
    />
    <WbSettingDialog
      ref="WbSetting"
      v-if="wbSettingVisible"
      :wbSettingTitle="wbSettingTitle"
      :takeEffectType="takeEffectType"
      :initWbSettingId="initWbSettingId"
      :initWbSettingInfo="initWbSettingInfo"
      :isEdit="isEdit"
      @close="closeWbSetting"
    />
    <EleDialog
      ref="EleDialog"
      v-if="chooseEleVisible"
      :clooseEleType="clooseEleType"
      :propertyUnitId="fign.eleMaintainContractDTO.propertyUnitId"
      :maintainUnitId="fign.eleMaintainContractDTO.maintainUnitId"
      :initClooseEleId="initClooseEleId"
      :initClooseEleInfo="initClooseEleInfo"
      :isEdit="isEdit"
      @close="closeChooseEle"
    />
    <FirstTimeDialog
      ref="FirstTimeDialog"
      v-if="firstTimeVisible"
      :firstTimeTitle="firstTimeTitle"
      :startTime="fign.eleMaintainContractDTO.startTime"
      :endTime="fign.eleMaintainContractDTO.endTime"
      :initClooseEleInfo="initClooseEleInfo"
      :isEdit="isEdit"
      @close="closeFirstTime"
    />
    <BankDialog
      ref="BankDialog"
      v-if="setBankVisible"
      :actionBankTitle="actionBankTitle"
      :typeBankTitle="typeBankTitle"
      :initFign="
        typeBankTitle == '物业'
          ? fign.propertyAccountDTO
          : fign.maintainAccountDTO
      "
      :isEdit="isEdit"
      @close="closeBankSetting"
    />
  </div>
</template>

<script>
let dayArr = [
  { val: '01', lab: "1号" },
  { val: '02', lab: "2号" },
  { val: '03', lab: "3号" },
  { val: '04', lab: "4号" },
  { val: '05', lab: "5号" },
  { val: '06', lab: "6号" },
  { val: '07', lab: "7号" },
  { val: '08', lab: "8号" },
  { val: '09', lab: "9号" },
  { val: '10', lab: "10号" },
  { val: '11', lab: "11号" },
  { val: '12', lab: "12号" },
  { val: '13', lab: "13号" },
  { val: '14', lab: "14号" },
  { val: '15', lab: "15号" },
  { val: '16', lab: "16号" },
  { val: '17', lab: "17号" },
  { val: '18', lab: "18号" },
  { val: '19', lab: "19号" },
  { val: '20', lab: "20号" },
  { val: '21', lab: "21号" },
  { val: '22', lab: "22号" },
  { val: '23', lab: "23号" },
  { val: '24', lab: "24号" },
  { val: '25', lab: "25号" },
  { val: '26', lab: "26号" },
  { val: '27', lab: "27号" },
  { val: '28', lab: "28号" },
];
import FileUpload from "../../../../components/FileUploadPWP";
import BankDialog from "./bankDialog.vue";
import WbItemDialog from "./wbItemDialog.vue";
import WbSettingDialog from "./wbSettingDialog.vue";
import EleDialog from "./eleDialog.vue";
import FirstTimeDialog from "./firstTimeDialog.vue";
export default {
  components: {
    FileUpload,
    BankDialog,
    WbItemDialog,
    WbSettingDialog,
    EleDialog,
    FirstTimeDialog,
  },
  props: ["maintainUnit", "propertyUnit", 'actionType', 'initFign'],
  data() {
    var validatePrice = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^\d+(\.\d{0,2})?$/,
        "合同总金额只可输入大于0的数字且仅保留两位小数",
        callback
      );
    };
    var validateEleNum = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^\d+?$/,
        "合同电梯数量只可输入大于0的整数",
        callback
      );
    };
    return {
      actionFormType: 'add',
      num: 1,
      fign: {
        eleMaintainItemDTOList: [
          { name: "", type: 1 },
          { name: "", type: 2 },
          { name: "", type: 3 },
          { name: "", type: 5 },
          { name: "", type: 4 },
        ],
        eleMaintainContractDTO: {
          type: 0,
          startTime: "",
          contractTime: 1,
          periodicMaintainData: {
            byb: {},
            ydb: {},
            jdb: {},
            bnb: {},
            ndb: {},
          },
          demandMaintainData: {},
          eleMaintainContractElevatorDTOList: [],
          fixedMaintainUnit: 0,
          fixedMaintainUser: 0,
          maintainOrderStatus: 0,
          fileUrl: ''
        },
        eleMaintainPaymentDataVo: {
          properPrepayType: 1,
          periodicMaintainSettleTime: 1,
          periodicMaintainSettleType: 1,
          demandMaintainSettleType: 1,
        },
        propertyAccountDTO: {
          bankName: "",
        },
        maintainAccountDTO: {
          bankName: "",
        },
        roleByOperation: {
          name: "",
        },
        roleByFalut: {
          name: "",
        },
      },
      contractArr: [
        { val: 0, lab: "三方合同" },
        { val: 1, lab: "双方合同" },
      ],
      contractTimeArr: [
        { val: 1, lab: "一年" },
        { val: 2, lab: "两年" },
        { val: 3, lab: "三年" },
      ],
      // 预付方式
      paymentArr: [
        { val: 1, lab: "按月预付" },
        { val: 2, lab: "按季度预付" },
        { val: 3, lab: "按年度预付" },
      ],
      // 结算方式
      settlementArr: [
        { val: 1, lab: "按月结算" },
        { val: 2, lab: "按季度结算" },
        { val: 3, lab: "按年度结算" },
      ],
      // 按需维保结算
      onDemandArr: [
        { val: 1, lab: "按次结算" },
        { val: 2, lab: "按年结算" },
      ],
      seasonArr: [
        { val: "01,04,07,10", lab: "1月,4月,7月,10月" },
        { val: "02,05,08,11", lab: "2月,5月,8月,11月" },
        { val: "03,06,09,12", lab: "3月,6月,9月,12月" },
      ],
      monthArr: [
        { val: '01', lab: "一月" },
        { val: '02', lab: "二月" },
        { val: '03', lab: "三月" },
        { val: '04', lab: "四月" },
        { val: '05', lab: "五月" },
        { val: '06', lab: "六月" },
        { val: '07', lab: "七月" },
        { val: '08', lab: "八月" },
        { val: '09', lab: "九月" },
        { val: '10', lab: "十月" },
        { val: '11', lab: "十一月" },
        { val: '12', lab: "十二月" },
      ],
      dayArr1: [...dayArr],
      dayArr2: [...dayArr, { val: '29', lab: "29号" }, { val: '30', lab: "30号" }],
      dayArr3: [
        ...dayArr,
        { val: '29', lab: "29号" },
        { val: '30', lab: "30号" },
        { val: '31', lab: "31号" },
      ],
      prepaymentSeason: "01,04,07,10", // 物业预付季度
      prepaymentMonth: '01', // 物业预付月份
      prepaymentDay: '01', // 物业预付日期
      settlementSeason: "01,04,07,10", // 维保结算季度
      settlementMonth: '01', // 维保结算月份
      settlementDay: '01', // 维保结算日期
      demandMaintainSettleMonth: '01', // 按需维保结算月份
      demandMaintainSettleDay: '01', // 按需维保结算日期
      maintainTypeDayArr: [], // 按需维保结算日期数组
      periodicStatus: true, // 周期维保状态
      demandStatus: true, // 按需维保状态

      //设置维保项
      setItemsVisible: false,
      wbItemTitle: "",
      initWbItemId: "",
      initWbItemInfo: "",
      wbItemType: "",

      //设置维保规则
      wbSettingVisible: false,
      wbSettingTitle: "",
      takeEffectType: "",
      initWbSettingId: "",
      initWbSettingInfo: "",

      // 选择电梯
      chooseEleVisible: false,
      clooseEleType: 0,
      elevatorName: "",
      elevatorId: [],
      initClooseEleId: [],
      initClooseEleInfo: [],

      // 电梯首次维保
      firstTimeVisible: false,
      firstTimeTitle: "",
      isEditTime: false, //是否首次维保时间（true无设置）
      initFirstTime: [],

      // 银行卡设置
      setBankVisible: false,
      actionBankTitle: "新增",
      typeBankTitle: "物业",
      // 表单限制
      rules: {
        eleMaintainContractDTO: {
          code: [
            {
              required: true,
              message: "请输入合同编号",
              trigger: "blur",
            },
          ],
          type: [
            {
              required: true,
              message: "请选择合同类型",
              trigger: "change",
            },
          ],
          propertyUnitId: [
            {
              required: true,
              message: "请选择维保单位",
              trigger: "change",
            },
          ],
          maintainUnitId: [
            {
              required: true,
              message: "请选择物业单位",
              trigger: "change",
            },
          ],
          contractTime: [
            {
              required: true,
              message: "请选择合同时长",
              trigger: "change",
            },
          ],
          startTime: [
            {
              required: true,
              message: "合同开始时间不能为空",
              trigger: "blur",
            },
          ],
          price: [
            {
              required: true,
              validator: validatePrice,
              trigger: "blur",
            },
          ],
          eleNum: [
            {
              required: true,
              validator: validateEleNum,
              trigger: "blur",
            },
          ],
          fixedMaintainUnit: [
            {
              required: true,
              message: "请选择维保单位",
              trigger: "change",
            },
          ],
          fixedMaintainUser: [
            {
              required: true,
              message: "请选择维保单位",
              trigger: "change",
            },
          ],
        },
      },

      checked: "",
      url: "/api/file/web/uploadByOSS", // 上传地址
      contractFormDisabled: false, // 查看禁点

      isEdit: false

    };
  },
  mounted() {
    this.changeMaintainSettleType('01');
    this.actionFormType = this.actionType
    this.isEdit = this.actionType == 'check'
    if (this.initFign.eleMaintainContractDTO && this.initFign.eleMaintainContractDTO.id) {
      this.fign = JSON.parse(JSON.stringify(this.initFign))
      this.periodicStatus = !this.fign.eleMaintainContractDTO.periodicMaintainStatus
      this.demandStatus = !this.fign.eleMaintainContractDTO.demandMaintainStatus
      this.isEditTime = !!!this.fign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.find( (item) => item.firstMaintainTime == "" )
      const eleName = []
      this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.map(item => {
        eleName.push(item.name)
      })
      this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.map(item => {
        this.initClooseEleId.push(item.id)
      })
      this.initClooseEleInfo = this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList
      this.elevatorName = eleName.join(',')

      // 预存时间
      if (this.fign.eleMaintainPaymentDataVo.properPrepayType == 1) {
        this.prepaymentDay = this.fign.eleMaintainPaymentDataVo.properPrepayTime
      } else if (this.fign.eleMaintainPaymentDataVo.properPrepayType == 2) {
        const seasonArr = []
        const dayArr = []
        this.fign.eleMaintainPaymentDataVo.properPrepayTime.split(',').map(item => {
          seasonArr.push(item.split('-')[0])
          dayArr.push(item.split('-')[1])
        })
        this.prepaymentSeason = seasonArr.join(',')
        this.prepaymentDay = dayArr[0]
        // data.eleMaintainPaymentDataVo.properPrepayTime = this.prepaymentSeason.split(',').map(item => `${item}-${this.prepaymentDay}`).join(',')
      } else {
        this.prepaymentMonth = this.fign.eleMaintainPaymentDataVo.properPrepayTime.split('-')[0]
        this.prepaymentDay = this.fign.eleMaintainPaymentDataVo.properPrepayTime.split('-')[1]
      }


      // 周期维保结算时间
      if (this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 1) {
        this.settlementDay = this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleTime
      } else if (this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 2) {
        const seasonArr = []
        const dayArr = []
        this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleTime.split(',').map(item => {
          seasonArr.push(item.split('-')[0])
          dayArr.push(item.split('-')[1])
        })
        this.settlementSeason = seasonArr.join(',')
        this.settlementDay = dayArr[0]
        // data.eleMaintainPaymentDataVo.periodicMaintainSettleTime = this.settlementSeason.split(',').map(item => `${item}-${this.settlementDay}`).join(',')
      } else {
        this.settlementMonth = this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleTime.split('-')[0]
        this.settlementDay = this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleTime.split('-')[1]
      }
      
      // 按需维保结算时间
      if (this.fign.eleMaintainPaymentDataVo.demandMaintainSettleType == 1) {
        this.demandMaintainSettleDay = '01'
      } else {
        this.demandMaintainSettleMonth = this.fign.eleMaintainPaymentDataVo.demandMaintainSettleTime.split('-')[0]
        this.demandMaintainSettleDay = this.fign.eleMaintainPaymentDataVo.demandMaintainSettleTime.split('-')[1]
      }
    }
  },
  methods: {
    // 月份对应天数
    getMonthDay(value) {
      let monthday = [];
      if (['01', '03', '05', '07', '08', '10', '12'].includes(value)) {
        monthday = this.dayArr3;
      }
      if (['04', '06', '09', '11'].includes(value)) {
        monthday = this.dayArr2;
      }
      if (value == '02') {
        monthday = this.dayArr1;
      }
      return monthday;
    },

    // 改变开始时间
    changeStartTime (value) {
      const year = new Date(value).getFullYear()
      const month = new Date(value).getMonth() > 10 ? new Date(value).getMonth() + 1 : `0${new Date(value).getMonth() + 1}`
      const day = new Date(value).getDate() > 10 ? new Date(value).getDate() : `0${new Date(value).getDate()}`
      const date = `${year + this.fign.eleMaintainContractDTO.contractTime}-${month}-${day} 00:00:00`
      this.fign.eleMaintainContractDTO.endTime = new Date(date).getTime() + 24 * 3600 * 1000 - 1000;
      const startTime = new Date(this.fign.eleMaintainContractDTO.startTime).getTime()
      const endTime = this.fign.eleMaintainContractDTO.endTime
      this.initClooseEleInfo.map(item => {
        const time = item.firstTime.getTime()
        if (time > endTime || time < startTime) {
          item.firstTime = ''
          item.firstMaintainTime = ''
          this.isEditTime = false
        }
      })
    },

    // 改变合同时长
    changeContractTime (value) {
      const year = new Date(value).getFullYear()
      const month = new Date(value).getMonth() > 10 ? new Date(value).getMonth() + 1 : `0${new Date(value).getMonth() + 1}`
      const day = new Date(value).getDate() > 10 ? new Date(value).getDate() : `0${new Date(value).getDate()}`
      const date = `${year + this.fign.eleMaintainContractDTO.contractTime}-${month}-${day} 00:00:00`
      console.log(date,'changeContractTime');
      this.fign.eleMaintainContractDTO.endTime = new Date(date).getTime() + 24 * 3600 * 1000 - 1000;
      const startTime = new Date(this.fign.eleMaintainContractDTO.startTime).getTime()
      const endTime = this.fign.eleMaintainContractDTO.endTime
      this.initClooseEleInfo.map(item => {
        const time = item.firstTime.getTime()
        if (time > endTime || time < startTime) {
          item.firstTime = ''
          item.firstMaintainTime = ''
          this.isEditTime = false
        }
      })
    },

    // 改变周期维保
    changePeriodicStatus(status) {
      this.periodicStatus = !status
      this.fign.eleMaintainContractDTO.periodicMaintainData = {
        byb: {},
        ydb: {},
        jdb: {},
        bnb: {},
        ndb: {}
      }
      this.fign.eleMaintainItemDTOList = [
          { name: "", type: 1 },
          { name: "", type: 2 },
          { name: "", type: 3 },
          { name: "", type: 5 },
          { name: "", type: 4 },
        ]
    },
    // 改变按需维保
    changeDemandStatus(status) {
      this.demandStatus = !status
      this.fign.eleMaintainContractDTO.demandMaintainData = {
        falutRoleStatus: 0,
        operationRoleStatus: 0
      }
      this.fign.roleByOperation = {
        name: "",
      }
      this.fign.roleByFalut = {
        name: "",
      }
    },

    // 按需维保结算修改
    changeMaintainSettleType(value) {
      
      this.maintainTypeDayArr = this.getMonthDay(value);
    },

    // 维保项
    setItems(listNumber, action) {
      this.wbItemTitle = action ? "修改维保项" : "新建维保项";
      this.initWbItemInfo = this.fign.eleMaintainItemDTOList[listNumber];
      this.wbItemType = listNumber;
      console.log(this.fign.eleMaintainContractDTO.periodicMaintainData,'this.fign.eleMaintainContractDTO.periodicMaintainData.byb.maintainItemId');
      switch (listNumber) {
        case 0:
          this.initWbItemId =
            this.fign.eleMaintainContractDTO.periodicMaintainData.byb.maintainItemId;
          // this.initWbItemId = '1666721356223430657'
          break;

        case 1:
          this.initWbItemId =
            this.fign.eleMaintainContractDTO.periodicMaintainData.ydb.maintainItemId;
          break;

        case 2:
          this.initWbItemId =
            this.fign.eleMaintainContractDTO.periodicMaintainData.jdb.maintainItemId;
          break;

        case 3:
          this.initWbItemId =
            this.fign.eleMaintainContractDTO.periodicMaintainData.bnb.maintainItemId;
          break;

        case 4:
          this.initWbItemId =
            this.fign.eleMaintainContractDTO.periodicMaintainData.ndb.maintainItemId;
          break;
      }
      this.setItemsVisible = true;
    },
    closeSetItems(data, listNumber) {
      
      this.fign.eleMaintainItemDTOList[listNumber] = data;
      this.setItemsVisible = false;
    },

    // 按需维保条件
    // 1:故障 2：运行状态
    setCondition(type, action) {
      this.wbSettingTitle = action ? "新建按需维保条件" : "修改按需维保条件" ;
      this.takeEffectType = type;
      // this.initWbSettingId = '1635926445730160642'
      this.initWbSettingId =
        type == 1
          ? this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleId
          : this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleId;
      this.initWbSettingInfo =
        type == 1 ? this.fign.roleByFalut : this.fign.roleByOperation;
      this.wbSettingVisible = true;
    },
    closeWbSetting(data, type) {
      
      console.log(type,'type');
      if (type == 2) {
        this.fign.roleByOperation = data
      } else {
        this.fign.roleByFalut = data
      }
      console.log(this.fign,'closeWbSetting.fign');
      this.wbSettingVisible = false;
    },

    // 按需维保条件修改
    changeRuleStatus() {
      this.elevatorName = "";
      this.initClooseEleId = [];
      this.initClooseEleInfo = [];
      if (this.actionType != 'add') {
        const eleName = []
        this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.map(item => {
          eleName.push(item.name)
        })
        this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.map(item => {
          this.initClooseEleId.push(item.id)
        })
        this.initClooseEleInfo = this.initFign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList
        this.elevatorName = eleName.join(',')
        
      } 
      this.elevatorId = [];
      this.initFirstTime = [];
      this.fign.eleMaintainContractDTO.eleMaintainContractElevatorDTOList = [];
      this.isEditTime = false
    },
    // 选择电梯
    chooseEle() {
      if (!this.fign.eleMaintainContractDTO.propertyUnitId) {
        this.$message.error("请先选择物业单位");
        return;
      }
      if (!this.fign.eleMaintainContractDTO.maintainUnitId) {
        this.$message.error("请先选择维保单位");
        return;
      }
      if (!this.fign.eleMaintainContractDTO.startTime) {
        this.$message.error("请先设置合同开始时间");
        return;
      }
      if (!this.fign.eleMaintainContractDTO.contractTime) {
        this.$message.error("请先设置合同时长");
        return;
      }
      // 0: 电梯列表 其它：运行状态电梯
      if (this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus) {
        this.clooseEleType = 2;
      }
      if (
        this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus
      ) {
        this.clooseEleType = 1;
      }
      if (
        this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus &&
        this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus
      ) {
        this.clooseEleType = -1;
      } else {
        this.clooseEleType = 0;
      }
      this.chooseEleVisible = true;
    },
    closeChooseEle (data, isCanle) {
      const elevatorName = [];
      if (isCanle) {
        this.elevatorId = []
        this.initClooseEleInfo = data.map((item) => {
          elevatorName.push(item.name);
          this.elevatorId.push(item.id);
          if (!item.firstTime) {
            item.firstTime = "";
          }
          item.elevatorId = item.id
          return item;
        });
        
        this.elevatorName = elevatorName.join(",");
        this.initClooseEleId = this.elevatorId;
        this.isEditTime = !!!data.find((item) => item.firstMaintainTime == "" )
      }

      this.chooseEleVisible = false;
    },

    // 设置电梯维保时间
    setFirstTime(type, action) {
      this.firstTimeTitle = action
        ? "修改电梯首次维保时间"
        : "新建按需维保条件时间";
      this.firstTimeVisible = true;
    },
    closeFirstTime(data) {
      let notTime = data.find((item) => item.firstMaintainTime == "");
      if (!notTime) {
        this.initFirstTime = data;
        this.initClooseEleInfo = data
      } else {
        this.initFirstTime = this.initClooseEleInfo;
      }
      this.isEditTime = !!!this.initFirstTime.find( (item) => item.firstMaintainTime == "" )
      this.firstTimeVisible = false;
    },

    chooseProperty () {
      this.getBankInfo(this.fign.eleMaintainContractDTO.propertyUnitId, 'wy')
      this.changeRuleStatus()
    },
    chooseMaintain () {
      this.getBankInfo(this.fign.eleMaintainContractDTO.maintainUnitId, 'wb')
      this.changeRuleStatus()
    },
    getBankInfo (id, type) {
      this.$http
        .get(`/api/system/web/account/getOrgAccountList?orgId=${id}`)
        .then((res) => {
          if (res.data.success) {
            if (type == 'wy') {
              this.fign.propertyAccountDTO = res.data.data.length > 0 ? res.data.data[0] : { bankName: '' }
            } 
            if (type == 'wb') {
               this.fign.maintainAccountDTO = res.data.data.length > 0 ? res.data.data[0] : { bankName: '' }
            } 
          }
        });
     },

    // 银行卡
    setBank(type, data) {
      if (type == "Property") {
        this.fign.propertyAccountDTO = data;
        this.actionBankTitle = data.bankName != "" ? "修改" : "新增";
        this.typeBankTitle = "物业";
      } else {
        this.fign.maintainAccountDTO = data;
        this.actionBankTitle = data.bankName != "" ? "修改" : "新增";
        this.typeBankTitle = "维保";
      }
      this.setBankVisible = true;
    },
    closeBankSetting(type, data) {
      if (type == "物业") {
        this.fign.propertyAccountDTO = data;
      } else {
        this.fign.maintainAccountDTO = data;
      }
      this.setBankVisible = false;
    },
    // 文件打开
    openFile(url) {
      window.open(url, '_blank');
    },

    confirm() {
      
      
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.fign.eleMaintainContractDTO.periodicMaintainStatus && !this.fign.eleMaintainContractDTO.demandMaintainStatus) {
            this.$message({
              message: "请选择维保类型",
              type: "error",
            });
            return
          }
          if (this.fign.eleMaintainContractDTO.periodicMaintainStatus) {
            if (!this.fign.eleMaintainContractDTO.periodicMaintainData.byb.price || this.fign.eleMaintainContractDTO.periodicMaintainData.byb.price == 0 ||
            !this.fign.eleMaintainContractDTO.periodicMaintainData.ydb.price || this.fign.eleMaintainContractDTO.periodicMaintainData.ydb.price == 0 ||
            !this.fign.eleMaintainContractDTO.periodicMaintainData.jdb.price || this.fign.eleMaintainContractDTO.periodicMaintainData.jdb.price == 0 ||
            !this.fign.eleMaintainContractDTO.periodicMaintainData.bnb.price || this.fign.eleMaintainContractDTO.periodicMaintainData.bnb.price == 0 ||
            !this.fign.eleMaintainContractDTO.periodicMaintainData.ndb.price || this.fign.eleMaintainContractDTO.periodicMaintainData.ndb.price == 0 
            ) {
              this.$message({
                message: "请输入周期维保金额且必须大于0元",
                type: "error",
              });
              return
            }
            if (!(this.fign.eleMaintainContractDTO.periodicMaintainData.byb.maintainItemId || this.fign.eleMaintainItemDTOList[0].name) ||
            !(this.fign.eleMaintainContractDTO.periodicMaintainData.ydb.maintainItemId || this.fign.eleMaintainItemDTOList[1].name) ||
            !(this.fign.eleMaintainContractDTO.periodicMaintainData.jdb.maintainItemId || this.fign.eleMaintainItemDTOList[2].name) ||
            !(this.fign.eleMaintainContractDTO.periodicMaintainData.bnb.maintainItemId || this.fign.eleMaintainItemDTOList[3].name) ||
            !(this.fign.eleMaintainContractDTO.periodicMaintainData.ndb.maintainItemId || this.fign.eleMaintainItemDTOList[4].name)
            ) {
              this.$message({
                message: "请先设置维保项",
                type: "error",
              });
              return
            }
          }
          if (this.fign.eleMaintainContractDTO.demandMaintainStatus) {
            if (!this.fign.eleMaintainContractDTO.demandMaintainData.maxNum ||
            !this.fign.eleMaintainContractDTO.demandMaintainData.minNum ||
            !this.fign.eleMaintainContractDTO.demandMaintainData.maxInterval ||
            !this.fign.eleMaintainContractDTO.demandMaintainData.price ||
            (!this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus && !this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus)
            ) {
              this.$message({
                message: "请输入按需维保信息",
                type: "error",
              });
              return
            }
            if (this.fign.eleMaintainContractDTO.demandMaintainData.maxInterval < 15) {
              this.$message({
                message: "按需维保最大间隔必须大于15天",
                type: "error",
              });
              return
            }
            if (this.fign.eleMaintainContractDTO.demandMaintainData.price == 0) {
              this.$message({
                message: "按需维保单次金额必须大于0元",
                type: "error",
              });
              return
            }
             if (this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus && !(this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleId || (this.fign.roleByFalut && this.fign.roleByFalut.name))) {
              this.$message({
                message: "请设置故障的维保规则",
                type: "error",
              });
              return
            }
            if (this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus && !(this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleId || (this.fign.roleByOperation && this.fign.roleByOperation.name))) {
              this.$message({
                message: "请设置运行状态的维保规则",
                type: "error",
              });
              return
            }
          }
          if (this.initClooseEleInfo.length == 0) {
            this.$message({
              message: "请选择电梯",
              type: "error",
            });
            return
          }
          console.log(!this.isEditTime);
          if (!this.isEditTime && this.fign.eleMaintainContractDTO.periodicMaintainStatus) {
            this.$message({
              message: "请设置电梯首次维保时间",
              type: "error",
            });
            return
          }
          if (!this.fign.propertyAccountDTO.bankName) {
            this.$message({
              message: "请设置物业付款银行账号",
              type: "error",
            });
            return
          }
          if (!this.fign.eleMaintainPaymentDataVo.properPrepayAmount || this.fign.eleMaintainPaymentDataVo.properPrepayAmount <= 0) {
            this.$message({
              message: "请输入收付款每次金额且不得为0",
              type: "error",
            });
            return
          }
          if (!this.fign.maintainAccountDTO.bankName) {
            this.$message({
              message: "请设置维保收款银行账号",
              type: "error",
            });
            return
          }
          if (!this.fign.eleMaintainContractDTO.fileUrl) {
            this.$message({
              message: "请上传合同文件",
              type: "error",
            });
            return
          }

          const data = JSON.parse(JSON.stringify(this.fign))

          console.log(this.fign.eleMaintainContractDTO.endTime,'this.fign.eleMaintainContractDTO.endTime');

          // 合同开始日期-合同结束日期
          data.eleMaintainContractDTO.startTime = new Date(this.fign.eleMaintainContractDTO.startTime).getTime();
          const year = new Date(this.fign.eleMaintainContractDTO.startTime).getFullYear()
          const month = new Date(this.fign.eleMaintainContractDTO.startTime).getMonth() > 10 ? new Date(this.fign.eleMaintainContractDTO.startTime).getMonth() + 1 : `0${new Date(this.fign.eleMaintainContractDTO.startTime).getMonth() + 1}`
          const day = new Date(this.fign.eleMaintainContractDTO.startTime).getDate() > 10 ? new Date(this.fign.eleMaintainContractDTO.startTime).getDate() : `0${new Date(this.fign.eleMaintainContractDTO.startTime).getDate()}`
          const date = `${year + this.fign.eleMaintainContractDTO.contractTime}-${month}-${day} 00:00:00`
          data.eleMaintainContractDTO.endTime = new Date(date).getTime() + 24 * 3600 * 1000 - 1000;

          // 预存时间
          if (this.fign.eleMaintainPaymentDataVo.properPrepayType == 1) {
            data.eleMaintainPaymentDataVo.properPrepayTime = this.prepaymentDay
          } else if (this.fign.eleMaintainPaymentDataVo.properPrepayType == 2) {
            data.eleMaintainPaymentDataVo.properPrepayTime = this.prepaymentSeason.split(',').map(item => `${item}-${this.prepaymentDay}`).join(',')
          } else {
            data.eleMaintainPaymentDataVo.properPrepayTime = `${this.prepaymentMonth}-${this.prepaymentDay}`
          }
          // 周期维保结算时间
          console.log(this.settlementMonth,'this.settlementMonth');
          if (this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 1) {
            data.eleMaintainPaymentDataVo.periodicMaintainSettleTime = this.settlementDay
          } else if (this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleType == 2) {
            data.eleMaintainPaymentDataVo.periodicMaintainSettleTime = this.settlementSeason.split(',').map(item => `${item}-${this.settlementDay}`).join(',')
          } else {
            data.eleMaintainPaymentDataVo.periodicMaintainSettleTime = `${this.settlementMonth}-${this.settlementDay}`
          }
          
          // 按需维保结算时间
          if (this.fign.eleMaintainPaymentDataVo.demandMaintainSettleType == 1) {
            data.eleMaintainPaymentDataVo.demandMaintainSettleTime = this.demandMaintainSettleDay
          } else {
            data.eleMaintainPaymentDataVo.demandMaintainSettleTime = `${this.demandMaintainSettleMonth}-${this.demandMaintainSettleDay}`
          }

          if (this.periodicStatus) {
            data.eleMaintainContractDTO.periodicMaintainStatus = 0
            data.eleMaintainContractDTO.periodicMaintainData = ''
          } else {
            data.eleMaintainContractDTO.periodicMaintainStatus = 1
            data.eleMaintainContractDTO.periodicMaintainData = JSON.stringify(this.fign.eleMaintainContractDTO.periodicMaintainData)
          }

          if (this.demandStatus) {
            data.roleByOperation = {}
            data.roleByFalut = {}
            data.eleMaintainContractDTO.demandMaintainStatus = 0
            data.eleMaintainContractDTO.demandMaintainData = ''
          } else {
            console.log(this.fign,'this.fign');
            data.roleByOperation = this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus ? this.fign.roleByOperation : {}
            data.roleByFalut = this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus ? this.fign.roleByFalut : {}
            data.eleMaintainContractDTO.demandMaintainStatus = 1
            this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus = !!this.fign.eleMaintainContractDTO.demandMaintainData.operationRoleStatus != 0
            this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus = !!this.fign.eleMaintainContractDTO.demandMaintainData.falutRoleStatus != 0
            
            data.eleMaintainContractDTO.demandMaintainData = JSON.stringify(this.fign.eleMaintainContractDTO.demandMaintainData)
            
          }
          console.log(this.initClooseEleInfo,'this.initClooseEleInfo');
          data.eleMaintainContractDTO.eleMaintainContractElevatorDTOList = []
          this.initClooseEleInfo.map(item => {
            data.eleMaintainContractDTO.eleMaintainContractElevatorDTOList.push({
              elevatorId: item.elevatorId,
              firstMaintainTime: item.firstTime ? item.firstTime.getTime() : ''
            })
          })
          console.log(this.fign.eleMaintainPaymentDataVo,'this.fign.eleMaintainPaymentDataVo');
          if (this.actionType != 'add' ) {
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.properPrepayType = this.fign.eleMaintainPaymentDataVo.properPrepayType
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.properPrepayTime = this.fign.eleMaintainPaymentDataVo.properPrepayTime
            
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.periodicMaintainSettleType = this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleType
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.periodicMaintainSettleTime = this.fign.eleMaintainPaymentDataVo.periodicMaintainSettleTime
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.properPrepayAmount = this.fign.eleMaintainPaymentDataVo.properPrepayAmount
            
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.demandMaintainSettleType = this.fign.eleMaintainPaymentDataVo.demandMaintainSettleType
            data.eleMaintainContractDTO.eleMaintainPaymentDataVo.demandMaintainSettleTime = this.fign.eleMaintainPaymentDataVo.demandMaintainSettleTime
            
          }

          //
          data.eleMaintainItemDTOList = data.eleMaintainItemDTOList.filter(item => item.name != '')
          
            let url = this.actionType == 'add' ?
            '/api/ele/web/maintainContract/save' :
            '/api/ele/web/maintainContract/update'
            this.$http.post(url, data)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit('Close')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
        }
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.maintenanInfoTypeBox {
  margin-bottom: 22px;
  .maintenanInfoTypeLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 40px;
    .InfoLabel {
      width: 138px;
      text-align: right;
      padding-right: 12px;
    }
  }
}
.inputBox {
  margin-right: 15px;
}

.isDisplay {
  display: flex;
  align-items: center;
}
.displayFlex-between {
  justify-content: space-between;
}
.displayFlex-around {
  justify-content: space-around;
}
.displayFlex-start {
  justify-content: flex-start;
}
.mb20 {
  margin-bottom: 20px;
}
/deep/.inputAppend {
  .el-input-group__append {
    padding: 0 10px;
  }
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409eff !important;
  background: #409eff !important;
}
/deep/.el-radio {
  color: #fff !important;
}

/deep/.is-checked .el-radio__label {
  color: #409eff !important;
}
</style>
