var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":'维保项详情',"visible":_vm.visible,"append-to-body":"","close-on-click-modal":false,"width":"950px","top":"15vh"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.selectunitDialogClose}},[_c('div',{staticClass:"admin-project-myproject-container",staticStyle:{"height":"40vh","overflow-y":"scroll","padding-right":"20px"}},[_c('el-form',{ref:"mainterItemForm",attrs:{"label-position":"right","label-width":"120px","model":_vm.mainterItemForm}},[_c('el-row',{staticStyle:{"margin":"10px 0"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"维保项名称:","prop":"name"}},[_c('el-input',{staticClass:"wid90",attrs:{"disabled":"","placeholder":"请输入"},model:{value:(_vm.mainterItemForm.name),callback:function ($$v) {_vm.$set(_vm.mainterItemForm, "name", $$v)},expression:"mainterItemForm.name"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"维保类型:","prop":"type"}},[_c('el-select',{staticClass:"wid90",attrs:{"disabled":"","placeholder":"请输入"},model:{value:(_vm.mainterItemForm.type),callback:function ($$v) {_vm.$set(_vm.mainterItemForm, "type", $$v)},expression:"mainterItemForm.type"}},_vm._l((_vm.maintainType),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":Number(item.value)}})}),1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_vm._l((_vm.mainterItemForm.mainItemsArr),function(item,index){return [_c('el-form-item',{key:index,attrs:{"label":`标题${index + 1}:`,"prop":'mainItemsArr.' + index + '.value',"rules":{
                required: true,
                message:
                  '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                trigger: 'change',
                min: 5,
                max: 200,
              }}},[_c('el-input',{staticClass:"wid70 mr20",attrs:{"disabled":"","placeholder":"请输入"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('el-checkbox',{attrs:{"disabled":""},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v("是否拍照")])],1)]})],2)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.selectunitDialogClose}},[_vm._v("关 闭")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }