<template>
  <el-dialog v-if="visiable" :title="tit" :visible.sync="visiable" :append-to-body="true" :close-on-click-modal="false"
    @close="back" width="500px" top="25vh">
    <div style="margin: 20px;">
      <span>手机号: {{ adminInfo.phone }}</span>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="短信验证" prop="code">
          <el-input placeholder="请输入短信验证码" style="width: 98%;" v-model="form.code">
            <template slot="append">
              <el-button @click="send" :disabled="(!timeShow )"
                type="primary">{{ timeShow ? '发送验证码' : `${time}秒后发送` }}</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button type="primary" @click="sure">确认</el-button>
      <el-button @click="back">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: ["tit", 'Id', 'propForm'],
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        visiable: true,
        time: '',
        timeShow: true,
        timershow: null,
        form: {
          code: ''
        },
        rules: {
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }]
        },
      }
    },
    created() {
      // this.visiable = true
      let EndTime = sessionStorage.getItem('infoEndTime');
      if (EndTime) {
        this.downTime(EndTime);
      }
    },
    methods: {
      sure() {
        console.log(this.propForm, 111)
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            if (Number(this.adminInfo.roleId) == 8) {//短信验证
              if (this.tit == '短信验证') {
                const data = {
                  id: this.Id,
                  code: this.form.code
                }
                this.$http.post('/api/system/web/withdrawal/submitApproval', data).then(res => {
                  if (res.data.code == 200) {
                    this.$emit('stockclose')
                    this.$message({
                      type: 'success',
                      message: res.data.msg
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
              } else if (this.tit == '提现驳回') {//驳回
                const data = {
                  id: this.Id,
                  code: this.form.code,
                  downReason: this.propForm.info
                }
                this.$http.post('/api/system/web/withdrawal/rejectionWithdrawal', data).then(res => {
                  console.log(res, 'res2')
                  if (res.data.code == 200) {
                    this.$emit('stockclose')
                    this.$message({
                      type: 'success',
                      message: res.data.msg
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
              } else {//转账
                const data = {
                  id: this.Id,
                  code: this.form.code,
                  remark: this.propForm.info,
                  transferNumber: this.propForm.transferNumber
                }
                this.$http.post('/api/system/web/withdrawal/byTransfer', data).then(res => {
                  console.log(res, 'res2')
                  if (res.data.code == 200) {
                    this.$emit('stockclose')
                    this.$message({
                      type: 'success',
                      message: res.data.msg
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
              }
            } else {
              if (this.tit == '短信验证') {
                const data = {
                  id: this.Id,
                  code: this.form.code
                }
                this.$http.post('/api/system/web/withdrawal/passApproval', data).then(res => {
                  if (res.data.code == 200) {
                    this.$emit('stockclose')
                    this.$message({
                      type: 'success',
                      message: res.data.msg
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
              }
              // else{

              // }
            }
          }
        })
      },
      back() {
        this.visiable = false
        this.$emit('close')
      },
      downTime(timer) {
        if (timer) {
          this.time = Math.ceil((JSON.parse(timer) - new Date().getTime()) / 1000);
          this.timeShow = false;
          this.timershow = setInterval(() => {
            if (this.time > 0) {
              this.time--
            } else {
              this.timeShow = true;
              clearTimeout(this.timershow)
              sessionStorage.removeItem('infoEndTime')
              return
            }
          }, 1000)
        }
      },
      send() {
        let clicktime = new Date().getTime() + 60000;
        sessionStorage.setItem('infoEndTime', JSON.stringify(clicktime));
        console.log(clicktime, 'clicktime')
        // this.data.count = Math.ceil((JSON.parse(time) - new Date().getTime())/1000);  //取出计时
        // if (this.time == 0) {
        // this.time = 60
        this.$http.get(`/api/system/web/sms/sendProcessingWithdrwalSms?phone=${this.adminInfo.phone}`).then(res => {
          
          if (res.data.code == 200) {
            this.downTime(clicktime)
            this.$message({
              type: "success",
              message: res.data.msg
            })
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            })
          }
        })
      }
    }
  }
</script>

<style>
</style>
