<template>
	<div>
		<el-dialog :before-close='bclose' :destroy-on-close='true' title="批量导入错误情况" :visible.sync="isDia"
			:close-on-click-modal="false" append-to-body width="30%" top="20vh" center>
			<div style="height: 20vh;overflow-y: scroll; padding: 30px 20px;" class="scrollElement">
				<template v-for="(item, index) in errorLists">
					<p :key="index">{{item}}</p>
				</template>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="bclose">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ['errorLists'],
		data() {
			return {
				isDia: true,
			}
		},
		methods: {
			bclose() {
				this.$emit('dioClose')
			}
		}
	}
</script>

<style lang="scss" scoped>
.errorDialogTitle{
	height: 30vh;
	padding: 30px 20px;
	overflow-y: scroll;
}
</style>
