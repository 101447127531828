<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="checkPage" class="reportTab">
      <el-tab-pane label="报告概览" name="first">
        <ReportOverview ref="ReportOverview" v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="全部报告" name="second">
        <ReportAll ref="ReportAll" v-if="activeName == 'second'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ReportOverview from "./components/reportOverview.vue";
import ReportAll from "./components/reportAll.vue";
export default {
  components: {
    ReportOverview,
    ReportAll,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    checkPage(tab, event) {
      if (tab.name == "first") {
        this.UnitRelated();
      } else {
        this.PersonRelated();
      }
    },
    //人员信息
    PersonRelated() {
      this.$nextTick(() => {
        this.$refs.ReportAll.init((refresh) => {
          if (refresh) {
          }
        });
      });
    },
    //单位信息
    UnitRelated() {
      this.$nextTick(() => {
        this.$refs.ReportOverview.init((refresh) => {
          if (refresh) {
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-tabs__item {
  font-size: 16px;
  height: 30px;
  line-height: 30px;
}
/deep/.el-form-style .el-form-item {
  margin-right: 20px;
}
/deep/.el-form-style .el-input {
  width: 250px;
}
</style>
