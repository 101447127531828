<template>
  <div>
    <el-form :inline="true" class="el-form-style" :model="searchForm">
      <el-form-item label="人员名称">
        <el-input placeholder="请输入" v-model="searchForm.userName" clearable></el-input>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="checkInfo">查询</el-button>
        <el-button type="warning" @click="reseat">重置</el-button>
      </el-form-item>
    </el-form>
    <template v-if="noticetotal != 0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-102" style="width:100%; margin: 20px 0;">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="人员名称" align="center" prop="name"></el-table-column>
        <el-table-column label="联系方式" align="center" prop="contactWay"></el-table-column>
        <el-table-column label="证书编号" align="center" prop="certificateCode"></el-table-column>
        <el-table-column label="所属单位" align="center" prop="orgName"></el-table-column>
        <el-table-column label="证书有效期" align="center">
        <template slot-scope="scope">
            {{scope.row.certificateEndTime ? scope.row.certificateEndTime : ''}} - {{scope.row.certificateStartTime ? scope.row.certificateStartTime : ''}}
          </template>
        </el-table-column>
      </el-table>
      <Pagination
      :total="noticetotal"
      :page.sync="searchForm.current"
      :limit.sync="searchForm.size"
      @pagination=" changPage"/>
    </template>

		<div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default{
    components:{
      Pagination
    },
    data(){
      return{
        loading:true,
        tableData:[],
        searchCallback:null,
        noticetotal:0,
        checkFrom:{
          current:1,
          size:10,
        },
        searchForm:{
          current:1,
          size:10,
        },
        tableLoading: true
      }
    },
    methods:{
      //初始化
      init(callback){
        this.searchCallback = callback;
        this.loading = false;
        // this.$nextTick(()=>{
        //   this.onRefer()
        // })
      },
      //分页
     changPage(data){
       this.searchForm.current = data.page
       this.searchForm.size = data.limit
       this.onRefer();
     },
      //重置
      reseat(){
        this.searchForm = {
          current:1,
          size:10
        }
        this.onRefer();
      },
      //查询
      checkInfo(){
        this.searchForm.current = 1
        this.onRefer();
      },
      //信息
      onRefer(){
        this.tableLoading = true
        this.$http.post('/api/ele/web/elevatorInfo/eleAssociatedPersonnel',this.searchForm)
          .then((res)=>{
            console.log('人员',res.data)
            if(res.data.success){
              this.tableData = res.data.data.records ?? []
              this.noticetotal = res.data.data.total ?? 0
              this.$nextTick(()=>{
                if(this.noticetotal > 0){
                  this.$refs.multipleTable.bodyWrapper.scrolltop = 0
                }
              })
            }else{
              this.$message.error(res.data.data.msg)
            }
            this.tableLoading = false
        })
      }
    },
    created(){
      this.onRefer();
    }
  }
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
