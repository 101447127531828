<template>
  <el-dialog
    title="字典配置"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    top="8vh"
    @close="closeDialog"
  >
    <div class="searchBox scrollElement" style="height: 68vh;">
      <el-form :inline="true" :model="childDictionary" class="demo-form-inline">
        <el-form-item label="字典名称">
          <el-input
            v-model="childDictionary.dictValue"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchDictionary" class="searchBtn"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
      <div class="dataList">
        <el-button type="success" size="small" @click="addDictionary"
          >新增</el-button
        >
        <el-button type="danger" size="small" @click="batchDel"
          >批量删除</el-button
        >
        <el-table
          ref="multipleTable"
          :data="dictionarData"
          border
          style="width: 100%; margin-top: 20px"
          max-height="400px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55px" />
          <el-table-column prop="code" label="字典编号" min-width="150px" />
          <el-table-column
            prop="dictValue"
            label="字典名称"
            min-width="200px"
            align="center"
          />
          <el-table-column
            prop="dictKey"
            label="字典键值"
            min-width="150px"
            align="center"
          />
          <el-table-column
            prop="isSealed"
            label="是否启用"
            width="120px"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isSealed"
                :active-value="0"
                :inactive-value="1"
                active-color="#409eff"
                inactive-color="#909399"
                @change="isSealedChange(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="更新时间"
            min-width="120px"
            align="center"
          />
          <el-table-column
            fixed="right"
            label="操作"
            min-width="200px"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="editDictionary(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="delDictionary(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="dictionarTotal"
          :page.sync="dictionarQuery.current"
          :limit.sync="dictionarQuery.size"
          @pagination="dictionarPage"
        />
      </div>
    </div>

    <Action ref="acitonDictionary" :key="Math.random()" />
    <div slot="footer" class="dialog-footer" >
      <el-button type="primary" @click="closeDialog">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Pagination from '../../../../components/Pagination'
import Action from './action'
export default {
  components: {
    Pagination,
    Action,
  },
  data() {
    return {
      loading: true,
      visible: false,
      search: false,
      dictionarData: [],
      dictionarTotal: 0,
      dictionarQuery: {
        current: 1,
        size: 10,
      },
      selectRow: [],
      addBrandCallback: null, // 弹窗回调
    }
  },
  props: {
    childDictionary: {
      type: Object,
      default: () => {
        return {
          dictValue: '',
          code: '',
          parentId: '',
        }
      },
    },
  },

  mounted() {},

  methods: {
    // 父字典id，父字典数据，回调
    init(callback) {
      this.addBrandCallback = callback
      this.visible = true
      this.loading = false
      this.$nextTick(() => {
        this.getDictionary()
      })
    },
    // 选中行
    handleSelectionChange(val) {
      console.log(val, '选中行')
      this.selectRow = val
    },
    searchDictionary() {
      this.dictionarQuery.current = 1
      this.search = true
      this.getDictionary()
      console.log('搜索词典')
    },
    // 新增词典
    addDictionary() {
      console.log('新增词典', this.childDictionary)
      this.$nextTick(() => {
        this.$refs.acitonDictionary.init(
          'add',
          this.childDictionary.parentId,
          this.childDictionary,
          '',
          () => {
            console.log('新增词典')
            this.getDictionary()
          }
        )
      })
    },
    // 修改词典
    editDictionary(row) {
      console.log('修改词典', this.childDictionary)
      this.$nextTick(() => {
        this.$refs.acitonDictionary.init(
          'edit',
          this.childDictionary.parentId,
          this.childDictionary,
          row,
          () => {
            console.log('修改词典')
            this.getDictionary()
          }
        )
      })
    },
    // 删除词典
    delDictionary(id) {
      console.log(id.indexOf(',') > -1 ? id.split(',').length : 1, '删除词典')
      this.$http.post(`/api/system/web/dict/remove?ids=${id}`).then((res) => {
        if (res.data.code === 200) {

          const num = id.indexOf(',') > -1 ? id.split(',').length : 1
          if (this.dictionarData.length === num) {
            this.dictionarQuery.current -= 1
          }
          this.getDictionary()
        }
        if (res.data.code === 400) {
          this.$message({
            type: 'warning',
            message: res.data.msg,
          })
        }
      })
    },
    // 批量删除词典
    batchDel() {
      const ids = []
      this.selectRow.map((item) => {
        ids.push(item.id)
      })
      console.log(ids.join(','), 'batchDel')
      this.delDictionary(ids.join(','))
    },
    // 词典分页
    dictionarPage(data) {
      this.dictionarQuery.current = data.page
      this.dictionarQuery.size = data.limit
      this.getDictionary()
    },
    // 是否开启
    isSealedChange(val) {
      const data = {
        id: val.id,
        parentId: val.parentId,
        code: val.code,
        isSealed: val.isSealed,
      }

      this.$http.post('/api/system/web/dict/submit', data).then((res) => {
        if (res.data.code === 200) {

          this.getDictionary()
        }
      })
    },
    // 获取词典列表
    getDictionary() {
      const data = this.dictionarQuery
      if (this.search) {
        data.dictValue = this.childDictionary.dictValue
      }
      data.parentId = this.childDictionary.parentId
      this.$http
        .get('/api/system/web/dict/child-list', { params: data })
        .then((res) => {
          const {
            data: { data },
          } = res
          if (res.data.code === 200) {
            this.dictionarData = JSON.parse(JSON.stringify(data.records))
            this.dictionarTotal = data.total
            this.search = false
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          console.log('res123', data)
        })
    },
    closeDialog() {
      console.log('closeDialog')
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.searchBox {
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}
</style>
