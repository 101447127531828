<template>
	<div style="display:flex;justify-content: space-between;">
		<div style="margin:0 10px;background-color: #2a3d62;padding: 10px;"
			:style="{'width': rowNum != 5 ? '20%' : '20%'}">
			<div class="page-top">
				<el-form ref="searchForm" :model="searchForm" class="demo-form-inline">
					<el-form-item style="margin-bottom: 0px;display: flex;" class="rightFromTitle">
						<!-- <span slot="label">
		        <span class="lab-span">电梯名称: </span>
		      </span> -->
						<el-input class="search input-with-select" placeholder="请输入车棚名称" clearable v-model="searchName"
							@keyup.enter.native="searchOnRefer">
							<!-- <el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width:110px">
								<el-option label="车棚名称" :value="1"></el-option>
							</el-select> -->
							<!-- <template slot="prepend">电梯名称</template> -->
							<el-button slot="append" icon="el-icon-search" @click="searchOnRefer"></el-button>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div style="height: 100%;">
				<template v-if="tableData != 0">
					<!-- <el-table ref="multipleTable" :data="tableData" :height="height" highlight-current-row
						style="width: 100%; margin-top: 18px; margin-bottom: 20px" :row-class-name="tableRowClassName"
						@row-click="addMonitor">
						<el-table-column align="left" label="电梯列表 " min-width="200px">
							<template slot-scope="scope">
								<span class="overName" :title="scope.row.name ? scope.row.name : ''">
									电梯名称: {{ scope.row.name ? scope.row.name : "-" }}
								</span><br />
								<span>
									AI摄像头ID: {{ scope.row.deviceCode ? scope.row.deviceCode : "" }}
								</span>
							</template>
						</el-table-column>
					</el-table> -->

					<div style="height: 80%;margin-top: 10px;">
						<el-tree :props="defaultProps" :data="tableData" node-key="id" default-expand-all
							highlight-current>
							<div class="custom-tree-node" slot-scope="{ node, data }" :class="data.deviceCode && deviceCodeList.includes(data.deviceCode) ? 'deviceCodeAction' : ''">
								<span>
									{{ data.name }}
									<!-- <span v-if="!data.bikeDeviceInfoDTOList">#监控{{ data.deviceCode }}</span> -->
								</span>
								<span v-if="!data.bikeDeviceInfoDTOList">
									<el-button type="text" size="mini" @click="addJk(data)">
										查看
									</el-button>
								</span>
							</div>
						</el-tree>
					</div>

					<Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
						@pagination="dictionarPage" :layout="'prev, pager, next'"  style="justify-content: center;"/>

				</template>
				<div class="nullDate" v-else>
					<img src="@/assets/nullDate.png" style="width: 260px" />
				</div>
			</div>
		</div>

		<div style="background-color: #2a3d62;" :style="{'width': rowNum != 5 ? '83%' : '80%'}">
			<div ref="row" style="height: 5%">
				<el-select v-model="rowNum" placeholder="请选择" @change="changeType" style="margin-left: 10px">
					<el-option v-for="item in screenArr" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
				<el-button type="primary" style="margin-left: 15px" @click="handleFullScreen">全屏</el-button>
			</div>
			<div ref="screenList" id="screenList" :style="[{'background': isFull ? '#1d2d4e' : ''}]"
				style="height: 95%;width: 100%;" class="playList">
				<div v-for="item in screenType" :key="item" class="screenBox"
					:class="rowNum == 3 ? 'padBigWidth' : 'padSmallWidth'"
					:style="[{'height': compHeight }, {'width': compWidth }]">
					<div class="playBox">
						<div v-if="showMonitorArr[item - 1] && showMonitorArr[item - 1].id"
							style="width: 100%; height: 100%; position: relative">
							<MonitorCarportBox :eleInfo="showMonitorArr[item - 1]" :playNum="item"
								:screenType="screenType" @delMMonitor="delMMonitor" />
						</div>
						<div v-else class="defaultBox">
							<el-image fit="contain" :src="require('@/assets/Monitor1.png')" style="width: 50%" />
							<span class="eleStatusTips"
								:class="screenType > 4 ? (screenType > 9 ? 'eleStatusTipssmall' : 'eleStatusTipsmiddle') : 'eleStatusTipsbig'">点击左侧列表查看监控</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div type="primary" @click="drawer = !drawer" :style="{'right': drawer ? '40vh' : '0'}"
			style="position:absolute;height: 79vh; width: 20px; margin-left: 16px;background: #2a3d62;border: none;color: #FFF;display: flex;align-items: center;cursor: pointer;justify-content: center;">
			<span style='width: 20px;'>{{ drawer == true ? '〉' : '〈'}}</span>
		</div>
		<div v-if="drawer" style="width: 40vh;background: #2a3d62;color: #FFF;position:absolute;right: 0;height: 79vh;">
			<h3>最新报警：</h3>
			<div style='padding-bottom: 10px;overflow: hidden;' :style="{'height': drawer ? '70vh' : '70vh'}">
				<vue-seamless-scroll v-if="sosList.length>0" :data="sosList" :class-option="rescueClassOption"
					class="right-a-list-container">
					<div class="right-a-list">
						<div :data-obj="JSON.stringify(item)" :data-i="index" v-for="(item,index) in sosList"
							:key="item.id" @click="check(item)">
							<div style="display: flex;align-items: center; justify-content: space-between;width: 95%;">
								<el-button type="danger"
									style="border-radius: 5px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: default;">{{SosTypeArr.find((ids)=>item.sosType == ids.value).label}}</el-button>
								<div>
									<p>{{item.carportName}}</p>
									<p>{{item.sosTime}}</p>
								</div>
								<el-button type="text"
									style="cursor: default;">{{StateArr.find((ids)=>item.alarmState == ids.value).label}}</el-button>
							</div>
							<div style="border: 1px dashed #FFF;"></div>
							<!-- <div class="sos"></div>
              <div class="right-a-title-container">
                <div class="right-a-title ellipsis">{{ item.eleName }}</div>
                <div class="right-a-date">{{ item.createTime }}</div>
              </div>
              <div class="right-a-time-container">
                <img class="right-a-img" :src="rescueStatusObj[item.status].img" />
                <div class="right-a-time">{{ item.time }}</div>
              </div>
              <div class="right-a-status">{{ rescueStatusObj[item.status].value }}</div> -->
						</div>
					</div>
				</vue-seamless-scroll>
				<!-- <div v-for="(item,index) in sosList" :key="index" style="width: 95%;" @>
          <div style="display: flex;align-items: center; justify-content: space-between;width: 95%;">
            <el-button type="danger"
              style="border-radius: 5px;height: 30px;display: flex;justify-content: center;align-items: center;cursor: default;">{{SosTypeArr.find((ids)=>item.sosType == ids.value).label}}</el-button>
            <div>
              <p>{{item.carportName}}</p>
              <p>{{item.sosTime}}</p>
            </div>
            <el-button type="text"
              style="cursor: default;">{{statusArr.find((ids)=>item.alarmState == ids.value).label}}</el-button>
          </div>
          <div style="border: 1px dashed #FFF;"></div>
        </div> -->
			</div>
		</div>
		<el-dialog v-if="CheckDialog" :visible.sync="CheckDialog" :close-on-click-modal='false' width="1200px"
			top='15vh' @close="closeDialog">
			<div class="tabBut">
				<el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
				</el-button>
				<el-button @click="detailsItem.seleId=2" :type='2==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
			</div>
			<div style="height: 60vh;margin-top: 10px;" class="scrollElement"
				:class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
				<alarmMessage :dioData="dioDetailsItem" :seleId="detailsItem.seleId" />
				<!-- v-if="detailsItem.seleId==1" -->
				<!-- <process v-else :dioData="dioDetailsItem" /> -->
			</div>
			<span slot="footer" class="dialog-footer"
				style="display: flex;align-items: center;justify-content: center;">
				<div v-if="detailsItem.seleId == 1&&dioDetailsItem.sosStatusName == '待处理' ">
					<!-- && dioDetailsItem.sosStatus === 1 -->
					<template>
					  <el-button size="mini" type="primary" @click="sosClick(2)" :disabled="disabled"
					    v-if="dioDetailsItem.sosType != 35">
					    <span>人为触发(无故障)</span>
					    <p style="margin: 10px 0 0 0;">确认关闭</p>
					  </el-button>
					  <el-button size="mini" type="warning" @click="sosClick(3)" :disabled="disabled"
					    v-if="dioDetailsItem.sosType != 4&&dioDetailsItem.sosType != 35">
					    <span>确认火情</span>
					    <p style="margin: 10px 0 0 0;">立即通知救援</p>
					  </el-button>
					  <el-button class="errorSos" size="mini"
					    v-if="dioDetailsItem.sosType != 35"
					    @click="sosClick(1)" :disabled="disabled">
					    <span>误报</span>
					    <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
					  </el-button>
					</template>
				</div>
			</span>
		</el-dialog>


	</div>
</template>
<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import MonitorCarportBox from "./MonitorCarportBox.vue";
	import Pagination from '@/components/Pagination.vue'
	import AlarmMessage from "../cpbjjl/components/alarmMessage.vue"
	export default {
		components: {
			MonitorCarportBox,
			Pagination,
			vueSeamlessScroll,
			AlarmMessage
		},
		data() {
			return {
				CheckDialog: false,
				detailsItem: {
					seleId: 1,
					seleArr: [{
						value: 1,
						label: '报警消息'
					}, {
						value: 2,
						label: '处置流程'
					}],
				},
				timeAll: null, //定时器
				drawer: false, //右边报警
				disabled: false,
				dioDetailsItem: null,
				sosList: [],
				rescueClassOption: { //滚动
					step: 0.5,
					limitMoveNum: 4,
					autoPlay: true,
				},
				SosTypeArr: [],
				StateArr: [{
					value: 0,
					label: '待处理'
				}, {
					value: 1,
					label: '误报'
				}, {
					value: 2,
					label: '人为触发'
				}, {
					value: 3,
					label: '确认火情'
				}],
				// 定时器
				getOlineTime: null,
				// 表格参数
				searchForm: {
					current: 1,
					size: 20,
					status: 1,
				},
				screenType: 4,
				rowNum: 2,
				screenArr: [{
						label: "两行",
						value: 2,
					},
					{
						label: "三行",
						value: 3,
					},
					{
						label: "四行",
						value: 4,
					},
					// {
					// 	label: "五行",
					// 	value: 5,
					// },
				],
				showMonitorArr: [],
				tableData: [],
				// 分页参数
				maintenRuleTotal: 0,
				searchType: 1,
				searchName: '',
				dictionarTotal: 0, //总数
				isMore: false,
				isFull: false,
				height: '', // 监控列表高度
				width: '', // 监控列表宽度

				compHeight: '', // 监控高度
				compWidth: '', // 监控宽度
				colNum: '', // 列数

				proportion: ' ', // 比例


				defaultProps: {
					children: 'bikeDeviceInfoDTOList',
					label: 'name'
				},
				adminInfo: JSON.parse(sessionStorage.getItem("adminInfo")),
				deviceCodeList: []
			};
		},
		computed: {},

		filters: {},
		methods: {
			getSosList() {
				const data = {
					current: 1,
					size: 30
				}
				this.$http.post('/api/bike/web/sos/getSosList', data).then((res) => {
					if (res.data.code == 200) {
						this.sosList = res.data.data.records
					}
				})
			},
			check(data) { //查看
				this.$http.get(`/api/bike/web/sos/getSosInfo?id=${data.id}`).then((res) => {
					if (res.data.code == 200) {
						this.detailsItem.seleId = 1
						res.data.data.sosStatusName = this.StateArr.find(item => item.value === res.data.data
								.alarmState) ? this.StateArr.find(item => item.value === res.data.data.alarmState)
							.label : ''
						res.data.data.sosTypeName = this.SosTypeArr.find(item => Number(item.value) === res.data
								.data
								.sosType) ? this.SosTypeArr.find(item => Number(item.value) === res.data.data
								.sosType)
							.label : ''
						this.dioDetailsItem = res.data.data
						this.CheckDialog = true
					}
				})

			},
			closeDialog() { //关闭弹窗
				this.disabled = false
				this.CheckDialog = false
				this.disabled = false
			},
			sosClick(type) {
				this.disabled = true
				if (this.dioDetailsItem.submitType === 0) {
					// this.dioDetailsItem.sosStatus = 2
					this.$http
						.post("/api/bike/web/sos/commitSos", {
							id: this.dioDetailsItem.id,
							submitType: type,
							// sosTime: this.dioDetailsItem.sosTime,
						})
						.then((res) => {
							if (res.data.success) {
								// this.$message.success(res.data.msg)
								this.CheckDialog = false
                // if(type == 1){
                //   this.$confirm('确认误报成功，是否删除该报警误报记录？','提示',{
                //     cancelButtonText:'取消',
                //     confirmButtonText:'确认',
                //     type:'warning'
                //   }).then(()=>{
                //   }).catch(()=>{
                //   })
                // }
							} else {
								this.$message.error(res.data.msg)
							}
							this.onRefer()

							this.check(this.tableData.find(item => item.id === this.dioDetailsItem.id))
						})
				}
			},
			//高亮
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (this.showMonitorArr.find(item => item.deviceCode == row.deviceCode)) {
					return 'highlight';
				}
				return '';
			},


			dictionarPage(data) { //翻页
				this.searchForm.current = data.page
				this.searchForm.size = data.limit
				this.onRefer()
			},
			loadMoreData() {
				console.log('loadMoreData');
			},
			//全屏方法
			handleFullScreen() {
				// 此处可根据获取节点进行区域全屏事件
				let element = document.getElementById('screenList');
				if (this.isFull) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						// IE11
						element.msRequestFullscreen();
					}
				}
				this.isFull = !this.isFull;
				console.log(this.isFull, 'this.isFull');
			},

			//监听屏幕是否全屏
			fullScreenChangeFn(e) {
				if (this.$refs.screenList.offsetWidth < window.innerWidth) {
					this.isFull = false;
				} else {
					this.isFull = true;
				}
				this.widthComp()
			},
			// 搜索
			searchOnRefer() {
				this.isMore = false
				this.searchForm.current = 1
				this.onRefer();
			},
			widthComp() {
				if (this.isFull) {
					if (this.rowNum == 2) {
						this.screenType = 4
						this.compWidth = window.screen.width / 2.2 + "px"
						this.compHeight = window.screen.height / 2.2 + "px"
					} else if (this.rowNum == 3) {
						this.screenType = 12
						this.compWidth = window.screen.width / 4.2 + "px"
						this.compHeight = window.screen.height / 3.2 + "px"
					} else if (this.rowNum == 4) {
						this.screenType = 16
						this.compWidth = window.screen.width / 4.2 + "px"
						this.compHeight = window.screen.height / 4.2 + "px"
					}
					this.showMonitorArr = this.showMonitorArr.slice(0, this.screenType);
				} else {
					this.compHeight = this.height / this.rowNum - (this.rowNum != 5 ? 10 : 0)
					// this.compWidth = this.compHeight / 0.75 + "px"
					// this.colNum = Math.trunc(this.width / (this.compHeight / 0.75 + 10))
					// this.screenType = this.rowNum * this.colNum

					if (this.rowNum == 2) {
						this.screenType = 4
						this.compWidth = this.width / 2.2 + "px"
					} else if (this.rowNum == 3) {
						this.screenType = 12
						this.compWidth = this.width / 4.2 + "px"
					} else if (this.rowNum == 4) {
						this.screenType = 16
						this.compWidth = this.width / 4.2 + "px"
					}

					this.compHeight = this.compHeight + "px"
					this.showMonitorArr = this.showMonitorArr.slice(0, this.screenType);
				}
			},

			// 改变多屏类型
			changeType() {
				this.widthComp()
			},
			addJk(data) {
				console.log('data', data)
				let data1 = {
					type: 0,
					...data
				}
				data1.id1 = data.id
				data1.id = 'kj' + data.id
				this.addMonitor(data1)

				setTimeout(() => {
					let data2 = {
						type: 1,
						...data
					}
					data2.id1 = data.id
					data2.id = 'hw' + data.id
					this.addMonitor(data2)
				}, 100)
				this.deviceCodeList.push(data.deviceCode)
			},
			// 加入监控
			addMonitor(row) {
				console.log(123, row);
				if (this.showMonitorArr.find((item) => item.id == row.id)) {
					if (row.type == 0) {
						this.$message.error("不可重复打开监控");
					}
					return;
				}
				if (this.showMonitorArr.find((item) => !item.id)) {
					for (var index = 0; index < this.showMonitorArr.length; index++) {
						if (!this.showMonitorArr[index].id) {
							this.showMonitorArr.splice(index, 1, row);
							break;
						}
					}
					return;
				}
				console.log(this.showMonitorArr,'this.showMonitorArr');
				if (this.showMonitorArr.length >= this.screenType) {
					if (row.type == 0) {
						this.$message.error("暂时无法打开更多的监控");
					}
					return;
				}
				this.showMonitorArr.push(row);
				console.log(this.showMonitorArr,'this.showMonitorArr');
				this.$forceUpdate();
			},
			// 移除监控
			delMMonitor(data) {

				for (var index = 0; index < this.showMonitorArr.length; index++) {
					if (this.showMonitorArr[index].deviceCode == data.deviceCode) {
						this.showMonitorArr.splice(index, 1, {});
					}
				}

			},

			// 获取监控列表方法
			onRefer() {
				if (this.searchType == 1) {
					this.searchForm.name = this.searchName
				} else {
					this.searchForm.deviceCode = this.searchName
				}

				let data = {
					page: 1,
					size: 20,
					...this.searchForm
				}

				this.$http
					.post("/api/bike/web/carport/getCarportMonitoringList", data)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							if (this.isMore) {
								this.tableData =
									data.records.length > 0 ? [...this.tableData, ...data.records] :
									this.tableData;
								setTimeout(() =>
									this.isMore = false, 500)
							} else {
								this.tableData = data.records
							}
							this.maintenRuleTotal = data.total
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 分页
		},
		created() {
			this.getSosList()
			this.timeAll = setInterval(() => {
				this.getSosList()
			}, 60000)
			this.proportion = window.innerHeight / window.innerWidth
			this.$http
				.post('/api/system/web/dict/dictionary?codes=CARPORT_SOS_TYPE')
				.then((res) => {
					if (res.data.success) {
						const ArrOne = res.data.data.CARPORT_SOS_TYPE
						this.SosTypeArr = this.adminInfo.userName == 'admin' ? ArrOne : ArrOne.filter(item => item
							.value !== '35')
					}
				})
		},
		mounted() {
			this.onRefer();
			// 阻止F11键默认事件，用HTML5全屏API代替
			window.addEventListener('keydown', this.fullScreenKeydown)
			window.addEventListener('resize', this.fullScreenChangeFn)

			this.$nextTick(() => {
				this.height = this.$refs.screenList.offsetHeight
				this.width = this.$refs.screenList.offsetWidth

				// window.innerHeight / window.innerWidth
				this.widthComp()
			})
		},
		destroyed() {
			clearInterval(this.getOlineTime);
			clearInterval(this.timeAll);
			window.removeEventListener('resize', this.fullScreenKeydown)
			window.removeEventListener('resize', this.fullScreenChangeFn)
		},
	};
</script>
<style lang="scss" scoped>
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 20px;
		height: 35px;
	}

	.right-a-list-container,
	.right-b-list-container {
		height: 100%;
		overflow: hidden;
	}

	.tabBut {
		width: 80%;
		margin-left: 10%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

  .yeScroll {
    overflow-y: scroll;
  }

  .noScroll {
    overflow-y: none;
  }

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.playList {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		// justify-content: space-around;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.padSmallWidth {
		padding: 6px;
	}

	.padBigWidth {
		// padding: 3px 10px;
		padding: 6px;
	}

	.eleStatusTipssmall {
		margin-top: 10px;
		transform: scale(0.6)
	}

	.eleStatusTipsmiddle {
		margin-top: 15px;
		transform: scale(0.8)
	}

	.eleStatusTipsbig {
		margin-top: 15px;
		transform: scale(1)
	}

	.overName {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	::v-deep.search {

		.el-input-group__append,
		.el-input-group__prepend {
			background-color: #3c92ed;
			color: #fff;
		}
	}

	.searchBtn {
		margin-left: 15px;
	}

	.rightFromTitle {
		/deep/.el-form-item__label {
			width: 100px;
		}
	}

	/deep/.highlight {
		background-color: #265a6a !important;
	}
	.deviceCodeAction{
		background-color: #02608e
	}
</style>
