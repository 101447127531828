<template>
  <el-dialog title="查看明细" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="1400px">
    <div style="height: 70vh;">
      <el-form :model="searchForm" inline>
        <el-form-item label="维保订单">
          <el-input placeholder="请输入维保订单" v-model="searchForm.id" clearable/>
        </el-form-item>
        <el-form-item label="电梯名称">
          <el-input placeholder="请输入电梯名称" v-model="searchForm.eleName" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"> 查询</el-button>
          <el-button type="warning" @click="reast"> 重置</el-button>
        </el-form-item>
      </el-form>
      <div v-if="dictionarTotal > 0">
        <el-table :data="tableDate" v-loading="tableLoading"  ref="multipleTable" :height="$store.state.tabHeight-150"
          style="width: 100%;margin-bottom: 20px;">
          <el-table-column type="index" align="center" label="序号" />
          <el-table-column prop="id" align="center" label="维保订单编号" />
          <el-table-column prop="eleName" align="center" label="电梯名称" />
          <el-table-column prop="maintainTypeName" align="center" label="维保类型" />
          <el-table-column prop="startTime" align="center" label="计划时间" />
          <el-table-column prop="price" align="center" label="订单价格" />
          <el-table-column prop="maintainUserNames" align="center" label="维保人员" />
          <el-table-column prop="finishTime" align="center" label="完成时间" />
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import JSONbig from "json-bigint"
  import Pagination from '@/components/Pagination'
  export default {
    components:{
      Pagination
    },
    props: ['order'],
    data() {
      return {
        dictionarTotal: 0,
        detailVisible: true,
        searchForm: {
          current: 1,
          size: 10,
        },
        tableDate: [],
        tableLoading: true
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      dictionarPage(data){
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.getDate()
      },
      getDate() {
        console.log(this.order ,'order')
        this.tableLoading = true
        this.searchForm.ids = this.order.maintenanceOrderNumber ? JSONbig.parse(this.order.maintenanceOrderNumber) : [],
        this.$http.post('/api/ele/web/maintainTask/getTaskByIds', this.searchForm).then(res => {
          
          this.tableDate = res.data.data.records ? res.data.data.records : []
          this.dictionarTotal = res.data.data.total
          this.tableLoading = false
          this.$nextTick(() => {
            if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              this.$refs.multipleTable.doLayout();
            }
          })
        })
      },
      search() {
        this.searchform.current = 1
        this.getDate()
      },
      reast() {
        this.searchForm = {
          current: 1,
          size: 10,
        },
        this.getDate()
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
