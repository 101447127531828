<template>
  <div>
    <el-dialog v-if="dialogvisible" title="非监测设备列表" :visible.sync="dialogvisible" :close-on-click-modal="false"
      @close="back" width="1400px">
      <div style="height: 70vh;">
        <el-form :inline="true" :model="formInline">
          <el-form-item label="AI摄像头ID">
            <el-input placeholder="AI摄像头ID" v-model="formInline.deviceCode"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer(true)">查询</el-button>
            <el-button type="warning" @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
        <div style="display: flex;">
          <el-button type="success" @click="Add()">新增</el-button>
          <form name="form1" style="margin: 0 10px;">
            <input type="file" id="docxFile" @change="readFile($event)" style="position:absolute; clip:rect(0 0 0 0);">
            <el-button type="primary" @click="toLead">导入</el-button>
          </form>
          <el-button type="warning" @click="dowMode(11)">下载模版</el-button>
        </div>
        <template v-if="dictionarTotal != 0">
          <el-table ref="multipleTable" v-loading="tableLoading" style="width: 100%;margin: 20px 0;" :data="tableData"
            height="40vh">
            <el-table-column type="index" label="编号" align="center" width="55px"/>
            <el-table-column label="AI摄像头ID" align="center" prop="deviceCode" />
            <el-table-column label="备注" align="center" prop="memo" />
            <el-table-column label="创建时间" align="center" prop="createTime" />
            <el-table-column label="操作" align="center" width="140px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="Del(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="nullDate" v-else v-loading="tableLoading">
        	<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
        <ErrorDio v-if="errorList != null" :errorLists="errorList" @dioClose="dioCloseB" />
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </div>
      <el-dialog v-if="addVisible" title="新增非监测设备" :visible.sync="addVisible" :append-to-body="true"
        :close-on-click-modal="false" @close="addVisible = false" width="700px">
        <div style="height: 35vh;">
          <div style="height: 25vh;padding: 20px;overflow-y: scroll;" class="scrollElement">
            <el-form label-width="100px" ref="form" :model="form" :rules="rules">
              <el-form-item label="AI摄像头ID" prop="deviceCode">
                <el-input placeholder="请输入AI摄像头ID" v-model="form.deviceCode" :maxlength="15"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" resize="none" style="height: 10vh;" placeholder="请输入备注内容，不超过100字"
                  v-model="form.memo" :autosize="{ minRows: 4, maxRows: 4}" maxlength="100"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" >
              <el-button type="primary" @click="sure()">确定</el-button>
              <el-button @click="addVisible = false">取消</el-button>
            </span>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
  const pageType = 'ELEVATOR_WEBCAM';
  import ErrorDio from '@/components/errorDio.vue'
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    components: {
      Pagination,
      ErrorDio
    },
    data() {
      var codeVald = (rule, value, callback) => {
        var reg = /^[0-9]{15}$/
        if (!value || !reg.test(value)) {
          callback(new Error('请输入AI摄像头ID，且长度为15'))
        }
        callback()
      }
      return {
        addVisible: false,
        dialogvisible: true,
        dictionarTotal: 0,
        tableData: [],
        errorList: null,
        formInline: {
          current: 1,
          size: 10,
          deviceCode: ''
        },
        form: {
          deviceCode: '',
          memo: ''
        },
        rules: {
          deviceCode: [{
            required: true,
            validator: codeVald,
            trigger: 'blur'
          }]
        },
        tableLoading: true
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      dowMode(type) {
        downloadMode(type)
      },
      Del(row) {
        const data = {
          id: row.id
        }
        this.$confirm("删除后将无法撤销，是否继续操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$http.post('api/ele/web/device/deleted', data).then((res) => {
              console.log(res, 'res')
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.onRefer()
              }
            })
          })
          .catch(() => {});
      },
      // 错误列表回调
      dioCloseB() {
        this.errorList = null;
      },
      toLead() {
        document.form1.docxFile.click()
      },
      readFile(file) {
        let _this = this
        let docxFile = file.target.files[0]
        if (docxFile == undefined) {
          return
        }
        let hasFile = false
        let fileName = docxFile.name
        let fileArr = file.target.files[0].name.split('.')
        let files = docxFile
        if (!file) {
          this.$message('请先选择正确的文件类型上传')
        } else {
          let fileData = new FormData()
          fileData.append('file', files)
          fileData.append('type', files.type)
          let config = {
            onUploadProgress: (e) => {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到e.total和e.loaded
              if (e.lengthComputable) {
                let rate = e.loaded / e.total //已上传的比例
                if (rate < 1) {
                  //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                  //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                  //等响应回来时，再将进度设为100%
                  let uploadRate = (rate * 100).toFixed(2) + '%'
                }
              }
            },
          }
          this.$http
            .post(`/api/ele/web/batch/saveData/${pageType}`, fileData, config)
            .then((res) => {
              console.log(res,'res')
              if (res.data.success) {
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.errorList = res.data.data
                this.$message.error(res.data.msg)
              }
              file.target.value = ''
            })
        }
      },
      Add() {
        this.form = {
            deviceCode: '',
            memo: ''
          },
          this.addVisible = true
      },
      back() {
        this.dialogvisible = false
        this.$emit('close')
      },
      sure() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log(111, this.form)
            this.$http.post('api/ele/web/device/insert', this.form).then((res) => {
              console.log(res, 'res')
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.addVisible = false
                this.onRefer()
                // this.form = {
                //   deviceCode: '',
                //   memo: ''
                // }
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          }
        })
      },
      onRefer(flag) {
        this.tableLoading = true
        this.$http.post('api/ele/web/device/get', this.formInline).then((res) => {
          console.log(res, '非设备监测')
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
          }
          this.tableLoading = false
        })
      },
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          deviceCode: ''
        }
        this.onRefer()
      },
      contractPage(data) {
        console.log(data, 'data')
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
