<template>
  <div>
    <div class="page-top">
      <el-form inline :model="formList">
        <el-form-item label="合同编号">
          <el-input placeholder="请输入合同编号" v-model="formList.contractNumber" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请选择状态" v-model="formList.status" clearable>
            <el-option v-for="(item,index) in statusListL" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算类型">
          <el-select placeholder="请选择结算类型" v-model="formList.type" clearable>
            <el-option v-for="(item,index) in typeList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维保订单">
          <el-input placeholder="请输入维保订单" v-model="formList.maintenanceOrderNumber" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"> 查询</el-button>
          <el-button type="warning" @click="reast"> 重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" v-loading="tableLoading"  ref="multipleTable" :height="$store.state.tabHeight-325"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" />
        <el-table-column prop="contractNumber" align="center" min-width="120px" label="合同编号" />
        <el-table-column prop="type" align="center" min-width="120px" label="结算类型">
          <template slot-scope="scope">
            {{typeList.filter(item=>item.value == scope.row.type)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="amount" align="center" min-width="120px" label="结算金额" />
        <el-table-column prop="status" align="center" min-width="120px" label="状态">
          <template slot-scope="scope">
            {{scope.row.status == 2 ? '未发放' : statusListL.filter(item=>item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="maintenanceOrderNumber" align="center" min-width="120px" label="维保订单编号" >
          <template slot-scope="scope">
            {{scope.row.type == 0 ? scope.row.maintenanceOrderNumber : '--'}}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="" align="center" min-width="100px" label="开始时间" />
        <el-table-column prop="" align="center" min-width="100px" label="结束时间" /> -->
        <el-table-column prop="createTime" align="center" min-width="120px" label="创建时间" />
        <el-table-column prop="updateTime" align="center" min-width="120px" label="更新时间" />
        <el-table-column align="center" min-width="120px" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="small" v-if="scope.row.type != 4 && scope.row.type != 0" @click="checkInfo(scope.row)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formList.current" :limit.sync="formList.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <Details v-if="visible" :order='order' @close="back"></Details>
  </div>
</template>

<script>
  import Details from './Details.vue'
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    props: ['Id', 'money'],
    components: {
      Pagination,
      Details
    },
    data() {
      return {
        dictionarTotal: 0,
        visible:false,
        tableDate: [],
        // orderId:[],
        order:{},
        formList: {
          current: 1,
          size: 10,
        },
        statusListL: [{
            value: 0,
            label: '未发放'
          },
          {
            value: 1,
            label: '已发放'
          },
          //状态2改为未发放
          // {
          //   value: 2,
          //   label: '未发放'
          // }
        ],
        typeList: [{
            value: 0,
            label: '按次结算'
          },
          {
            value: 1,
            label: '按月结算'
          },
          {
            value: 2,
            label: '按季度结算'
          },
          {
            value: 3,
            label: '按年结算'
          },
          {
            value: 4,
            label: '按年度汇算'
          },
        ],
        tableLoading: true
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      //分页
      noticePage(data) {
        this.formList.current = data.page
        this.formList.size = data.limit
        this.getDate()
      },
      //搜索
      search() {
        this.formList.current = 1
        this.getDate()
        this.$emit('checkInfo')
      },
      //重置
      reast() {
        this.formList = {
          current: 1,
          size: 10,
        }
        this.getDate()
      },
      //列表数据
      getDate() {
        this.formList.orgId = this.Id != undefined ? this.Id : ''
        const data = {
          orgId: this.orgId,
          ...this.formList
        }
        this.tableLoading = true
        this.$http.post("/api/system/web/settlement/getOrgSettlementRecordList", data).then((res) => {
          
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records ? res.data.data.records : []
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
          this.tableLoading = false
        })
      },
      // 查看明细
      checkInfo(data){
        
        this.visible = true
        this.order = data
      },
      back(){
        this.visible = false
      },
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
