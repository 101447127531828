<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">菜单名称</span>
					</span>
					<el-input v-model="formInline.user" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">URL</span>
					</span>
					<el-input v-model="formInline.user" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-table :data="tableData" v-loading="tableLoading" style="width: 100%;margin-bottom: 20px;" row-key="id"
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column align='center' label="菜单名称">
					<template slot-scope="scope">
						<span>
							<!-- :style="{'margin-left': scope.row.children==null?'0':'30px'}" -->
							{{ scope.row.resourceName}}
						</span>
					</template>
				</el-table-column>
				<el-table-column align='center' prop="routePath" label="URL">
				</el-table-column>
				<el-table-column align='center' prop="address" label="是否启用">
					<template slot-scope="scope" v-if="scope.row.id!=0">
						<el-switch disabled v-model="scope.row.isActive" active-color="#409eff" inactive-color="#909399"
							:active-value="1" :inactive-value="0">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column align='center' prop="updateTime" label="更新时间">
				</el-table-column>
				<el-table-column align='center' prop="address" label="操作">
					<template slot-scope="scope">
						<el-button v-if="scope.row.id!=0" @click="upd(scope.row)" type="primary" size="small">编辑
						</el-button>
						<el-button v-if="scope.row.id!=0" @click="del(scope.row)" type="danger" size="small">删除
						</el-button>
						<el-button v-if="scope.row.typeId!=2" @click="addDj(scope.row)" type="warning" size="small">创建下级
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="tit" :visible.sync="dialogVisible" width="800px" top='20vh'>
			<div style="height: 25vh;" class="scrollElement">
				<el-form label-position="right" label-width="100px" :model="fign">
					<el-row style="margin:10px 0">
						<el-col :span="12" v-show="fign.typeId==2">
							<el-form-item label="按钮类型:" prop="resource">
								<el-select v-model="fign.resource" placeholder="请选择按钮类型" class="einp">
									<el-option v-for="item in butList" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12" v-show="fign.typeId==1">
							<el-form-item label="菜单名称:" prop="resourceName">
								<el-input v-model="fign.resourceName" placeholder="请输入菜单名称" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上级菜单:">
								<el-input disabled v-model="fign.topName" placeholder="请输入上级菜单" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="资源接口:" prop="resourcePath">
								<el-input v-model="fign.resourcePath" placeholder="请输入资源接口" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="菜单类型:" prop="topName">
								<el-select v-model="fign.typeId" placeholder="请选择菜单类型" class="einp">
									<el-option label="菜单" :value="1"></el-option>
									<el-option label="按钮" :value="2"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0" v-show="fign.typeId==1">
						<el-col :span="12">
							<el-form-item label="前端路由:" prop="routePath">
								<el-input v-model="fign.routePath" placeholder="请输入前端路由" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="文件地址:" prop="routePage">
								<el-input v-model="fign.routePage" placeholder="请输入文件地址" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="是否启用:">
								<el-switch v-model="fign.isActive" active-color="#409eff" inactive-color="#909399"
									:active-value="1" :inactive-value="0">
								</el-switch>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				butList: [],
				// 表格参数
				formInline: {},
				tableData: [],
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {
					typeId: '1',
					topName: '',
					routePage: '',
					routePath: '',
					isActive: "1",
					resourcePath: '',
					resourceName: '',
				},
				tableLoading: true
				// 分页参数
			}
		},
		methods: {
			// 弹出框提交
			dioSub() {
				let url = '/api/system/web/resource/add'
				if (this.tit == '修改') {
					url = '/api/system/web/resource/edit'
				}

				if (this.fign.typeId == 2) {
					this.butList.forEach((qs) => {
						if (this.fign.resource == qs.value) {
							this.fign.resourceName = qs.label
							return
						}
					})
					let onRot = this.fign.routePath.split('/')
					this.fign.buttonKey = onRot[onRot.length - 1] + this.fign.resource
					console.log(123, this.fign)
				}

				this.$http
					.post(url, this.fign)
					.then((res) => {
						if (res.data.success) {
							this.onRefer()
							this.dialogVisible = false
							this.$message.success(res.data.msg)
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},

			// 表格方法
			onRefer() {
				this.tableLoading = true
				this.$http
					.get("/api/system/web/resource/tree", this.formInline)
					.then((res) => {
						console.log('res123', res.data.data)
						this.tableData = [res.data.data]
						this.tableLoading = false
					})

				// this.$http
				// .post("/api/system/web/resource/getResourceTree", this.formInline)
				// .then((res) => {
				// 	console.log('res123', res.data.data)
				// 	this.tableData = [res.data.data]

				// })
			},
			upd(item) {
				this.tit = '修改'
				this.dialogVisible = true
				item.topName = item.resourceName
				console.log('qa', item)
				this.fign = JSON.parse(JSON.stringify(item))
			},
			del(item) {
				this.tit = '删除'
				let data = {
					id: item.id
				}
				this.$http
					.post("/api/system/web/resource/delete", data)
					.then((res) => {
						console.log('res123', res)
						if (res.data.success) {
							this.onRefer()
							this.$message.success(res.data.msg)
						} else {
							this.$message.error(res.data.msg)
						}
					})
				console.log(item)
			},
			addDj(item) {
				this.tit = '新增'
				this.dialogVisible = true
				item.topName = item.resourceName
				this.fign = JSON.parse(JSON.stringify(item))
				this.fign.id = null
				this.fign.resourceName = ''
				this.fign.parentId = item.id
				console.log(item)
			},
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=BUTTON_TYPE`)
				.then((res) => {
					if (res.data.success) {
						this.butList = res.data.data.BUTTON_TYPE
						this.onRefer()
					}
				})
		}
	}
</script>
<style scoped>
	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
