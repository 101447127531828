<template>
  <div class="page-top">
    <el-row>
      <el-col :span="24">
        <div class="boxShow" style="width: 100%;height: 25vh;">
          <div style="padding: 20px;">
            <div class="top-header">
              <div>
                <span style="font-weight: 600;font-size: 20px;">数据概览</span>
                <span class="top-timer">{{DateForm[0]? DateForm[0].createTime : ''}}已更新</span>
              </div>
              <div>
                <el-button type="primary" size="small" @click="getMonitor()">非监测设备</el-button>
                <el-button type="primary" size="small" :disabled="exportTypes"
                  @click="exportDatas()">{{exportTitles}}</el-button>
              </div>
            </div>
            <div class="content scrollElement" v-if="DateForm&&DateForm[0]">
              <div class="content-box">
                <span>监测设备总数（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].monitoringEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].monitoringEquipmentCount - DateForm[1].monitoringEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].monitoringEquipmentCount - DateForm[1].monitoringEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    <!-- {{DateForm[0].monitoringEquipmentCount}}
                    {{DateForm[1].monitoringEquipmentCount}} -->
                    {{Math.abs(DateForm[0].monitoringEquipmentCount-DateForm[1].monitoringEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>健康设备（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].healthEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].healthEquipmentCount - DateForm[1].healthEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].healthEquipmentCount - DateForm[1].healthEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].healthEquipmentCount - DateForm[1].healthEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>异常设备（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].abnormalEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].abnormalEquipmentCount - DateForm[1].abnormalEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].abnormalEquipmentCount - DateForm[1].abnormalEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].abnormalEquipmentCount - DateForm[1].abnormalEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>电池供电异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].batteryEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].batteryEquipmentCount - DateForm[1].batteryEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].batteryEquipmentCount - DateForm[1].batteryEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].batteryEquipmentCount - DateForm[1].batteryEquipmentCount)}}
                  </span>
                  <!-- <i class="el-icon-bottom iconsColor"></i> -->
                  <!-- <i class="el-icon-top iconColor"></i> -->
                </div>
              </div>
              <div class="content-box">
                <span>网络信号异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].signalEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].signalEquipmentCount - DateForm[1].signalEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].signalEquipmentCount - DateForm[1].signalEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].signalEquipmentCount - DateForm[1].signalEquipmentCount)}}
                  </span>
                  <!-- <i class="el-icon-bottom iconsColor"></i> -->
                  <!-- <i class="el-icon-top iconColor"></i> -->
                </div>
              </div>
              <div class="content-box">
                <span>流量异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].flowEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].flowEquipmentCount - DateForm[1].flowEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].flowEquipmentCount - DateForm[1].flowEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].flowEquipmentCount - DateForm[1].flowEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>上线异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].topLineEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].topLineEquipmentCount - DateForm[1].topLineEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].topLineEquipmentCount - DateForm[1].topLineEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].topLineEquipmentCount - DateForm[1].topLineEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>应用异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].applyEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].applyEquipmentCount - DateForm[1].applyEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>

                    <i v-if="DateForm[0].applyEquipmentCount - DateForm[1].applyEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].applyEquipmentCount - DateForm[1].applyEquipmentCount)}}
                  </span>
                </div>
              </div>
              <!-- <div class="content-box">
                <span>HDMI异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].hdmiEquipmentCount}}</span>
                  <span class="Dayword">较昨日
                    <i v-if="DateForm[0].hdmiEquipmentCount - DateForm[1].hdmiEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-else class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].hdmiEquipmentCount - DateForm[1].hdmiEquipmentCount)}}
                  </span>
                </div>
              </div> -->
              <div class="content-box">
                <span>T卡异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].tcardEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].tcardEquipmentCount - DateForm[1].tcardEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-if="DateForm[0].tcardEquipmentCount - DateForm[1].tcardEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].tcardEquipmentCount - DateForm[1].tcardEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>视频生成异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].videoEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].videoEquipmentCount - DateForm[1].videoEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-if="DateForm[0].videoEquipmentCount - DateForm[1].videoEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].videoEquipmentCount - DateForm[1].videoEquipmentCount)}}
                  </span>
                </div>
              </div>
              <!-- <div class="content-box">
                <span>广告播放异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].advertisementEquipmentCount}}</span>
                  <span class="Dayword">较昨日
                    <i v-if="DateForm[0].advertisementEquipmentCount - DateForm[1].advertisementEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-else class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].advertisementEquipmentCount - DateForm[1].advertisementEquipmentCount)}}
                  </span>
                </div>
              </div> -->
              <div class="content-box">
                <span>固件升级异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].firmwareEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].firmwareEquipmentCount - DateForm[1].firmwareEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-if="DateForm[0].firmwareEquipmentCount - DateForm[1].firmwareEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].firmwareEquipmentCount - DateForm[1].firmwareEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>视频通话异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].videoCallEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].videoCallEquipmentCount - DateForm[1].videoCallEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-if="DateForm[0].videoCallEquipmentCount - DateForm[1].videoCallEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].videoCallEquipmentCount - DateForm[1].videoCallEquipmentCount)}}
                  </span>
                </div>
              </div>
              <div class="content-box">
                <span>Flash异常（个）</span>
                <div class="information">
                  <span class="word">{{DateForm[0].flashEquipmentCount}}</span>
                  <span class="Dayword" v-if="DateForm[1]">较昨日
                    <i v-if="DateForm[0].flashEquipmentCount - DateForm[1].flashEquipmentCount > 0"
                      class="el-icon-top iconColor"></i>
                    <i v-if="DateForm[0].flashEquipmentCount - DateForm[1].flashEquipmentCount < 0"
                      class="el-icon-bottom iconsColor"></i>
                    {{Math.abs(DateForm[0].flashEquipmentCount - DateForm[1].flashEquipmentCount)}}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="boxShow" style="width: 100%;height: 56vh;">
          <div style="padding: 20px;">
            <div class="top-header">
              <div>
                <span style="font-weight: 600;font-size: 20px;">今日异常Top100</span>
              </div>
              <div>
                <el-button type="text" size="small" link @click="checks()">查看详情</el-button>
                <!-- <el-button type="primary" size="small">导出记录</el-button> -->
                <el-button type="primary" size="small" :disabled="exportType"
                  @click="exportData()">{{exportTitle}}</el-button>
              </div>
            </div>
            <div>
              <template v-if="dictionarTotal != 0">
                <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData"
                  style="width: 100%;margin: 20px 0;" :height="$store.state.tabHeight-200">
                  <el-table-column align='center' prop="deviceCode" label="AI设备ID" />
                  <el-table-column align='center' label="电梯名称">
                    <template slot-scope="scope">
                      <span style="text-decoration:underline ;color: #409EFF;"
                        @click="checkID(scope.row)">{{scope.row.name}}</span>
                      <!-- <el-link type="primary">{{scope.row.name}}</el-link> -->
                    </template>
                  </el-table-column>
                  <el-table-column align='center' prop="abnormal" label="异常项" />
                </el-table>
              </template>
              <div class="nullDate" v-else v-loading="tableLoading">
                <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 230px;" />
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="boxShow" style="width: 100%;height: 56vh;">
          <div style="padding: 20px;">
            <div style="display: flex;justify-content: space-between;">
              <div style="display: flex;">
                <div :class="nums == 1 ? 'active' : 'tabsType' " @click="check(1)">
                  <span style="font-weight: 600;font-size: 20px;">每日设备统计</span>
                  <div v-if="nums == 1" style="background:rgb(255, 118, 2) ;height: 3px;width: 118px;margin-top: 7px;">
                  </div>
                </div>
                <div :class="nums == 2 ? 'active' : 'tabsType' " @click="check(2)">

                  <span style="font-weight: 600;font-size: 20px;">每日异常分布</span>
                  <div v-if="nums == 2" style="background:rgb(255, 118, 2) ;height: 3px;width: 118px;margin-top: 7px;">
                  </div>
                </div>
              </div>
              <div style="display: flex;align-items: flex-end;">
                <el-button type="text" v-if="nums == 1" link style="margin-right: 10px;"
                  @click="checkInfo(1)">查看详情</el-button>
                <el-button type="text" v-else link style="margin-right: 10px;" @click="checkInfo(2)">查看详情</el-button>
                <el-date-picker v-model="dataTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="checkDate(dataTime)" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </div>
            <!-- {{reFresh}} -->
            <div v-if="nums == 1" style="margin-top: 10px;">
              <Echart v-if="reFresh" :dateArray="dateArray" :echartDate="echartDate"></Echart>
            </div>
            <div v-else style="margin-top: 10px;">
              <EchartOne v-if="reFreshs" :dateArray="dateArray" :echartDate="echartDate"></EchartOne>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <MonitorInfo ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo' :activeNameProps="'dtjk'"
      :elevatorId="elevatorId" :deviceCode="deviceCode" @closeMonitor="closeMonitor" />
    <NonMonitor v-if="MonitorVisible" @close="back()" />
    <DeviceStock v-if="DeviceVisible" :Times="dataTime" :tit="Tit" @close="back()" />
    <AbnormalStock v-if="AbnormalVisible" @close="back()" />
  </div>
</template>

<script>
  import Echart from './components/Echarts1.vue'
  import EchartOne from './components/Echarts2.vue'
  import NonMonitor from './components/NonMonitor.vue'
  import DeviceStock from './components/DeviceStock.vue'
  import AbnormalStock from './components/AbnormalStock.vue'
  import MonitorInfo from '../../dtsj/dtjk/components/monitorInfo.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    components: {
      Echart,
      EchartOne,
      NonMonitor,
      DeviceStock,
      AbnormalStock,
      MonitorInfo
    },
    data() {
      return {
        MonitorInfoVisible: false,
        MonitorVisible: false,
        DeviceVisible: false,
        AbnormalVisible: false,
        tableData: [],
        deviceCode: '',
        elevatorId: '',
        eleInfo: {},
        reFresh: true,
        reFreshs: true,
        dictionarTotal: 0,
        activeName: 'first',
        nums: 1,
        exportType: false,
        exportTypes: false,
        // Listshow:false,
        exportTitle: '导出记录',
        exportTitles: '导出今日报告',
        Tit: '',
        DateForm: [],
        dataTime: [],
        dateArray: [],
        dateArrays: [],
        echartDate: [],
        isRestart: false,
        pickerMinDate: null,
        pickerMaxDate: null,
        tableLoading: true,
        pickerOptions: {
          onPick: ({
            maxDate,
            minDate
          }) => {
            if (minDate) {
              this.pickerMinDate = minDate.getTime();
            }
            if (maxDate) {
              this.pickerMaxDate = maxDate.getTime();
            }
          },
          disabledDate: (time) => {
            const now = Date.now();
            const thirtyDaysAgo = now - 120 * 24 * 60 * 60 * 1000; // 30天的毫秒数
            return time.getTime() > now || time.getTime() < thirtyDaysAgo;
          }
        },

      }
    },
    created() {

      const time1 = new Date().getTime()
      const time2 = new Date().getTime() - 6 * 24 * 60 * 60 * 1000;
      this.dataTime = [time2, time1]
      this.checkDate()
      this.getDate()
      this.getAbnormal()
      // this.getDevice()
    },
    mounted() {},
    methods: {
      // //导出记录
      // exportLists() {
      //   this.Listshow = true
      // },
      //导出
      async exportDatas() {
        this.exportTypes = true
        this.exportTitles = '正在导出'
        const data = {
          current: 1,
          size: 100
        }
        this.$http.post('api/ele/web/export/getDailyTestReport', '', {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            const time = formatDate(new Date(), `yyyy+MM+dd+HH+mm`)
            console.log(time, 'time')
            const time2 = (formatDate(new Date(), `yyyy+MM+dd`)).split('+')
            const time3 = time2[0] + time2[1] + time2[2]
            console.log(time2)
            const time1 = new Date(new Date()).getTime()
            link.download = `设备监测报告${time3}${time1}.xls`; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportTypes = false
          this.exportTitles = '导出今日报告'
        })
      },
      closeMonitor() {
        this.MonitorInfoVisible = false
      },
      checkID(data) {
        this.MonitorInfoVisible = true
        this.elevatorId = data.elevatorId
        this.eleInfo = {
          deviceCode: data.deviceCode
        }
        this.deviceCode = data.deviceCode
      },
      async exportData() {
        this.exportType = true
        this.exportTitle = '正在导出'
        const data = {
          current: 1,
          size: 100
        }
        this.$http.post('/api/ele/web/export/getEleAbnormalTopList', data, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '今日异常Top100.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出记录'
        })
      },
      getMonitor() {
        this.MonitorVisible = true
      },
      back() {
        this.MonitorVisible = false
        this.DeviceVisible = false
        this.AbnormalVisible = false
      },
      checks() {
        this.AbnormalVisible = true
      },
      checkInfo(type) {
        this.DeviceVisible = true
        if (type == 1) {
          this.Tit = "每日设备统计明细"
        } else {
          this.Tit = "每日异常设备统计明细"
        }
      },
      //数据概览
      getDate() {
        this.$http.get('/api/ele/web/overview/getDataOverviewList').then((res) => {
          console.log(res, '数据概览')
          if (res.data.code == 200) {
            this.DateForm = res.data.data
          }
        })
      },
      //今日异常top100
      getAbnormal() {
        const time1 = formatDate(new Date(), 'yyyy-MM-dd 00:00:00')
        const time2 = formatDate(new Date(), 'yyyy-MM-dd 23:59:59')
        const data = {
          current: 1,
          size: 100,
          startTime: new Date(time1).getTime(),
          endTime: new Date(time2).getTime()
        }
        this.tableLoading = true
        this.$http.post('/api/ele/web/abnormal/getEleAbnormalTopList', data).then((res) => {
          console.log(res, 'top100')
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
          }
          this.tableLoading = false

        })
      },
      //每日设备统计明细
      getDevice() {
        let data = {}
        console.log(this.dataTime, 'this.dataTime')
        if (this.dataTime) {
          const time = this.dataTime
          const times = formatDate(new Date(time[1]), 'yyyy-MM-dd 23:59:59')
          console.log(time[0], 'time')
          console.log(times, 'times')
          data = {
            startTime: time[0],
            endTime: new Date(times).getTime()
          }
        } else {
          data = {
            startTime: '',
            endTime: ''
          }
        }
        if (this.nums == 1) {
          this.echartDate = {
            monitoring: [],
            health: [],
            abnormal: []
          }
          this.$http.post('/api/ele/web/overview/getDailyEquipmentStatistics', data).then((res) => {
            if (res.data.code == 200) {
              this.reFresh = false
              this.$nextTick(() => {
                this.reFresh = true
              })
              let data = res.data.data
              for (let i = 0; i < this.dateArrays.length - 1; i++) {
                if (data.length !== this.dateArrays.length) {
                  data.push(1)
                }
              }
              let fog = this.dateArrays
              data.forEach(item => {
                let index = this.dateArrays.findIndex(e => formatDate(new Date(item.time), 'MM-dd') == e, 666)
                if (index > -1) {
                  fog[index] = item
                }
              })
              for (var i = 0; i < fog.length; i++) {
                this.echartDate.monitoring.push(fog[i] && fog[i].monitoringEquipmentCount ? fog[i]
                  .monitoringEquipmentCount :
                  0)
                this.echartDate.health.push(fog[i] && fog[i].healthEquipmentCount ? fog[i].healthEquipmentCount : 0)
                this.echartDate.abnormal.push(fog[i] && fog[i].abnormalEquipmentCount ? fog[i]
                  .abnormalEquipmentCount : 0)
              }

            }
            console.log(this.echartDate, 'this.echartDate')
          })
        } else {
          this.echartDate = {
            battery: [], //电池供电异常数
            signal: [], //网络信号异常
            flow: [], //流量异常数
            topLine: [], //上线异常
            apply: [], //应用异常
            video: [], //视频生成异常
            tcard: [], //T卡异常
            firmware: [], //固件升级异常
            videoCall: [], //视频通话异常
            flash: [], //Flash异常
          }
          this.$http.post('/api/ele/web/overview/getDailyAnomalyDistribution', data).then((res) => {
            this.reFreshs = false
            this.$nextTick(() => {
              this.reFreshs = true
            })
            console.log(res, '异常分布')
            if (res.data.code == 200) {

              let data = res.data.data
              for (let i = 0; i < this.dateArrays.length - 1; i++) {
                if (data.length !== this.dateArrays.length) {
                  data.push(1)
                }
              }
              let fog = this.dateArrays
              data.forEach(item => {
                let index = this.dateArrays.findIndex(e => formatDate(new Date(item.time), 'MM-dd') == e, 666)
                if (index > -1) {
                  fog[index] = item
                }
              })
              for (var i = 0; i < fog.length; i++) {
                console.log(fog[i].topLineEquipmentCount, 'tops')
                this.echartDate.battery.push(fog[i].batteryEquipmentCount ? fog[i].batteryEquipmentCount :
                  0) //电池供电异常数
                this.echartDate.signal.push(fog[i].signalEquipmentCount ? fog[i].signalEquipmentCount : 0) //网络信号异常
                this.echartDate.flow.push(fog[i].flowEquipmentCount ? fog[i].flowEquipmentCount : 0) //流量异常数
                this.echartDate.topLine.push(fog[i].topLineEquipmentCount ? fog[i].topLineEquipmentCount : 0) //上线异常
                this.echartDate.apply.push(fog[i].applyEquipmentCount ? fog[i].applyEquipmentCount : 0) //应用异常
                this.echartDate.video.push(fog[i].videoEquipmentCount ? fog[i].videoEquipmentCount : 0) //视频生成异常
                this.echartDate.tcard.push(fog[i].tcardEquipmentCount ? fog[i].tcardEquipmentCount : 0) //T卡异常
                this.echartDate.firmware.push(fog[i].firmwareEquipmentCount ? fog[i].firmwareEquipmentCount :
                  0) //固件升级异常
                this.echartDate.videoCall.push(fog[i].videoCallEquipmentCount ? fog[i].videoCallEquipmentCount :
                  0) //视频通话异常
                this.echartDate.flash.push(fog[i].flashEquipmentCount ? fog[i].flashEquipmentCount : 0) //flase异常
              }
            }
            console.log(this.echartDate, 'this.echartDate')
          })
        }
      },
      check(type) {
        this.nums = type
        this.getDevice()
      },
      checkDate(value) {
        console.log(value, 'value')
        console.log(this.dataTime, 'value')
        if (value == null && this.dataTime == null) {
          this.dataTime = []
          this.dateArrays = []
          this.dateArray = []
        } else {
          if (value) {
            const time1 = new Date(value[0]).getTime()
            const time2 = new Date(value[1]).getTime()
            this.dataTime = [time1, time2]
            if (value[0] && value[1]) {
              this.dateArray = this.getDateArray(value[0], value[1]);
              this.dateArrays = this.getDateArray(value[0], value[1]);
            } else {
              this.dateArray = [];
              this.dateArrays = [];
            }
          } else {
            // const {start,end}= this.dataTime
            if (this.dataTime[0] && this.dataTime[1]) {
              this.dateArray = this.getDateArray(this.dataTime[0], this.dataTime[1]);
              this.dateArrays = this.getDateArray(this.dataTime[0], this.dataTime[1]);
            } else {
              this.dateArray = [];
              this.dateArrays = [];
            }
          };
        }
        this.getDevice()
        // this.$forceUpdate()
        console.log(this.dateArray, 'dateArray')
      },
      getDateArray(start, end) {
        let dates = [];
        const currentDate = new Date(start);
        const endtime = new Date(end);
        while (currentDate <= endtime) {
          dates.push(formatDate(new Date(currentDate), 'MM-dd'));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .box {
    // background: #FFFFFF;
    // border-radius: 10px;
    // color: #000000;
  }

  .box1 {
    // background: #FFFFFF;
    // border-radius: 10px;
    // color: #000000;
    // margin-right: 15px;
  }

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-timer {
    color: #BBBBBB;
    margin-left: 10px;
    font-size: 14px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 165px;
  }

  .el-button--text {
    color: #409eff !important;
  }

  .content-box {
    width: 270px;
    margin: 10px 0px;
  }

  .information {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .word {
    font-size: 26px;
    min-width: 55px;
    // color: black;
  }

  .Dayword {
    font-size: 14px;
    margin-left: 50px;
    color: #d9d5d5;
  }

  .iconColor {
    color: #63A103;
    font-weight: 600;
  }

  .iconsColor {
    color: #cd030f;
    font-weight: 600;
  }

  .contain-buttom {
    display: flex;
    justify-content: space-between;
  }

  .left {
    width: 650px;
    height: 57vh;
  }

  .right {
    width: 980px;
    height: 52.8vh;
  }

  .tabsType {
    // color: black;
    margin: 0px 10px;
    cursor: pointer;
  }

  .active {
    color: rgb(255, 118, 2);
    margin: 0px 10px;
    cursor: pointer;
  }

  .nullDate {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
