<template>
  <el-dialog
    title="详情"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1200px"
    top="8vh"
    @close="closeDialog"
  >
    <div
      class="admin-project-myproject-container scrollElement"
      style="height: 68vh"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="type == 'tpxq'" label="图片详情" name="tpxq">
          <TpInfo
            :url="info.url"
            :userName="info.userName"
            :info="maintainInfo"
            ref="imgsInfo"
          />
        </el-tab-pane>
        <el-tab-pane v-if="type == 'spxq'" label="视频详情" name="spxq">
          <TpInfo
            :url="info.url"
            :videoList="videoList"
            :userName="info.userName"
            :info="maintainInfo"
            ref="videoInfo"
          />
        </el-tab-pane>
        <el-tab-pane label="维保详情" name="wbxq">
          <MaintenDetails ref="maintenInfo"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
import TpInfo from "./tpInfo";
import MaintenDetails from "./maintenDetails";
export default {
  components: {
    TpInfo,
    MaintenDetails,
  },
  data() {
    return {
      loading: true,
      visible: false,
      addBrandCallback: null, // 弹窗回调
      activeName: "",
      type: "",
      dioData: null,
      maintainInfo: {},
      remarkList: [],
      
      newRemarkList: [],
    };
  },
  props: ["info", "videoList", 'id'],

  mounted() {
    console.log(this.info,'info');
    this.getelevatorInfo();
  },
  created() {
  },

  methods: {
    // 电梯id，回调
    init(type, callback) {
      this.addBrandCallback = callback;
      this.type = type;
      this.activeName = type === "tpxq" ? "tpxq" : "spxq";
      this.visible = true;
      this.loading = false;
      console.log(this.info,'infos')
      this.$nextTick(() => {
        switch (this.activeName) {
          case "tpxq":
            this.$nextTick(() => {
              this.$refs["imgsInfo"].init("image", '', this.info.taskId);
            });
            break;
          case "spxq":
            this.$nextTick(() => {
              this.$refs["videoInfo"].init("video", this.info.url, this.info.taskId);
            });
            break;
          default:
            // this.getelevatorInfo();
            break;
        }
      });
    },

    handleClick(tab, event) {
      switch (this.activeName) {
        case "tpxq":
          this.$nextTick(() => {
            this.$refs["imgsInfo"].init("image");
          });
          break;
        case "spxq":
          this.$nextTick(() => {
            this.$refs["videoInfo"].init("video", this.info.url);
          });
          break;
        case "wbxq":
          this.$nextTick(() => {
            this.$refs["maintenInfo"].init(this.maintainInfo, this.newRemarkList);
          });
          break;
        default:
          break;
      }
    },

    // 获取列表
    getelevatorInfo() {
      // const data = { id: this.info.taskId };
      // console.log(this.info.taskId, 'id');
      const data = { id: this.info.taskId };
      this.$http.post("/api/ele/web/maintainTask/getInfo", data).then((res) => {
        if (res.data.code === 200) {
          const {
            data: { data },
          } = res;
          this.maintainInfo = data;
          this.maintainInfo.point =
            data.latitude && data.longitude
              ? [data.latitude, data.longitude]
              : null;
          let task = data.taskDetails;
          let tasklst = "";
          for (var i = task.length - 1; i >= 0; i--) {
            if (task[i].status == 2) {
              tasklst = task[i].maintainRecord;
              this.maintainInfo.wbImg = task[i].signImg ? JSON.parse(task[i].signImg) : '';
              break;
            } else if (task[i].status == 4) {
              this.maintainInfo.wyImg = task[i].signImg ? JSON.parse(task[i].signImg) : '';
            }
          }
          if (tasklst != "") {
            // this.newRemarkList = JSON.parse(tasklst);
            
            const newTasklst = JSON.parse(tasklst)
            if (newTasklst.data) {
							this.newRemarkList = JSON.parse(newTasklst.data)
							this.newRemarkList.forEach((qs) => {
								qs.statusName =
									qs.status == 1 ? '正常' : qs.status == 2 ? '异常' : '无此项'
							})
            } else {
							this.newRemarkList = newTasklst
							this.newRemarkList.forEach((qs) => {
								qs.statusName =
									qs.status == 1 ? '正常' : qs.status == 2 ? '异常' : '无此项'
							})
            }
            // console.log("this.remarkList", this.remarkList);
            // this.remarkList.forEach((qs) => {
            //   qs.statusName =
            //     qs.status == 1 ? "正常" : qs.status == 2 ? "异常" : "无此项";
            // });
          }
        }
      });
    },
    closeDialog() {
      this.visible = false;
      this.$emit("detailClose");
    },
  },
};
</script>
<style lang="scss" scoped></style>
