<template>
	<div v-loading="loading" style="width: 100%;height: 100%;">
		<div style="width: 100%;height: 100%;">
			<video style="width: 100%;height: 85%;" controls autoplay :id="'onPlayJk'+jkCode" />
			<div class="top-tit">
				<!-- <div>监控人: {{ jkData.name }}( {{ jkData.userPhone }} )</div> -->
				<div>监控人: {{ jkData.name }}( {{ jkData.code }} )</div>
				<div class="czBox">
					<img v-if="phoneType" @click="answer" title="开启通话" src="../../assets/telephone.svg">
					<img v-if="!phoneType" @click="dropped" title="关闭通话" src="../../assets/telephoneOf.svg">
					<img @click="closeJk" title="关闭" src="../../assets/off.svg">
				</div>
			</div>

			<!-- <el-button class="boxTime" v-if="phoneType" icon="el-icon-phone-outline" @click="answer"></el-button> -->
			<!-- <el-button class="boxTime closePhone" v-if="!phoneType" icon="el-icon-phone" @click="dropped"></el-button> -->

			<video v-show="false" style="width: 100px;height: 100px;" controls autoplay :id="jkCode" />
		</div>
	</div>
</template>

<script>
	let one = true
	export default {
		props: ['jkData'],
		data() {
			return {
				// 监控
				player: null,
				loading: true,
				timeWebRtc: null,
				// 通话
				playTime: 0, // 通话时间
				playWebrtc: '',
				//
				phoneType: true,
				meplayer: null,
				jkCode: this.jkData.code
			}
		},
		mounted() {
			setTimeout(() => {
				this.init()
				this.timeWebRtc = setInterval(() => {
					this.opJk()
				}, 5000)
			}, 1000)
		},
		beforeDestroy() {
			console.log('监控组件销毁了code top', this.jkCode)
			let agentData = {
				act: 'ma_stop_rtsp',
				code: this.jkCode,
			}
			this.socketCapsApi.sendCapsSock(agentData, (e) => {

			})
			console.log('监控组件销毁了code', this.jkCode)
			this.dropped()
			this.stop()
		},
		methods: {
			closeJk() {
				console.log('this.jkData1', this.jkCode)
				this.$emit('closeJks', this.jkData)
				console.log('this.jkData2', this.jkCode)
				setTimeout(() => {
					console.log('this.jkData3', this.jkCode)
				}, 2000)
			},
			//
			answer() {
				this.loading = true
				let agentData = {
					act: 'ma_webrtc_call',
					code: this.jkCode
				}
				this.socketCapsApi.sendCapsSock(agentData, (e) => {
					if (e.status && e.cmd == 'ma_webrtc_call') {
						let edata = JSON.parse(e.data)
						let data = {
							recvType: false,
							url: edata.userUrl.push,
							session_id: this.jkCode
						}
						this.meInit(data)
					} else {
						this.loading = false
						this.$message.error(e.msg)
					}
				})
			},
			// 拨打
			meInit(data) {
				if (this.meplayer) {
					this.meplayer.close();
					this.meplayer = null;
				}
				var local = document.getElementById(data.session_id);
				if (local) {
					local.load();
					local.srcObject = null;
				}
				const jswebrtc = document.getElementById(data.session_id)
				console.log('拨打状态', jswebrtc, data)
				const that = this
				that.meplayer = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: data.url, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: true,
					videoEnable: false,
					recvOnly: data.recvType, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480
					}
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
					console.log('ICE 协商出错')
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
					console.log('播放成功', e.streams)
					that.loading = false
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
					e) { // offer anwser 交换失败
					that.dropped()
					that.loading = false
					that.$message.error('拨打失败，请重试！')
					console.log('offer anwser 交换失败', e)
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
					jswebrtc.srcObject = s;
					jswebrtc.muted = true;
					that.phoneType = false
					that.loading = false;
					document.getElementById('alarmA').pause()
					console.log(that.playTime, '获取本地流成功')
					that.playWebrtc = setInterval(() => {
						that.playTime = that.playTime + 1
						if (that.playTime > 10 * 60) {
							that.dropped()
						}
					}, 1000)
					that.$emit('closeJks', that.jkData, 'all')
					that.$message.success('通话开启成功!')
				});
				that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
					that.dropped()
					console.log('获取本地流失败', data)
					that.$message.error('拨打失败，请重试！')
				});

			},
			// 挂断
			dropped() {
				let agentData = {
					act: 'ma_webrtc_stop_call',
					code: this.jkCode
				}
				console.log('agentData', agentData)
				this.socketCapsApi.sendCapsSock(agentData, (e) => {
					if (e.status && e.cmd == 'ma_webrtc_stop_call') {
						this.phoneType = true
						clearInterval(this.playWebrtc)
						this.playTime = 0
						// 报警声音开启
						this.$global.openJK = false
						one = true
						if (this.meplayer) {
							this.meplayer.close();
							this.meplayer = null;
						}
						var local = document.getElementById(this.jkCode);
						console.log('local', local)
						if (local) {
							local.load();
							local.srcObject = null;
							this.$message.success('通话关闭成功!')
						}
					} else {
						this.$message.error(e.msg)
					}
				})

			},
			// 重连
			opJk() {
				this.stop()
				let agentData = {
					act: 'ma_start_rtsp',
					code: this.jkCode
				}
				this.socketCapsApi.sendCapsSock(agentData, (e) => {
					if (e.status) {
						if (e.cmd == 'ma_start_rtsp') {
							this.init()
						}
					} else {
						this.$message.error(e.msg)
					}
				})
			},
			//关闭监控
			stop() {
				clearInterval(this.timeWebRtc)
				if (this.player) {
					this.player.close();
					this.player = null;
					var local = document.getElementById('onPlayJk' + this.jkCode);
					if (local) {
						local.srcObject = null;
						local.load();
					}
				}
			},
			init() {
				const that = this
				const jswebrtc = document.getElementById('onPlayJk' + this.jkCode)
				const openUrl = this.jkData.play_url
				const playUrl = openUrl[openUrl.length - 2]

				this.player = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: playUrl, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: false,
					videoEnable: false,
					recvOnly: true, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480
					}
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
					console.log('ICE 协商出错')
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
					console.log('播放成功', e.streams)
					clearInterval(that.timeWebRtc)
					that.loading = false
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
					e) { // offer anwser 交换失败
					that.loading = false
					console.log('offer anwser 交换失败', e)
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
					jswebrtc.srcObject = s;
					jswebrtc.muted = false;
					that.loading = false
					console.log('获取本地流成功--拉流')
				});
				this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
					console.log('获取本地流失败--拉流')
				});
			}
		}
	}
</script>

<style scoped>
	.czBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 15%;
	}

	.top-tit {
		width: 95%;
		padding: 0 2.5%;
		height: 15%;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #7b7b7b;
	}

	.czBox img {
		width: 35%;
		max-width: 48px;
		min-width: 22px;
		cursor: pointer;
	}

	.conBox {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 6%;
		height: 100%;
	}
</style>
