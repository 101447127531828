<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1100px"
    top="8vh"
    @close="closeDialog"
  >
    <div class="searchBox scrollElement">
      <div class="admin-project-myproject-container">
        <template v-if="tableData != 0">
          <el-table
            ref="multipleTable"
            :data="tableData"
            height="650px"
          >
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column prop="url" label="地址" min-width="150px" align="center"/>
            <el-table-column
              align="center"
              prop="status"
              label="生成状态"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{scope.row.status == 0 ? '生成中' : (scope.row.status == 1 ? '已完成' : '失败')}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="申请时间"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{scope.row.createTime ? scope.row.createTime : '-'}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="address"
              label="操作"
              width="200px"
            >
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.status == 0 || scope.row.status == 2"
                  @click="actionBtn(scope.row.url, 'download')"
                  type="primary"
                  size="small"
                  >下载
                </el-button>
               <!-- <el-button
                  :disabled="scope.row.status == 0 || scope.row.status == 2"
                  @click="actionBtn(scope.row.url, 'detail')"
                  type="primary"
                  size="small"
                  >详情
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="tableTotal"
            :page.sync="searchForm.current"
            :limit.sync="searchForm.size"
            @pagination="maintenRulePage"
          />
        </template>

        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px" />
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      loading: true,
      dialogVisible: true,
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      tableTotal: 0,
    };
  },

  created() {},
  mounted() {
    this.getData();
  },
  destroyed() {},

  methods: {
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.getData();
    },

    //
    actionBtn(url, type) {
      if (type == 'download') {
        window.open(url)
      } else {
        window.open(url, '_blank')
      }
    },

    getData() {
      this.$http
        .get(
          `/api/ele/web/operationalReport/list?type=${2}&current=${this.searchForm.current}&size=${this.searchForm.size}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.tableTotal = data.total ?? 0;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeCheck");
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  margin-top: 20px;
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}

.nullDate {
  width: 100%;
  height: 70vh;
  max-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
