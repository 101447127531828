<template>
  <div style="padding: 0 30px" class="scrollElement">
    <el-descriptions :column="3" border class="descriptionsType" v-if="contractdata.propertyCheckStatus != 0">
        <el-descriptions-item label="物业确认状态">
          {{contractdata.propertyCheckStatus == 1 ? '不通过' : '通过'}}
        </el-descriptions-item>
        <el-descriptions-item label="确认时间">
          {{contractdata.propertyCheckTime}}
        </el-descriptions-item>
        <el-descriptions-item label="确认人员">
          {{contractdata.propertyCheckUserName}}
        </el-descriptions-item>
        <el-descriptions-item label="不通过原因">
          {{contractdata.propertyCheckReason}}
        </el-descriptions-item>
    </el-descriptions>
    
    <el-descriptions :column="3" border class="descriptionsType" v-if="contractdata.maintainCheckStatus != 0">
        <el-descriptions-item label="维保确认状态">
          {{contractdata.maintainCheckStatus == 1 ? '不通过' : '通过'}}
        </el-descriptions-item>
        <el-descriptions-item label="确认时间">
          {{contractdata.maintainCheckTime}}
        </el-descriptions-item>
        <el-descriptions-item label="确认人员">
          {{contractdata.maintainCheckUserName}}
        </el-descriptions-item>
        <el-descriptions-item label="不通过原因">
          {{contractdata.maintainCheckReason}}
        </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" border class="descriptionsType">
        <el-descriptions-item label="维保计划生成">
          {{contractdata.maintainTaskInitStatus == 1 ? '全部生成' : '未生成'}}
        </el-descriptions-item>
        <el-descriptions-item label="结算状态">
          {{contractdata.settlementStatus == 1 ? '已结算' : '未结算'}}
        </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>

export default {
  props: ['contractdata'],
  data() {
    return {
    };
  },
  mounted() {
    
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.descriptionsType{
  margin: 30px 0;
}
/deep/.el-descriptions-item__content {
  width: 210px;
}
</style>
