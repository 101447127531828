<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    width="1200px"
    top="10vh"
    @close="closeDialog"
  >
    <div
      class="searchBox scrollElement"
      style="height: 60vh;overflow-y: scroll;"
    >
      <el-form
        class="formBox"
        ref="elevatorForm"
        :model="elevatorForm"
        :rules="elevatorFormRules"
        label-width="150px"
        label-position="left"
      >
          <el-form-item label="设备号:" label-width="90px" prop="terminalId" >
              <el-input v-model="elevatorForm.terminalId" @input="changeProductName($event)" placeholder="请输入" />
          </el-form-item>
          <div class="parameterBox" v-for="(item, index) in terminalData" :key="index">
              <el-form-item label-width="90px" prop="terminalControlParam" >
              <span slot="label"> {{item.label}} </span>
                  <el-select
                    :disabled="terminalData[index].isDisabled"
                    v-model="elevatorForm.terminalControlParam[index].sosType"
                    placeholder="请选择"
                    @change="changeType($event, terminalData[index].isHightype, index)">
                      <template v-if="terminalData[index].isHightype">
                        <el-option
                          v-for="(item, indexx) in sosHighType"
                          :key="indexx"
                          :label="item.label"
                          :value="Number(item.value)"
                          :disabled="item.disabled"
                        />
                      </template>
                      <template v-else-if="index < 8">
                        <el-option
                          v-for="(item, indexx) in sosLowType"
                          :key="indexx"
                          :label="item.label"
                          :value="Number(item.value)"
                          :disabled="item.disabled"
                        />
                      </template>
                      <template v-else>
                        <el-option
                          v-for="(item, indexx) in sosLowTypeMore"
                          :key="indexx"
                          :label="item.label"
                          :value="Number(item.value)"
                          :disabled="item.disabled"
                        />
                      </template>
                  </el-select>
              </el-form-item>
              <el-form-item label="参数00" label-width="70px" >
                  <el-select
                    :disabled="terminalData[index].isDisabled"
                    v-model="elevatorForm.terminalControlParam[index].paramZero"
                    placeholder="请选择"
                    @change="changeSos($event, index, 0)">
                      <el-option
                          v-for="item in statusTitle"
                          :key="item.value"
                          :label="item.label"
                          :value="Number(item.value)"
                          :disabled="item.value == elevatorForm.terminalControlParam[index].paramOne"
                        />
                  </el-select>
              </el-form-item>
              <el-form-item label="参数01" label-width="70px" >
                  <el-select
                    :disabled="terminalData[index].isDisabled"
                    v-model="elevatorForm.terminalControlParam[index].paramOne"
                    placeholder="请选择"
                    @change="changeSos($event, index, 1)">
                      <el-option
                          v-for="item in statusTitle"
                          :key="item.value"
                          :label="item.label"
                          :value="Number(item.value)"
                          :disabled="item.value == elevatorForm.terminalControlParam[index].paramZero"
                        />
                  </el-select>
              </el-form-item>
              <el-form-item label="是否报警" label-width="80px" >
                  <el-select
                    :disabled="terminalData[index].isDisabled"
                    v-model="elevatorForm.terminalControlParam[index].sosSign"
                    placeholder="请选择">
                      <el-option
                          v-for="(item, index) in terminalData[index].sosTitle"
                          :key="index"
                          :label="item.label"
                          :value="Number(item.value)"
                        />
                  </el-select>
              </el-form-item>
              <el-form-item label="是否启用" label-width="80px" >
                  <el-checkbox
                    :true-label="1"
                    :false-label="0"
                    v-model="elevatorForm.terminalControlParam[index].enableSign"
                    @change="changeSign($event, index)">
                  </el-checkbox>
              </el-form-item>
          </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer"  >
      <el-button
        type="primary"
        @click="confirmForm"
      >确定</el-button>
      <el-button
        type="indo"
        @click="closeDialog"
      >取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
var validateCode = (rule, value, callback) => {
  let operation = /^[0-9]{10,15}$/
  inpYz(rule, value, callback, true, operation, '电梯数据终端ID长度为：10-15位')
};
export default {
  components: {
  },
  props: {
    initData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      visible: false,
      title: '', // 弹窗标题
      eleData: '',
      elevatorForm: {
        terminalControlParam: [{
          sosType: 104,
          // name: '安全回路',
          paramZero: 2, // 参数00
          paramOne: 1, // 参数01
          sosSign: 0, // 报警
          enableSign: 1, // 启用
          sort: 0
        }, {
          sosType: 102,
          // name: '门锁回路',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 1
        }, {
          sosType: 103,
          // name: '抱闸线圈',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 2
        }, {
          sosType: 101,
          // name: '厅门回路',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 3
        }, {
          sosType: 111,
          // name: '检修',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 5
        }, {
          sosType: 105,
          // name: '冲顶',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 6
        }, {
          sosType: 106,
          // name: '蹲底',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 7
        }, {
          sosType: 112,
          // name: '锁梯',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 1,
          sort: 8
        }, {
          sosType: '',
          // name: '预留',
          paramZero: 2,
          paramOne: 1,
          sosSign: 0,
          enableSign: 0,
          sort: 4
        }]
      },
      elevatorFormRules: {
        terminalId: [
          {
            required: false,
            validator: validateCode,
            trigger: 'blur'
          }
        ]
      },
      addBrandCallback: null, // 弹窗回调
      terminalData: [
        {
          label: 'P00(高压)',
          isHightype: true,
          isDisabled: false // 是否禁用
        }, {
          label: 'P01(高压)',
          isHightype: true,
          isDisabled: false // 是否禁用
        }, {
          label: 'P02(高压)',
          isHightype: true,
          isDisabled: false // 是否禁用
        }, {
          label: 'P03(高压)',
          isHightype: true,
          isDisabled: false // 是否禁用
        }, {
          label: 'P34',
          isHightype: false,
          isDisabled: false // 是否禁用
        }, {
          label: 'P37',
          isHightype: false,
          isDisabled: false // 是否禁用
        }, {
          label: 'P20',
          isHightype: false,
          isDisabled: false // 是否禁用
        }, {
          label: 'P21',
          isHightype: false,
          isDisabled: false // 是否禁用
        }, {
          label: 'P04',
          isHightype: false,
          isDisabled: false // 是否禁用
        }
      ],
      sosHighType: [],
      sosLowType: [],
      sosLowTypeMore: [],
      statusTitle: [],
      sosTitle: [{
          label: '动作报警',
          value: 0
        }, {
          label: '断开报警',
          value: 1
        }, {
          label: '不报警',
          value: 2
        }]
    }
  },

  mounted(){
    this.getNotice('公告类型', [
      'HIGH_SOS_PARAM',
      'LOW_SOS_PARAM',
      'DEVICE_OPER_STATUS',
    ])
  },

  methods: {
    // 新增/修改，电梯信息，回调
    init (data, callback) {
      this.title = '数字终端'
      this.addBrandCallback = callback
      this.visible = true
      this.loading = false
      this.eleData = data
      this.getControlParam()
      console.log(this.props, 'this.props.initData');
    },

    changeProductName () {
      // this.$forceUpdate()
    },
    getControlParam () {
      this.$http.get(`/api/ele/web/elevatorInfo/getControlParam?id=${this.eleData.id}`).then((res) => {
        if (res.data.success) {

          const { data: { data } } = res
          const initTerminalControlParam = JSON.parse(data.terminalControlParam)
          const terminalId = data.terminalId ? JSON.parse(JSON.stringify(data.terminalId)) : ''
          // this.elevatorForm.terminalControlParam = initTerminalControlParam
          this.$set(this.elevatorForm, 'terminalId', terminalId)
          this.$set(this.elevatorForm, 'terminalControlParam', initTerminalControlParam)
          this.getTerminalData()
          this.getTimeSos()
        }
      })
    },

    getTerminalData () {
      this.terminalData.forEach((item, index ) => {
        let obj
        let count = index;
        let terminalData = this.elevatorForm.terminalControlParam[index];
        for (let i = 0; i < count + 1; i++){
          obj = this.sosTitle.map((ite, inde) => {
            if (inde == 0) {
              ite.label = `${this.statusTitle.find(data => data.value == terminalData.paramZero).label}报警`
            } else if (inde == 1) {
              ite.label = `${this.statusTitle.find(data => data.value == terminalData.paramOne).label}报警`
            }
            return ite
          })
          //利用JSON转换
          item.sosTitle = JSON.parse(JSON.stringify(obj));
        }
      })
    },
    getTimeSos(){
      this.sosHighType = this.sosHighType.map(item => {
        if (this.elevatorForm.terminalControlParam.find((itm, index) => (itm.sosType == item.value) && this.terminalData[index].isHightype)) {
          item.disabled = true
        } else {
          if (this.elevatorForm.terminalControlParam.find((itm, index) => itm.sosType == '110') && item.label == '安全回路') {
            item.disabled = true
          } else {
            item.disabled = false
          }
        }
        return item
      })
      this.sosLowType = this.sosLowType.map(item => {
        if (this.elevatorForm.terminalControlParam.find((itm, index) => (itm.sosType == item.value) && !this.terminalData[index].isHightype)) {
          item.disabled = true
        } else {
          if (this.elevatorForm.terminalControlParam.find((itm, index) => itm.sosType == '104') && item.label == '安全回路') {
            item.disabled = true
          } else {
            item.disabled = false
          }
        }
        return item
      })
    },

    // 类型修改
    changeType (data, type, index) {
      if (data == 0) {
        this.elevatorForm.terminalControlParam[index].enableSign = 0
      }
      if (type) {
        this.sosHighType = this.sosHighType.map(item => {
          if (this.elevatorForm.terminalControlParam.find((itm, index) => (itm.sosType == item.value) && this.terminalData[index].isHightype)) {
            item.disabled = true
          } else {
            item.disabled = false
          }
          return item
        })
      } else {
        this.sosLowType = this.sosLowType.map(item => {
          if (this.elevatorForm.terminalControlParam.find((itm, index) => (itm.sosType == item.value) && !this.terminalData[index].isHightype)) {
            item.disabled = true
          } else {
            item.disabled = false
          }
          return item
        })
      }
    },

    // 报警修改
    changeSos (data, index, type) {
      this.terminalData.map((item, inde) => {
        if (inde == index) {
          item.sosTitle = item.sosTitle.map(itm => {
            if (itm.value == type) {
              itm.label = `${this.statusTitle.find(item => item.value == data).label}报警`
            }
            return itm
          })
        }
      })
    },

    // 启用报警
    changeSign (data, index) {
      this.terminalData[index].isDisabled = data == 0
      // this.elevatorForm.terminalControlParam[index].sosSign = 2
    },

    // 提交弹框
    confirmForm () {
      console.log(this.elevatorForm, 'this.elevatorForm')
      // this.$refs.elevatorForm.validate(async (valid) => {
      //   if (valid) {
          const terminalControlParam = JSON.parse(JSON.stringify(this.elevatorForm.terminalControlParam))
          const data = {
            id: this.eleData.id,
            terminalId: this.elevatorForm.terminalId,
            terminalControlParam: JSON.stringify(terminalControlParam)
          }
          this.$http.post('/api/ele/web/elevatorInfo/controlParamUpdate', data).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg)
              this.closeDialog()
            }else{
              this.$message.error(res.data.msg)
            }
          })
      //   }
      // })
    },
    closeDialog () {
      this.visible = false
      this.$emit('terminalClose')
    },
    // 获取字典
    getNotice (type, code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.success) {
            const { data: { data } } = res
            this.statusTitle = data.DEVICE_OPER_STATUS
            this.sosHighType = data.HIGH_SOS_PARAM
            this.sosHighType = [...[{ label: '无', value: '0' }], ...data.HIGH_SOS_PARAM]
            this.sosLowType = data.LOW_SOS_PARAM
            this.sosLowTypeMore = [...[{ label: '无', value: '0' }], ...this.sosLowType]
          } else {
            this.$message.error(res.data.msg)
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.formBox {
  width: 94%;
  margin: 0 auto;
}
.parameterBox {
    display: flex;
    .el-form-item{
        margin-right: 15px;
    }
    :last-child{
        margin-right: 0;
    }
}
</style>
