<template>
  <el-dialog
    title="合同确认"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="600px"
    @close="Close"
  >
      <div
      style="height: 25vh; padding-right: 50px; margin-top: 30px"
      class="scrollElement"
    >
          <el-form
            ref="confirmForm"
            :model="confirmForm"
            :rules="confirmRules"
            label-width="150px"
            label-position="right"
          >
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="审核结果:"
                  prop="status"
                >
                  <el-radio-group
                    v-model="confirmForm.status"
                    @change="changeStatus"
                  >
                    <el-radio :label="2">确认通过</el-radio>
                    <el-radio :label="1">不通过</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <el-form-item
                  label="不通过原因:"
                  prop="reason"
                >
                  <el-input
                    :disabled="confirmForm.status == 2"
                    v-model.trim="confirmForm.reason"
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 4, maxRows: 4}"
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>


    <span slot="footer" class="dialog-footer">
      <el-button
        size="small"
        type="primary"
        @click="selectunitConfirm"
        >确 定</el-button
      >
      <el-button size="small" @click="Close">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {
  },
  props: ['contractId'],
  data() {
    return {
      visible: true,
      loading: true,
      confirmForm: {
        status: 2,
        reason: ''
      },
      confirmRules: {
        status: [{
						required: true,
						message: '审核结果不能为空',
						trigger: 'blur'
					}]
        }
    };
  },
  mounted() {
    this.confirmForm.id = this.contractId
  },
  methods: {
    changeStatus () {
      console.log(this.confirmForm.status,'this.confirmForm.status');
      if (this.confirmForm.status == 2) {
        this.confirmForm.reason = ''
      }
    },
    selectunitConfirm() {
      this.$refs.confirmForm.validate(async (valid) => {
        if (valid) {
          if (this.confirmForm.status == 1 && !this.confirmForm.reason) {
            this.$message({
              message: "不通过原因不能为空",
              type: "error",
            });
            return
          }
          console.log(this.confirmForm,'this.confirmForm');
          this.$http.get(`/api/ele/web/maintainContract/checkStatus?status=${this.confirmForm.status}&id=${this.confirmForm.id}&reason=${this.confirmForm.reason}`)
            .then((res) => {
              if (res.data.success) {
                 this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit('close')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
          })
        }
      })
    },

    Close() {
      this.visible = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409eff !important;
  background: #409eff !important;
}
/deep/.el-radio {
  color: #fff !important;
}

/deep/.is-checked .el-radio__label {
  color: #409eff !important;
}
</style>
