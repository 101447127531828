<template>
  <el-dialog
    :title="`${actionBankTitle}${typeBankTitle}银行卡设置`"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="960px"
    top="10vh"
    @close="Close"
  >
    <div class="admin-project-myproject-container">
      <div style="height: 55vh;overflow-y: scroll;" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :model="fign" style="width: 70%; margin: 0 auto;">
          <el-row style="margin: 10px 0">
            <h2>银行卡信息</h2>
          </el-row>
					<el-row style="margin: 10px 0">
							<el-form-item label="公司名称:" prop="orgName">
								<el-input :disabled="isEdit" v-model="fign.orgName" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
					</el-row>
					<el-row style="margin: 10px 0">
							<el-form-item label="银行卡号:" prop="bankNumber">
								<el-input :disabled="isEdit" v-model="fign.bankNumber" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
					</el-row>
					<el-row style="margin: 10px 0">
							<el-form-item label="银行名称:" prop="bankName">
								<el-input :disabled="isEdit" v-model="fign.bankName" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
					</el-row>
					<el-row style="margin: 10px 0">
							<el-form-item label="开户行:" prop="bankOf">
								<el-input :disabled="isEdit" v-model="fign.bankOf" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
					</el-row>
					<el-row style="margin: 10px 0">
            <h2>开票信息</h2>
          </el-row>
					<el-row style="margin: 10px 0">
							<el-form-item label="纳税识别码:" prop="taxCode">
								<el-input :disabled="isEdit" v-model="fign.taxCode" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
					</el-row>
				</el-form>
			</div>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        v-if="!isEdit"
        size="small"
        type="primary"
        @click="selectunitConfirm"
      >确 定</el-button>
      <el-button
        size="small"
        @click="Close"
      >取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {
  },
  props: ['actionBankTitle', 'typeBankTitle', 'initFign', 'isEdit'],
  data () {
    var validateBankNumber = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^\d{16,19}$/,
        "请输入正确的银行卡号(16到19位数字)",
        callback
      );
    };
    return {
      visible: true,
      loading: true,
      selectunitCallback: null,
      fign: {},
      rules: {
        orgName: [
          { required: true, message: "请输入公司名称且不得超过25个字符", max: 25, trigger: "blur"},
        ],
        bankNumber: [
          { required: true, validator: validateBankNumber, trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入银行名称且不得超过25个字符", max: 25, trigger: "blur" },
        ],
        bankOf: [
          { required: true, message: "请输入开户行且不得超过25个字符", max: 25, trigger: "blur" },
        ],
      }
    }
  },
  mounted(){
    this.fign = JSON.parse(JSON.stringify(this.initFign))
  },
  methods: {
    selectunitConfirm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.fign,'this.fign');
          this.$emit('close', this.typeBankTitle, this.fign)
        }
      })
    },


    Close() {
        this.visible = false
        this.$emit('close', this.typeBankTitle, this.initFign)
    },
  },
}
</script>
