<template>
  <div>
    <el-row>
      <el-col :span="14">
        <template v-if="type == 'image'">
          <div class="imgBox">
            <el-image style="width: 100%; height: 60vh" :src="url" fit="contain" />
            <p class="boxTime">拍摄人：{{ userName }}</p>
          </div>
        </template>
        <template v-else>
          <div class="imgBox" v-show="urlVideo">
            <div class="selectVideo">
              <el-select v-model="urlVideo" placeholder="请输入" clearable filterable @change="changeUrl">
                <el-option v-for="(item, index) in videoList" :key="index" :label="item.recordingTime"
                  :value="item.url" />
              </el-select>
            </div>
            <video :src="urlVideo" style="width: 100%; height: 60vh" controls autoplay />
          </div>
          <div v-show="!urlVideo"class="imgs_type">
            <el-image fit="contain" :src="require(`@/assets/noVideo.png`)"
              style="height:400px;width:420px;margin:auto;" />
          </div>
        </template>
      </el-col>
      <el-col :span="10">
        <div class="info" v-if="taskId != 0">
          <p class="title" v-if="info.elevatorInfo">
            <span v-if="info.elevatorInfo.name">{{info.elevatorInfo.name}}
              <span v-if="info.elevatorInfo.maintainTypeName">
                ({{info.maintainTypeName}})
              </span>
            </span>
            <span>{{info.startTime ? info.startTime.split(' ')[0] : ''}}</span>
          </p>
          <p v-if="info.elevatorInfo">
            {{info.elevatorInfo.maintainUnitName}}
            <span v-if="info.elevatorInfo">
              （{{info.elevatorInfo.maintainUserNames}}）
            </span>
          </p>
          <p v-if="info.elevatorInfo">{{info.elevatorInfo.propertyUnitName}}</p>
          <p v-if="info.elevatorInfo">{{info.elevatorInfo.eleAddress}}</p>
          <p>签到时间：<span v-if="info.signTime">{{info.signTime}}</span></p>
          <p>结束时间：<span v-if="info.finishTime">{{info.finishTime}}</span></p>
          <p>维保时长：<span v-if="info.useTime">{{info.useTime}}</span></p>
        </div>
        <div class="info nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 200px" />
        </div>
        <!-- 高德地图 -->
        <!-- <div class="map">
          <Map :height="'30vh'" ref="map" :point="info.point" />
        </div> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import Map from "@/components/map";
  export default {
    components: {
      Map
    },
    data() {
      return {
        loading: true,
        addBrandCallback: null, // 弹窗回调
        type: "image",
        urlVideo: "",
        taskId: ''
      };
    },
    props: ["url", 'userName', "info", "videoList"],

    mounted() {
      console.log(this.videoList,'videoList');
    },
    created() {},

    methods: {
      // 电梯id，回调
      init(type, url, id, callback) {
        this.type = type;
        this.urlVideo = this.videoList[0]?.url ?? ''
        this.taskId = id
      },
      changeUrl(val) {
        this.urlVideo = val
      }
    },
  };
</script>
<style lang="scss" scoped>
  .imgs_type {
    width: 98%;
    height: 60vh;
    background-color: #65809b85;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgBox {
    position: relative;
    overflow: hidden;
    color: #fff;
    margin-right: 15px;

    .boxTime {
      position: absolute;
      top: 0;
      margin: 0;
      background: #343434db;
      padding: 8px;
      font-size: 14px;
      width: 100%;
    }

    .selectVideo {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
  }

  .info {
    background: #65809b85;
    border-radius: 5px;
    color: #fff;
    height: 59vh;
    margin-bottom: 10px;
    padding: 5px 20px;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  .nullDate {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map {
    width: 100%;
    border-radius: 5px;
    height: 30vh;
    margin-top: 15px;
  }
</style>
