<template>
  <div>
  		<!-- <el-progress type="circle" v-show="percentage != 100" :text-inside="true" :stroke-width="26" :percentage="percentage" style="width: 60%; margin: 0 auto; color:#fff"/> -->
        <!-- <div> -->
  		<div v-show="percentage != 100" style="position: relative;">
  			<span class="span-width">
  				<VueCountUp :start-value="0" :end-value="percentage" :duration="1"/>%
  			</span>
  			<img class="rotate" :src="require(`@/assets/img/${percentage}.png`)"  style="width: 75px; height: 75px;">
  		</div>
  
  		<div v-show="percentage == 100" style="width: 88%;height: 100%;">
  			<video style="width: 95%;height: 100%;" controls autoplay id="onPlayJk" />
  			<template v-for="item in meData">
  				<video v-show="false" style="width: 100px;height: 100px;" controls autoplay :id="item.session_id" />
  			</template>
  		</div>
  		<div v-show="percentage == 100" class="conBox">
  			<el-button :disabled='isDial||isble' @click="dialZlv" title="拨打" type="success"
  				style="margin-left: 10px;margin-bottom: 40px;" icon="el-icon-microphone" circle></el-button>
  			<el-button :disabled='!isDial||isble' @click="dropped" title="挂断" type="danger" style="margin-bottom: 40px;"
  				icon="el-icon-turn-off-microphone" circle></el-button>
  			<el-button @click="ladder" title="阻梯" type="warning" icon="el-icon-s-release" circle></el-button>
  		</div>
  </div>
</template>

<script>
	let one = true
	import VueCountUp from 'vue-countupjs'
	export default {
		props: ['jkData', 'percentage', 'id'],
		components: {
			VueCountUp
		},
		data() {
			return {
				isble: false,
				// 监控
				player: null,
				loading: true,
				timeWebRtc: null,
				// 通话
				isDial: false,
				isMuted: true,
				meData: [],
				meObj: null,
				playTime: 0, // 通话时间
				playWebrtc: '',
				type: 1,

				// percentage: 0
			}
		},
		mounted() {
			setTimeout(() => {
				console.log('我是c2')
				this.init()
				this.timeWebRtc = setInterval(() => {
					this.opJk()
				}, 5000)
			}, 1000)
		},
		beforeDestroy() {
			let agentData = {
				act: 'ma_stop_webrtc_call',
				sos_room_id: this.jkData.device_id,
			}
			this.socketApi.sendSock(agentData, (e) => {

			})
			console.log('监控组件销毁了123')
			this.dropped()
			this.stop()
		},
    wacth:{

    },
		methods: {
			// 拨打
			meInit(data) {
				if (this.meplayer) {
					this.meplayer.close();
					this.meplayer = null;
				}
				var local = document.getElementById(data.session_id);
				if (local) {
					local.load();
					local.srcObject = null;
				}
				console.log('拨打状态', data)
				const jswebrtc = document.getElementById(data.session_id)
				const that = this
				that.meplayer = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: false, // 是否打印日志
					zlmsdpUrl: data.url, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: true,
					videoEnable: false,
					recvOnly: data.recvType, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480
					}
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
					console.log('ICE 协商出错')
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
					console.log('播放成功', e.streams)
					that.loading = false
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
					e) { // offer anwser 交换失败
					// that.dropped()
					that.loading = false
					that.$message.error('拨打失败，请重试！')
					console.log('offer anwser 交换失败', e)
				});
				that.meplayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
					jswebrtc.srcObject = s;
					jswebrtc.muted = true;
					document.getElementById('alarmA').pause()
					console.log(that.playTime, '获取本地流成功')
					that.playWebrtc = setInterval(() => {
						that.playTime = that.playTime + 1
						if (that.playTime > 10 * 60) {
							that.dropped()
						}
					}, 1000)
				});
				that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
					that.dropped()
					console.log('获取本地流失败', data)
				});

			},
			dialZlv() {
				// 报警声音关闭
				this.$global.openJK = true
				let agentData = {
					type: 2,
					act: 'ma_webrtc_call',
					sos_room_id: this.jkData.device_id,
					token: sessionStorage.getItem('AdminToken')
				}
				console.log('agentData', agentData)
        console.log(sessionStorage.getItem('me_id'),'me_id1')
				if (sessionStorage.getItem('me_id')) {
					let se_id = JSON.parse(sessionStorage.getItem('me_id')).sessionId
					this.socketApi.sendSock(agentData, (e) => {
						if (e.cmd == 'ma_webrtc_call' && e.room_info != undefined && one) {
							one = false
							this.isMuted = false
							e.room_info.members.forEach((qs, ind) => {
								if (ind != 0) {
									//recvType 拉流为true 推流为false
									let obj = {
										url: qs.play,
										recvType: true,
										session_id: qs.session_id
									}
									if (qs.session_id == se_id) {
										obj.url = qs.push
										obj.recvType = false
										this.meData.push(obj)
										this.meObj = qs.session_id
									}
									// this.meData.push(obj)
								}
							})
							this.isDial = true

							// 按钮禁点2.5秒防止对象未操作就挂断
							this.isble = true
							setTimeout(() => {
								this.isble = false
							}, 2500)


							setTimeout(() => {
								console.log('this.meData2', this.meData)
								this.meData.forEach((qs) => {
									this.meInit(qs)
								})
							}, 1000)
						} else {
							clearInterval(this.timeWebRtc)
						}
					})
				} else {
					this.$message.error('您现在的网络情况不好，请刷新页面再尝试')
					return
				}
			},
			// 挂断
			dropped() {
				this.meData = []
				clearInterval(this.playWebrtc)
				this.playTime = 0
				// 报警声音开启
				this.$global.openJK = false
				one = true
				this.isMuted = true
				this.isDial = false
				if (this.meplayer) {
					this.meplayer.close();
					this.meplayer = null;
				}
				var local = document.getElementById(this.meObj);
				if (local) {
					local.load();
					local.srcObject = null;
				}
			},
			// 阻梯
			ladder() {
				let agentData = {
					act: 'ma_change_ele_status',
					deviceId: this.jkData.device_id,
					type: this.type
				}
				this.socketApi.sendSock(agentData, (e) => {
					if (e.cmd == 'ma_change_ele_status' && e.status) {
						this.$message.success(e.msg)
						this.type = this.type == 1 ? 2 : 1
					} else {
						this.$message.error(e.msg)
					}
				})
			},
			// 重连
			opJk() {
				this.stop()
				let agentData = {
					act: 'ca_start_rtsp',
					device_id: this.jkData.device_id
				}
				console.log('agentData', agentData)
				this.socketApi.sendSock(agentData, (e) => {
					if (e.status) {
						if (e.hb) {
							this.$emit('changePercentage', 25)
						}
						if (e.cmd == 'ca_start_rtsp') {
							this.$emit('changePercentage', 50)
							this.getStreamStatus()
						}
					} else {
						this.$message.error(e.msg)
					}
				})
			},

			getStreamStatus() {
				this.$http
				.get(`api/ele/web/getStreamStatus?id=${this.id}`)
				.then((res) => {
					if (res.data.success) {
						this.$emit('changePercentage', res.data.data ? 75 : 50)
						this.init()
					} else {
					this.$message.error(res.data.msg)
					}
				})
			},
			//关闭监控
			stop() {
				clearInterval(this.timeWebRtc)
				if (this.player) {
					this.player.close();
					this.player = null;
					var local = document.getElementById('onPlayJk');
					if (local) {
						local.srcObject = null;
						local.load();
					}
				}
			},
			init() {
				const that = this
				const jswebrtc = document.getElementById('onPlayJk')
				const openUrl = this.jkData.play_url
				const playUrl = openUrl[openUrl.length - 2]

				this.player = new ZLMRTCClient.Endpoint({
					element: jswebrtc, // video 标签
					debug: true, // 是否打印日志
					zlmsdpUrl: playUrl, //流地址
					simulcast: false,
					useCamera: false,
					audioEnable: false,
					videoEnable: false,
					recvOnly: true, //拉流为true 推流为false
					resolution: {
						w: 640,
						h: 480
					}
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function(e) { // ICE 协商出错
					console.log('ICE 协商出错')
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function(e) { //获取到了远端流，可以播放
					console.log('播放成功', e.streams)
					clearInterval(that.timeWebRtc)
					that.loading = false
					that.$emit('changePercentage', 100)
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function(
					e) { // offer anwser 交换失败
					console.log('offer anwser 交换失败', e)
				});
				this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) { // 获取到了本地流
					jswebrtc.srcObject = s;
					jswebrtc.muted = false;
					console.log('获取本地流成功--拉流')
				});
				this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) { // 获取本地流失败
					console.log('获取本地流失败--拉流')
				});
			}
		}
	}
</script>

<style scoped>
	.conBox {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 6%;
		height: 100%;
	}
  .span-width{
    position: absolute;
    top: calc(50% - 16px);
	left: calc(50% - 50px);
	font-size: 26px;
	font-weight: 600;
	color:#052e54;
	width: 100px;
	line-height: 32px;
	text-align: center;
	z-index: 999;
  }
  .rotate{
	animation: rotate 2s infinite;
  }
  /* 定义旋转动画的关键帧 */
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	/deep/.el-progress-bar__innerText{
		color: #fff !important;
	}
</style>
