<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="checkPage">
      <el-tab-pane label="关联单位" name="first"> <UnitRelated ref="UnitRelated" v-if="activeName == 'first'"/> </el-tab-pane>
      <el-tab-pane label="关联人员" name="second"> <PersonRelated ref="PersonRelated" v-if="activeName == 'second'"/> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import UnitRelated from './components/UnitRelated.vue'
  import PersonRelated from './components/PersonRelated.vue'
  export default {
    components:{
      UnitRelated,
      PersonRelated
    },
    data(){
      return{
        activeName: 'first'
      }
    },
    methods:{
      checkPage(tab,event){
        if(tab.name == 'first'){
          this.UnitRelated();
        }else{
          this.PersonRelated();
        }
      },
      //人员信息
      PersonRelated(){
        this.$nextTick(()=>{
          this.$refs.PersonRelated.init((refresh)=>{
            if(refresh){}
          })
        })
      },
      //单位信息
      UnitRelated(){
        this.$nextTick(()=>{
          this.$refs.UnitRelated.init((refresh)=>{
            if(refresh){}
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-tabs__item{
    font-size: 20px;
  }
  /deep/.el-form-style .el-form-item{
    margin-right: 20px;
  }
  /deep/.el-form-style .el-input{
    width: 250px;
  }
</style>
