<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">位置</span>
          </span>
          <el-select v-model="searchForm.location" placeholder="请输入" clearable>
            <el-option v-for="(item, index) in positionArr" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">展示状态</span>
          </span>
          <el-select v-model="searchForm.showType" placeholder="请输入" clearable>
            <el-option label="展示" value="1" />
            <el-option label="不展示" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" v-focus @click="addDj">新增</el-button>
      <template v-if="noticeTotal != 0">
        <el-table ref="multipleTable" :data="tableData" v-loading="loading" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="名称" min-width="250px" />
          <el-table-column align="center" label="位置" width="100px">
            <template slot-scope="scope">
              {{
                scope.row.location
                  ?( positionArr.find((item) => item.value == scope.row.location)
                    ? positionArr.find((item) => item.value == scope.row.location)
                        .label
                    : "-")
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="imageUrl" label="跳转地址" min-width="200px" />
          <el-table-column align="center" prop="ordinal" label="序号" min-width="100px" />
          <el-table-column align="center" prop="status" label="展示状态" min-width="100px">
            <template slot-scope="scope">
              {{scope.row.showType == 0 ?  '不展示' : '展示' }}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
          <el-table-column fixed="right" align="center" label="操作" class="fixedRight" width="300px">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" type="warning" size="small" v-focus>编辑
              </el-button>
              <el-button v-if="scope.row.showType == 0" @click="del(scope.row.id)" type="danger" size="small" v-focus>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="noticeTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="noticePage" />
      </template>
      <div class="nullDate" v-else v-loading="loading">
        <img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px" />
      </div>
    </div>
    <Action v-if="actionVisibile" ref="actionVisibile" :tit="tit" :positionArr="positionArr" :actionForm="actionForm"
      @updateList="onRefer" @closeAction="closeAction" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import Action from './components/action.vue'
  export default {
    components: {
      Pagination,
      Action
    },
    data() {
      return {
        // 表格参数
        loading:true,
        searchForm: {
          current: 1,
          size: 10,
          name: '',
          location: '',
          showType: ''
        },
        tableData: [],
        // 分页参数
        noticeTotal: 0,
        tit: '',
        actionForm: {},
        actionVisibile: false,
        positionArr: []

      }
    },
    methods: {
      // 分页
      noticePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      searchOnRefer() {
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
          name: '',
          location: '',
          showType: ''
        }
        this.onRefer()
      },

      // 获取公告
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))
        this.$http.post('/api/system/web/rotateImg/getRotateImg', data).then((res) => {

          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.noticeTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.loading = false
        })
      },

      addDj() {
        this.tit = '新增banner'
        this.actionForm = {
          showType: 1
        }
        this.actionVisibile = true
      },
      // 修改公告
      edit(item) {
        this.tit = '编辑banner'
        this.actionForm = item
        this.actionVisibile = true
        // const data = { id: item.id }
        // this.$http.post('/api/system/web/rotateImg/getRotateImg', data)
        //     .then((res) => {
        //       if (res.data.success) {
        //         this.actionForm = item
        //         this.actionVisibile = true
        //       } else {
        //         this.$message.error(res.data.msg)
        //       }
        //     })
      },
      closeAction() {
        this.actionVisibile = false
      },

      // 查看电梯明细
      del(id) {
        this.$confirm('是否继续当前操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const data = {
            id
          }
          this.$http
            .post('api/system/web/rotateImg/deletedRotateImg', data)
            .then((res) => {
              if (res.data.success) {
                if (this.tableData.length === 1) {
                  this.searchForm.current -= 1
                }
                this.onRefer()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
      },

      infoElevatorDialogClose() {
        this.noticeForm = {}
        this.dialogVisible = false
      },

      // 获取字典数据
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.positionArr = data.BANNER_TYPE
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },


    },
    created() {
      this.getNotice('BANNER_TYPE')
      this.onRefer()
    }
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .wid90 {
    width: 90%;
  }

  /deep/.el-dialog__header span {
    color: #00f6ff !important;
    width: 80%;
    text-align: center;
  }
</style>
