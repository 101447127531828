<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">图片名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">拍摄时间</span>
          </span>
          <el-date-picker
            v-model="searchForm.allTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="total != 0">
        <el-row>
          <template v-for="(item, index) in tableData">
            <el-col :span="8" :key="index" class="col20">
              <div @click="openImg(item)">
                <div class="imgBox">
                  <el-image
                    style="width: 100%; height: 180px"
                    :src="item.url"
                    fit="cover"
                  />
                  <p class="boxTime">拍摄时间：{{ item.shootingTime }}</p>
                </div>
                <h5>{{ item.name }}</h5>
              </div>
            </el-col>
          </template>
        </el-row>
        <Pagination
          :total="total"
          :layout="'total, sizes, prev, pager, next'"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="videoRulePage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
      <el-dialog
        :visible.sync="imageUrlVisible"
        :title="tit"
        append-to-body
        width="700px"
        top="8vh"
        @close="closeDialog"
      >
        <div
          style="height: 50vh; overflow-y: scroll; padding: 20px"
          class="scrollElement"
        >
          <el-image
            fit="contain"
            :src="ImageUrl"
            style="width: 100%; height: 100%"
            alt
          />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  props: ["useId"],
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
        type: "",
      },
      tableData: [],
      // 分页参数
      total: 0,

      imageUrlVisible: false,
      ImageUrl: "",
      tit: ''
    };
  },
  filters: {},
  methods: {
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },
    // 图片
    videoRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },
    // 打开图片
    openImg(row) {
      console.log(row, "openImg");
      this.ImageUrl = row.url;
      this.tit = row.name
      this.imageUrlVisible = true;
    },
    // 关闭图片
    closeDialog() {
      this.imageUrlVisible = false;
    },

    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      data.userId = this.useId;
      data.start = this.searchForm.allTime
        ? this.searchForm.allTime[0].getTime()
        : "";
      data.end = this.searchForm.allTime
        ? this.searchForm.allTime[1].getTime() + 24 * 60 * 60 * 1000 - 1000
        : "";
      this.$http.post("/api/cap/web/images/getCapImages", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.tableData = data.records ?? [];
          this.total = data.total ?? 0;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

  },
  created() {},
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
h4 {
    margin: 0;
    font-size: 15px;
  }
.mapBox {
  height: 50vh;
  background: #000;
  margin-left: 20px;
  border-radius: 5px;
}

.searchBtn {
  margin-left: 15px;
}
.col20 {
  padding: 0 20px;
  color: #fff;
  margin-bottom: 10px;
  .imgBox {
    position: relative;
    overflow: hidden;
    .boxTime {
      position: absolute;
      bottom: 2px;
      margin: 0;
      background: #5c5c5cdb;
      padding: 8px;
      font-size: 14px;
      width: 100%;
    }
  }
  h5 {
    margin: 10px 0;
    font-size: 14px;
    height: 40px;
  }
}
/deep/.el-dialog__title{
  width: 70%;
  text-align: center;
  line-height: 32px;
}
</style>
