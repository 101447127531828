<template>
    <el-dialog
        custom-class="custom-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>人员名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>所属单位:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="orgName"
                        clearable>
                    </el-input>
                </div>
                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                stripe
                ref="multipleTable"
                height="500px"
                style="width: 100%">
                    <el-table-column type="index" label="序号" width="50px" />
                    <el-table-column align="center" prop="name" label="人员名称" min-width="200px" />
                    <el-table-column align="center" prop="orgName" label="所属单位" />
                    <el-table-column align="center" prop="createTime" label="添加时间" width="200px" />
					<el-table-column align="center" prop="createUserName" label="创建人" />
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

       
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
export default {
    props:{
        dialogTitle:String,
        start:Number|String,
        end:Number|String,
        openType:Number|String,
    },
    components: {
        Pagination,
    },
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            orgName:'',
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,

        
        }
    },
    computed:{
        params(){
            return {
                current:this.current,
                size:this.size,
                name:this.name,
                orgName:this.orgName,
                // start:this.start,
                // end:this.end,
                type:"*MAINTAIN_UNIT*"
            }
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog',{
                openType:this.openType
            })
        },
        reset(){
            this.name=''
            this.orgName=''
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },


        getTable(){
            this.loading=true
            this.$http.post('/api/system/web/appUser/list', this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                }
                this.loading=false
            })
        },

        

    },
    created(){
        this.getTable()
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    // overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>